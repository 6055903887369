import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import { TeamInfoDto } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { AlertWrapper, Form, InputAdornmentWrapper } from "./styles";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { useTeamQuery, useTeamUpdateMutation } from "shared/api/team";
import { CTAButton, CopyToClipboardButton, FormTextField } from "components";
import { useSnackbar } from "notistack";

const validationSchema = () =>
  Yup.object({
    hostname: Yup.string().nullable(),
  });

const initialValues: Pick<TeamInfoDto, "hostname"> = {
  hostname: null,
};

const useStyles = makeStyles({
  root: {
    minWidth: "100%",
    width: "fit-content",
    "& .MuiInputBase-root": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  input: {
    width: "fit-content",
  },
});

const HostnameForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { selectedTeamId } = useTeam();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(validationSchema()),
  });

  const { data } = useTeamQuery(selectedTeamId);
  const updateTeam = useTeamUpdateMutation();

  const hostnameValue = watch("hostname");

  useEffect(() => {
    if (data) {
      reset({
        hostname: data.hostname,
      });
    }
  }, [data]);

  const onSubmit = async (values: typeof initialValues) => {
    if (!values.hostname) {
      return;
    }
    try {
      await updateTeam.mutateAsync({
        teamId: selectedTeamId,
        team: {
          hostname: values.hostname,
        },
      });
      reset();
      enqueueSnackbar(
        t("pages.settings.tabs.team-security.information.updated"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("pages.settings.tabs.team-security.information.error"),
        {
          variant: "error",
        }
      );
    }
  };

  const buttonDisabled = !isDirty || !hostnameValue;

  const hostName = useMemo(() => {
    const hostNameParts = window.location.hostname.split(".");
    if (hostNameParts.length > 3) {
      return hostNameParts.slice(-3).join(".");
    } else {
      return hostNameParts.join(".");
    }
  }, [data]);

  return (
    <CardWrapper>
      <CardHeaderWrapper
        title={t("pages.settings.tabs.team-security.form.hostname.title")}
        subTitle={t(
          "pages.settings.tabs.team-security.form.hostname.description"
        )}
      />
      <AlertWrapper severity="warning">
        <Trans i18nKey="pages.settings.tabs.team-security.form.hostname.warning" />
      </AlertWrapper>
      <Form name="hostnameForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormTextField
          control={control}
          disabled={!!data?.hostname}
          classes={{ root: classes.root }}
          name="hostname"
          InputProps={{
            classes: { input: classes.input },
            endAdornment: (
              <InputAdornmentWrapper position="end">
                <span>{`.${hostName}`}</span>
                <CopyToClipboardButton
                  content={`${data?.hostname as string}.${hostName}`}
                />
              </InputAdornmentWrapper>
            ),
          }}
          label={t("pages.settings.tabs.team-security.form.hostname.title")}
        />
        {!data?.hostname && (
          <CTAButton
            label="updateBtn"
            name={t("pages.settings.tabs.backup.form.submit")}
            size="stretched"
            type="submit"
            disabled={buttonDisabled}
          />
        )}
      </Form>
    </CardWrapper>
  );
};

export default HostnameForm;
