import { Field, Link } from "./styles";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";

export type CopyFieldProps = {
  value: string;
  disabled?: boolean;
  valueAsLink?: boolean;
};

const CopyField = ({ value, disabled, valueAsLink }: CopyFieldProps) => {
  return (
    <Field disabled={disabled} aria-label="copy-field">
      {valueAsLink ? (
        <Link href={`mailto:${value}`}>{value}</Link>
      ) : (
        <span>{value}</span>
      )}
      <CopyToClipboardButton content={value} />
    </Field>
  );
};

export default CopyField;
