import styled from "@emotion/styled";
import { MenuItem as MUIMenuItem } from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled.div`
  [data-testid="jobs-status"] {
    border: 1px solid orange;
  }

  .flatfile_displayAsModal {
    width: 100% !important;
    height: 100% !important;
    padding: 48px !important;
    padding-bottom: 24px !important;
  }

  .flatfile_iFrameContainer {
    height: calc(100% - 6px) !important;
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.md};
  color: ${theme.color.gray[700]};
  transition: background-color 0.25s ease-in-out;

  :first-of-type:hover {
    border-top-left-radius: ${theme.borderRadius};
    border-top-right-radius: ${theme.borderRadius};
  }

  :last-of-type:hover {
    border-bottom-left-radius: ${theme.borderRadius};
    border-bottom-right-radius: ${theme.borderRadius};
  }

  :hover {
    background-color: ${theme.color.blue[700]}14;
  }
`;
