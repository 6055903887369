import { useTranslation } from "react-i18next";
import { Wrapper, List, ListItem, IconWrapper, Name } from "./styles";

import ContractIcon from "assets/svg/documents-icon.svg?react";
import TasksIcon from "assets/svg/clipboard-check.svg?react";
import SignatureIcon from "assets/svg/pen-tool.svg?react";
import LogIcon from "assets/svg/clock-rewind.svg?react";
import NodeIcon from "assets/svg/dataflow.svg?react";
import AiIcon from "assets/svg/ai.svg?react";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/";

export type TabProps = {
  id: number;
  key: string;
};

type StickyMenuProps = {
  currentSelectedTab: TabProps;
  handleSelectedTab: (val: number, key: string) => void;
  provider?: string;
};

export const options = [
  {
    id: 1,
    key: "documents",
    icon: ContractIcon,
    color: "var(--color-primary)",
  },
  {
    id: 2,
    key: "summary",
    icon: AiIcon,
    color: "var(--color-base-analysis)",
  },
  {
    id: 3,
    key: "tasks",
    icon: TasksIcon,
    color: "var(--color-base-tasks)",
  },
  {
    id: 4,
    key: "esignature",
    icon: SignatureIcon,
    color: "var(--color-base-signature)",
  },
  {
    id: 5,
    key: "related",
    icon: NodeIcon,
    color: "var(--color-base-related)",
  },
  {
    id: 6,
    key: "log",
    icon: LogIcon,
    color: "var(--color-base-logs)",
  },
] as const;

const StickyMenu = ({
  currentSelectedTab,
  handleSelectedTab,
  provider,
}: StickyMenuProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useTeam();

  const hiddenButtons: string[] = [];

  if (!provider) {
    const item = options.find((item) => item.key === "esignature");
    if (item) {
      hiddenButtons.push(item?.key);
    }
  }

  const availableOptions = options.filter(
    (item) => !hiddenButtons.includes(item.key)
  );

  return (
    <>
      <Wrapper>
        <List>
          {availableOptions.map((item) => {
            const Icon = item.icon;
            // @TODO: Show only the tabs which are available for current user (based on permissions system).
            if (item.key === "summary" && !hasFeature(Features.AI_SUMMARY))
              return null;
            return (
              <>
                <ListItem
                  key={item.key}
                  aria-label={`${item.key}-tab-option`}
                  active={currentSelectedTab.id === item.id}
                  onClick={() => handleSelectedTab(item.id, item.key)}
                  color={item.color}
                >
                  <IconWrapper color={item.color}>
                    <Icon />
                  </IconWrapper>
                  {/* @Todo: Add a tooltip in for long names */}
                  <Name color={item.color} className="list-item-name">
                    {t(`pages.contractDetails.folders.${item.key}`)}
                  </Name>
                </ListItem>
              </>
            );
          })}
        </List>
      </Wrapper>
    </>
  );
};

export default StickyMenu;
