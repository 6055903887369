import styled from "@emotion/styled";
import Icon from "assets/svg/fancy-ai-icon.svg?react";
import { theme } from "theme";

const iconSize = "24px";

export const Wrapper = styled.div`
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

export const Header = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  width: 100%;
  min-width: 0;

  background: var(--gradient-background);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  span {
    font-size: ${theme.font.size.subheading};
    font-weight: 400;
    position: relative;

    background: var(--gradient-background);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
`;

export const AiIcon = styled(Icon)`
  width: ${iconSize};
  height: ${iconSize};
  flex-shrink: 0;
`;

export const Divider = styled.div`
  flex: 1;
  min-width: 1px;
  height: 1px;
  background: var(--color-neutral-200);
`;

export const Container = styled.div`
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  background-color: ${theme.color.purple[100]};
  height: 100%;
  overflow: auto;
`;
