import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Event as EventIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  ArrowRightAlt as ArrowRightAltIcon,
  ArrowRight as ArrowDropDownIcon,
} from "@mui/icons-material";
import { Accordion } from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { theme } from "theme";
import { avatarsInfo, contactInfo } from "./content";
import * as Styled from "./styles";

const iconsStyles = {
  fontSize: theme.font.size.subheading,
  marginRight: theme.spacing.xs,
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon />}
    style={{ minHeight: "36px" }}
    {...props}
  />
))(() => ({
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    marginTop: "0.5rem",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  padding: 0,
  minHeight: 0,
  backgroundColor: "#e9f3ff",
}));

export const GroupAvatars = () => (
  <Styled.AvatarGroup>
    {avatarsInfo.map((avatar) => (
      <Styled.Avatar key={avatar.alt} src={avatar.src} alt={avatar.alt} />
    ))}
  </Styled.AvatarGroup>
);

const ContactUs = ({
  expanded,
  hasExpandedIcon = true,
  ...props
}: {
  expanded?: boolean;
  hasExpandedIcon?: boolean;
  style?: React.CSSProperties;
  isOpen?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Wrapper {...props} data-testid="contact-us">
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={
            hasExpandedIcon ? <ArrowDropDownIcon fontSize="large" /> : null
          }
          aria-controls="contact-us-content"
          id="contact-us-header"
        >
          <Styled.Title
            style={{ marginLeft: !hasExpandedIcon ? theme.spacing.sm : "auto" }}
            data-testid="contact-us-title"
          >
            <>{t("navbar.contactUs.header")}</>
            <GroupAvatars />
          </Styled.Title>
        </AccordionSummary>
        <Styled.Details>
          <Styled.Link
            href={`tel:${contactInfo.phone.value}`}
            data-testid="contact-us-phone"
          >
            <PhoneIcon sx={iconsStyles} />
            {contactInfo.phone.label}
          </Styled.Link>

          <Styled.Link
            href={`mailto:${contactInfo.email}`}
            data-testid="contact-us-email"
          >
            <EmailIcon sx={iconsStyles} />
            {contactInfo.email}
          </Styled.Link>

          <Styled.EventLink
            href={contactInfo.calendar}
            target="_blank"
            rel="noreferrer"
            data-testid="contact-us-cta"
          >
            <EventIcon sx={iconsStyles} />
            <span>{t("navbar.contactUs.callToAction")}</span>
            <ArrowRightAltIcon fontSize="small" />
          </Styled.EventLink>
        </Styled.Details>
      </Accordion>
    </Styled.Wrapper>
  );
};

export default ContactUs;
