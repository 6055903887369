import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton } from "@mui/material";
import {
  OrganizationService,
  RoleDTO,
  TeamInvitationRequestDto,
  RoleCreateDTO,
  PermissionCreateDTO,
  RoleUpdateDTO,
  TeamDTOV1,
  TeamDTO,
  CategoryDTO,
} from "openapi";
import { BasicTable, NewModal, Loader, CTAButton } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { Header } from "components/BasicTable/types";
import { useTeam } from "contexts/team/hooks";
import DeleteRoleModal from "./DeleteRoleModal";
import { useRolesQuery } from "shared/api";
import RoleForm, { ROLE_FORM_ID } from "./RoleForm";
import { validationSchema, generatePrefilledCategories } from "./helpers";
import { PermissionType, FormType } from "./types";
import { ActionButtons } from "../../styles";
import { DeleteIcon, EditIcon, AddRoleIcon, Wrapper } from "./styles";
import TeamRoleMembers from "./TeamRoleMembers";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";

const getInitialValues = (
  id: string,
  roleName: string,
  teamId: string,
  permissions: PermissionType[]
): FormType => {
  return {
    id: id || "",
    roleName: roleName || "",
    teamId: teamId || "",
    roleType: TeamInvitationRequestDto.role.LIMITED_USER,
    permissions: permissions.map((permission) => ({
      ...permission,
      level: permission.level || "NO_ACCESS",
    })),
  };
};

type TeamInfoType = {
  teams: TeamDTO[] | TeamDTOV1[] | undefined;
};

const TeamRoles = ({ teamInfoData }: { teamInfoData?: TeamDTO }) => {
  const { t } = useTranslation();
  const { parentTeamId, organizationId } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
  const [isNewRoleModalOpen, setIsNewRoleModalOpen] = useState(false);
  const [currentRoleId, setCurrentRoleId] = useState("");
  const [roleToDelete, setRoleToDelete] = useState<string>("");
  const methods = useForm<FormType>({
    resolver: yupResolver(validationSchema()),
  });
  const {
    setValue,
    getValues,
    watch,
    reset,
    formState: { isValid },
  } = methods;

  const { data: rolesData, refetch: refetchRoles } =
    useRolesQuery(parentTeamId);

  const teamIdInput = watch("teamId");
  const permissions = watch("permissions");

  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (data) =>
      data.filter((category) => categoriesTeamSelector(category, teamIdInput))
  );

  const {
    data: newTeamsInfo,
    isLoading: newTeamsLoading,
    refetch: refetchAllTeams,
  } = useOrganizationAllTeamsQuery(organizationId);

  useEffect(() => {
    if (currentRoleId) {
      void OrganizationService.getRole(currentRoleId, parentTeamId).then(
        (roleData) => {
          reset({
            id: roleData.id,
            roleName: roleData.name,
            teamId: roleData.teamId,
            permissions: roleData.permissions,
          });
        }
      );
    }
  }, [currentRoleId]);

  useEffect(() => {
    if (categories && categories?.length > 0) {
      const prefilledCategories = generatePrefilledCategories(
        categories,
        getValues().permissions
      );
      if (prefilledCategories) {
        setValue("permissions", prefilledCategories);
      }
    }
  }, [categories, permissions]);

  useEffect(() => void refetchAllTeams(), [teamInfoData]);

  if (newTeamsLoading) return <Loader />;

  const teamsInfo: TeamInfoType = {
    teams: newTeamsInfo,
  };

  // Note: Combine the permissions with the categories and filter out the ones that are undefined / no access.
  const getPermissionsRequestBody = (
    categories: CategoryDTO[],
    permissions: PermissionType[]
  ): PermissionCreateDTO[] => {
    const defaultOption = "NO_ACCESS";
    return categories
      .map((category, index) => {
        const permission = permissions[index];
        return {
          categoryId: category.id,
          level: (permission?.level ??
            defaultOption) as PermissionCreateDTO.level,
        };
      })
      .filter(({ level }: { level: string }) => level !== defaultOption);
  };

  /**
   * @Note:
   * As we currently mirror the categories and they are not yet the same in
   * all teams, that means we need to manually get the categories that are mirrored from
   * the organization team.
   * */

  const handleCreateRole = async () => {
    const teamId = getValues("teamId");
    const roleName = getValues("roleName");
    const permissions = getPermissionsRequestBody(
      categories ?? [],
      getValues("permissions")
    );

    // @TODO: Use errors instead, from react hook form.
    if (!roleName || !teamId) return;

    try {
      await OrganizationService.createRole(parentTeamId, {
        teamId: teamId,
        name: roleName,
        permissions: permissions,
      } as RoleCreateDTO);

      void refetchRoles();
      handleCloseCreateRoleModal();

      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.create.successMessage"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.create.errorMessage"),
        {
          variant: "error",
        }
      );
      Sentry.captureException(error);
    }
  };

  const handleEditRole = async () => {
    const teamId = getValues("teamId");
    const roleName = getValues("roleName");
    const rolePermissions = getPermissionsRequestBody(
      categories ?? [],
      getValues("permissions")
    );

    // @TODO: Use errors instead, from react hook form.
    if (!roleName || !teamId) return;

    try {
      await OrganizationService.updateRole(currentRoleId, parentTeamId, {
        name: roleName,
        permissions: rolePermissions,
      } as RoleUpdateDTO);

      void refetchRoles();
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.edit.successMessage"),
        {
          variant: "success",
        }
      );

      void refetchRoles();
      handleCloseEditRoleModal();
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.team.teamRoles.modals.edit.errorMessage"),
        {
          variant: "error",
        }
      );
      console.error(error);
    }
  };

  const handleCloseEditRoleModal = () => {
    setCurrentRoleId("");
    reset(getInitialValues("", "", "", []));
  };

  const handleCloseCreateRoleModal = () => {
    setIsNewRoleModalOpen(false);
    reset();
  };

  const getTeamName = (role: RoleDTO): string | null => {
    if (teamsInfo && teamsInfo.teams) {
      const team = (teamsInfo.teams as (TeamDTO | TeamDTOV1)[]).find(
        (team) => team.id === role.teamId
      );
      if (team && "name" in team) {
        return team.name;
      }
    }
    return null;
  };

  const tableHeaders = [
    {
      key: "teamName",
      name: t("pages.settings.tabs.team.teamRoles.table.headers.teamName"),
    },
    {
      key: "roleName",
      name: t("pages.settings.tabs.team.teamRoles.table.headers.roleName"),
    },
    {
      key: "action",
      name: "",
      notSortable: true,
    },
  ] as Header[];

  const newTableData = rolesData
    ?.filter(
      (roleInfo) =>
        roleInfo.type !== RoleDTO.type.OWNER &&
        roleInfo.type !== RoleDTO.type.USER
    )
    ?.map((roleInfo) => {
      return {
        id: roleInfo.id,
        roleName: roleInfo.name,
        roleType: roleInfo.type,
        teamName: getTeamName(roleInfo),
        action: (
          <ActionButtons isInline>
            <IconButton
              className="edit"
              onClick={() => {
                setCurrentRoleId(roleInfo.id);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>

            <IconButton
              className="delete"
              onClick={() => {
                setRoleToDelete(roleInfo.id);
                setIsDeleteRoleModalOpen(true);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </ActionButtons>
        ),
      };
    });

  return (
    <>
      <CardWrapper>
        <Wrapper>
          <CardHeaderWrapper
            title={t("pages.settings.tabs.team.teamRoles.table.title")}
            actions={
              <CTAButton
                variant="secondary"
                name={t("pages.settings.tabs.team.teamRoles.table.addNewRole")}
                onClick={() => {
                  reset(getInitialValues("", "", "", []));
                  setIsNewRoleModalOpen(true);
                }}
              />
            }
          />

          {newTableData && newTableData.length > 0 ? (
            <BasicTable
              headers={tableHeaders}
              data={newTableData}
              isLastCellStyled
              defaultSort={{ column: "roleName", direction: 1 }}
            />
          ) : (
            <></>
          )}
        </Wrapper>
      </CardWrapper>

      <NewModal
        open={isNewRoleModalOpen}
        handleClose={handleCloseCreateRoleModal}
        icon={<AddRoleIcon />}
        title={t("pages.settings.tabs.team.teamRoles.table.headers.roleName")}
        body={
          <FormProvider {...methods}>
            <RoleForm
              onSubmit={handleCreateRole}
              isEditMode={false}
              teams={teamsInfo?.teams}
              selectedTeamId={teamIdInput}
              categories={categories as CategoryDTO[]}
            />
          </FormProvider>
        }
        footer={
          <>
            <CTAButton
              type="button"
              variant="secondary"
              onClick={handleCloseCreateRoleModal}
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              type="submit"
              name={t("common.buttons.create")}
              form={ROLE_FORM_ID}
              disabled={!isValid}
            />
          </>
        }
        fullWidth
        maxWidth="md"
      />

      <NewModal
        id={currentRoleId}
        open={!!currentRoleId}
        handleClose={handleCloseEditRoleModal}
        icon={<AddRoleIcon />}
        title={t("pages.settings.tabs.team.teamRoles.modals.edit.title")}
        body={
          <>
            <TeamRoleMembers
              title={t(
                "pages.settings.tabs.team.teamRoles.modals.edit.members"
              )}
              roleId={currentRoleId}
              organizationId={parentTeamId}
            />
            <FormProvider {...methods}>
              <RoleForm
                isEditMode
                onSubmit={handleEditRole}
                teams={teamsInfo?.teams}
                selectedTeamId={teamIdInput}
                categories={categories as CategoryDTO[]}
                currentRoleId={currentRoleId}
              />
            </FormProvider>
          </>
        }
        footer={
          <>
            <CTAButton
              type="button"
              variant="secondary"
              onClick={handleCloseEditRoleModal}
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              type="submit"
              form={ROLE_FORM_ID}
              name={t("common.buttons.update")}
              disabled={!isValid}
            />
          </>
        }
        fullWidth
        maxWidth="md"
      />

      <DeleteRoleModal
        open={isDeleteRoleModalOpen}
        handleClose={() => setIsDeleteRoleModalOpen(false)}
        currentOrganizationId={parentTeamId}
        roleId={roleToDelete}
        fetchRoles={refetchRoles}
      />
    </>
  );
};

export default TeamRoles;
