import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { NewModal } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { WarningAmber } from "@mui/icons-material";
import { theme } from "theme";
import styled from "@emotion/styled";

type Props = {
  title: string;
  description: string;
  showModal: boolean;
  handleSaveChanges: () => void;
  handleDiscardChanges: () => void;
  handleCloseModal: (shouldResetBlocker?: boolean) => void;
  saveBtnId?: string;
  discardBtnId?: string;
};

const RoundedBox = styled(Box)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: ${theme.color.red[100]};
`;

export const ConfirmSaveChangesModal = ({
  title,
  description,
  showModal,
  handleDiscardChanges,
  handleSaveChanges,
  handleCloseModal,
  saveBtnId,
  discardBtnId,
}: Props) => {
  const { t } = useTranslation();
  const buttonClasses = useStyles();

  if (!showModal) return null;

  const onSave = () => {
    handleSaveChanges();
    handleCloseModal(false);
  };

  const onDiscard = () => {
    handleDiscardChanges();
    handleCloseModal(false);
  };

  const body = <span>{description}</span>;

  const footer = (
    <>
      <Button
        id={saveBtnId}
        type="submit"
        className={buttonClasses.cancelButton}
        onClick={onSave}
      >
        {t("common.buttons.confirm")}
      </Button>

      <Button
        id={discardBtnId}
        type="submit"
        className={buttonClasses.deleteButton}
        onClick={onDiscard}
      >
        {t("common.buttons.discard")}
      </Button>
    </>
  );

  const icon = (
    <RoundedBox className="flex-column-center">
      <WarningAmber sx={{ fill: theme.color.red[600] }} />
    </RoundedBox>
  );
  return (
    <NewModal
      open={showModal}
      handleClose={handleCloseModal}
      fullWidth
      title={title}
      body={body}
      footer={footer}
      icon={icon}
    />
  );
};
