import Grid from "@mui/material/Grid";
import { useLocale } from "hooks/GlobalStateHooks";
import { ContractDTOV1, CountryFieldTypeDtoV1 } from "openapi";
import { DataPointProps } from "./types";
import { CountrySelect } from "components/FormItems/CountrySelect/CountrySelect";
import { useFormContext } from "react-hook-form";
import { DatapointField } from "./components/DatapointField";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";

export const CountryDatapoint = ({
  definition,
  values,
  editable,
  contractId,
  teamId,
  fetchData,
}: DataPointProps) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContractDTOV1>();
  const data = values[definition.id] as CountryFieldTypeDtoV1;
  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  const name = definition.name[locale];
  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <CountrySelect
          control={control}
          name={`fields.${definition.id}.value`}
          label={definition.name[locale]}
        />
      </Grid>
    );
  }

  const displayNames = new Intl.DisplayNames(locale, { type: "region" });
  let displayedCountry: string;
  try {
    displayedCountry = data?.value ? displayNames.of(data?.value) ?? "-" : "-";
  } catch (e) {
    displayedCountry = "-";
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
        name={name}
      >
        {displayedCountry}
      </DatapointField>
    </Grid>
  );
};
