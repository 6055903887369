import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { Groups } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TeamDTO, TeamInfoDto, TeamMembersDto, TeamService } from "openapi";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { setValidationErrors } from "shared/service/errorResponseService";
import { queryClient } from "shared/api/client";
import { SectionHeader, CTAButton, FormTextField } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import EditIcon from "assets/svg/edit-icon.svg?react";
import { Heading, Name, Form, FormButtons } from "../../Team/styles";
import { HeaderIconStyles } from "../../Teams/styles";
import DocumentListIcon from "assets/svg/document-list-icon.svg?react";
import SupportActiveMessageBox from "./SupportActiveMessageBox";
import { HeaderWrapper } from "./styles";

type Props = {
  currentEntity?: TeamDTO | TeamInfoDto;
  teamMembersData?: TeamMembersDto;
  refetch?: () => void;
};

const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(),
  });

const DetailsSection = ({ currentEntity, teamMembersData, refetch }: Props) => {
  const [nameFieldValue, setNameFieldValue] = useState<string>("");
  const [isNameEditable, setIsNameEditable] = useState<boolean>(false);
  const { t } = useTranslation();
  const { refreshUserInfo } = useUserInfo();

  const isOrganization = !(currentEntity as TeamDTO)?.parentTeam;

  const toggleNameEditable = () => setIsNameEditable((prev) => !prev);

  const { handleSubmit, control, reset, setError } = useForm({
    defaultValues: { name: currentEntity?.name ?? "" },
    resolver: yupResolver(validationSchema()),
  });

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameFieldValue(e.target.value);
  };

  const hasSupportUser = !!teamMembersData?.members.find(
    (member) => member.isSupportUser
  );

  const onNameSubmit = async (teamInfoUpdateData: { name: string }) => {
    if (!currentEntity?.id) return;

    try {
      await TeamService.updateTeamInfo(currentEntity?.id, {
        name: teamInfoUpdateData?.name,
      });

      if (isOrganization) {
        void refetch?.();
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["organizationCurrentTeam", currentEntity.organizationId],
        });
      }

      await refreshUserInfo();

      enqueueSnackbar(
        t(
          "pages.settings.organization.overview.sections.details.notifications.success.updated"
        ),
        {
          variant: "success",
        }
      );

      setIsNameEditable(false);
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        "pages.settings.tabs.team.teamNameForm",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  return (
    <>
      <CardWrapper>
        <Form
          key={`entity-form-${currentEntity?.id ?? ""}`}
          onSubmit={handleSubmit(onNameSubmit)}
        >
          <SectionHeader
            title={
              isOrganization
                ? t(
                    "pages.settings.organization.overview.sections.details.title"
                  )
                : t("pages.settings.team.overview.sections.details.title")
            }
            baseButtonText={
              isOrganization
                ? t(
                    "pages.settings.organization.overview.sections.details.buttons.edit"
                  )
                : t(
                    "pages.settings.team.overview.sections.details.buttons.edit"
                  )
            }
            icon={isOrganization ? <DocumentListIcon /> : <Groups />}
            iconStyle={HeaderIconStyles}
            noPadding
            buttonVariant="secondary"
            buttonIcon={<EditIcon />}
            hideButton={isNameEditable}
            onButtonClick={toggleNameEditable}
            renameFieldActionButtons={
              <FormButtons>
                <CTAButton
                  name={t("common.buttons.cancel")}
                  type="reset"
                  variant="secondary"
                  onClick={() => {
                    reset();
                    toggleNameEditable();
                  }}
                />
                <CTAButton
                  name={t("common.buttons.saveChanges")}
                  type="submit"
                  variant="primary"
                  disabled={
                    !nameFieldValue || currentEntity?.name === nameFieldValue
                  }
                  label="sub-team-submit-button"
                />
              </FormButtons>
            }
          />

          {isNameEditable ? (
            <HeaderWrapper>
              <div>
                <FormTextField
                  key={`key-name-${currentEntity?.id ?? ""}`}
                  name="name"
                  placeholder={currentEntity?.name}
                  control={control}
                  label={t(
                    "pages.settings.tabs.subTeams.section.showSubTeams.fieldLabel"
                  )}
                  margin="none"
                  required
                  InputProps={{
                    onInput: onNameChange,
                  }}
                />
                {hasSupportUser && <SupportActiveMessageBox />}
              </div>
            </HeaderWrapper>
          ) : (
            <HeaderWrapper>
              <div>
                <Heading>
                  {isOrganization
                    ? t(
                        "pages.settings.organization.overview.sections.details.caption"
                      )
                    : t(
                        "pages.settings.team.overview.sections.details.caption"
                      )}
                </Heading>
                <Name aria-label="name">{currentEntity?.name}</Name>
              </div>
              {hasSupportUser && <SupportActiveMessageBox />}
            </HeaderWrapper>
          )}
        </Form>
      </CardWrapper>
    </>
  );
};

export default DetailsSection;
