import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { ContractUpload } from "components";
import { Header } from "../styles";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";

type Props = {
  handleClose: () => void;
};

const ImportViaPdf = ({ handleClose }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <Typography id="modal-title" variant="h6" component="h2">
          {t("guide.steps.importViaPDF.title")}
        </Typography>
        <Typography id="modal-subtitle1" variant="subtitle1" component="span">
          {t("guide.steps.importViaPDF.subtitle")}
        </Typography>
      </Header>
      <ContractUpload
        handleClose={handleClose}
        uploadType={UploadTypeEnum.CREATE_CONTRACT}
      />
    </>
  );
};

export default ImportViaPdf;
