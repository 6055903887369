import { Cell } from "./styles";
import { FormCheckbox } from "components/FormItems";
import { Control } from "react-hook-form";

type Props = {
  control: Control;
  name: string;
  label: string;
};

const ListItem = ({ control, label, name }: Props) => {
  return (
    <>
      <Cell>{label}</Cell>
      <Cell>
        <FormCheckbox control={control} name={name} label="" />
      </Cell>
    </>
  );
};

export default ListItem;
