import Container from "@mui/material/Container";
import ForgotPasswordForm from "./forgotPasswordForm";
import AuthCardWrapper from "../../components/AuthCardWrapper/AuthCardWrapper";
import { CardLinks } from "../../components/AuthCardWrapper/AuthCardWrapperLinks";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const links = [CardLinks.login];

  return (
    <>
      <Container component="main" sx={{ marginTop: "2.5rem" }}>
        <AuthCardWrapper
          title={t("pages.forgotPassword.title")}
          subTitle={t("pages.forgotPassword.subtitle")}
          links={links}
        >
          <ForgotPasswordForm />
        </AuthCardWrapper>
      </Container>
    </>
  );
};

export default ForgotPassword;
