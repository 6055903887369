import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TeamService } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { ErrorIcon, StatusWrapper, SuccessIcon } from "./styles";
import BasicTable from "components/BasicTable/BasicTable";
import { Row } from "components/BasicTable/types";

const SuccessStatus = () => {
  const { t } = useTranslation();
  return (
    <StatusWrapper>
      <SuccessIcon />
      <span>{t("pages.settings.tabs.backup.log.success")}</span>
    </StatusWrapper>
  );
};

const FailedStatus = () => {
  const { t } = useTranslation();
  return (
    <StatusWrapper>
      <ErrorIcon />
      <span>{t("pages.settings.tabs.backup.log.error")}</span>
    </StatusWrapper>
  );
};

const BackupLog = () => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const [data, setData] = useState<Row[]>([]);

  useEffect(() => {
    TeamService.getBackupLog(selectedTeamId)
      .then((logs) => {
        const data = logs
          .map((log) => {
            return {
              createdAt: log.createdAt,
              status: log.success ? <SuccessStatus /> : <FailedStatus />,
              message: log?.message,
            };
          })
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        setData(data);
      })
      .catch((e) => console.error(e));
  }, []);

  const headers = [
    {
      key: "createdAt",
      name: t("pages.settings.tabs.backup.log.createdAt"),
      columnType: "datetime",
    },
    { key: "status", name: t("common.status") },
    {
      key: "message",
      name: t("pages.settings.tabs.backup.log.message"),
    },
  ];

  return <BasicTable headers={headers} data={data} />;
};

export default BackupLog;
