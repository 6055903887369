import routePaths from "constants/routePaths";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiError, AuthService, LimitedIdentityProviderDTO } from "openapi";
import { useOAuthAction } from "./OAuthAction";
import { GoogleImage, MicrosoftImage, SignInButton, SignInText } from "./style";
import { AuthProvider } from "./AuthProvider.enum";
import { useAuthentication } from "hooks/GlobalStateHooks";
import { useSnackbar } from "notistack";
import { getOriginWithoutHostname, useHostname } from "hooks/HostnameHook";
import { CircularProgress } from "@mui/material";

type Props = {
  mode: "login" | "register" | "verify";
  onSuccess?: () => void;
  provider: AuthProvider;
  data?: LimitedIdentityProviderDTO;
};

export const AuthWithSSOButton: FC<Props> = ({
  provider,
  mode = "login",
  data,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAuthentication();
  const [searchParams] = useSearchParams();

  const hostname = useHostname();

  const handleAuthMessage = (
    code: string,
    redirect: string,
    verifier: string
  ) => {
    if (code) {
      if (mode === "login") {
        setLoading(true);
        void AuthService.handleOAuthAuthorizationCodeForLogin(provider, {
          code: code,
          redirect: redirect,
          verifier: verifier,
          hostname: hostname,
        })
          .then(async (tokenResponse) => {
            await login(tokenResponse);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (err instanceof ApiError) {
              if (err.status === 403 || err.status === 409) {
                const body = err.body as { error: string; id: string };
                if (body.error === "link_approval") {
                  const url = new URL(
                    getOriginWithoutHostname() +
                      routePaths.FEDERATED_IDENTITY_APPROVAL.replace(
                        ":id",
                        body.id
                      )
                  );
                  url.searchParams.append("redirect", window.location.href);
                  window.location.replace(url);
                  return;
                }
              }
              if (err.status === 401) {
                enqueueSnackbar(t("pages.login.validation.sso.invalidAccess"), {
                  variant: "error",
                });
                return;
              }
            }
            enqueueSnackbar(t("pages.login.validation.sso.invalidData"), {
              variant: "error",
            });
          });
      } else {
        setLoading(true);
        void AuthService.handleOAuthAuthorizationCodeForRegistration(provider, {
          authData: {
            code: code,
            redirect: redirect,
            verifier: verifier,
            hostname: hostname,
          },
          invitationToken: searchParams.get("invitationToken") || undefined,
        })
          .then(async (data) => {
            setLoading(false);
            if ("emailVerified" in data) {
              navigate(routePaths.VERIFICATION_EMAIL, {
                state: { email: data.email },
              });
              return;
            }
            await login(data, false);
            navigate(routePaths.HOME);
          })
          .catch((err) => {
            setLoading(false);
            if (err instanceof ApiError) {
              if (err.status === 403) {
                const body = err.body as { error: string; id: string };
                if (body.error === "link_approval") {
                  const url = new URL(
                    getOriginWithoutHostname() +
                      routePaths.FEDERATED_IDENTITY_APPROVAL.replace(
                        ":id",
                        body.id
                      )
                  );
                  url.searchParams.append("redirect", window.location.href);
                  window.location.replace(url);
                  return;
                }
              }
              if (err.status === 409) {
                enqueueSnackbar(
                  t(
                    "pages.register.validation.externalIdentityEmailAlreadyPresent"
                  ),
                  {
                    variant: "error",
                  }
                );
              }
            }
          });
      }
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const getAuthText = () => {
    const buttonLabelKey = `pages.${mode}.`;
    if (mode === "login") {
      switch (provider) {
        case "microsoft":
          return buttonLabelKey + "loginWithMicrosoft";
        case "google":
          return buttonLabelKey + "loginWithGoogle";
        default:
          return buttonLabelKey + "loginWithOIDC";
      }
    } else {
      switch (provider) {
        case "microsoft":
          return buttonLabelKey + "registerWithMicrosoft";
        case "google":
          return buttonLabelKey + "registerWithGoogle";
        default:
          return buttonLabelKey + "registerWithOIDC";
      }
    }
  };

  const { triggerAuthFlow, actionResult } = useOAuthAction(provider, data);

  useEffect(() => {
    if (actionResult) {
      handleAuthMessage(
        actionResult.code,
        actionResult.redirect,
        actionResult.verifier
      );
    }
  }, [actionResult]);

  const getImage = () => {
    switch (provider) {
      case "microsoft":
        return <MicrosoftImage />;
      case "google":
        return <GoogleImage />;
      default:
        return null;
    }
  };

  return (
    <SignInButton
      variant="outlined"
      fullWidth
      onClick={triggerAuthFlow}
      provider={provider}
      disabled={loading}
      id={
        provider === AuthProvider.MICROSOFT
          ? "microsoft-login-button"
          : "google-login-button"
      }
    >
      {loading ? (
        <CircularProgress size="1rem" />
      ) : (
        <>
          {getImage()}
          <SignInText>{t(getAuthText())}</SignInText>
        </>
      )}
    </SignInButton>
  );
};
