import { Loader } from "components";
import routePaths from "constants/routePaths";
import { GlobalContext } from "contexts/GlobalState";
import { OverviewProvider } from "contexts/grid/grid";
import { useAuthentication } from "hooks/GlobalStateHooks";
import {
  AdminLayout,
  OrganizationLayout,
  TopNavigationLayout,
  UserSettingsLayout,
} from "layouts";
import { ViewItemDTO } from "openapi";
import * as Pages from "pages";
import { TemplateProvider } from "pages/Settings/OrganizationSettings/ContractTemplates/context";
import Signature from "pages/Signature/Signature";
import { useContext, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Path,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import globalConfig from "shared/config/global.config";

const isMaintenanceModeEnabled =
  globalConfig.REACT_APP_MAINTENANCE_MODE === "true";

const NavigateToRedirectStateOrHome = () => {
  const location = useLocation();
  const state = location.state as { redirect?: Path } | null;

  return <Navigate to={state?.redirect ?? routePaths.HOME} />;
};

const Logout = () => {
  const { logout, isAuthenticated } = useAuthentication();
  const navigate = useNavigate();

  // the logout snackbar shows twice times in dev mode,
  // since the useEffect is called twice times because of StrictMode
  useEffect(() => {
    if (isAuthenticated) {
      void logout().then(() => {
        navigate(routePaths.LOGIN);
      });
    }
  }, []);

  return null;
};

const organizationRoutes = (
  <Route element={<OrganizationLayout />}>
    <Route path="home" element={<Pages.Dashboard />} />

    <Route path="contracts" element={<Outlet />}>
      {/* Main contracts page */}
      <Route
        index
        element={
          // attention (!): do not remove the key attribute here
          // React optimizes the render, when switching between contracts and contacts,
          // meaning the state within the overview provider doesn't fully reset and leads
          // to issues with the views.
          // To circumvent that, it is suggested in the docs to provide a key attribute.
          <OverviewProvider
            key={ViewItemDTO.type.CONTRACT}
            type={ViewItemDTO.type.CONTRACT}
          >
            <Pages.Contracts />
          </OverviewProvider>
        }
      />

      {/* Child routes of contracts
       * Note: Explicity providing diff key other than index/parent route so the state within the overview provider fully reset and not leads
       * to issues with the views.
       */}
      <Route
        path=":id"
        element={
          <OverviewProvider
            key={ViewItemDTO.key.CONTRACT_DETAILS}
            type={ViewItemDTO.type.CONTRACT}
          >
            <Pages.Contract />
          </OverviewProvider>
        }
      />
      <Route
        path=":id/tasks"
        element={
          <OverviewProvider
            key={ViewItemDTO.key.CONTRACT_DETAILS}
            type={ViewItemDTO.type.CONTRACT}
          >
            <Pages.Contract />
          </OverviewProvider>
        }
      />
      <Route
        path=":id/documents"
        element={
          <OverviewProvider
            key={ViewItemDTO.key.CONTRACT_DETAILS}
            type={ViewItemDTO.type.CONTRACT}
          >
            <Pages.Contract />
          </OverviewProvider>
        }
      />
      <Route
        path=":id/documents/:documentId"
        element={
          <OverviewProvider
            key={ViewItemDTO.key.CONTRACT_DETAILS}
            type={ViewItemDTO.type.CONTRACT}
          >
            <Pages.Contract />
          </OverviewProvider>
        }
      />
    </Route>

    <Route
      path="contacts/:id/:mode?"
      element={
        // attention (!): do not remove the key attribute here
        // React optimizes the render, when switching between contracts and contacts,
        // meaning the state within the overview provider doesn't fully reset and leads
        // to issues with the views.
        // To circumvent that, it is suggested in the docs to provide a key attribute.
        <OverviewProvider
          key={ViewItemDTO.key.CONTRACT_DETAILS}
          type={ViewItemDTO.type.CONTRACT}
        >
          <Pages.Contact />
        </OverviewProvider>
      }
    />
    <Route
      path="contacts"
      element={
        // attention (!): do not remove the key attribute here
        // React optimizes the render, when switching between contracts and contacts,
        // meaning the state within the overview provider doesn't fully reset and leads
        // to issues with the views.
        // To circumvent that, it is suggested in the docs to provide a key attribute.
        <OverviewProvider
          key={ViewItemDTO.type.CONTACT}
          type={ViewItemDTO.type.CONTACT}
        >
          <Pages.Contacts />
        </OverviewProvider>
      }
    />

    <Route
      path="tasks"
      element={
        <OverviewProvider
          key={ViewItemDTO.type.TASK}
          type={ViewItemDTO.type.TASK}
        >
          <Pages.Tasks />
        </OverviewProvider>
      }
    />
    <Route path="export" element={<Pages.ContractsExport />} />
    <Route path="settings/*">
      <Route path="teams" element={<Pages.OrganizationSettingsTeams />} />
      <Route path="teams/:id" element={<Pages.OrganizationSettingsTeam />} />
      <Route path="security" element={<Pages.OrganizationSettingsSecurity />} />
      <Route
        path="members/:id"
        element={<Pages.OrganizationSettingsTeamMember />}
      />
      <Route path="backup" element={<Pages.OrganizationSettingsBackup />} />
      {/* @Todo temporary redirect, added on 05.02.2025, remove this in several months */}
      <Route
        path="webhooks"
        element={<Pages.OrganizationSettingsIntegrations />}
      />
      <Route
        path="webhooks/:id"
        element={<Pages.OrganizationSettingsIntegrations />}
      />
      {/* Todo end */}
      <Route
        path="integrations/:tab"
        element={<Pages.OrganizationSettingsIntegrations />}
      />
      <Route
        path="integrations/webhooks/:id"
        element={<Pages.OrganizationSettingsWebhooks />}
      />
      <Route
        path="integrations/webhooks/:id/events/:eventId"
        element={<Pages.OrganizationSettingsWebhooks />}
      />
      <Route
        path="task-automations"
        element={<Pages.OrganizationSettingsTaskAutomations />}
      />
      <Route
        path="task-automations/:id"
        element={<Pages.OrganizationSettingsTaskAutomation />}
      />
      <Route
        path="custom-fields"
        element={<Pages.OrganizationSettingsCustomFields />}
      />
      <Route
        path="category/:id"
        element={<Pages.OrganizationSettingsCategory />}
      />
      <Route
        path="contract-templates/*"
        element={
          <Routes>
            <Route path="/" element={<Pages.OrganizationContractTemplates />} />
            <Route
              path=":id"
              element={<Pages.OrganizationContractTemplate />}
            />
          </Routes>
        }
      />
      <Route path="organization/*">
        <Route
          path="permission-change-log"
          element={<Pages.OrganizationSettingsPermissionChangeLog />}
        />
        <Route
          path="details"
          element={<Pages.OrganizationSettingsOrganization />}
        />
        <Route path="" element={<Pages.OrganizationSettingsOrganization />} />
      </Route>
      <Route path="*" element={<Navigate to="organization/details" />} />
    </Route>
  </Route>
);

const privateRoutes = (
  <Route element={<TopNavigationLayout />}>
    <Route path="/" element={<Navigate to={routePaths.HOME} />} />
    <Route path="/login" element={<NavigateToRedirectStateOrHome />} />
    <Route path="/logout" element={<Logout />} />
    <Route
      path="/profile"
      element={<Navigate to={routePaths.SETTINGS_USER_PROFILE} />}
    />
    <Route
      path="/settings/profile"
      element={<Navigate to={routePaths.SETTINGS_USER_PROFILE} />}
    />
    <Route
      path="/notifications"
      element={<Navigate to={routePaths.SETTINGS_USER_NOTIFICATIONS} />}
    />
    <Route
      path="/settings/notifications"
      element={<Navigate to={routePaths.SETTINGS_USER_NOTIFICATIONS} />}
    />
    <Route
      path={routePaths.REGISTER}
      element={<Pages.InvitationVerification />}
    />
    <Route
      path="/notifications"
      element={<Navigate to="/user/notifications" />}
    />
    {organizationRoutes}
    <Route path="/kiosk/*">
      <Route element={<AdminLayout />}>
        <Route path="contracts/:id?" element={<Pages.PendingContracts />} />
        <Route path="users" element={<Pages.Users />} />
        <Route path="users/:id" element={<Pages.UserImpersonate />} />
        <Route
          path={routePaths.ADMIN_ORGANIZATIONS}
          element={<Pages.Organizations />}
        />
        <Route
          path={`${routePaths.ADMIN_ORGANIZATIONS}/:id`}
          element={<Pages.OrganizationsDetails />}
        />
        <Route path="*" element={<Navigate to="contracts" />} />
      </Route>
    </Route>
    <Route path="/user/*" element={<UserSettingsLayout />}>
      <Route path="profile" element={<Pages.UserSettingsProfile />} />
      <Route
        path="notifications"
        element={<Pages.UserSettingsNotifications />}
      />
    </Route>
    <Route path="/team-creation/*">
      <Route path="*" element={<Pages.OrganizationCreation />} />
    </Route>
    {/* To be handle to handle links pointing to specific teams this endpoint is used as entrypoint */}
    <Route path="/organizations/:organizationId">{organizationRoutes}</Route>
    <Route path="/forbidden" element={<Pages.Forbidden />} />
    <Route path="/federated-identity/:id/approve" element={<Pages.Link />} />
    <Route path="*" element={<Pages.NotFound />} />
  </Route>
);

const baseRoutes = (
  <Route>
    <Route
      path="federated-identity/:provider"
      element={<Pages.OAuthCallback />}
    />
    <Route path="signature" element={<Signature />} />
  </Route>
);

export const StandardRoutes = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  const location = useLocation();

  if (isAuthenticated) {
    return (
      <TemplateProvider>
        <Routes>
          {baseRoutes}
          {privateRoutes}
        </Routes>
      </TemplateProvider>
    );
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Navigate
            to={routePaths.LOGIN}
            state={{
              redirect: location,
            }}
            replace
          />
        }
      />
      {baseRoutes}
      <Route path="register" element={<Pages.Register />} />
      <Route path="verify" element={<Pages.Verify />} />
      <Route path="login" element={<Pages.Login />} />
      <Route path="forgot-password" element={<Pages.ForgotPassword />} />
      <Route path="reset-password" element={<Pages.ResetPassword />} />
      <Route path="notfound" element={<Pages.NotFound />} />
    </Routes>
  );
};

const maintenanceRouter = (
  <Routes>
    <Route path="*" element={<Pages.Maintenance />} />
  </Routes>
);

const getRoutes = (isAuthenticated: boolean) => {
  if (isMaintenanceModeEnabled) return maintenanceRouter;
  return <StandardRoutes isAuthenticated={isAuthenticated} />;
};

export default function AppRoutes() {
  const { state } = useContext(GlobalContext);
  const { authenticated, status } = state;

  if (authenticated && status === "idle") return <Loader />;

  return getRoutes(authenticated);
}
