import { useMemo } from "react";
import { Control, UseFormTrigger, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TeamDTOV1 } from "openapi";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { Language } from "shared/i18n/i18n";
import { useTeam } from "contexts/team/hooks";
import { FormErrors, InvitationType } from "./TeamMembers";
import { sortRolesAlphabetically } from "./helper";
import { getNameWithSuffix } from "../../helper";
import InputEmails from "./EmailsInput";
import { FormSwitch } from "components/FormItems/FormSwitch/FormSwitch";
import { Form, Subheading } from "./styles";
import FormMultiSelect from "components/FormItems/FormMultiSelect/FormMultiSelect";
import { useRolesQuery } from "shared/api";

const TeamInvitationForm = ({
  teams,
  control,
  errors,
  trigger,
}: {
  teams?: TeamDTOV1[];
  control: Control<InvitationType>;
  errors: FormErrors;
  trigger: UseFormTrigger<InvitationType>;
}) => {
  const { t } = useTranslation();
  const { parentTeamId } = useTeam();

  const { data: roles } = useRolesQuery(parentTeamId);

  const ownerFlag = useWatch({
    control: control,
    name: "owner",
  });

  const roleOptions = useMemo(() => {
    if (!roles) {
      return [];
    }

    const sortedRoles = sortRolesAlphabetically(roles);

    return sortedRoles.map((role) => ({
      key: role.id,
      value: getNameWithSuffix(role.name, role.teamId, teams ?? [], t),
    }));
  }, [roles]);

  return (
    <Form name="teamInvitationForm" noValidate>
      <div>
        <Subheading>
          {t("pages.settings.tabs.team.teamInvitationForm.description.email")}
        </Subheading>
        <InputEmails
          name="email"
          control={control as unknown as Control}
          error={!!errors.email}
          trigger={trigger}
        />
        <FormSelect
          className="language-input"
          control={control}
          name="language"
          options={Object.keys(Language)}
          translationPrefix={"common.languages"}
          label={t("pages.settings.tabs.team.teamInvitationForm.language")}
        />
      </div>
      <div>
        <Subheading>
          {t("pages.settings.tabs.team.teamInvitationForm.description.owner")}
        </Subheading>
        <FormSwitch
          control={control}
          label={t("pages.settings.tabs.team.teamInvitationForm.isOwner")}
          name="owner"
          labelPosition="left"
          stretch
        />
      </div>
      <div>
        <Subheading>
          {t(
            "pages.settings.tabs.team.teamInvitationForm.rolesSettings.emptyMessage"
          )}
        </Subheading>
        <FormMultiSelect
          size="small"
          control={control}
          name="roles"
          label={t(
            "pages.settings.tabs.team.teamInvitationForm.rolesSettings.label"
          )}
          required
          options={roleOptions}
          disableCloseOnSelect
          disabled={ownerFlag}
          limitTags={3}
        />
      </div>
    </Form>
  );
};

export default TeamInvitationForm;
