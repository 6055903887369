import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  DragOverEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { FormTextField } from "components";
import { SortableListType } from "./types";
import SortableSignatory from "./SignatoryItem";
import * as Styled from "./styles";

const SortableList = ({
  control,
  fields,
  remove: removeSignatory,
  move,
  disabled,
  eid,
}: SortableListType) => {
  const [overId, setOverId] = useState<UniqueIdentifier>();
  const { t } = useTranslation();
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // @Note 💡: autocomplete to suggest team member's email address
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    // Reset overId regardless of whether the order changed
    setOverId("");

    if (over && active?.id !== over?.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);
      // Only proceed if the new index is different from the old index
      if (oldIndex !== newIndex) move(oldIndex, newIndex);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setOverId(event?.over?.id ?? "");
    }
  };

  const handleDragCancel = () => setOverId("");

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <SortableContext
        items={fields}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {fields.map((field, index: number) => (
          <SortableSignatory
            data-testid="signers-list"
            key={field.id}
            id={field.id}
            isOver={field?.id === overId}
            disabled={disabled}
            eid={eid}
          >
            <Styled.SignersField data-testid="signer">
              <FormTextField
                name={`signers.${index}.name`}
                control={control}
                label={t("common.name")}
              />
              <FormTextField
                name={`signers.${index}.email`}
                control={control}
                label="E-Mail"
                type="email"
                sx={{ marginLeft: "1rem" }}
              />
              {!eid && fields.length > 1 ? (
                <Styled.RemoveIcon onClick={() => removeSignatory(index)} />
              ) : null}
            </Styled.SignersField>
          </SortableSignatory>
        ))}
      </SortableContext>
      {fields.length > 1 && !eid && !disabled ? (
        <Styled.HintWrapper>
          {t("pages.contractDetails.modals.requestSignature.labels.drag")}
        </Styled.HintWrapper>
      ) : null}
    </DndContext>
  );
};

export default SortableList;
