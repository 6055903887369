import LogEntity from "./components/LogEntity/LogEntity";
import { Container, Title, Wrapper } from "./styles";
import { useTranslation } from "react-i18next";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { theme } from "theme";
import { useTeam } from "contexts/team/hooks";
import { useContractChangeLogQuery } from "shared/api/contracts";
import { Loader } from "components";
import {
  useContactsQuery,
  useFieldsQuery,
  useOrganizationCategoriesQuery,
  useTeamMembersQuery,
} from "shared/api";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import { ContractDTOV1 } from "openapi";
import { CONTENT_HEADER_HEIGHT, CONTENT_HEIGHT } from "../RightSide/helpers";

export type ContracChangeLogProps = {
  contract: ContractDTOV1;
};

const ContractChangeLog = ({ contract }: ContracChangeLogProps) => {
  const { t } = useTranslation();
  const { organizationId, parentTeamId, selectedTeamId } = useTeam();
  const contractId = contract.id;

  const { data: changeLogData, isLoading: changeLogDataLoading } =
    useContractChangeLogQuery(contractId, organizationId);

  const { data: categoriesData, isLoading: categoriesDataLoading } =
    useOrganizationCategoriesQuery(organizationId);

  const { data: teamsData, isLoading: isTeamsLoading } =
    useOrganizationAllTeamsQuery(organizationId);

  const { data: fields, isLoading: fieldsLoading } =
    useFieldsQuery(organizationId);

  const { data: teamMemberData, isLoading: isTeamMemberDataLoading } =
    useTeamMembersQuery(selectedTeamId);

  const { data: contacts, isLoading: contactsLoading } = useContactsQuery(
    parentTeamId,
    selectedTeamId
  );

  const isLoading =
    !changeLogData ||
    !categoriesData ||
    !teamsData ||
    !fields ||
    !contacts ||
    !teamMemberData ||
    changeLogDataLoading ||
    categoriesDataLoading ||
    isTeamsLoading ||
    isTeamsLoading ||
    fieldsLoading ||
    contactsLoading ||
    isTeamMemberDataLoading;

  if (isLoading) return <Loader />;

  return (
    <Container>
      <Title>
        <span>{t("pages.contractDetails.changeLog.title")}</span>
      </Title>
      <OverlayScrollbarsComponent
        defer
        style={{
          overflowX: "auto",
          paddingRight: theme.spacing.xl,
          height: `calc(${CONTENT_HEIGHT} - ${CONTENT_HEADER_HEIGHT})`,
        }}
      >
        <Wrapper>
          {changeLogData?.map((item) => {
            return (
              <LogEntity
                key={item.id}
                changeLog={item}
                categories={categoriesData}
                teams={teamsData}
                fields={fields}
                contacts={contacts}
                teamMembers={teamMemberData.members}
                currentUserId={teamMemberData.memberId}
              />
            );
          })}
        </Wrapper>
      </OverlayScrollbarsComponent>
    </Container>
  );
};

export default ContractChangeLog;
