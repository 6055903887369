import {
  Values,
  Wrapper,
  ValueIconWrapper,
  ValueWrapper,
  AllowIcon,
  DenyIcon,
} from "./styles";
import ArrowIcon from "assets/svg/arrow-up.svg?react";
import { useTranslation } from "react-i18next";
import { Diff } from "openapi";

export type DiffType = {
  from?: boolean;
  to: boolean;
};

export type FromToType = {
  data: DiffType | Diff;
};

const FromTo = ({ data }: FromToType) => {
  const { t } = useTranslation();

  if (!data) return null;

  const isUpdated = "from" in data && "to" in data;

  const getChangeValue = (data: DiffType | Diff, type: keyof DiffType) => {
    if (data[type]) {
      return (
        <ValueWrapper>
          <AllowIcon />
          {t("common.labels.allowed")}
        </ValueWrapper>
      );
    } else {
      return (
        <ValueWrapper>
          <DenyIcon /> {t("common.labels.denied")}
        </ValueWrapper>
      );
    }
  };

  return (
    <Wrapper>
      {isUpdated ? (
        <Values>
          {getChangeValue(data, "from")}
          <ValueIconWrapper>
            <ArrowIcon />
          </ValueIconWrapper>
          {getChangeValue(data, "to")}
        </Values>
      ) : (
        <Values>{getChangeValue(data, "to")}</Values>
      )}
    </Wrapper>
  );
};

export default FromTo;
