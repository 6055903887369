import { Table, TableBody, TableContainer } from "@mui/material";

import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

type Props = {
  children: JSX.Element[];
  scrollable?: boolean;
  maxHeight?: number;
};

const SectionTableWrapper = ({ children, scrollable, maxHeight }: Props) => {
  const sectionTable = (
    <TableContainer>
      <Table sx={{ width: "100%" }}>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
  return scrollable ? (
    <OverlayScrollbarsComponent
      defer
      style={{
        overflowY: "auto",
        maxHeight: maxHeight ? `${maxHeight}px` : "280px",
      }}
    >
      {sectionTable}
    </OverlayScrollbarsComponent>
  ) : (
    sectionTable
  );
};

export default SectionTableWrapper;
