import { useEffect, useState } from "react";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useTranslation } from "react-i18next";
import EditPendingContract from "./PendingContractsModalContents/EditPendingContract";
import { AdminService, PendingContractListItemDto } from "openapi";
import { Box, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { Header, Row } from "components/BasicTable/types";
import BasicTable from "components/BasicTable/BasicTable";
import { useNavigate, useParams } from "react-router-dom";
import routePaths from "../../../constants/routePaths";
import styled from "@emotion/styled";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import { theme } from "theme";
import NewModal from "components/Modal/NewModal";

const Clickable = styled.div`
  cursor: pointer;
`;

const PendingContracts = () => {
  const { t } = useTranslation();
  const [pendingContracts, setPendingContracts] = useState<
    PendingContractListItemDto[]
  >([]);
  const [pendingContractsCount, setPendingContractsCount] = useState(0);
  const [data, setData] = useState<Row[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedIdToEdit, setSelectedContractId] = useState<
    string | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { id: contractId } = useParams();
  const navigate = useNavigate();

  const header: Header[] = [
    {
      key: "title",
      name: t("pages.admin.tabs.pendingContracts.contractTitle").toString(),
    },
    {
      key: "userFullName",
      name: t("pages.admin.tabs.pendingContracts.user").toString(),
    },
    {
      key: "organizationName",
      name: t("pages.admin.tabs.pendingContracts.organization").toString(),
    },
    {
      key: "teamName",
      name: t("pages.admin.tabs.pendingContracts.team").toString(),
    },
    {
      key: "uploadDate",
      name: t("pages.admin.tabs.pendingContracts.uploadDate").toString(),
      type: "date",
    },
    {
      key: "analysisStatus",
      name: t("pages.admin.tabs.pendingContracts.analysisStatus").toString(),
    },
    {
      key: "action",
      name: "",
      notSortable: true,
    },
  ];

  const fetchData = async () => {
    const pendingContractData = await AdminService.getPendingContracts();
    setPendingContracts(pendingContractData.contracts);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const convertData = (contracts: PendingContractListItemDto[]): Row[] => {
    return contracts.map((contract) => {
      return {
        id: contract.id,
        title: (
          <Clickable onClick={() => handleActionEditClick(contract.id)}>
            {contract.name}
          </Clickable>
        ),
        userFullName: contract.createdBy,
        teamName: contract.teamName,
        organizationName: contract.organizationName,
        uploadDate: contract.createdAt,
        analysisStatus: contract.analysisStatus,
        action: (
          <Box sx={{ width: "100%", justifyContent: "end" }} className="flex">
            <Tooltip title={t("pages.admin.tabs.pendingContracts.analyse")}>
              <EditIcon
                sx={{
                  margin: "0 0.5rem",
                  ":hover": { cursor: "pointer" },
                }}
                onClick={() => handleActionEditClick(contract.id)}
              />
            </Tooltip>
            <Tooltip title={t("pages.admin.tabs.pendingContracts.copyID")}>
              <ContentCopyIcon
                sx={{
                  ":hover": { cursor: "pointer" },
                  margin: "0 0.5rem",
                }}
                onClick={() => handleActionCopyClick(contract.id)}
              />
            </Tooltip>
            <Tooltip title={t("pages.admin.tabs.pendingContracts.triggerOCR")}>
              <EmojiNatureIcon
                sx={{
                  margin: "0 0.5rem",
                  ":hover": { cursor: "pointer" },
                  color: theme.color.pink[600],
                }}
                onClick={() => handleActionTriggerOCR(contract.id)}
              />
            </Tooltip>
          </Box>
        ),
      };
    });
  };

  useEffect(() => {
    setData(convertData(pendingContracts));
    setPendingContractsCount(pendingContracts.length);
  }, [pendingContracts]);

  const handleModalClose = () => {
    navigate(`${routePaths.ADMIN}/${routePaths.ADMIN_PENDING_CONTRACT}`);
    setShowEditModal(false);
  };

  useEffect(() => {
    if (contractId) {
      setShowEditModal(true);
      setSelectedContractId(contractId);
    }
  }, [contractId]);

  const handleActionEditClick = (id: string) => {
    navigate(`${routePaths.ADMIN}/${routePaths.ADMIN_PENDING_CONTRACT}/${id}`);
  };

  const handleActionTriggerOCR = async (id: string) => {
    try {
      await AdminService.triggerOcr(id);
      enqueueSnackbar(t("pages.admin.tabs.pendingContracts.ocrTriggered"), {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(t("pages.admin.tabs.pendingContracts.ocrFailed"), {
        variant: "error",
      });
    }
  };

  const handleActionCopyClick = async (id: string) => {
    enqueueSnackbar(t("pages.admin.tabs.pendingContracts.copiedMessage"), {
      variant: "success",
    });
    try {
      await navigator.clipboard.writeText(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CardWrapper>
      <>
        <CardHeaderWrapper
          title={t("pages.admin.tabs.pendingContracts.title")}
          titleInformation={
            pendingContractsCount ? `(${pendingContractsCount})` : undefined
          }
        />
        <BasicTable headers={header} data={data} />
        <NewModal
          open={showEditModal}
          handleClose={handleModalClose}
          fullWidth={true}
          maxWidth={false}
          title={t("pages.admin.tabs.pendingContracts.modal.title")}
          body={
            <EditPendingContract
              id={selectedIdToEdit}
              handleClose={handleModalClose}
              refetch={fetchData}
            />
          }
        ></NewModal>
      </>
    </CardWrapper>
  );
};

export default PendingContracts;
