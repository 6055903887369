import styled from "@emotion/styled";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAvatarGroup from "@mui/material/AvatarGroup";
import MuiAvatar from "@mui/material/Avatar";
import { theme } from "theme";

export const Wrapper = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  color: #718096;
  margin: 0;
  padding: 0 0.5rem;
  background-color: #e9f3ff;
  border-radius: ${theme.borderRadius};
  border: 1px solid ${theme.color.blue[200]}80;
  ${({ isOpen }) =>
    isOpen &&
    `position: fixed;
      width: 240px;
      bottom: 8px;
      left: 8px;`}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #718096;
  width: 100%;
`;

export const AvatarGroup = styled(MuiAvatarGroup)`
  width: 20%;
`;

export const Avatar = styled(MuiAvatar)`
  width: 18px;
  height: 18px;
`;

export const Details = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  background-color: #e9f3ff;
`;

const common = `
  margin: .4rem 0;
  display: flex;
  align-items: center;
  font-size: .8rem;
  transition: color .2s ease-in-out;

  svg {
    margin-left: ${theme.spacing.xs};
    fill: ${theme.color.gray[600]};
  }

  :hover {
    opacity: .9;
  }
`;

export const Link = styled.a`
  ${common}
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    color: #2e4469;
  }
`;

export const EventLink = styled.a`
  ${common}
  margin: .8rem 0;
  background-color: #3182ce;
  border-radius: 4px;
  padding: 0.5rem 0;
  width: 100%;

  svg {
    fill: #ffffff;
  }

  span {
    color: #fff;
  }

  svg:last-of-type {
    transition: transform 0.2s ease-in-out;
  }

  :hover {
    svg:last-of-type {
      transform: translateX(5px);
    }
  }
`;
