import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import ExpandIcon from "assets/svg/expand-icon.svg?react";

type Props = ToolbarSpecificButtonProps<"EnterFullScreen"> & {
  fileUrl: string;
};

export const FullscreenButton = ({ Slot, fileUrl }: Props) => {
  const { t } = useTranslation();
  return (
    <Slot>
      {() => {
        return (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.fullscreen")}
            onClick={() => {
              window.open(fileUrl, "_blank");
            }}
          >
            <ExpandIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
