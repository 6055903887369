import { useTranslation } from "react-i18next";
import Cloud from "assets/svg/cloud.svg?react";
import Sync from "assets/svg/sync.svg?react";
import { Container } from "./styles";

const SaveStatus = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();

  const saving = (
    <>
      <Sync />
      {t("common.saving")}
    </>
  );

  const saved = (
    <>
      <Cloud />
      {t("common.saved")}
    </>
  );

  const ariaLabel = isLoading ? "saving" : "saved";
  return (
    <Container aria-label={ariaLabel}>{isLoading ? saving : saved}</Container>
  );
};

export default SaveStatus;
