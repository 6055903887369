import { useState } from "react";
import { DropdownWrapper, DropdownMenu, Divider } from "./styles";
import PrintIcon from "assets/svg/print-icon.svg?react";
import { useOutsideClick } from "hooks";
import DownloadIcon from "assets/svg/download-file-icon.svg?react";
import DeleteIcon from "assets/svg/trash-icon.svg?react";
import { CTAButton } from "components";
import { Tooltip } from "@mui/material";
import DotsHorizontalIcon from "assets/svg/dots-horizontal.svg?react";
import { useTranslation } from "react-i18next";

type DropdownProps = {
  onDeleteClick?: () => void;
  onPrintClick?: () => void;
  onDownloadClick?: () => void;
  isPdf: boolean;
};

const Dropdown = ({
  onDeleteClick,
  onPrintClick,
  onDownloadClick,
  isPdf,
}: DropdownProps) => {
  const { t } = useTranslation();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const dropdownRef = useOutsideClick(() => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  const onPrintHandler = () => {
    setIsDropdownOpened(false);
    onPrintClick && onPrintClick();
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Tooltip title={t("pages.contractDetails.buttons.moreDocumentOptions")}>
        <CTAButton
          label="documentOptionsMenuButton"
          icon={<DotsHorizontalIcon />}
          onClick={() => setIsDropdownOpened(!isDropdownOpened)}
          iconSize="medium"
          size="tiny"
          variant="tertiary"
        />
      </Tooltip>
      {!!isDropdownOpened && (
        <DropdownMenu>
          {isPdf && (
            <>
              <CTAButton
                variant="tertiary"
                label="print-document-button"
                onClick={onPrintHandler}
                icon={<PrintIcon fontSize="small" />}
                name={t("pages.contractDetails.buttons.printDocument")}
                iconSize="medium"
                color="gray"
                hoverColor="blue"
              />
              <CTAButton
                variant="tertiary"
                label="download-document-button"
                onClick={onDownloadClick}
                icon={<DownloadIcon fontSize="small" />}
                name={t("pages.contractDetails.buttons.downloadDocument")}
                iconSize="medium"
                color="gray"
                hoverColor="blue"
              />
              <Divider />
            </>
          )}
          <CTAButton
            variant="tertiary"
            label="deleteDocumentOptionButton"
            onClick={onDeleteClick}
            icon={<DeleteIcon />}
            name={t("pages.contractDetails.buttons.deleteDocument")}
            color="danger"
            iconSize="medium"
          />
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
