import { Checkbox } from "@mui/material";
import { ColDef } from "ag-grid-community";
import { ViewMode } from "../../../Teams";

type CheckboxCellRendererProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  data: {
    id: string;
  };
  colDef: ColDef & { viewMode: ViewMode };
  teamId?: string;
  categoryId?: string;
};

const CheckboxCellRenderer = ({
  value,
  setValue,
  data,
  colDef,
  teamId = "",
  categoryId = "",
}: CheckboxCellRendererProps) => {
  const isTeamView = colDef.viewMode === "team";

  const fieldName = isTeamView
    ? `${data.id}-${categoryId}`
    : `${teamId}-${data.id}`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setValue(e.target.checked);
  };

  return <Checkbox key={fieldName} checked={value} onChange={handleChange} />;
};

export default CheckboxCellRenderer;
