import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, Chip } from "@mui/material";
import { ApiError, SignatureRequestDTO } from "openapi";
import { AlertV2, FormTextField } from "components";
import { useSignatureProvider } from "pages/Contract/hooks/useSignature";
import SignatoriesSortableList from "./SignatoriesList";
import { useStyles as useStyledButtons } from "../../../../components/StyledComponents/StyledBaseButtons";
import * as Styled from "./styles";
import globalConfig from "shared/config/global.config";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { RadioGroup } from "components/FormItems";
import { getLanguageOptions } from "utils/helpers";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getErrorMessage } from "./utils";
import QesPreview from "./QesPreview";
import { Switch } from "new-components";
import DocumentListPreview from "./DocumentListPreview";

export type FormValues = {
  message: string;
  documentId?: string;
  signers: { name: string; email: string }[];
  language: string;
  eID: boolean;
  withOrder: boolean;
  ccEmails: string[];
};

export type RequestSignatureContentProps = {
  handleClose: () => void;
  contractId?: string | null;
  refetchData?: () => Promise<void>;
  name?: string;
  setShowSignatureModal: (value: boolean) => void;
  setIsRequestSignatureLoading?: (value: boolean) => void;
  setIsSignatureSubmitted?: (value: boolean | null) => void;
};

const RequestSignatureContent = ({
  contractId,
  handleClose,
  refetchData,
  setShowSignatureModal,
  setIsRequestSignatureLoading,
  setIsSignatureSubmitted,
}: RequestSignatureContentProps) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const { hasFeature } = useTeam();
  const [eid, setEid] = useState(false);
  const [ccs, setCcs] = useState<string[]>([]);
  const [ccErrorText, setCcErrorText] = useState<string>("");
  const [ccInputValue, setCcInputValue] = useState("");
  const [error, setError] = useState<string>();
  const [ordering, setOrdering] = useState(false);
  const [showQes, setShowQes] = useState(true);
  const [hideQesPreview, setHideQesPreview] = useState(true);
  const dontShowQesKey = "show_qes_accordion";

  const methods = useFormContext<FormValues>();
  const { handleSubmit, control } = methods;
  const { requestSignature } = useSignatureProvider();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "signers",
  });

  useEffect(() => {
    if (eid && fields.length > 1) {
      for (let index = fields.length - 1; index > 0; index--) {
        remove(index);
      }
    }
  }, [eid, remove]);

  useEffect(() => {
    setShowQes(
      JSON.parse(localStorage.getItem(dontShowQesKey) ?? "true") as boolean
    );
  }, []);

  const languageOptions = getLanguageOptions();
  const emailSchema = yup.array().of(yup.string().email());

  const handleCCAdd = (emails: string[]) => {
    if (!emails) return;
    const isValid = emailSchema.isValidSync(emails);
    if (isValid) {
      setCcErrorText("");
      setCcs(emails);
      setCcInputValue("");
    } else {
      setCcErrorText(
        t("pages.contractDetails.modals.requestSignature.errors.invalidEmail")
      );
    }
  };

  const handleBlur = () => {
    if (ccInputValue.trim() === "") return;
    handleCCAdd([...ccs, ccInputValue.trim()]);
  };

  const handleErrors = (e: ApiError) => {
    const errorType = (e.body as { message: string }).message;
    if (e.status === 400) {
      switch (errorType) {
        case "write_protected_file":
          setError("write_protected_file");
          break;
        case "no_eid_feature":
          setError("team_has_no_eid_feature");
          break;
        case "no_signature_feature":
          setError("team_has_no_signature_feature");
          break;
        default:
          setError("true");
      }
    } else if (e.status === 402) {
      switch (errorType) {
        case "not_enough_eid_credits":
          setError("not_enough_eid_credits");
          break;
        case "not_enough_signature_credits":
          setError("not_enough_signature_credits");
          break;
        default:
          setError("true");
      }
    } else {
      setError("true");
    }
  };

  const onSubmit = async (data: FormValues) => {
    const { message, signers: recipients, language: locale, documentId } = data;
    const values: SignatureRequestDTO = {
      locale,
      message,
      recipients,
      documentId,
      eID: eid,
      withOrder: ordering,
      ccEmails: ccs,
    };

    try {
      if (!contractId) {
        return;
      }

      localStorage.setItem(dontShowQesKey, JSON.stringify(hideQesPreview));

      await requestSignature(
        contractId,
        values,
        setShowSignatureModal,
        handleClose,
        refetchData,
        setIsRequestSignatureLoading,
        setIsSignatureSubmitted
      );
    } catch (e) {
      if (e instanceof ApiError) {
        handleErrors(e);
      }
    }
  };

  return (
    <div data-testid="signature-request-modal">
      <div id="sign-here" />
      <Styled.Header>
        <Styled.DescriptionWrapper>
          {t("pages.contractDetails.modals.requestSignature.description")}
        </Styled.DescriptionWrapper>
      </Styled.Header>
      {error && (
        <AlertV2
          message={t(getErrorMessage(error))}
          type={AlertTypeEnum.error}
          icon={<ErrorOutlineIcon />}
        />
      )}
      <FormProvider {...methods}>
        <Styled.Form
          onSubmit={handleSubmit(onSubmit)}
          name="contractRequestESignatureForm"
          noValidate
          id="requestSignatureForm"
        >
          <FormTextField
            control={control}
            name="message"
            label={t(
              "pages.contractDetails.modals.requestSignature.fields.message"
            )}
            minRows={4}
            maxRows={8}
            multiline
            fullWidth
            sx={{ margin: "0" }}
          />
          {globalConfig.REACT_APP_DROPBOX_CLIENT_ID ? (
            <>
              {hasFeature(Features.EID_ESIGNATURE) ? (
                <Styled.BorderedBox>
                  <Styled.SubTitleWrapper>
                    {t(
                      "pages.contractDetails.modals.requestSignature.fields.eid"
                    )}
                  </Styled.SubTitleWrapper>
                  <Styled.SwitcherWrapper>
                    <Switch
                      data-testid="eid-switch"
                      checked={eid}
                      label={t(
                        "pages.contractDetails.modals.requestSignature.labels.eid"
                      )}
                      labelPosition="left"
                      onChange={() => {
                        setEid(!eid);
                        setOrdering(false);
                      }}
                      name="eid"
                    />
                  </Styled.SwitcherWrapper>
                </Styled.BorderedBox>
              ) : (
                <QesPreview
                  showQes={showQes}
                  setHideQesPreview={setHideQesPreview}
                />
              )}
            </>
          ) : null}

          <DocumentListPreview />

          <Styled.SubTitleWrapper>
            {t("pages.contractDetails.modals.requestSignature.fields.signers")}
          </Styled.SubTitleWrapper>

          <SignatoriesSortableList
            control={control}
            fields={fields}
            remove={remove}
            move={move}
            disabled={!ordering}
            eid={eid}
          />
          {!eid ? (
            <Styled.AddButton
              data-testid="add-signer-button"
              onClick={() => append({ name: "", email: "" })}
              className={buttonClasses.addFieldButton}
            >
              <Styled.AddIcon />
              <Styled.AddDescription>
                {t("pages.contractDetails.modals.requestSignature.addSigner")}
              </Styled.AddDescription>
            </Styled.AddButton>
          ) : null}

          {!!globalConfig.REACT_APP_DROPBOX_CLIENT_ID && !eid ? (
            <>
              <Styled.SubTitleWrapper>
                {t(
                  "pages.contractDetails.modals.requestSignature.fields.order"
                )}
              </Styled.SubTitleWrapper>
              <Styled.SwitcherWrapper>
                <Switch
                  stretch
                  checked={ordering}
                  onChange={() => setOrdering(!ordering)}
                  name="withOrder"
                  label={t(
                    "pages.contractDetails.modals.requestSignature.toggleOrdering"
                  )}
                  labelPosition="left"
                  disabled={eid}
                />
              </Styled.SwitcherWrapper>
            </>
          ) : null}
          {globalConfig.REACT_APP_DROPBOX_CLIENT_ID ? (
            <>
              <Styled.FieldLabel>
                {t("pages.contractDetails.modals.requestSignature.labels.ccs")}
              </Styled.FieldLabel>
              <Autocomplete
                value={ccs}
                multiple
                id="tags-filled"
                options={[]}
                freeSolo
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      color="primary"
                      key={index}
                    />
                  ));
                }}
                sx={{ mt: 0, mb: 0 }}
                onChange={(_event, newValue) => {
                  handleCCAdd(newValue);
                }}
                renderInput={(params) => {
                  const _params = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      value: ccInputValue,
                    },
                  };
                  return (
                    <StyledTextField
                      {..._params}
                      variant="outlined"
                      size="small"
                      error={!!ccErrorText}
                      helperText={ccErrorText}
                      label={t(
                        "pages.contractDetails.modals.requestSignature.labels.ccsPlaceholder"
                      )}
                      onChange={(e) => setCcInputValue(e.target.value)}
                      onBlur={() => {
                        handleBlur();
                      }}
                    />
                  );
                }}
              />
            </>
          ) : null}
          <Styled.FieldLabel>
            {t("pages.contractDetails.modals.requestSignature.labels.language")}
          </Styled.FieldLabel>

          <RadioGroup
            name="language"
            control={control}
            options={languageOptions}
            row
          />
        </Styled.Form>
      </FormProvider>
    </div>
  );
};

export default RequestSignatureContent;
