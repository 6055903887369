import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CachedIcon from "@mui/icons-material/Cached";
import dayjs from "dayjs";
import { useLocale, useUserInfo } from "hooks";
import { Info } from "new-components";
import { dateFormats } from "constants/utils";
import CardBodyWrapper from "components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import CardHeaderWrapperWithButton from "components/CardHeaderWrapperWithButton/CardHeaderWrapperWithButton";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useStyles as useStyledBaseButtons } from "components/StyledComponents/StyledBaseButtons";
import routePaths from "constants/routePaths";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import RecentItem from "./RecentItem";
import SectionTableWrapper from "../SectionTableWrapper";
import { InfoWrapper, AiIcon, ArrowRightIcon } from "./styles";
import { WidgetProps } from "../Dashboard";
import { useRecentlyAddedOverviewQuery } from "shared/api/overview";
import { getSelectedData } from "../utils";

const Recent = ({ statisticsViewMode }: WidgetProps) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledBaseButtons();
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const { hasFeature, selectedTeamId } = useTeam();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const { data: recentlyAddedContracts } =
    useRecentlyAddedOverviewQuery(selectedTeamId);
  const selectedData = getSelectedData(
    statisticsViewMode,
    recentlyAddedContracts
  );

  const handleButtonClick = () => {
    navigate(routePaths.CONTRACTS, {
      state: {
        filter:
          selectedData && selectedData.length > 0
            ? "recently_added_contracts_view"
            : "",
      },
    });
  };

  const handleGoToContractsAnalysisView = () => {
    navigate(routePaths.CONTRACTS, {
      state: {
        filter:
          selectedData && selectedData.length > 0
            ? "contract_analysis_view"
            : "",
      },
    });
  };

  const formatDateAndTime = (date: string) => {
    return dayjs(date).locale(locale).format(`${userDateFormat}, HH:mm`);
  };

  return (
    <CardWrapper
      isShowTopAndBottomPaddingOnly={selectedData ? "true" : undefined}
    >
      <>
        <CardHeaderWrapperWithButton
          titleKey="pages.dashboard.recent.title"
          icon={<CachedIcon />}
          buttonStyle={buttonClasses.whiteButtonWithBlueLabel}
          colorStyle={theme.color.gray[600]}
          baseButtonTextKey="common.buttons.showAll"
          onButtonClick={handleButtonClick}
        />
        {selectedData != null && selectedData?.length > 0 ? (
          <SectionTableWrapper>
            {selectedData.map((item, index) => {
              return (
                <RecentItem
                  key={index}
                  item={item}
                  index={index}
                  rightSide={<span>{formatDateAndTime(item.createdAt)}</span>}
                />
              );
            })}
          </SectionTableWrapper>
        ) : (
          <CardBodyWrapper textKey="pages.dashboard.recent.description" />
        )}

        {hasFeature(Features.CONTRACT_ANALYSIS) && (
          <InfoWrapper>
            <Info
              className="info"
              onClick={handleGoToContractsAnalysisView}
              icon={<AiIcon />}
              text={t("pages.dashboard.recent.reviewAnalyzedContracts")}
              element={<ArrowRightIcon className="cta" />}
            />
          </InfoWrapper>
        )}
      </>
    </CardWrapper>
  );
};

export default Recent;
