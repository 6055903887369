import { useState, useEffect, useRef, memo } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { ImagePreview, Overlay, NoContent } from "./styles";
import { CircularProgress } from "@mui/material";

const MAX_RETRIES = 10;

const Preview = ({
  onClick,
  templateId,
  organizationId,
}: {
  templateId: string;
  organizationId: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const [fileURL, setFileURL] = useState<string | null>(null);
  const retryCount = useRef(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (templateId) {
      loadImage();
    }
  }, [templateId]);

  const loadImage = () => {
    const imageURL = `/api/organizations/${organizationId}/contract-template/templates/${templateId}/preview?timestamp=${Date.now()}`;

    const image = new Image();
    image.src = imageURL;

    image.onload = () => {
      setLoading(false);
      setFileURL(imageURL);
    };

    image.onerror = () => {
      onImageLoadError();
    };
  };

  const onImageLoadError = () => {
    if (retryCount.current < MAX_RETRIES) {
      retryCount.current += 1;
      setTimeout(() => {
        loadImage();
      }, retryCount.current * 1000);
    } else {
      Sentry.captureException(
        new Error("Error fetching template preview image"),
        (scope) => {
          scope.setExtra("fileURL", fileURL);
          return scope;
        }
      );
      setFileURL(null);
      setLoading(false);
    }
  };

  return (
    <ImagePreview onClick={onClick} role="button">
      {loading ? (
        <CircularProgress />
      ) : fileURL ? (
        <img src={fileURL} alt={t("common.labels.loading")} />
      ) : (
        <NoContent>
          <span>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.templateCard.noContent"
            )}
          </span>
        </NoContent>
      )}
      <Overlay />
    </ImagePreview>
  );
};

export default memo(Preview);
