import { Control } from "react-hook-form";
import FormDatePickerField from "components/FormItems/FormDatePicker/FormDatePicker";

const DueDateComponent = ({
  control,
  disabled,
  fieldName,
  fieldLabel,
}: {
  control: unknown;
  disabled?: boolean;
  fieldName: string;
  fieldLabel?: string;
}) => {
  return (
    <FormDatePickerField
      control={control as Control}
      name={fieldName}
      label={fieldLabel}
      disabled={disabled}
      disablePast
    />
  );
};

export default DueDateComponent;
