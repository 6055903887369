import { useEffect } from "react";
import Cross from "assets/svg/cross-icon.svg?react";
import { createPortal } from "react-dom";
import "overlayscrollbars/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  Overlay,
  Footer,
  Header,
  TitleText,
  Body,
  Content,
  BodyContent,
  ButtonContainer,
  Container,
} from "./styles";
import { CTAButton } from "components";
import { motion, AnimatePresence } from "framer-motion";

export type DrawerProps = {
  open: boolean;
  handleClose: () => void;
  title?: string | JSX.Element;
  body: string | JSX.Element;
  footer?: JSX.Element;
};

const Drawer = ({ title, body, footer, open, handleClose }: DrawerProps) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const clickOutside = () => {
    if (open) {
      handleClose();
    }
  };

  return createPortal(
    <AnimatePresence>
      <Container>
        {open && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
            onClick={clickOutside}
          >
            <Overlay onClick={clickOutside} />
          </motion.div>
        )}
        <motion.div
          initial={{ x: "100%" }}
          animate={open ? { x: "0" } : { x: "100%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.25 }}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            width: "600px",
            height: "100%",
            zIndex: "1",
          }}
        >
          <Content>
            <Header>
              {title && (
                <TitleText aria-label="drawer-title">{title}</TitleText>
              )}
              <ButtonContainer>
                <CTAButton
                  variant="tertiary"
                  icon={<Cross />}
                  onClick={handleClose}
                  color="gray"
                  iconSize="small"
                />
              </ButtonContainer>
            </Header>

            {body && (
              <OverlayScrollbarsComponent defer style={{ flex: "1" }}>
                <Body>
                  <BodyContent aria-label="drawer-body-content">
                    {body}
                  </BodyContent>
                </Body>
              </OverlayScrollbarsComponent>
            )}

            {footer && <Footer aria-label="drawer-footer">{footer}</Footer>}
          </Content>
        </motion.div>
      </Container>
    </AnimatePresence>,
    document.body
  );
};

export default Drawer;
