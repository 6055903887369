import LoginMethodForm from "./LoginMethodForm";
import HostnameForm from "./HostnameForm";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { Navigate } from "react-router-dom";
import routePaths from "constants/routePaths";
import DelegationForm from "./DelegationForm";
import { PageWrapper } from "./styles";

const Security = () => {
  const { hasFeature } = useTeam();

  if (!hasFeature(Features.SSO)) {
    return <Navigate to={routePaths.HOME} />;
  }

  const delegationEnabled = hasFeature(Features.DELEGATED_SSO);

  return (
    <PageWrapper>
      <HostnameForm />
      <LoginMethodForm />
      {delegationEnabled && <DelegationForm />}
    </PageWrapper>
  );
};

export default Security;
