import { useMemo } from "react";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { ContractFieldDTOV1, ContractSectionDTOV1 } from "openapi";
import { List, EmptyFieldsGroup, SortableField } from "../../components";
import { FieldsArea } from "./styles";
import { toSortableFieldId } from "../Draggable/SortableField";

type Props = {
  sectionFields: ContractFieldDTOV1[];
  sectionId: string;
  onRemoveField: (field: ContractFieldDTOV1, sectionId: string) => void;
};

const FieldsList = ({ sectionFields, sectionId, onRemoveField }: Props) => {
  const fieldIds = useMemo(
    () => sectionFields.map((f) => toSortableFieldId(f.id)),
    [sectionFields]
  );

  if (sectionFields?.length < 1) return null;

  return (
    <SortableContext
      id={sectionId}
      items={fieldIds}
      strategy={rectSortingStrategy}
    >
      <List>
        {sectionFields.map((field) => (
          <SortableField
            id={field?.id}
            key={field?.id}
            field={field}
            sectionId={sectionId}
            onRemoveField={onRemoveField}
          />
        ))}
      </List>
    </SortableContext>
  );
};

type DroppableAreaProps = {
  section: ContractSectionDTOV1;
  sectionId: string;
  onRemoveField: (field: ContractFieldDTOV1, sectionId: string) => void;
  isCollapsed?: boolean;
  showDroppableArea: boolean;
  hover: boolean;
  fields: ContractFieldDTOV1[];
};

const DroppableArea = ({
  sectionId,
  section,
  fields,
  onRemoveField,
  isCollapsed,
  showDroppableArea,
  hover,
}: DroppableAreaProps) => {
  const { isOver, setNodeRef, active } = useDroppable({
    id: `droppable-section-${sectionId}`,
    data: { type: "section", id: sectionId },
  });

  const isFieldsGroupEmpty = !section?.fields?.length;
  const activeIsField = active?.data?.current?.type === "field";

  // When moving sections
  if (isCollapsed) return null;

  // When moving fields from collection
  if (isFieldsGroupEmpty || showDroppableArea) {
    return (
      <FieldsArea ref={setNodeRef} highlighted={isOver && activeIsField}>
        <EmptyFieldsGroup />
      </FieldsArea>
    );
  }

  return (
    <FieldsArea highlighted={hover}>
      <FieldsList
        sectionFields={fields}
        sectionId={section.id}
        onRemoveField={onRemoveField}
      />
    </FieldsArea>
  );
};

export default DroppableArea;
