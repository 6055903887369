import styled from "@emotion/styled";
import { CardWrapper } from "components";

export const HeaderIconStyles = {
  backgroundColor: "var(--color-kaki-900)",
  width: "44px",
  height: "44px",
  borderRadius: "var(--border-radius)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  marginRight: "var(--spacing-lg)",
  svg: {
    fill: "var(--color-white)",
  },
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const Card = styled(CardWrapper)`
  max-height: 100%;
  margin: 0;
`;
