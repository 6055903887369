import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Grid } from "@mui/material";
import { useOrganizationQuery, useOrganizationMutation } from "./helpers";
import { FormTextField } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { ApiError } from "openapi";
import { Button } from "components";
import { BadRequestResponse } from "../../../shared/types/ErrorResponse.type";
import Typography from "@mui/material/Typography";

const OrganizationsDetails = () => {
  const { t } = useTranslation();
  const { id: organizationId } = useParams();
  const {
    data: organizationInfo,
    isLoading,
    refetch,
    error,
  } = useOrganizationQuery(organizationId);
  const { mutateAsync: updateOrganizationDetails, isLoading: isUpdating } =
    useOrganizationMutation();
  const { control, handleSubmit, formState, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: { chargebeeId?: string }) => {
    if (!organizationId) return;

    try {
      const inputData = {
        chargebeeId: data?.chargebeeId || undefined,
      };

      await updateOrganizationDetails({
        teamId: organizationId,
        ...inputData,
      } as {
        teamId: string;
        chargebeeId: string | null;
      });
      enqueueSnackbar(
        t(`pages.admin.tabs.organizations.modal.successMessage`),
        {
          variant: "success",
        }
      );
      void refetch();
    } catch (error) {
      if (error instanceof ApiError) {
        const errorMsg = (error.body as BadRequestResponse)
          .message as unknown as string;
        enqueueSnackbar(
          t(`pages.admin.tabs.organizations.errors.${errorMsg}`),
          {
            variant: "error",
          }
        );
        return;
      }
      enqueueSnackbar((error as ApiError).message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    reset({
      chargebeeId: organizationInfo?.chargebeeId,
    });
  }, [organizationInfo]);

  if (isLoading) return <p>Loading ...</p>;
  if (error) return <p>Error ...</p>;

  return (
    <CardWrapper>
      <Typography variant={"h5"} style={{ fontStyle: "bold" }}>
        Organization name: <b>{organizationInfo?.name}</b>
      </Typography>

      <form
        name="updateChargebeeIdForm"
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginTop: "1rem" }}
      >
        <Grid container sx={{ display: "flex", gap: ".5rem" }}>
          <Grid item sm={6}>
            <FormTextField
              defaultValue={organizationInfo?.name}
              control={control}
              name="name"
              label="Organization name:"
              disabled
            />
          </Grid>
          <Grid item sm={6}>
            <FormTextField
              defaultValue={organizationInfo?.id}
              control={control}
              name="id"
              label="Organization Id:"
              disabled
            />
          </Grid>

          <Grid item sm={6}>
            <FormTextField
              defaultValue={organizationInfo?.billingInfoCompany}
              control={control}
              name="billingInfoCompany"
              label="Billing Info:"
              disabled
            />
          </Grid>
          <Grid item sm={6}>
            <FormTextField
              defaultValue={organizationInfo?.chargebeeId}
              control={control}
              name="chargebeeId"
              label="Chargebee customer Id:"
              required
            />
          </Grid>

          <Grid item sm={6} style={{ display: "flex" }} gap={"1rem"}>
            {Object.keys(formState.dirtyFields).length > 0 && (
              <>
                <Grid item sm={6}>
                  <Button
                    variant="outlined"
                    type={"reset"}
                    onClick={() => reset(organizationInfo)}
                    fullWidth
                  >
                    {t("common.buttons.reset")}
                  </Button>
                </Grid>
                <Grid item sm={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    isLoading={isUpdating}
                    fullWidth
                  >
                    {t("common.buttons.save")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </CardWrapper>
  );
};

export default OrganizationsDetails;
