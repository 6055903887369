import { Loader } from "components";
import { TeamProvider } from "contexts";
import { useTeam } from "contexts/team/hooks";
import { CustomLayout } from "layouts";
import { Navigate } from "react-router-dom";
import { routePaths } from "../../constants";

const OrganizationLayout = () => {
  const { selectedTeamId, status } = useTeam();

  if (status === "idle" && selectedTeamId) return <Loader />;

  if (status === "success" && !selectedTeamId)
    return <Navigate to={routePaths.TEAM_CREATION} />;

  return <CustomLayout />;
};

const WithOrganizationProvider = () => {
  return (
    <TeamProvider>
      <OrganizationLayout />
    </TeamProvider>
  );
};

export default WithOrganizationProvider;
