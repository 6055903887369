import { Link as RouterLink } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import { TeamListItemDto, AdminService } from "openapi";
import { Header } from "components/BasicTable/types";

export const useTableHeaders = () => {
  const { t } = useTranslation();

  return [
    {
      key: "index",
      notSortable: true,
    },
    {
      key: "name",
      name: t("pages.admin.tabs.users.resultTable.tableHeader.name"),
    },
    {
      key: "chargebeeId",
      name: "chargeBeeID",
    },
  ] as Header[];
};

export const useTableRows = (rawData?: TeamListItemDto[]) =>
  rawData?.map((item, index) => {
    const orgDetailsLink = `/kiosk/organizations/${item.id}`;

    return {
      index: index + 1,
      name: (
        <>
          <Link
            component={RouterLink}
            to={orgDetailsLink}
            className="hover-underline"
          >
            <div>{item.name}</div>
          </Link>
        </>
      ),
      chargebeeId: (
        <>
          <Link
            component={RouterLink}
            to={orgDetailsLink}
            className="hover-underline"
          >
            <div>{item.chargebeeId}</div>
          </Link>
        </>
      ),
    };
  });

export const useOrganizationQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["organization-details", teamId],
    queryFn: () => AdminService.getTeamDetails(teamId as string),
    enabled: !!teamId,
  });
};

export const useOrganizationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { teamId: string; chargebeeId: string | null }) => {
      return AdminService.updateOrganization(data.teamId, {
        chargebeeId: data.chargebeeId as string,
      });
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["organization-details", variables.teamId],
      });
    },
  });
};
