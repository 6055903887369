import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import BaseTextField, { BaseTextFieldProps } from "./TextField";

export type ControlledTextFieldProps<TFieldValues extends FieldValues> = Omit<
  BaseTextFieldProps,
  "name" | "onChange" | "onBlur" | "value" | "defaultValue"
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, FieldPath<TFieldValues>>;
};

export const TextField = <TFieldValues extends FieldValues>({
  control,
  name,
  defaultValue,
  placeholder,
  fullWidth,
  size,
  isBorderLessInput,
  error,
  required,
  disabled,
  suggestion,
  ...rest
}: ControlledTextFieldProps<TFieldValues>) => {
  const { t } = useTranslation();

  const trimValue = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.value = e.target.value.toString().trim();
    return e;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <BaseTextField
          {...rest}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onChange(trimValue(e));
            onBlur();
          }}
          error={invalid}
          fullWidth={fullWidth}
          size={size}
          isBorderLessInput={isBorderLessInput}
          suggestion={suggestion}
          disabled={disabled}
          required={required}
          helperText={t(error?.message as string) || rest.helperText}
        />
      )}
    />
  );
};

export default TextField;
