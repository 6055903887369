import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Tooltip } from "@mui/material";
import { SignerDTO } from "openapi";
import { useSignatureProvider } from "pages/Contract/hooks/useSignature";
import { useTranslation } from "react-i18next";
import { isSendReminderAllowed } from "./helpers";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";

const LOCALE_BASE =
  "pages.contractDetails.signature.table.cells.reminder.tooltip";

export const RemindButton = ({
  signer,
  contractId,
}: {
  signer: SignerDTO;
  contractId: string;
}) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledButtons();
  const { sendReminder } = useSignatureProvider();
  const [isDisabled, setIsDisabled] = useState(false);
  const isSigned = signer.status === "signed";

  const handleRemindToSign = () => {
    if (!signer.id || !contractId) return;
    void sendReminder(contractId, signer.id);
    setIsDisabled(true);
  };

  const disabledTooltipTitle = isDisabled ? t(`${LOCALE_BASE}.disabled`) : "";
  const tooltipTitle = isSigned
    ? t(`${LOCALE_BASE}.signed`, {
        name: signer.name,
      })
    : disabledTooltipTitle;

  useEffect(() => {
    const isRequestAllowed = isSendReminderAllowed(signer, 2);

    if (isRequestAllowed && !isSigned) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [signer]);

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <span>
        <Button
          className={buttonClasses.previewHeaderButton}
          onClick={handleRemindToSign}
          disabled={isDisabled}
        >
          {t("common.buttons.remind")}
        </Button>
      </span>
    </Tooltip>
  );
};
