import { ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { ContractDTOV1 } from "openapi";
import { useContractVerificationMutation } from "shared/api";
import VerifyTag from "../components/VerifyTag/VerifyTag";
import { useTeam } from "contexts/team/hooks";

const VerifiedCellRenderer = (props: ICellRendererParams) => {
  const contractVerificationMutation = useContractVerificationMutation();
  const { t } = useTranslation();
  const { organizationId } = useTeam();

  if (!props?.data) return;

  const data = props.data as ContractDTOV1;
  const teamId = data.teamId;
  const contractId = data.id;
  const isVerified = !data.unapprovedAnalysisValues;
  const isProcessing = data.status === "PROCESSING";

  const handleOnClick = async () => {
    try {
      await contractVerificationMutation.mutateAsync({
        organizationId,
        teamId,
        contractId,
      });

      enqueueSnackbar(
        t("pages.contracts.messages.successfulContractVerification", {
          contractName: (props.data as ContractDTOV1).name,
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(
        t("pages.contracts.messages.failedContractVerification", {
          contractName: (props.data as ContractDTOV1).name,
        }),
        {
          variant: "error",
        }
      );

      console.error(e);
    }
  };

  return (
    <VerifyTag
      onClick={handleOnClick}
      verified={isVerified}
      disabled={isProcessing}
    />
  );
};

export default VerifiedCellRenderer;
