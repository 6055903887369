/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppDTO = {
  id: string;
  name: string;
  description: Record<string, string>;
  logo_path?: string;
  status: AppStatus | string;
  categories: string[];
  pinned: boolean;
};

export type AppCategoryDTO = {
  id: string;
  name: Record<string, string>;
};

export const APP_STATUSES = {
  AVAILABLE: "AVAILABLE",
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
} as const;

export type AppStatus = (typeof APP_STATUSES)[keyof typeof APP_STATUSES];

export const TAB_KEYS = {
  APPS: "apps",
  WEBHOOKS: "webhooks",
};
