import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link } from "@mui/material";

import logoSrc from "../../assets/svg/logo-sidebar.svg";
import Gears from "./gears.svg?react";
import { useStyles } from "../../components/StyledComponents/StyledBaseButtons";
import {
  Wrapper,
  MainContainer,
  Content,
  SvgContainer,
  ContactContainer,
  Logo,
  Details,
  DetailsItem,
  InfoButton,
} from "./styles";

const Maintenance = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const buttonClasses = useStyles();

  return (
    <Wrapper>
      <MainContainer>
        <Content>
          <h1>{t("pages.maintenance.title")}</h1>
          <p>
            {t("pages.maintenance.description")}
            <InfoButton onClick={() => setShow(!show)} />
          </p>
          <Link href="https://www.contracthero.com" target="_blank">
            <Button className={buttonClasses.baseButton}>
              {t("pages.maintenance.button")}
            </Button>
          </Link>
        </Content>
        <SvgContainer>
          <br />
          <br />
          <Gears />
        </SvgContainer>
      </MainContainer>

      <ContactContainer active={show}>
        <Logo src={logoSrc} alt="contract hero logo" />
        <Details>
          <DetailsItem>ContractHero GmbH</DetailsItem>
          <DetailsItem>Kiautschoustraße 14</DetailsItem>
          <DetailsItem>13353 Berlin</DetailsItem>
          <DetailsItem>Mo-Fr: 09-18Uhr</DetailsItem>
          <DetailsItem>
            <a href="mailto:info@contracthero.com">info@contracthero.com</a>
          </DetailsItem>
          <DetailsItem>
            <a href="tel:+49 156 7837 0933">+49 156 7837 0933</a>
          </DetailsItem>
        </Details>
      </ContactContainer>
    </Wrapper>
  );
};

export default Maintenance;
