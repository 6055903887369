import { TextFieldProps } from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { StyledTextField } from "../../StyledComponents/StyledBaseFields";
import { useTranslation } from "react-i18next";

export type FormTextFieldProps<T extends FieldValues> = Omit<
  TextFieldProps,
  "name"
> & {
  control?: Control<T>;
  name: Path<T>;
  margin?: "none" | "dense" | "normal";
  defaultValue?: PathValue<T, Path<T>>;
  inputProps?: {
    suggestion?: boolean;
    value?: string;
  };
  helperText?: string;
};

export default function FormTextField<T extends FieldValues>({
  name,
  control,
  inputProps,
  margin,
  defaultValue,
  helperText,
  InputProps,
  ...rest
}: FormTextFieldProps<T>): JSX.Element {
  const { t } = useTranslation();

  const trimValue = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.value = e.target.value.toString().trim();
    return e;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <StyledTextField
          {...rest}
          id={name}
          margin={margin ?? "normal"}
          value={value ?? ""}
          onChange={onChange}
          onBlur={(e) => {
            onChange(trimValue(e));
            onBlur();
          }}
          fullWidth
          size="small"
          error={invalid}
          helperText={t(error?.message as string) || helperText || ""}
          suggestion={inputProps?.suggestion}
          inputProps={inputProps}
          InputProps={InputProps}
        />
      )}
    />
  );
}
