import "./style";
import { LoaderCubes } from "./style";

const Loader = () => {
  return (
    <LoaderCubes data-testid="loader">
      <div></div>
      <div></div>
      <div></div>
    </LoaderCubes>
  );
};
export default Loader;
