import DotsHorizontalIcon from "assets/svg/dots-horizontal.svg?react";
import PrintIcon from "assets/svg/print-icon.svg?react";
import { CTAButton } from "components";
import { usePrintContext } from "contexts/contract/context";
import { useOutsideClick } from "hooks";
import { t } from "i18next";
import { ContractAttachmentListItemDTO } from "openapi";
import DeleteContract from "pages/Contract/components/Related/components/DeleteContract/DeleteContract";
import { useState } from "react";
import { Divider, DropdownMenu, DropdownWrapper } from "./styles";

type Props = {
  contractGroup?: ContractAttachmentListItemDTO[];
};

const Dropdown = ({ contractGroup }: Props) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { handlePrint } = usePrintContext();

  const dropdownRef = useOutsideClick(() => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  const onPrintHandler = () => {
    setIsDropdownOpened(false);
    handlePrint();
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <CTAButton
        label="toggle-contract-menu-button"
        icon={<DotsHorizontalIcon />}
        onClick={() => setIsDropdownOpened(!isDropdownOpened)}
        iconSize="medium"
        size="square"
        variant="secondary"
      />
      {!!isDropdownOpened && (
        <DropdownMenu>
          <CTAButton
            variant="tertiary"
            label="print-contract-details-button"
            onClick={onPrintHandler}
            icon={<PrintIcon fontSize="small" />}
            name={t("pages.contractDetails.buttons.printContractDetails")}
            iconSize="medium"
            color="gray"
          />
          <Divider />
          <DeleteContract contractGroup={contractGroup} buttonType="dropdown" />
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
