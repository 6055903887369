import { ChangeLogDataItemType } from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { getFieldIcon } from "../LogEntity/LogEntity";
import { ContractFieldDTOV1 } from "openapi";
import { useLocale, useUserInfo } from "hooks";
import dayjs from "dayjs";

export type DateFieldType = {
  fieldData: ChangeLogDataItemType;
  fieldVisibleId: string;
  fields: ContractFieldDTOV1[] | undefined;
  variant?: "row";
};

const DateField = ({
  fieldData,
  fieldVisibleId,
  fields,
  variant,
}: DateFieldType) => {
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();

  const field = fields?.find((field) => field.visibleId === fieldVisibleId);
  const fieldName = field?.name[locale];
  const convertedDate = {
    from: {
      value: fieldData?.from?.value
        ? dayjs(fieldData?.from?.value).format(userInfo?.dateFormat)
        : "",
    },
    to: {
      value: fieldData?.to?.value
        ? dayjs(fieldData?.to?.value).format(userInfo?.dateFormat)
        : "",
    },
  };
  const fieldIcon = getFieldIcon(field?.type);
  return (
    <EditEntityItem
      data={convertedDate}
      fieldName={fieldName}
      fieldIcon={fieldIcon}
      variant={variant}
    />
  );
};

export default DateField;
