import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import ZoomOutIcon from "assets/svg/zoom-out-icon.svg?react";

export const ZoomOutButton = ({
  Slot,
  collapses,
  collapsed,
}: ToolbarSpecificButtonProps<"ZoomOut">) => {
  const { t } = useTranslation();
  return (
    <Slot>
      {(props) => {
        return collapsed ? (
          <MenuItem
            onClick={() => {
              props.onClick();
            }}
          >
            <ListItemIcon>
              <ZoomOutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("pdfViewer.toolbar.zoomOut")}</ListItemText>
          </MenuItem>
        ) : (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.zoomOut")}
            sx={{ ml: "auto" }}
            onClick={() => {
              props.onClick();
            }}
            collapses={collapses}
          >
            <ZoomOutIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
