import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ContractDTOV1, TagDto } from "openapi";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import {
  Collapse,
  Autocomplete,
  InputAdornment,
  AutocompleteRenderInputParams,
} from "@mui/material";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { AddNewTagIcon, Tag } from "./components";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { EmptyTag, TagWrapper } from "./styles";
import {
  useAddNewTagToContractMutation,
  useTagQuery,
  useAddTagToContractMutation,
  useRemoveTagFromContractMutation,
} from "shared/api/team";

export type TagsSectionProps = {
  contract: ContractDTOV1;
  hasWriteAccess: boolean;
};

const TagsSection = ({ contract, hasWriteAccess }: TagsSectionProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isTagExists, setIsTagExists] = useState<boolean>(false);
  const [collapseStatus, setCollapseStatus] = useState("close");
  const [selectedTagList, setSelectedTagList] = useState<TagDto[]>(
    contract.tags
  );

  const { data: tagList } = useTagQuery(contract.teamId);
  const { mutateAsync: addTag } = useAddTagToContractMutation();
  const { mutateAsync: removeTag } = useRemoveTagFromContractMutation();
  const { mutateAsync: addNewTag } = useAddNewTagToContractMutation();

  useEffect(() => {
    setSelectedTagList(contract.tags);
  }, [contract]);

  const handleChange = async (newTag?: TagDto | string) => {
    if (!newTag) return;
    if (typeof newTag === "string") {
      const existingTag = tagList?.find((tag) => tag.name === newTag);
      if (!existingTag) {
        await addNewTagToContract(newTag);
        return;
      }
      newTag = existingTag;
    }
    const isTagAlreadyAdded = selectedTagList.some(
      (item) => item.name === (newTag as TagDto)?.name
    );

    if (isTagAlreadyAdded) {
      setIsTagExists(true);
      enqueueSnackbar(t("pages.contractDetails.tags.existingTag"), {
        variant: "error",
      });
      return;
    }
    await addTagToContract(newTag);
  };

  const addTagToContract = async (tag: TagDto) => {
    await addTag({
      contractId: contract.id,
      tagId: tag.id,
      teamId: contract.teamId,
    });
    setSelectedTagList([...selectedTagList, tag]);
  };

  const removeTagFromContract = async (tag: TagDto) => {
    await removeTag({
      contractId: contract.id,
      tagId: tag.id,
      teamId: contract.teamId,
    });
    const _filteredTags = selectedTagList.filter((item) => item.id !== tag.id);
    setSelectedTagList(_filteredTags);
  };

  const addNewTagToContract = async (name: string) => {
    const newTag = await addNewTag({
      teamId: contract.teamId,
      name: name.trim(),
      color: "#2788b2",
    });
    await addTagToContract(newTag);
  };

  const tagOptions = tagList?.map((tag) => {
    return {
      label: tag.name,
      name: tag.name,
      id: tag.id,
      color: tag.color,
    };
  });

  const selectedTagIds = selectedTagList?.map((item) => item.id);
  const tagSelectOptions = tagOptions?.filter(
    (item) => !selectedTagIds?.includes(item.id)
  );

  return (
    <CardWrapper>
      <TagWrapper>
        {hasWriteAccess && (
          <AddNewTagIcon
            status={collapseStatus}
            onClick={() =>
              setCollapseStatus((status) =>
                status === "open" ? "close" : "open"
              )
            }
          />
        )}
        {selectedTagList?.length ? (
          selectedTagList.map((tag, index) => (
            <Tag
              key={index}
              tag={tag}
              hasWriteAccess={hasWriteAccess}
              onDelete={() => removeTagFromContract(tag)}
            />
          ))
        ) : (
          <EmptyTag>{t("pages.contractDetails.tags.emptyTags")}</EmptyTag>
        )}
      </TagWrapper>

      <Collapse in={collapseStatus === "open"}>
        {hasWriteAccess ? (
          <Autocomplete
            value={[]}
            multiple
            freeSolo
            disableClearable
            data-testid="contract-tags"
            id="contract-tags"
            options={tagSelectOptions?.map((option) => option) || []}
            onChange={async (event, newValue, reason, details) => {
              await handleChange(details?.option);
            }}
            filterOptions={(options, params) => {
              setIsTagExists(false);
              return options.filter((item) =>
                item.name.includes(params.inputValue)
              );
            }}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            renderTags={(value: readonly TagDto[]) =>
              value.map((tag: TagDto, index: number) => (
                <Tag
                  key={index}
                  tag={tag}
                  hasWriteAccess={hasWriteAccess}
                  onDelete={() => removeTagFromContract(tag)}
                />
              ))
            }
            renderInput={(params) => {
              const _params = {
                ...params,
                InputProps: {
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SellOutlinedIcon />
                    </InputAdornment>
                  ),
                },
                size: "small",
              } as AutocompleteRenderInputParams;
              return (
                <StyledTextField
                  {..._params}
                  style={{ marginTop: "1rem" }}
                  error={isTagExists}
                  variant="outlined"
                  label={t("pages.contractDetails.tags.tagInputLabel")}
                />
              );
            }}
          />
        ) : (
          <></>
        )}
      </Collapse>
    </CardWrapper>
  );
};

export default TagsSection;
