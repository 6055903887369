import { useTranslation } from "react-i18next";
import { Link } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export type BaseDropDownButtonItemProps = {
  id: string;
  textKey: string;
  linkClassName?: string;
  itemClassName?: string;
  icon?: JSX.Element;
};

type Props = {
  onClick?: (id: string) => void;
};

const BaseDropDownButtonItem = ({
  id,
  textKey,
  linkClassName,
  itemClassName,
  icon,
  onClick,
}: BaseDropDownButtonItemProps & Props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(id);
  };

  return (
    <Link className={linkClassName}>
      <ListItem
        button
        id={id}
        data-testid={id}
        onClick={() => handleClick()}
        className={itemClassName}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={t(textKey)} />
      </ListItem>
    </Link>
  );
};

export default BaseDropDownButtonItem;
