import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import categoriesData from "../../data/categoriesData.json";
import appsData from "../../data/appsData.json";
import { AppDTO, AppStatus } from "../../types";
import AppCard from "../AppCard/AppCard";
import { orderBy } from "lodash";
import {
  Cards,
  Categories,
  Wrapper,
  Title,
  Sidebar,
  CategoryButton,
  SearchBar,
  Main,
  ScrollWrapper,
} from "./styles";
import { useLocale } from "hooks";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const Apps = () => {
  const { control, watch } = useForm();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [activeCategory, setActiveCategory] = useState("all");
  const appsSorted = orderBy(appsData, ["pinned"], ["desc"]);

  const [filteredAppData, setFileteredAppData] = useState<AppDTO[]>(appsSorted);

  const searchTerm = watch("search") as string;

  const onCategoryClick = (id: string) => {
    setActiveCategory(id);
    let filteredList: AppDTO[] | undefined = appsSorted ?? [];
    if (searchTerm) {
      filteredList = filterBySubstring(searchTerm, appsSorted);
    }
    filteredList = filterByCategory(id, filteredList) || [];
    setFileteredAppData(filteredList);
  };

  const onSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    let filteredList: AppDTO[] | undefined = appsSorted ?? [];
    if (activeCategory) {
      filteredList = filterByCategory(activeCategory, appsSorted);
    }
    filteredList = filterBySubstring(searchTerm, filteredList) || [];
    setFileteredAppData(filteredList);
  };

  const filterBySubstring = (string: string, list?: AppDTO[]) => {
    const filteredList = list?.filter((item) =>
      item.name.toLowerCase().includes(string)
    );
    if (filteredList) {
      return filteredList;
    }
  };

  const filterByCategory = (id: string, list?: AppDTO[]) => {
    let filteredList;
    if (id !== "all") {
      filteredList = list?.filter((item) => item?.categories?.includes(id));
      if (filteredList) {
        return filteredList;
      }
    } else {
      return list;
    }
  };

  const sortedData = filteredAppData?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Wrapper>
      <Sidebar>
        <Categories>
          <Title>
            <span>
              {t("pages.settings.organization.apps.titles.categories")}
            </span>
          </Title>
          <div>
            {/* @Todo find other places where it is used / may be used and create a component */}
            <CategoryButton
              onClick={() => onCategoryClick("all")}
              active={activeCategory === "all"}
            >
              {t("pages.settings.organization.apps.titles.all")}
            </CategoryButton>
            {categoriesData.map((category) => (
              <CategoryButton
                onClick={() => onCategoryClick(category.id)}
                key={category.id}
                active={category.id === activeCategory}
              >
                {category.name[locale]}
              </CategoryButton>
            ))}
          </div>
        </Categories>
      </Sidebar>
      <Main>
        <SearchBar
          name="search"
          control={control}
          label={t("common.components.search.placeholder")}
          InputProps={{
            onInput: onSearchInput,
          }}
          margin="none"
        />
        <ScrollWrapper>
          <OverlayScrollbarsComponent
            defer
            style={{ overflowY: "auto", maxHeight: "100%" }}
          >
            {sortedData.length ? (
              <Cards>
                {sortedData.map((app) => (
                  <AppCard
                    name={app.name}
                    description={app.description[locale]}
                    status={app.status as AppStatus}
                    logoPath={app.logo_path}
                    key={app.id}
                  />
                ))}
              </Cards>
            ) : (
              <div>
                {t("pages.settings.organization.apps.messages.noResults")}
              </div>
            )}
          </OverlayScrollbarsComponent>
        </ScrollWrapper>
      </Main>
    </Wrapper>
  );
};

export default Apps;
