import { useEffect, useRef, useState } from "react";
import { CellEditorField } from "./style";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { CellValue } from "../helpers";
import { DatePicker } from "components/DatePicker/DatePicker";
import { flushSync } from "react-dom";
import { CustomCellEditorProps } from "ag-grid-react";

interface Props extends CustomCellEditorProps<CellValue> {
  value: CellValue;
  isCustomField?: boolean;
}

export const DateCellEditor = (props: Props) => {
  const { isCustomField, value: cellValue } = props;
  const [value, setValue] = useState<string | null>(
    (cellValue?.value ?? cellValue) as string
  );
  const refInput = useRef<HTMLInputElement | null>(null);
  const refCalendar = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event: MouseEvent) {
    const isClickedOutside =
      refInput.current &&
      !refInput.current.contains(event.target as Node) &&
      refCalendar.current &&
      !refCalendar.current.contains(event.target as Node);
    // Close cell editor when clicking outside the input and the calendar
    if (isClickedOutside || isClickedOutside === null) {
      flushSync(() => {
        refInput.current?.blur();
      });
      props.api.stopEditing();
    }
  }

  const handleValueChanged = (value: string | null) => {
    if (!value) {
      setValue(null);
    }
    setValue(value);
    const formattedValue = isCustomField ? { value: value } : value;
    props.onValueChange(formattedValue);
  };

  // focus on the input
  useEffect(() => {
    // get ref from React component
    refInput.current?.focus();
  }, []);

  return (
    <DatePicker
      ref={refCalendar}
      inputRef={refInput}
      value={value ?? null}
      inputProps={{
        sx: { fontSize: "12px" },
      }}
      datePickerProps={{
        slots: {
          textField: CellEditorField,
        },
        slotProps: {
          popper: {
            role: "presentation",
            ref: refCalendar,
          },
        },
      }}
      onDateChanged={(value) => {
        handleValueChanged(value);
      }}
    />
  );
};
DateCellEditor.displayName = "DateCellEditor";
