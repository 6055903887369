import { useTranslation } from "react-i18next";
import { Wrapper, Title, Email, ListWrapper } from "./styles";

import { TeamMemberLogDTO, TeamMemberDto, RoleDTO, TeamDTOV1 } from "openapi";
import { AllowIcon, DenyIcon, Value } from "../InitialEntry/styles";
import { Role } from "components";
import { getNameWithSuffix } from "pages/Settings/OrganizationSettings/Organization/helper";

export type MemberInitialType = {
  data: TeamMemberLogDTO;
  members: TeamMemberDto[];
  roles: RoleDTO[];
  teams: TeamDTOV1[];
};

const MemberInitial = ({ data, members, roles, teams }: MemberInitialType) => {
  const { t } = useTranslation();

  const member = members.find((item) => item.id === data.memberId);

  const memberName = member
    ? `${member?.firstname ?? ""} ${member?.lastname ?? ""}`
    : t("common.labels.deletedUser");

  const isOwner = data.owner;

  return (
    <Wrapper>
      <Title>
        <div>{memberName}</div> <Email>{data.email}</Email>
      </Title>
      <Value>
        {isOwner ? <AllowIcon /> : <DenyIcon />}
        <div>
          {t("pages.settings.organization.permissionChangeLog.labels.owner")}
        </div>
      </Value>
      {!!data.roles.length && !isOwner && (
        <Value>
          <AllowIcon />
          <ListWrapper>
            {data.roles.map((roleItem) => {
              const role = roles.find((item) => item.id === roleItem.id);

              const roleName = role
                ? getNameWithSuffix(role.name, role.teamId, teams, t)
                : t("common.labels.deletedRole");
              return <Role key={roleItem.id} name={roleName} />;
            })}
          </ListWrapper>
        </Value>
      )}
      {!isOwner && (
        <>
          <Value>
            {data.taskAutomationAdmin ? <AllowIcon /> : <DenyIcon />}
            <div>
              {t(
                "pages.settings.organization.permissionChangeLog.labels.taskAutomation"
              )}
            </div>
          </Value>
          <Value>
            {data.categoryAdmin || data.owner ? <AllowIcon /> : <DenyIcon />}
            <div>
              {t(
                "pages.settings.organization.permissionChangeLog.labels.categoryAdmin"
              )}
            </div>
          </Value>
        </>
      )}
    </Wrapper>
  );
};

export default MemberInitial;
