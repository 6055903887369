import { Navigate, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useContractTemplatesQueryV1 } from "shared/api/contract-templates";
import { useTeam } from "contexts/team/hooks";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { Features } from "constants/features";
import routePaths from "constants/routePaths";
import TemplateCollection from "./TemplateCollection/TemplateCollection";
import { Header } from "./TemplateCollection/styles";
import { ArrowIcon } from "../ContractTemplates/components/Header/styles";
import { ImportTemplate } from "./components";
import { useTemplate } from "./context";

export const ContractTemplates = () => {
  const navigate = useNavigate();
  const { organizationId, hasFeature } = useTeam();
  const { data } = useContractTemplatesQueryV1(organizationId);
  const { setImportedFile } = useTemplate();

  if (!data) return <CircularLoading isLoading />;

  if (!hasFeature(Features.CONTRACT_TEMPLATES))
    return <Navigate to={routePaths.HOME} />;

  return (
    <>
      <Header>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowIcon />
        </IconButton>

        <ImportTemplate setImportedFile={setImportedFile} />
      </Header>

      <TemplateCollection />
    </>
  );
};
