import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserListDto } from "openapi";
import UserSearchForm from "./UserSearchForm";
import UserResultTable from "./UserResultTable";
import UserImpersonate from "./UserImpersonate";

const Users = () => {
  const { id } = useParams();
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [showSearchBarAndResult, setShowSearchBarAndResult] = useState(true);
  const [userResults, setUserResults] = useState<UserListDto>();

  useEffect(() => setShowSearchBarAndResult(id == null), [id]);

  return (
    <>
      {showSearchBarAndResult ? (
        <>
          <UserSearchForm
            searchResultSetter={setUserResults}
            searchResultTableDisplaySetter={setShowSearchResult}
          />
          {showSearchResult && <UserResultTable userData={userResults} />}
        </>
      ) : (
        <UserImpersonate />
      )}
    </>
  );
};

export default Users;
