import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { Box, Button } from "@mui/material";
import { UserService } from "openapi";
import { passwordValidator } from "constants/utils";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { setValidationErrors } from "shared/service/errorResponseService";
import { FormTextField } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import {
  AuthResponse,
  useOAuthAction,
} from "pages/LoginPage/components/OAuthAction";
import { AuthProvider } from "../../../LoginPage/components/AuthProvider.enum";
import { useHostname } from "hooks/HostnameHook";
import { useAuthMethodsQuery } from "shared/api/auth";

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const SecurityForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userInfo, refreshUserInfo } = useUserInfo();
  const { enqueueSnackbar } = useSnackbar();

  const hasPassword = userInfo?.hasPassword;

  const resolver = useMemo(
    () =>
      yupResolver(
        Yup.object({
          currentPassword: hasPassword
            ? Yup.string().required()
            : Yup.string().optional(),
          newPassword: passwordValidator(),
          confirmNewPassword: Yup.string().oneOf(
            [Yup.ref("newPassword"), null],
            "pages.settings.tabs.security.validation.passwordConfirmation"
          ),
        })
      ),
    [hasPassword]
  );

  const { control, reset, getValues, handleSubmit, setError } = useForm({
    defaultValues: initialValues,
    resolver: resolver,
  });

  const hostname = useHostname();
  const { data } = useAuthMethodsQuery(hostname ?? "");

  const googleAction = useOAuthAction(AuthProvider.GOOGLE);
  const microsoftAction = useOAuthAction(AuthProvider.MICROSOFT);
  const oidcAction = useOAuthAction(AuthProvider.OIDC, data?.oidc ?? undefined);

  const changePassword = async (provider?: string, result?: AuthResponse) => {
    const { currentPassword, newPassword } = getValues();
    try {
      await UserService.changePassword({
        currentPassword: hasPassword ? currentPassword : null,
        newPassword: newPassword,
        authenticationData: result && {
          ...result,
          provider: provider as string,
          hostname: hostname,
        },
      });
      reset();
      void refreshUserInfo();
      enqueueSnackbar(
        t("pages.settings.tabs.security.information.passwordUpdated"),
        { variant: "success" }
      );
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        "pages.settings.tabs.security",
        getValues,
        enqueueSnackbar,
        t
      );
    }
  };

  useEffect(() => {
    let usedAction: typeof oidcAction | null = null;
    if (oidcAction.actionResult) {
      usedAction = oidcAction;
    } else if (googleAction.actionResult) {
      usedAction = googleAction;
    } else if (microsoftAction.actionResult) {
      usedAction = microsoftAction;
    }

    if (usedAction) {
      void changePassword(usedAction.provider, usedAction.actionResult).then(
        () => {
          if (usedAction) usedAction.reset();
        }
      );
    }
  }, [googleAction, microsoftAction, oidcAction]);

  const triggerAuthFlow = async () => {
    const identities = await UserService.getFederatedIdentities();
    if (identities.oidc && hostname) {
      void oidcAction.triggerAuthFlow();
      return;
    }
    if (identities.google && !hostname) {
      void googleAction.triggerAuthFlow();
      return;
    }
    if (identities.microsoft && !hostname) {
      void microsoftAction.triggerAuthFlow();
      return;
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    if (!hasPassword) {
      void triggerAuthFlow();
      return;
    }

    await changePassword();
  };

  return (
    <>
      <form name="securityForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        {hasPassword && (
          <FormTextField
            control={control}
            name="currentPassword"
            label={t("pages.settings.tabs.security.form.currentPassword")}
            type="password"
            required
          />
        )}
        <FormTextField
          control={control}
          name="newPassword"
          label={t("pages.settings.tabs.security.form.newPassword")}
          type="password"
          required
        />
        <FormTextField
          control={control}
          name="confirmNewPassword"
          label={t("pages.settings.tabs.security.form.confirmNewPassword")}
          type="password"
          required
        />

        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            type="submit"
            size="large"
            className={classes.baseButton}
            fullWidth
          >
            {t("pages.settings.tabs.security.form.submit")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default SecurityForm;
