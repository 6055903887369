import { motion } from "framer-motion";
import { motionStyles } from "../styles";

export const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
};

interface Props {
  children: React.ReactNode;
  padding?: string;
}

export const MotionWrapper = ({ children, padding = "0" }: Props) => (
  <motion.div
    {...motionProps}
    style={{ ...motionStyles, padding: padding || "var(--spacing-md)" }}
  >
    {children}
  </motion.div>
);
