import { useTranslation } from "react-i18next";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { theme } from "theme";
import { OverviewSignatureDataDTO, SignerDTO } from "openapi";
import { Features, routePaths } from "../../../constants";
import {
  EmptyMessage,
  SignatureHeaderContainer,
  SignerStatusContainer,
  Point,
  SignatureCreditContainer,
  SignatureCreditInfo,
  SignatureType,
  SignatureToolTipContainer,
} from "./styles";
import { Tooltip } from "@mui/material";
import CardHeaderWrapperWithButton from "../../../components/CardHeaderWrapperWithButton/CardHeaderWrapperWithButton";
import { useStyles as useStyledBaseButtons } from "../../../components/StyledComponents/StyledBaseButtons";
import { useNavigate } from "react-router-dom";
import RecentItem from "../Recent/RecentItem";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  CheckedDashedIcon,
  CheckedIcon,
  SignatureIcon,
} from "../../../components/Icons";
import { useTeam } from "../../../contexts/team/hooks";
import { Language } from "../../../shared/i18n/i18n";
import { useLocale } from "../../../hooks";
import { WidgetProps } from "../Dashboard";
import { useSignatureOverviewQuery } from "shared/api/overview";
import { getSelectedData } from "../utils";

type Props = {
  name: string;
  title: string;
  usage: number;
  credit: number;
  ppu: number;
  hasPPU: boolean;
  showPoint: boolean;
};

const SignatureUsage = ({
  usage,
  credit,
  ppu,
  name,
  title,
  hasPPU,
  showPoint,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <SignatureToolTipContainer>
          <div>{`${title}: ${credit && `${usage}/${credit}`} `}</div>
          {hasPPU && (
            <div>{`${t("pages.dashboard.signature.ppuText")}: ${ppu}`}</div>
          )}
        </SignatureToolTipContainer>
      }
      placement="top"
    >
      <SignatureCreditInfo>
        {showPoint && <Point size={6} />}
        <span>
          {name}: {hasPPU && `(${ppu}) `}
          {credit && `${usage}/${credit}`}
        </span>
      </SignatureCreditInfo>
    </Tooltip>
  );
};

const SignatureHeader = ({ data }: { data?: OverviewSignatureDataDTO }) => {
  const { t } = useTranslation();
  const { hasFeature } = useTeam();
  const { locale } = useLocale();

  const displayPPUData =
    hasFeature(Features.ESIGNATURE_PPU) ||
    hasFeature(Features.EID_ESIGNATURE_PPU);

  const showPoints = displayPPUData
    ? hasFeature(Features.ESIGNATURE) || hasFeature(Features.EID_ESIGNATURE)
    : hasFeature(Features.ESIGNATURE) && hasFeature(Features.EID_ESIGNATURE);

  const getDateShortForm = (value: string, locale: Language) => {
    if (!value) {
      return;
    }
    const date = new Date(value);

    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    return date.toLocaleDateString(
      locale === Language.de ? "de-DE" : "en-US",
      options
    );
  };

  if (!data) return <></>;

  return (
    <SignatureHeaderContainer>
      <span>{t("pages.dashboard.signature.title")}</span>
      <SignatureCreditContainer>
        {displayPPUData && (
          <Tooltip
            title={t("pages.dashboard.signature.billedText")}
            placement={"top"}
          >
            <SignatureCreditInfo>
              <span>
                {t("pages.dashboard.signature.billed")}:{" "}
                {getDateShortForm(data.billingDate, locale)}
              </span>
            </SignatureCreditInfo>
          </Tooltip>
        )}
        <SignatureUsage
          name={t("pages.dashboard.signature.aes")}
          title={t("pages.dashboard.signature.aesText")}
          usage={data.signatureUsage}
          credit={data.signatureCredit}
          ppu={data.signaturePPUUsage}
          hasPPU={displayPPUData}
          showPoint={showPoints}
        />
        {hasFeature(Features.EID_ESIGNATURE) && (
          <SignatureUsage
            name="QES"
            title={t("pages.dashboard.signature.qesText")}
            usage={data.eidSignatureUsage}
            credit={data.eidSignatureCredit}
            ppu={data.eidSignaturePPUUsage}
            hasPPU={displayPPUData}
            showPoint={showPoints}
          />
        )}
      </SignatureCreditContainer>
    </SignatureHeaderContainer>
  );
};

const SignerStatus = ({ signers }: { signers: SignerDTO[] }) => {
  const orderedSigners = signers.sort((a, b) => {
    if (!a.signedAt) {
      return -1;
    }
    if (!b.signedAt) {
      return 1;
    }
    return new Date(a.signedAt) > new Date(b.signedAt) && a.status === "signed"
      ? 1
      : -1;
  });

  return (
    <SignerStatusContainer>
      {orderedSigners.map((signer, index) => (
        <Tooltip key={index} title={signer.name} placement="top">
          {signer.status === "signed" ? (
            <CheckedIcon
              style={{
                maxWidth: "unset",
                fill: theme.color.green[600],
              }}
            />
          ) : (
            <CheckedDashedIcon
              style={{
                maxWidth: "unset",
              }}
            />
          )}
        </Tooltip>
      ))}
    </SignerStatusContainer>
  );
};

const Signature = ({ statisticsViewMode }: WidgetProps) => {
  const { t } = useTranslation();
  const buttonClasses = useStyledBaseButtons();
  const navigate = useNavigate();
  const { selectedTeamId } = useTeam();
  const { data } = useSignatureOverviewQuery(selectedTeamId);
  const signatureData = getSelectedData(statisticsViewMode, data);

  const pendingContracts = signatureData?.signaturesInProgress || [];
  const hasPendingContracts = pendingContracts?.length > 0;

  const handleButtonClick = () => {
    navigate(routePaths.CONTRACTS, {
      state: {
        filter: hasPendingContracts ? "pending_signatures_view" : "",
      },
    });
  };

  return (
    <CardWrapper
      isShowTopAndBottomPaddingOnly={signatureData ? "true" : undefined}
    >
      <CardHeaderWrapperWithButton
        header={<SignatureHeader data={signatureData} />}
        icon={
          <SignatureIcon
            style={{
              fill: theme.color.gray[600],
              display: "flex",
              width: 20,
              marginRight: theme.spacing.sm,
            }}
          />
        }
        buttonStyle={buttonClasses.whiteButtonWithBlueLabel}
        colorStyle={theme.color.gray[600]}
        baseButtonTextKey="common.buttons.showAll"
        onButtonClick={handleButtonClick}
      />

      <div>
        {hasPendingContracts ? (
          <OverlayScrollbarsComponent
            style={{
              overflowY: "auto",
              maxHeight: "280px",
            }}
          >
            {pendingContracts.map((item, index) => {
              return (
                <RecentItem
                  key={index}
                  item={item}
                  index={index}
                  rightSide={<SignerStatus signers={item.signers} />}
                  description={
                    <>
                      <br />
                      <SignatureType>
                        {item.isQes
                          ? t("pages.dashboard.signature.qes")
                          : t("pages.dashboard.signature.aes")}
                      </SignatureType>
                    </>
                  }
                />
              );
            })}
          </OverlayScrollbarsComponent>
        ) : (
          <EmptyMessage>
            {t("pages.dashboard.signature.emptyMessage")}
          </EmptyMessage>
        )}
      </div>
    </CardWrapper>
  );
};

export default Signature;
