import styled from "@emotion/styled";
import { MotionStyle } from "framer-motion";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import CustomCardWrapper from "components/CardWrapper/CardWrapper";

export const motionStyles = {
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  width: "100%",
  padding: "var(--spacing-xl)",
} as MotionStyle;

export const Wrapper = styled.div`
  width: 100%;
`;

export const CardWrapper = styled(CustomCardWrapper)`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
`;

export const Container = styled.div`
  background-color: var(--color-white);
  height: 100%;
`;

export const Tabs = styled(MuiTabs)`
  border-bottom: 1px solid var(--color-primary);

  & .MuiTabs-indicator {
    background-color: var(--color-primary-active);
  }
`;

export const Tab = styled(MuiTab)`
  color: var(--color-primary);

  &.Mui-selected {
    color: var(--color-primary-active);
  }
`;
