import { useTranslation } from "react-i18next";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { CopyToClipboardButton } from "components";
import { Wrapper, Header, Title, Body } from "./styles";

export type CodeSnippetProps = {
  title: string;
  content: string;
  copyCaption?: {
    before: string;
    after: string;
  };
};

const CodeSnippet = ({ title, content, copyCaption }: CodeSnippetProps) => {
  const { t } = useTranslation();

  const defaultCopyCaption = {
    before: t("pages.settings.tabs.backup.information.copyToClipboard"),
    after: t("pages.settings.tabs.backup.information.copiedToClipboard"),
  };

  return (
    <Wrapper>
      <Header>
        {<Title>{title}</Title>}
        <CopyToClipboardButton
          caption={copyCaption ?? defaultCopyCaption}
          content={content}
        />
      </Header>
      <Body>
        <OverlayScrollbarsComponent defer style={{ maxHeight: "100%" }}>
          {content}
        </OverlayScrollbarsComponent>
      </Body>
    </Wrapper>
  );
};

export default CodeSnippet;
