import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import {
  isTotalUploadSizeValid,
  getSizeLimit,
  totalSizeInMegaBytes,
} from "components/ContractUpload/helpers";
import {
  Dropzone,
  FolderIcon,
  Description,
  Caption,
  Divider,
  Button,
  Footer,
} from "./styles";
import { useAddDocumentMutation } from "shared/api/documents";
import { useTeam } from "../../contexts/team/hooks";
import { useParams } from "react-router";
import { useContractQuery } from "shared/api";
import { Features } from "constants/features";
import { ContractDTOV1 } from "openapi";

type Props = {
  showImportFromTemplateModal: () => void;
};

export const SingleFileUpload = ({ showImportFromTemplateModal }: Props) => {
  const { t } = useTranslation();
  const { id: contractId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { hasWriteAccess, hasFeature, selectedTeamId, organizationId } =
    useTeam();

  const { data: contract } = useContractQuery(selectedTeamId, contractId);
  const { mutateAsync: addDocument } = useAddDocumentMutation();

  const showSelectTemplateButton =
    hasWriteAccess(contract?.categoryId, contract?.teamId) &&
    hasFeature(Features.CONTRACT_TEMPLATES) &&
    contract?.status === ContractDTOV1.status.DRAFT;

  const handleUpload = async (file: File) => {
    if (!contractId) {
      enqueueSnackbar("This is not an existing contract!", {
        variant: "error",
      });
      return;
    }

    const isValidSize = isTotalUploadSizeValid([file]);

    if (!isValidSize) {
      enqueueSnackbar(
        t("contractUpload.validation.rejectedBySize", {
          maxSize: getSizeLimit(),
          fileSize: totalSizeInMegaBytes([file]).toFixed(1),
        }),
        {
          variant: "error",
        }
      );
      return;
    }

    try {
      if (contract?.teamId) {
        await addDocument({
          organizationId: organizationId,
          file: file,
          contractId: contractId,
          teamId: contract?.teamId,
        });
        enqueueSnackbar(t("contractUpload.successfulUpload.subtitle"), {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(t("contractUpload.failedUpload"), {
        variant: "error",
      });
    }
  };

  const handleMultipleFilesDropped = (fileRejections: FileRejection[]) => {
    if (
      fileRejections.every(({ errors }) => {
        return errors.every(({ code }) => code === ErrorCode.TooManyFiles);
      })
    ) {
      enqueueSnackbar(
        t("contractUpload.validation.maxContractPerUpload", {
          maxUploadLimit: 1,
        }),
        { variant: "warning" }
      );
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        handleMultipleFilesDropped(fileRejections);
        return;
      }
      const file = acceptedFiles[0];
      void handleUpload(file);
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "application/pdf": [".pdf"] },
  });

  return (
    <>
      <Dropzone {...getRootProps()} isFullHeight={!showSelectTemplateButton}>
        <input {...getInputProps()} data-cy="dropzone" />
        <FolderIcon />
        <Description>{t("contractUpload.dropZoneText")}</Description>
        <Caption>{t("contractUpload.dropZoneCaption")}</Caption>

        <Button id="uploadButton" variant="outlined">
          {t("contractUpload.buttons.selectContract")}
        </Button>
      </Dropzone>

      {showSelectTemplateButton && (
        <Footer>
          <Divider>{t("common.labels.or")}</Divider>

          <Button
            onClick={showImportFromTemplateModal}
            id="selectTemplateButton"
            variant="outlined"
          >
            {t("contractUpload.buttons.selectTemplate")}
          </Button>
        </Footer>
      )}
    </>
  );
};
