import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  MenuItem,
  IconButton as MUIIconButton,
} from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { theme } from "theme";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import CHLogoIcon from "assets/svg/contracthero-logo.svg?react";
import PenIcon from "assets/svg/edit-icon.svg?react";
import Pin from "assets/svg/pin-icon.svg?react";

const commonStyles = `
  font-weight: ${theme.font.weight.medium};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.shadow.standard};
  padding: ${theme.spacing.sm} ${theme.spacing.md};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ViewButton = styled(Button)`
  color: ${theme.color.gray[900]};
  text-transform: none;
  border: 1px solid ${theme.color.gray[300]};
  ${commonStyles}
`;

export const ViewsList = styled(OverlayScrollbarsComponent)`
  overflow-y: auto;
  max-height: 280px;
`;

export const ViewsListTitle = styled.p`
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.body};
  margin: ${theme.spacing.md} ${theme.spacing.lg} ${theme.spacing.md};
`;

export const ViewWarningIcon = styled(WarningIcon)`
  margin-right: 0.5rem;
  color: ${theme.color.orange[400]};
  svg {
    fill: ${theme.color.orange[400]} !important;
  }
`;

export const BaseDropDownButtonWithIcon = styled(Button)<{
  isSubMenuOpened?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-transform: none;
  color: ${theme.color.gray[700]};
  padding: ${theme.spacing.md} ${theme.spacing.lg};
  gap: ${theme.spacing.sm};
  background-color: ${({ isSubMenuOpened }) =>
    isSubMenuOpened ? `${theme.color.black}08` : "transparent"};
  svg {
    fill: ${theme.color.black}80;
  }
  &:hover {
    background-color: ${theme.color.black}08 !important;
`;

export const ArrowIcon = styled(ArrowRightOutlinedIcon)`
  margin-right: 0 !important;
  margin-left: auto;
`;

export const ViewListHeader = styled.div`
  display: flex;
  alignt-items: center;
  justify-content: flex-start;
  color: ${theme.color.gray[700]};
  flex-wrap: wrap;
  width: 100%;
`;

export const ViewListHeading = styled.div`
  width: 100%;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.caption};
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
`;

export const CurrentView = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.xxs} ${theme.spacing.sm} ${theme.spacing.xxs}
    ${theme.spacing.lg};
  min-height: 40px;
  font-weight: ${theme.font.weight.semiBold};
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing.sm} 0;
  border: none;
  background-color: ${theme.color.gray[300]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchControl = styled(FormControl)`
  border-left: 1px solid var(--color-border);
  min-width: 90px;
  max-width: 90px;
  .MuiSelect-select {
    padding: 0 0 0 var(--spacing-md);
    color: var(--color-primary);
    svg {
      display: none;
    }
  }
  .MuiOutlinedInput-root {
    box-shadow: none;
  }
  .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline,
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const ListItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  color: var(--color-text-primary);

  svg {
    flex: 0 1 auto;
    fill: var(--color-text-primary);
    width: 16px;
    height: 16px;
  }
`;

export const TextField = styled(StyledTextField)`
  flex: 1;
  min-width: 300px;
  max-width: 454px;
  & .MuiInputBase-root {
    padding-right: 0;
  }
`;

export const PaperStyles = {
  boxShadow: "var(--dropdown-shadow)",
  transform: "translateY(var(--spacing-sm)) !important",
};

export const CHLogo = styled(CHLogoIcon)`
  max-width: 16px;
  margin-right: var(--spacing-sm);
`;

export const EditIcon = styled(PenIcon)`
  max-width: 16px;
  fill: var(--color-primary);
`;

export const PinIcon = styled(Pin)`
  width: 16px;
  max-width: 16px;
  fill: var(--color-primary);
  margin-right: var(--spacing-sm);
`;

export const DefaultViewOption = styled(MenuItem)<{ standard?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-lg);
  color: var(--text-secondary);
  text-transform: none;
  font-size: ${theme.font.size.body};

  :hover {
    background-color: ${theme.color.black}08;
    color: ${theme.color.gray[700]};
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .editViewAction {
    opacity: 0;
    width: 32px;
    height: 32px;
    transition: opacity 0.2s ease-in-out;
  }

  :hover .editViewAction {
    opacity: 1;
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .editViewAction {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  :hover .editViewAction {
    opacity: 1;
  }
`;

export const IconButton = styled(MUIIconButton)`
  width: 38px;
  height: 38px;
  margin: 0 ${theme.spacing.xs};
`;
