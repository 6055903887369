import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { UserService } from "../../openapi";
import { Navigate, useLocation } from "react-router-dom";
import routePaths from "../../constants/routePaths";
import { useStyles } from "../../components/StyledComponents/StyledBaseButtons";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../hooks/GlobalStateHooks";
import { Language } from "../../shared/i18n/i18n";
import { useSnackbar } from "notistack";
import { setInvalidTokenError } from "shared/service/errorResponseService";

const VerifyEmailForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = location;
  const { changeLocale } = useLocale();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const email = (location.state as { email: string })?.email;

  const verifyEmail = async (token: string) => {
    try {
      await UserService.verifyEmail({ token });
    } catch (e) {
      setInvalidTokenError(e, "pages.verify", enqueueSnackbar, t);
    }
  };

  useEffect(() => {
    let token = new URLSearchParams(search).get("emailVerificationToken");
    if (!token) {
      token = new URLSearchParams(search).get("token");
    }

    const lang = new URLSearchParams(search).get("lang");

    if (token) {
      void verifyEmail(token);
    }

    if (lang) {
      void changeLocale(lang as Language);
    }
  }, []);

  const resendEmail = async () => {
    await UserService.resendEmailVerification(email);
    enqueueSnackbar(t("pages.verify.emailAlert"), { variant: "success" });
  };

  return !email ? (
    <Navigate to={routePaths.LOGIN} />
  ) : (
    <Box sx={{ mt: 3, mb: 2 }} id="auth-verify-email">
      <Button onClick={resendEmail} size="large" className={classes.baseButton}>
        {t("pages.verify.requestEmail")}
      </Button>
    </Box>
  );
};

export default VerifyEmailForm;
