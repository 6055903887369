import { ChangeLogDataItemType, ExtendedContactDTO } from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { useTranslation } from "react-i18next";
import CircleArrow from "assets/svg/circle-arrow-icon.svg?react";

export type ContactFieldType = {
  fieldData: ChangeLogDataItemType;
  contacts: ExtendedContactDTO[] | undefined;
  variant?: "row";
};

const ContactField = ({ fieldData, contacts, variant }: ContactFieldType) => {
  const { t } = useTranslation();
  const oldPartnerName = contacts?.find(
    (item) => item.id === fieldData?.from?.value
  )?.name?.value?.value;

  const newPartnerName = contacts?.find(
    (item) => item.id === fieldData?.to?.value
  )?.name?.value?.value;

  const fieldName = t("pages.import.labels.partnerCompany");

  const partnerNames = {
    from: { value: oldPartnerName },
    to: { value: newPartnerName },
  };

  return (
    <EditEntityItem
      data={partnerNames}
      fieldName={fieldName}
      fieldIcon={<CircleArrow />}
      variant={variant}
    />
  );
};

export default ContactField;
