import { useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useTeam } from "contexts/team/hooks";
import { Loader } from "components";
import { Header, Table } from "./components";
import { Wrapper } from "./styles";
import { OrganizationService, ContactDatapointDefinitionDTO } from "openapi";
import { useNavigate } from "react-router";
import * as api from "shared/api";

const Contacts = () => {
  const { selectedTeam, selectedTeamId } = useTeam();
  const navigate = useNavigate();
  const organizationId = selectedTeam?.parentTeamId || selectedTeam?.id;
  const [contactsDataPointDefinitions, setContactsDataPointDefinitions] =
    useState<ContactDatapointDefinitionDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { data: contacts } = api.useContactsQuery(
    organizationId,
    selectedTeamId
  );
  const gridRef = useRef<AgGridReact>();

  const fetchContactDataPointDefinitions = async () => {
    try {
      setIsLoading(true);
      if (!organizationId) return;
      const response =
        await OrganizationService.getContactDatapointsDefinitions(
          organizationId
        );
      setContactsDataPointDefinitions(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    void fetchContactDataPointDefinitions();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <Header onCreateNewContact={() => navigate("/contacts/draft")} />

      <Table
        gridRef={gridRef}
        data={contacts ?? []}
        definitions={contactsDataPointDefinitions}
      />
    </Wrapper>
  );
};

export default Contacts;
