import styled from "@emotion/styled";
import { Accordion } from "@mui/material";
import { theme } from "theme";

export const FlexBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.lg};
`;

export const Description = styled.p`
  margin-top: ${theme.spacing.md};
`;

export const ConfigurationEditAccordion = styled(Accordion)`
  &::before {
    display: none;
  }
`;
