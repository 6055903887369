/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import HelloSign from "hellosign-embedded";
import { enqueueSnackbar } from "notistack";
import {
  ContractSignatureV1Service, // HelloSign
  SignatureRequestCancelDTO,
  SignatureRequestDTO,
} from "openapi";
import { useTranslation } from "react-i18next";
import globalConfig from "shared/config/global.config";
import { isDevelopment } from "utils/helpers";
import { MutableRefObject } from "react";

const refetchTimeDelay = 8000;
let isSignatureSent = false;
/**
 * Hook to handle signature-related actions, including canceling a signature request.
 *
 * @param {MutableRefObject<NodeJS.Timeout | null>} [timeoutRef] -
 * An optional ref to store the timeout ID. If provided, it allows clearing the timeout
 * when the component unmounts or when the contract is deleted to prevent unwanted API calls.
 */
export const useSignatureProvider = (
  timeoutRef?: MutableRefObject<NodeJS.Timeout | null>
) => {
  const { t } = useTranslation();

  const requestSignature = async (
    contractId: string,
    requestBody: SignatureRequestDTO,
    handleSignatureModal: (value: boolean) => void,
    closeSignatureRequestModal: () => void,
    refetchData?: () => Promise<void>,
    setIsRequestingSignature?: (values: boolean) => void,
    setIsSignatureSubmitted?: (values: boolean | null) => void
  ) => {
    const response = await ContractSignatureV1Service.initiateSignatureRequest(
      contractId,
      requestBody
    );
    setIsSignatureSubmitted?.(false);

    if (globalConfig.REACT_APP_DROPBOX_CLIENT_ID && response.claimURL) {
      handleSignatureModal(true);
      const client = new HelloSign();

      setTimeout(() => {
        client.open(response.claimURL, {
          clientId: globalConfig.REACT_APP_DROPBOX_CLIENT_ID,
          skipDomainVerification: isDevelopment(),
          container: document.getElementById("prepareSignature") || undefined,
        });
      }, 1000);

      client.on("send", () => {
        setIsRequestingSignature?.(true);
        closeSignatureRequestModal();
        handleSignatureModal(false);
        setIsSignatureSubmitted?.(true);
        void refetchData?.();
        if (!isSignatureSent) {
          enqueueSnackbar(
            t("pages.contractDetails.signature.message.success.initiate"),
            { variant: "success" }
          );
          isSignatureSent = true;
        }
        // @Note: As we are using a webhook to get the information,
        // we need to wait for a while (5s) before re-fetching the data
        // in order to update the signature status in our contract page.
        setTimeout(() => {
          void refetchData?.();
          setIsRequestingSignature?.(false);
          setIsSignatureSubmitted?.(false);
        }, refetchTimeDelay);
      });

      client.on("close", () => {
        isSignatureSent = false;
      });
    }
    return isSignatureSent;
  };

  const cancelSignature = async (
    contractId: string,
    data: SignatureRequestCancelDTO,
    provider?: string,
    refetchData?: () => void,
    setIsCanceling?: (value: boolean) => void
  ) => {
    setIsCanceling?.(true);
    if (provider === "DROPBOX") {
      try {
        await ContractSignatureV1Service.cancelSignatureRequest(
          contractId,
          data
        );
        if (timeoutRef) {
          timeoutRef.current = setTimeout(() => {
            void refetchData?.();
            setIsCanceling?.(false);
          }, refetchTimeDelay);
        }

        enqueueSnackbar(
          t("pages.contractDetails.signature.message.success.cancel"),
          { variant: "success" }
        );
      } catch (e) {
        enqueueSnackbar(
          t("pages.contractDetails.signature.message.error.cancel"),
          { variant: "error" }
        );
        console.error(e);
      }
    }
  };

  const sendReminder = async (contractId: string, signerId: string) => {
    try {
      await ContractSignatureV1Service.remindSigner(signerId, contractId);
      enqueueSnackbar(
        t("pages.contractDetails.signature.message.success.remind"),
        { variant: "success" }
      );
    } catch (e) {
      console.error(e);
      enqueueSnackbar(
        t("pages.contractDetails.signature.message.error.remind"),
        { variant: "error" }
      );
    }
  };

  return {
    requestSignature,
    cancelSignature,
    sendReminder,
  };
};
