import { useState } from "react";
import {
  Container,
  Title,
  AccordionDetails,
  AccordionSummary,
  ToggleMarker,
} from "./styles";

export type AccordionProps = {
  title: string | JSX.Element;
  children: string | JSX.Element;
  variant?: "warning";
  label?: string;
};

const Accordion = ({ title, children, variant, label }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Container variant={variant} aria-label={label}>
      <AccordionSummary
        onClick={toggleAccordion}
        aria-label="accordion-summary"
      >
        <Title>{title}</Title>
        <ToggleMarker isOpen={isOpen} />
      </AccordionSummary>
      {isOpen ? (
        <AccordionDetails aria-label="accordion-details">
          {children}
        </AccordionDetails>
      ) : null}
    </Container>
  );
};

export default Accordion;
