import { useTranslation } from "react-i18next";
import { Control, FieldValues } from "react-hook-form";
import { dateFormatter } from "constants/utils";
import { useLocale } from "hooks";
import { StyledControlledTextField } from "./styles";

type TitleProps = {
  control: Control<FieldValues>;
  name: string;
  lastModified?: string;
};

const Title = ({ control, name, lastModified }: TitleProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const formattedDate = lastModified ? dateFormatter(locale, lastModified) : "";
  const helperText = `${
    formattedDate ? `${t("common.lastModified")}: ${formattedDate}` : ""
  }`;
  return (
    <StyledControlledTextField
      control={control}
      name={name}
      helperText={helperText}
      isBorderLessInput
      size="sm"
    />
  );
};

export default Title;
