import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";
import { theme } from "theme";

export const TabsWrapper = styled.div`
  width: 100%;
`;

export const TabsContainer = styled(Tabs)`
  position: relative;
  min-height: 36px;
  max-height: 36px;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
  }

  .MuiTabs-indicator {
    background-color: var(--color-primary-active);
  }

  .MuiTabScrollButton-root {
    color: var(--color-primary);
  }
`;

export const TabItem = styled(Tab)`
  min-height: 36px;
  padding: 0;
  color: var(--color-primary);
  min-width: 0;
  flex-direction: row;
  gap: var(--spacing-xs);

  &.Mui-selected {
    color: var(--color-primary-active);
  }
  :first-of-type {
    margin-left: var(--spacing-lg);
  }
  :not(:first-of-type) {
    margin-left: var(--spacing-xxxl);
  }
  :active {
    background: none;
  }
`;

export const Label = styled.span`
  background-color: var(--color-primary-inverted);
  border-radius: var(--label-border-radius);
  font-size: ${theme.font.size.caption};
  padding: var(--spacing-xxs) var(--spacing-xs);
`;
