import { useTranslation } from "react-i18next";
import ButtonWithArrow from "components/ButtonWithArrow/ButtonWithArrow";
import { Wrapper, ToolbarContainer } from "./styles";
import { useLocation, useNavigate } from "react-router";
import routePaths from "constants/routePaths";
import { getBackNavigationUrl } from "pages/Contract/helpers";
import { useSearchParams } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
  editable: boolean;
};

const ContractHeader = ({ children, editable }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const handleNavigateBack = () => {
    const newUrl = getBackNavigationUrl(location.pathname, searchParams);
    navigate(newUrl);
  };

  const handleClick = () =>
    editable ? handleNavigateBack() : navigate(routePaths.CONTRACTS);

  return (
    <Wrapper>
      <ButtonWithArrow
        id="allContractsBtn"
        onClick={handleClick}
        label={
          editable
            ? t("pages.contractEdit.buttons.backToContracts")
            : t("pages.contractDetails.buttons.backToAllContracts")
        }
      />

      <ToolbarContainer>{children}</ToolbarContainer>
    </Wrapper>
  );
};

export default ContractHeader;
