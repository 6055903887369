import { assigneesWithoutAccessToCategoryProps } from "../utils";
import { AssigneeName, AssigneesWrapper } from "./styles";
import { Chip } from "components";
import { getColor } from "components/Chip/helpers";
import { theme } from "theme";
import { useLocale } from "hooks";
import routePaths from "constants/routePaths";
import { FlexRow } from "../styles";
import { SimpleLink } from "new-components";

type AssigneesWithoutAcceessProps = {
  assignees: assigneesWithoutAccessToCategoryProps[];
  nameAsLink?: boolean;
};

const AssigneesWithoutAcceess = ({
  assignees,
  nameAsLink,
}: AssigneesWithoutAcceessProps) => {
  const { locale } = useLocale();
  return (
    <FlexRow direction="column">
      {assignees.map((assignee) => (
        <AssigneesWrapper key={assignee.member.id}>
          <AssigneeName>
            {nameAsLink ? (
              <SimpleLink
                to={`${routePaths.SETTINGS_MEMBERS}/${assignee.member.id}`}
              >
                {assignee.member.firstname} {assignee.member.lastname}
              </SimpleLink>
            ) : (
              <span>
                {assignee.member.firstname} {assignee.member.lastname}
              </span>
            )}
          </AssigneeName>
          <FlexRow>
            {assignee?.unaccessibleCategories?.map((category) => (
              <Chip
                key={category?.id}
                blendMode="soft"
                color={getColor(category?.color || theme.color.blue[600])}
              >
                {category?.name[locale]}
              </Chip>
            ))}
          </FlexRow>
        </AssigneesWrapper>
      ))}
    </FlexRow>
  );
};

export default AssigneesWithoutAcceess;
