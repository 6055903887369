import { WarningAmber } from "@mui/icons-material";
import styled from "@emotion/styled";
import { theme } from "theme";

const RoundedBox = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background-color: ${theme.color.red[100]};
`;

const WarningIcon = () => (
  <RoundedBox className="flex-column-center">
    <WarningAmber sx={{ fill: theme.color.red[600] }} />
  </RoundedBox>
);

export default WarningIcon;
