import { ChangeLogDataItemType } from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { getChangedFields, getFieldIcon } from "../LogEntity/LogEntity";
import { ContractFieldDTOV1, UserInfoDto } from "openapi";
import { useTranslation } from "react-i18next";
import { useLocale } from "hooks";
import { fieldsToSkip } from "../../utils";

export type AmountFieldType = {
  fieldData: ChangeLogDataItemType;
  fieldVisibleId: string;
  userInfo: UserInfoDto | undefined;
  field: ContractFieldDTOV1 | undefined;
  variant?: "row";
};

const AmountField = ({
  fieldData,
  fieldVisibleId,
  userInfo,
  field,
  variant,
}: AmountFieldType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  if (!field) return <></>;

  const changedFields = getChangedFields(fieldData, fieldVisibleId, userInfo);
  return (
    <>
      {Object.keys(changedFields).map((key) => {
        if (fieldsToSkip.includes(key)) return null;
        const fieldName =
          key === "currency"
            ? `${field?.name[locale]} - ${t(
                "pages.contractEdit.forms.financialForm.currency"
              )}`
            : field?.name[locale];
        const item = changedFields?.[key];
        const fieldIcon = getFieldIcon(field?.type);
        return (
          <EditEntityItem
            key={key}
            data={item}
            fieldName={fieldName}
            fieldIcon={fieldIcon}
            variant={variant}
          />
        );
      })}
    </>
  );
};

export default AmountField;
