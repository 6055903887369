import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Text,
  Wrapper,
  ConfirmButton,
  DeclineButton,
  TextArea,
  Form,
  Buttons,
} from "./styles";
import ThumbsUpIcon from "assets/svg/thumbs-up.svg?react";
import ThumbsDownIcon from "assets/svg/thumbs-down.svg?react";
import { Tooltip } from "@mui/material";
import { I18nDto, OrganizationNewService, PromptFeedbackDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { CTAButton } from "components";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";

type Props = {
  snippet?: string | null;
  fieldName: I18nDto;
};

const Rating = ({ snippet, fieldName }: Props) => {
  const { t } = useTranslation();
  const [isRated, setIsRated] = useState(false);
  const [isHelpful, setIsHelpful] = useState(false);
  const { organizationId } = useTeam();
  const { control, reset, watch } = useForm();
  const feedbackFieldName = "feedback";
  const [messageText, setMessageText] = useState(
    t("pages.contractEdit.modals.customField.prompt.rating.text")
  );
  const [showForm, setShowForm] = useState(false);

  const resetForm = () => {
    reset();
    setIsRated(false);
    setShowForm(false);
    setMessageText(
      t("pages.contractEdit.modals.customField.prompt.rating.text")
    );
  };

  const feedbackText = watch(feedbackFieldName) as string;

  const handleFeedback = async (isHelpful: boolean) => {
    setIsRated(true);
    setIsHelpful(isHelpful);
    if (isHelpful) {
      await onSubmit(isHelpful);
    } else {
      setShowForm(true);
      setMessageText(
        t(
          "pages.contractEdit.modals.customField.prompt.placeholders.feedbackField"
        )
      );
    }
  };

  const onSubmit = async (isHelpful: boolean) => {
    const requestBody = {
      fieldName: fieldName.en,
      promptMessage: snippet as string,
      feedback: {
        isHelpful: isHelpful,
        message: isHelpful ? "helpful" : feedbackText,
      },
    } satisfies PromptFeedbackDTO;

    try {
      await OrganizationNewService.feedbackForPromptTest(
        organizationId,
        requestBody
      );
      if (isHelpful) {
        setMessageText(
          t("pages.contractEdit.modals.customField.prompt.rating.isHelpfuf")
        );
      } else {
        setShowForm(false);
        setMessageText(
          t("pages.contractEdit.modals.customField.prompt.rating.isNotHelpfuf")
        );
      }

      reset();
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractEdit.modals.customField.prompt.feedbackSubtmitError"),
        {
          variant: "error",
        }
      );
      // replace with sentry before deploy
      console.log(error);
    }
  };

  return (
    <>
      <Wrapper>
        <>
          <Tooltip title={t("common.yes")}>
            <ConfirmButton
              type="button"
              aria-label="thumbs-up"
              onClick={() => handleFeedback(true)}
              active={isRated && isHelpful}
            >
              <ThumbsUpIcon />
            </ConfirmButton>
          </Tooltip>
          <Tooltip title={t("common.no")}>
            <DeclineButton
              type="button"
              aria-label="thumbs-down"
              onClick={() => handleFeedback(false)}
              active={isRated && !isHelpful}
            >
              <ThumbsDownIcon />
            </DeclineButton>
          </Tooltip>
          <Text>{messageText}</Text>
        </>
      </Wrapper>
      {showForm && (
        <Form>
          <TextArea
            control={control}
            name={feedbackFieldName}
            multiline
            rows={3}
            placeholder={t(
              "pages.contractEdit.modals.customField.prompt.placeholders.feedbackField"
            )}
          />
          <Buttons>
            <CTAButton
              variant="secondary"
              name={t("common.buttons.cancel")}
              size="small"
              onClick={resetForm}
            />
            <CTAButton
              variant="primary"
              name={t("common.buttons.send")}
              size="small"
              onClick={() => onSubmit(false)}
              disabled={!feedbackText}
            />
          </Buttons>
        </Form>
      )}
    </>
  );
};

export default Rating;
