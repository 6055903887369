import { AttachmentIconTiled } from "./styles";
import { ICellRendererParams } from "ag-grid-community";
import { ContractDTOV1 } from "../../../openapi";

export const FileCellRenderer = (props: ICellRendererParams) => {
  if (!props?.data) return;
  const hasFile = (props.data as ContractDTOV1).hasFile;

  return hasFile ? <AttachmentIconTiled /> : <span>-</span>;
};
