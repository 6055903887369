import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { ClickAwayListener, Fade, Paper, Popper, Tooltip } from "@mui/material";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
import { ViewInputDTO, ViewItemDTO, ContractViewService } from "openapi";
import { useTranslation } from "react-i18next";
import { ViewButton, ViewWarningIcon } from "./styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { theme } from "theme";
import { EntityViewList } from "./components/EntityViewList";
import { useContractViewsQuery } from "shared/api/views";
import { useTeam } from "contexts/team/hooks";
import { flushSync } from "react-dom";
import { getActiveViewDeterminerFunction } from "contexts/grid/helpers";
import {
  clearTemporaryState,
  retrieveActiveView,
  retrieveTemporaryState,
} from "contexts/grid/storage";
import { exportView } from "pages/Contracts/helpers";
import { useFieldsQuery } from "shared/api";
import { getFilterModelAsJson, getViewName } from "./helpers";
import ContractSaveOrEditViewModal from "./components/Modals/SaveOrEditViewModal";
import { ModalResultEnum } from "components/Modal/Modal";
import ContractDeleteGridViewModalContent from "./components/Modals/DeleteGridViewModalContent";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useLocale } from "hooks";
import { CTAButton, NewModal } from "components";

export const initialValues = {
  name: "",
  shared: false,
  default: false,
};

export const EntityGridViewSelector = () => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { overview } = useOverviewState();
  const { overview: overviewActions } = useOverviewActions();
  const [showSaveOrEditViewModal, setShowSaveOrEditViewModal] = useState(false);
  const [showDeleteGridViewModal, setShowDeleteGridViewModal] = useState(false);
  const [viewToEdit, setViewToEdit] = useState<ViewItemDTO | null>(null);

  const methods = useForm({
    defaultValues: initialValues,
  });

  const { selectedTeamId, organizationId } = useTeam();
  const { data: views, refetch } = useContractViewsQuery(
    selectedTeamId,
    overview.type
  );
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);
  const [anchorViewEl, setAnchorViewEl] = useState<HTMLButtonElement | null>(
    null
  );
  const isViewModalOpen = Boolean(anchorViewEl);

  const openViewSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorViewEl(event.currentTarget);
  };

  const closeViewSelector = () => setAnchorViewEl(null);

  const getCustomDefaultView = () => {
    if (!views?.items.length) return {} as ViewItemDTO;
    const customDefaultView = views.items.find((view) => view.default === true);
    return customDefaultView;
  };

  const initializeDefaultView = () => {
    const defaultView = getCustomDefaultView();

    if (!defaultView || !views?.items.length) return;

    overviewActions.dispatch({
      type: "setActiveView",
      value: {
        id: defaultView.id,
        determineActiveView: getActiveViewDeterminerFunction(views),
      },
    });
  };

  // Preset default view
  useEffect(() => {
    if (overview.activePreset) return;

    const hasTemporaryState = retrieveTemporaryState(overview.type);
    const hasActiveView = retrieveActiveView(overview.type);

    if (hasTemporaryState || hasActiveView) return;

    initializeDefaultView();
  }, [views]);

  if (!views || !overview.agGrid.initialized) return null;

  const setView = async (viewId: string, shouldRefetch = false) => {
    closeViewSelector();

    if (!viewId) {
      const defaultView = getCustomDefaultView();
      if (!defaultView) return;

      clearTemporaryState(overview.type);

      return overviewActions.dispatch({
        // reset to default view
        type: "setActiveView",
        value: {
          id: defaultView.id,
          determineActiveView: getActiveViewDeterminerFunction(views),
        },
      });
    }
    let currentViews = views;
    if (shouldRefetch) {
      const refetchedViews = await refetch();
      if (refetchedViews.data) {
        currentViews = refetchedViews.data;
      }
    }
    flushSync(() => {
      overviewActions.dispatch({
        type: "setActiveView",
        value: {
          id: viewId,
          determineActiveView: getActiveViewDeterminerFunction(currentViews),
        },
      });
    });
  };

  const createView = async (viewData: Omit<ViewInputDTO, "type">) => {
    const id = await ContractViewService.create(selectedTeamId, {
      ...viewData,
      type: overview.type,
    });
    await setView(id, true);
  };

  const updateView = async (viewData: Omit<ViewItemDTO, "owner">) => {
    await ContractViewService.update(viewData.id, {
      name: viewData.name,
      data: viewData.data,
      filter: viewData.filter,
      type: viewData.type,
      shared: viewData.shared,
      default: viewData.default,
    });
    await refetch();
    setViewToEdit(null);
    methods.reset(initialValues);
  };

  const saveCurrentView = async () => {
    if (overview.agGrid.initialized) {
      let currentView = overview.activeView;
      const columnStateAsJson = JSON.stringify(
        overview.agGrid.gridRef.current?.api.getColumnState()
      );
      if (currentView && overview.agGrid.gridRef.current?.api) {
        currentView = {
          ...currentView,
          data: columnStateAsJson,
          filter: getFilterModelAsJson(overview.agGrid.gridRef.current?.api),
        };
        await updateView(currentView);
      }
    }
  };

  const deleteView = async (action?: ModalResultEnum, _values?: unknown) => {
    if (action === ModalResultEnum.DELETE && viewToEdit) {
      await ContractViewService.delete(viewToEdit.id);
      await refetch();
    }
    if (showSaveOrEditViewModal) {
      setShowSaveOrEditViewModal(false);
    }
    setViewToEdit(null);
    methods.reset(initialValues);
    setShowDeleteGridViewModal(false);
  };

  const setViewToEditForm = (view?: ViewItemDTO) => {
    const currentView = {
      ...initialValues,
      ...view,
      name: getViewName(t, view),
    };
    methods.reset(currentView);
  };

  return (
    <FormProvider {...methods}>
      <Tooltip
        title={`${t("pages.contracts.header.view")}: ${getViewName(
          t,
          overview.activeView
        )}`}
      >
        <ViewButton
          data-testid="viewBtn"
          onClick={openViewSelector}
          sx={{ ":hover": { backgroundColor: "white" } }}
        >
          {(overview.unsavedColumnChangePresent ||
            overview.unsavedFilterChangePresent) && <ViewWarningIcon />}
          <span>
            {`${t("pages.contracts.header.view")}: ${getViewName(
              t,
              overview.activeView
            )}`}
          </span>
          <KeyboardArrowDownIcon
            sx={{ marginLeft: "0.5rem", fill: theme.color.gray[700] }}
          />
        </ViewButton>
      </Tooltip>
      <Popper
        id="view-popper"
        open={isViewModalOpen}
        anchorEl={anchorViewEl}
        placement="bottom-end"
        transition
        sx={{
          width: "260px",
          zIndex: 2,
          boxShadow: theme.shadow.standard,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={closeViewSelector}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <EntityViewList
                  views={views}
                  setView={setView}
                  saveCurrentView={saveCurrentView}
                  editView={(view) => {
                    setShowSaveOrEditViewModal(true);
                    setViewToEdit(view);
                    setViewToEditForm(view);
                  }}
                  saveNewView={() => {
                    setShowSaveOrEditViewModal(true);
                  }}
                  exportView={() => {
                    if (overview.agGrid.initialized) {
                      exportView(
                        overview.agGrid.gridRef,
                        getViewName(t, overview.activeView),
                        t,
                        locale,
                        categories,
                        fields
                      );
                    }
                  }}
                />
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <ContractSaveOrEditViewModal
        open={showSaveOrEditViewModal}
        selectedView={viewToEdit}
        gridRef={overview.agGrid.gridRef}
        filterModelGetter={() =>
          overview.agGrid.gridRef?.current?.api
            ? getFilterModelAsJson(overview.agGrid.gridRef?.current?.api)
            : null
        }
        getFilterModelAsJson={() =>
          overview.agGrid.gridRef?.current?.api
            ? getFilterModelAsJson(overview.agGrid.gridRef?.current?.api)
            : null
        }
        createView={createView}
        updateView={updateView}
        deleteView={(view) => {
          setShowDeleteGridViewModal(true);
          setViewToEdit(view);
          setViewToEditForm(view);
        }}
        setShowSaveOrEditViewModal={setShowSaveOrEditViewModal}
      />
      <NewModal
        open={showDeleteGridViewModal}
        handleClose={() => deleteView(ModalResultEnum.CANCEL)}
        fullWidth
        body={<ContractDeleteGridViewModalContent />}
        title={t("pages.contracts.header.modals.deleteViewModal.title")}
        footer={
          <>
            <CTAButton
              label="cancelBtn"
              type="reset"
              onClick={() => deleteView(ModalResultEnum.CANCEL)}
              name={t("pages.contracts.header.modals.buttons.cancel")}
              variant="secondary"
            />
            <CTAButton
              label="deleteBtn"
              type="submit"
              variant="primary"
              color="danger"
              onClick={() => deleteView(ModalResultEnum.DELETE)}
              name={t("pages.contracts.header.modals.buttons.delete")}
            />
          </>
        }
      />
    </FormProvider>
  );
};
