import React, { useState } from "react";
import Dropdown from "./Dropdown/Dropdown";
import { enqueueSnackbar } from "notistack";
import { CTAButton } from "components";
import ArrowLeftIcon from "assets/svg/arrow-left.svg?react";
import ChevronDown from "assets/svg/ChevronDown.svg?react";
import ChevronUp from "assets/svg/ChevronUp.svg?react";
import { useTranslation } from "react-i18next";
import { Divider, Tooltip } from "@mui/material";
import { useTeam } from "../../../../../../contexts/team/hooks";
import { Features } from "../../../../../../constants";
import { AcceptedFileType } from "../../../../../../shared/enums/document.enum";
import { useUpdateDocumentDataMutation } from "shared/api/documents";
import { useParams } from "react-router";
import { useContractQuery } from "shared/api/contracts";
import { ContractDTOV1, DocumentDTO } from "openapi";
import { extractFileName } from "constants/utils";
import { Container, StyledControlledTextField, TextContainer } from "./styles";

export type DocumentHeaderProps = {
  contractFile?: Blob | undefined | null;
  selectedDocument?: DocumentDTO;
  onBackClick?: () => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
};

const DocumentHeader = ({
  contractFile,
  selectedDocument,
  onBackClick,
  onDeleteClick,
  onDownloadClick,
  onPreviousClick,
  onNextClick,
}: DocumentHeaderProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useTeam();
  const [inputDocumentName, setDocumentName] = useState("");
  const { id: contractId } = useParams();
  const { organizationId, selectedTeamId } = useTeam();
  const { mutateAsync: updateDocumentData } = useUpdateDocumentDataMutation();
  const { data: contract = {} as ContractDTOV1 } = useContractQuery(
    selectedTeamId,
    contractId
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDocumentName(event.target.value);

  const printPDF = () => {
    if (!contract || !selectedDocument || !contractId) return;
    const fileUrl = `/api/organizations/${organizationId}/teams/${contract.teamId}/contracts/${contractId}/documents/${selectedDocument.id}/file`;
    const newWindow = window.open(fileUrl, "_blank");
    if (newWindow) {
      newWindow.onload = () => {
        newWindow.focus();
        newWindow.print();
      };
    } else {
      enqueueSnackbar(t("pages.contractDetails.documents.printDocumentError"), {
        variant: "error",
      });
    }
  };

  const saveDocumentName = async () => {
    if (!inputDocumentName) {
      return;
    }
    try {
      if (
        !contractId ||
        !selectedDocument ||
        inputDocumentName === selectedDocument?.filename
      )
        return;
      await updateDocumentData({
        organizationId,
        teamId: contract.teamId,
        documentId: selectedDocument?.id,
        data: { filename: inputDocumentName },
        contractId,
      });
    } catch (e) {
      enqueueSnackbar({
        message: t("pages.contractDetails.documents.renameUnexpectedError"),
        variant: "error",
      });
    }
  };

  return (
    <div>
      <Container>
        {hasFeature(Features.MULTIPLE_DOCUMENT) && onBackClick && (
          <>
            <Tooltip title={t("pages.contractDetails.buttons.backToList")}>
              <CTAButton
                variant="tertiary"
                icon={<ArrowLeftIcon />}
                onClick={onBackClick}
                iconSize="medium"
                size="tiny"
              />
            </Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title={t("pages.contractDetails.buttons.previous")}>
              <CTAButton
                size="tiny"
                iconSize="small"
                variant="tertiary"
                icon={<ChevronUp />}
                onClick={onPreviousClick}
                disabled={!onPreviousClick}
                data-testid="previous-button"
              />
            </Tooltip>
            <Tooltip title={t("pages.contractDetails.buttons.next")}>
              <CTAButton
                size="tiny"
                iconSize="small"
                variant="tertiary"
                icon={<ChevronDown />}
                onClick={onNextClick}
                disabled={!onNextClick}
              />
            </Tooltip>
          </>
        )}
        {selectedDocument && (
          <TextContainer>
            <StyledControlledTextField
              name="name"
              defaultValue={extractFileName(selectedDocument.filename)}
              value={extractFileName(selectedDocument.filename)}
              isBorderLessInput
              size="xs"
              fullWidth
              onChange={handleInputChange}
              onBlur={saveDocumentName}
            />
          </TextContainer>
        )}
        <Dropdown
          onDeleteClick={onDeleteClick}
          onPrintClick={printPDF}
          onDownloadClick={onDownloadClick}
          isPdf={contractFile?.type === AcceptedFileType.PDF}
        />
      </Container>
    </div>
  );
};

export default DocumentHeader;
