import { MouseEvent } from "react";
import { Box, IconButton, Link } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { theme } from "theme";
import { OverflowTip } from "components/OverflowTip/OverflowTip";

export type BaseDropDownButtonItemWithActionsProps = {
  id: string;
  text: string;
  linkClassName?: string;
  itemClassName?: string;
  icon?: JSX.Element;
  editIcon?: JSX.Element;
};

type Props = {
  onClick?: (id: string) => void;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
};

const BaseDropDownButtonItemWithActions = ({
  id,
  text,
  linkClassName,
  itemClassName,
  icon,
  editIcon,
  onClick,
  onEditClick,
  onDeleteClick,
}: BaseDropDownButtonItemWithActionsProps & Props) => {
  const handleClick = () => {
    onClick && onClick(id);
  };

  const handleEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onEditClick && onEditClick(id);
  };

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onDeleteClick && onDeleteClick(id);
  };

  return (
    <Link className={linkClassName}>
      <ListItem
        button
        id={id}
        className={itemClassName}
        onClick={() => handleClick()}
        sx={{
          justifyContent: "space-between",
          padding: `${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.xs} ${theme.spacing.md}`,
          position: "relative",
          minHeight: "48px",
          "& .MuiBox-root: nth-child(2)": {
            display: "none",
          },
          "&:hover": {
            "& .MuiBox-root: nth-child(2)": {
              display: "flex",
            },
          },
        }}
      >
        <OverflowTip title={text}>
          <Box>
            {icon && (
              <ListItemIcon
                sx={{ verticalAlign: "middle", minWidth: "30px !important" }}
              >
                {icon}
              </ListItemIcon>
            )}
            <span style={{ verticalAlign: "middle" }}>{text}</span>
          </Box>
        </OverflowTip>

        <Box sx={{ display: "flex", alignItems: "center", flex: "0 0 auto" }}>
          {onEditClick ? (
            <IconButton
              sx={{ ml: "auto", minWidth: "32px" }}
              className="delete"
              onClick={(e) => handleEditClick(e)}
            >
              {editIcon}
            </IconButton>
          ) : null}
          {onDeleteClick ? (
            <IconButton
              sx={{ ml: "auto" }}
              className="delete"
              onClick={(e) => handleDeleteClick(e)}
            >
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          ) : null}
        </Box>
      </ListItem>
    </Link>
  );
};

export default BaseDropDownButtonItemWithActions;
