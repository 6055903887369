import { useEffect, useState } from "react";
import { Alert, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ModalResultEnum } from "components/Modal/Modal";
import {
  ContractAttachmentService,
  ContractDTOV1,
  ContractNameDto,
  ContractRelationDto,
} from "openapi";
import { compareCaseInsensitive } from "constants/utils";
import { FormSelectItem } from "components/FormItems/FormSelect/FormSelect";
import AutoCompleteSelect, {
  Option,
} from "components/FormItems/AutoCompleteSelect/AutoCompleteSelect";
import { CTAButton } from "components";
import { ButtonsWrapper } from "../styles";
import { enqueueSnackbar } from "notistack";

type ContractData = {
  id: string;
  name: string;
};

type Props = {
  handleClose: (action?: ModalResultEnum, values?: unknown) => void;
  selectedContract: ContractDTOV1 | undefined;
  contractGroup: ContractNameDto[];
  refetchData?: () => Promise<void>;
};

const ContractLinkModalContent = ({
  handleClose,
  selectedContract,
  contractGroup,
  refetchData,
}: Props) => {
  const { t } = useTranslation();

  const [mainContractData, setMainContractData] = useState<ContractData>();
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<string>();
  const [contractNameData, setContractNameData] = useState<ContractNameDto[]>(
    []
  );
  const [contractOptions, setContractOptions] = useState<FormSelectItem[]>([]);
  const [loading, setLoading] = useState(true);

  const fillContractList = (contractNameData: ContractNameDto[]) => {
    const formSelectOptions = contractNameData.reduce<FormSelectItem[]>(
      (accumulator, current) => {
        if (current.id !== selectedContract?.id) {
          accumulator.push({
            key: current.id,
            value: current.name,
          });
        }
        return accumulator;
      },
      []
    );

    setContractOptions(formSelectOptions);
  };

  const fetchData = async (teamId: string) => {
    const linkableContracts =
      await ContractAttachmentService.getLinkableContracts(teamId);
    linkableContracts.sort(({ name: a }, { name: b }) =>
      compareCaseInsensitive(a, b)
    );

    setContractNameData(linkableContracts);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedContract) {
      void fetchData(selectedContract.teamId);
      if (selectedContract.parentId) {
        setMainContractData({
          id: selectedContract.parentId,
          name:
            contractGroup.find(
              (contract) => contract.id === selectedContract.parentId
            )?.name ?? "",
        });
      } else {
        setMainContractData({
          id: selectedContract.id,
          name: selectedContract.name,
        });
      }
    }
  }, [selectedContract]);

  useEffect(() => {
    if (contractNameData) fillContractList(contractNameData);
  }, [contractNameData]);

  const handleModalClose = () => {
    handleClose();
  };

  const onSubmit = async () => {
    const values: ContractRelationDto = {
      mainContractId: mainContractData?.id as string,
      attachmentId: selectedAttachmentId as unknown as string,
    };
    await ContractAttachmentService.linkContract(values);
    handleClose();
    refetchData && void refetchData();
    enqueueSnackbar(
      t("pages.contractDetails.modals.linkContract.successMessage"),
      { variant: "success" }
    );
  };

  const hasContractOptions = contractOptions.length > 0;

  const isDisabled = !selectedAttachmentId || !hasContractOptions;
  return (
    <>
      <Box>
        {t("pages.contractDetails.modals.linkContract.subtitle", {
          contractName: mainContractData?.name,
        })}
      </Box>
      <form
        name="contractLinkForm"
        noValidate
        style={{
          marginTop: "1rem",
        }}
      >
        {hasContractOptions || loading ? (
          <AutoCompleteSelect
            dataCy="contractsAutocompleteList"
            id="contractsAutocompleteList"
            options={contractOptions as Option[]}
            onSelect={(item) => {
              setSelectedAttachmentId(item.key);
            }}
            label={t(
              "pages.contractDetails.modals.linkContract.selectFormLabel"
            )}
          />
        ) : (
          <Alert severity="info">
            {t("pages.contractDetails.modals.linkContract.noContracts")}
          </Alert>
        )}
        <ButtonsWrapper>
          <CTAButton
            label="cancelBtn"
            variant="secondary"
            onClick={() => handleModalClose()}
            name={t("pages.contractDetails.modals.linkContract.buttons.cancel")}
          />
          <CTAButton
            disabled={isDisabled}
            label="saveBtn"
            onClick={() => onSubmit()}
            name={t("pages.contractDetails.modals.linkContract.buttons.save")}
          />
        </ButtonsWrapper>
      </form>
    </>
  );
};

export default ContractLinkModalContent;
