import StarsIcon from "assets/svg/stars-01.svg?react";
import { Wrapper } from "./styles";

export type GradientHeadingProps = {
  text: string;
};

const GradientHeading = ({ text }: GradientHeadingProps) => {
  return (
    <Wrapper>
      <StarsIcon />
      <span>{text}</span>
    </Wrapper>
  );
};

export default GradientHeading;
