import styled from "@emotion/styled";
import { Button as MUIButton } from "@mui/material";
import { motion } from "framer-motion";
import { theme } from "theme";
import { SIDEBAR_WIDTH } from "./StickyMenu/styles";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto ${SIDEBAR_WIDTH};
  height: 100%;
`;

export const SignatureComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.color.blue[200]};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.blue[100]};
  padding: ${theme.spacing.md};
  color: ${theme.color.blue[900]};
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const Button = styled(MUIButton)`
  border: none !important;
  font-size: ${theme.font.size.caption} !important;
`;

export const ContentWrapper = styled.div`
  height: 80vh;
  background: var(--color-white);
  border-left: 1px solid ${theme.color.gray[300]};
`;

export const MotionDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 80vh;
`;

export const SignatureContentWrapper = styled(ContentWrapper)`
  overflow: hidden;
  padding: var(--spacing-lg);
`;
