import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { ContractDTOV1 } from "openapi";
import routePaths from "constants/routePaths";
import { Chip } from "components";
import { theme } from "theme";
import {
  ContractTitle,
  ImportDetail,
  ImportedContractIcon,
  ImportStatus,
} from "./styles";

type Props = {
  contract: ContractDTOV1;
  index: number;
};

const ImportContractItem = ({ contract, index }: Props) => {
  const { t } = useTranslation();

  const isNew =
    new Date(contract.createdAt).setMilliseconds(0) ===
    new Date(contract.updatedAt).setMilliseconds(0);
  return (
    <ImportDetail index={index}>
      <ContractTitle>
        <ImportedContractIcon status="success" />
        <Link
          style={{ fontWeight: 500 }}
          component={RouterLink}
          to={routePaths.CONTRACT_VIEW.replace(":id", contract.id)}
          className="hover-underline"
          target="_blank"
        >
          {contract.name}
        </Link>
      </ContractTitle>

      <ImportStatus>
        {isNew ? (
          <Chip color={theme.color.green[700]}>
            {t("pages.import.importModal.imported.created")}
          </Chip>
        ) : (
          <Chip color={theme.color.blue[700]}>
            {t("pages.import.importModal.imported.updated")}
          </Chip>
        )}
      </ImportStatus>
    </ImportDetail>
  );
};

export default ImportContractItem;
