import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { DialogActions, IconButton } from "@mui/material";
import {
  CommonService,
  ContractSectionDTOV1,
  I18nDto,
  TranslationDTO,
} from "openapi";
import { CTAButton, FormTextField, NewModal } from "components";
import theme from "theme/theme";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useLocale } from "hooks";
import TranslationIcon from "assets/svg/translation-icon.svg?react";
import {
  ButtonWithIcon,
  Row,
} from "pages/Settings/OrganizationSettings/Category/styles";
import { useSnackbar } from "notistack";
import EditIcon from "assets/svg/edit-icon.svg?react";

type AddSectionForm = {
  name: I18nDto;
};

type Props = {
  open: boolean;
  onClose: () => void;
  sections: ContractSectionDTOV1[];
  setSections: (value: ContractSectionDTOV1[]) => void;
};

const AddNewSection = ({ open, onClose, sections, setSections }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { enqueueSnackbar } = useSnackbar();

  const [isTranslationLoading, setIsTranslationLoading] =
    useState<boolean>(false);
  const [nameTranslationEdit, setNameTranslationEdit] =
    useState<boolean>(false);
  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.object().shape({
        [sourceLanguage ?? targetLanguage]: Yup.string().required(),
      }),
    });

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<AddSectionForm>({
      resolver: yupResolver(validationSchema()),
      defaultValues: {
        name: {
          de: "",
          en: "",
        },
      },
    });

  const getTranslation = async () => {
    const data = getValues();

    if (!data.name || !data.name[sourceLanguage]) return;

    if (data.name[sourceLanguage] === "") {
      setValue(`name.${targetLanguage}`, "");
      return;
    }
    const requestBody = {
      text: data.name[locale],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    };

    try {
      setIsTranslationLoading(true);
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setValue(`name.${targetLanguage}`, translatedName.text);
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    } finally {
      setIsTranslationLoading(false);
    }
  };

  const onSubmit = () => {
    const data = getValues() as ContractSectionDTOV1;

    const newSection = {
      id: data.name[sourceLanguage],
      name: data.name,
      fields: [],
    };

    const updatedSections = [...sections, newSection];

    setSections(updatedSections as ContractSectionDTOV1[]);

    handleOnClose();
  };

  const handleOnClose = () => {
    reset();
    onClose();
    setNameTranslationEdit(false);
  };

  const sourceLanguageName = useWatch({
    control,
    name: `name.${sourceLanguage}`,
  });

  const targetLanguageName = useWatch({
    control,
    name: `name.${targetLanguage}`,
  });

  useEffect(() => {
    const debounceTimerId = setTimeout(() => {
      void getTranslation();
    }, 1000);

    return () => {
      clearTimeout(debounceTimerId);
    };
  }, [sourceLanguageName]);

  const toggleEdit = () => {
    setNameTranslationEdit(!nameTranslationEdit);
  };

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t(
        "pages.settings.organization.administrator.sections.modal.create.title"
      )}
      fullWidth
      body={
        <>
          {t(
            "pages.settings.organization.administrator.sections.modal.create.description"
          )}
          <form
            id="contractSectionForm"
            name="contractSectionForm"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <FormTextField
              control={control}
              name={`name.${sourceLanguage}`}
              label={`${t(
                "pages.settings.tabs.customFields.form.customSectionName"
              )} (${t(`common.languages.${sourceLanguage}`)})`}
            />

            {targetLanguageName && !nameTranslationEdit ? (
              <Row
                justifyItems="flex-start"
                maxWidth="600px"
                gap={theme.spacing.xs}
              >
                <span>
                  {`${t(
                    "pages.settings.tabs.customFields.form.customSectionName"
                  )} (${t(`common.languages.${targetLanguage}`)}): `}
                  <strong>{targetLanguageName}</strong>
                </span>
                <IconButton onClick={toggleEdit}>
                  <EditIcon fill={theme.color.blue[600]} />
                </IconButton>
              </Row>
            ) : null}

            {nameTranslationEdit && (
              <>
                <FormTextField
                  control={control}
                  name={`name.${targetLanguage}`}
                  label={`${t(
                    "pages.settings.tabs.customFields.form.customSectionName"
                  )} (${t(`common.languages.${targetLanguage}`)})`}
                />
                <ButtonWithIcon
                  onClick={getTranslation}
                  startIcon={<TranslationIcon />}
                  loading={isTranslationLoading}
                  loadingPosition="start"
                  variant="contained"
                >
                  <span>{t("pages.categoryDetails.buttons.translation")}</span>
                </ButtonWithIcon>
              </>
            )}
            <DialogActions>
              <CTAButton
                type="reset"
                variant="secondary"
                label="cancel"
                onClick={handleOnClose}
                name={t("common.buttons.cancel")}
              />
              <CTAButton
                variant="primary"
                type="submit"
                label="addSectionButton"
                name={t("common.buttons.create")}
                disabled={!sourceLanguageName || !targetLanguageName}
              />
            </DialogActions>
          </form>
        </>
      }
    />
  );
};

export default AddNewSection;
