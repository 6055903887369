import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from "@mui/material";

export type ButtonAsyncProps = ButtonProps & {
  isLoading?: boolean;
};

const Button = ({
  isLoading = false,
  children,
  size,
  ...props
}: ButtonAsyncProps) => {
  return (
    <MuiButton
      {...props}
      disabled={isLoading || props.disabled}
      data-testid="button"
    >
      {isLoading ? (
        <CircularProgress
          size={size === "large" ? 25 : 18}
          color="inherit"
          data-testid="button-loader"
        />
      ) : (
        <span data-testid="button-title">{children}</span>
      )}
    </MuiButton>
  );
};

export default Button;
