import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Row } from "../../RelativeDueDate/styles";
import { theme } from "theme";
import { useTranslation } from "react-i18next";
import { useLocale } from "hooks";
import { FormMessage } from "./styles";
import { CategoryDTO } from "openapi";

type AssigneeOption = {
  key: string;
  value: string;
};

type Props = {
  teamMembersWithoutAccess: AssigneeOption[];
  selectedCategory: CategoryDTO[];
};

const Hint = ({ teamMembersWithoutAccess, selectedCategory }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  if (!teamMembersWithoutAccess.length || !selectedCategory) return <></>;

  return (
    <Row
      alignItems="center"
      justifyItems="flex-start"
      gap={theme.spacing.md}
      verticalMargin={theme.spacing.xxs}
    >
      <InfoOutlinedIcon style={{ color: theme.color.orange[600] }} />
      <FormMessage>
        <span>
          {teamMembersWithoutAccess.length
            ? teamMembersWithoutAccess.map((item) => {
                return <strong key={item.key}>{item.value}</strong>;
              })
            : null}
        </span>
        <span>
          {t("pages.tasks.modal.hints.noCategoryAccess", {
            count: teamMembersWithoutAccess.length,
          })}
        </span>
        {selectedCategory && (
          <strong>{selectedCategory[0].name[locale]}</strong>
        )}
      </FormMessage>
    </Row>
  );
};

export default Hint;
