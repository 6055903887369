import { Role } from "components";
import { useTranslation } from "react-i18next";
import { OrgWrapper, Wrapper, Title } from "./styles";
import StatusLabel from "../StatusLabel/StatusLabel";
import { PermissionChangeLogDTO } from "openapi";

export type InvitationEditType = {
  event: PermissionChangeLogDTO.event;
  organizationName: string;
};

const SupportUserInvited = ({
  event,
  organizationName,
}: InvitationEditType) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>
        <StatusLabel
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
          variant={event}
        />
        <span>
          {t(
            "pages.settings.organization.permissionChangeLog.messages.invitationTo"
          )}
        </span>
        <span>
          {t("pages.settings.organization.permissionChangeLog.supportUser")}
        </span>
      </Title>
      <OrgWrapper>
        <Role name={organizationName} />
      </OrgWrapper>
    </Wrapper>
  );
};

export default SupportUserInvited;
