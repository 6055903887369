import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { Header } from "./styles";
import { EntityGridViewSelector } from "components/EntityGrid/EntityGridViewSelector";
import { EntityGridFilterResetButton } from "components/EntityGrid/EntityGridFilterResetButton";
import UploadContractDropdown from "components/ContractUpload/Dropdown/Dropdown";
import { EntityGridSearchInput } from "components/EntityGrid/EntityGridSearchInput";

export type ContractGridHeaderProps = {
  canCreateContract?: boolean;
};

const ContractGridHeader = ({ canCreateContract }: ContractGridHeaderProps) => {
  const { t } = useTranslation();
  const { hasWriteAccessToAny } = useTeam();

  const showContractAddButton = hasWriteAccessToAny();

  return (
    <Header>
      <EntityGridSearchInput
        label={t("pages.contracts.header.searchContract")}
      />
      <div style={{ display: "flex", flexShrink: 1, gap: theme.spacing.lg }}>
        <EntityGridFilterResetButton />
        <EntityGridViewSelector />
        {showContractAddButton ? (
          <UploadContractDropdown
            dataCy="addNewContract"
            showLabel
            showTooltip={false}
            canCreateContract={canCreateContract}
          />
        ) : null}
      </div>
    </Header>
  );
};

export default ContractGridHeader;
