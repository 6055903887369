import { Dispatch, SetStateAction, useMemo } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoryDTO } from "openapi";
import { useStyles as useStyledFields } from "../../components/StyledComponents/StyledFields";
import styled from "@emotion/styled";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "../../hooks/GlobalStateHooks";
import { Chip } from "components";
import theme from "theme/theme";
import { getColor } from "components/Chip/helpers";

const LabelWrapper = styled.p`
  font-weight: 500;
  color: ${theme.color.gray[700]};
  margin: 0rem;
  margin-right: 0.5rem;
`;

const SelectButtonWrapper = styled(Button)`
  color: ${theme.color.blue[700]};
  padding: 0;
  line-height: inherit;
  text-transform: none;
  font-size: 100%;
  font-weight: unset;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      boxShadow: theme.shadow.standard,
    },
  },
};

type Props = {
  setSelectedCategories: Dispatch<SetStateAction<string[]>>;
  selectedCategories: string[];
  categoriesData: CategoryDTO[] | undefined;
  customLabel?: string | null;
  isHideLabel?: boolean;
  isEnabledReadAccess?: boolean;
  error?: string | null;
  multiTeam?: boolean;
};

const CustomMultipleSelect = ({
  setSelectedCategories,
  selectedCategories,
  categoriesData,
  customLabel,
  error = null,
  isHideLabel = false,
  isEnabledReadAccess = false,
  multiTeam = false,
}: Props) => {
  const { hasWriteAccess } = useTeam();
  const { locale } = useLocale();
  const fieldClasses = useStyledFields();

  const categoriesById = useMemo(() => {
    return new Map(categoriesData?.map((category) => [category.id, category]));
  }, [categoriesData]);

  const { t } = useTranslation();

  const handleChange = (value: string | string[]) => {
    setSelectedCategories(!Array.isArray(value) ? [value] : value);
  };

  const selectAll = () => {
    if (categoriesData) {
      setSelectedCategories(categoriesData.map((category) => category.id));
    }
  };

  const resetSelection = () => {
    setSelectedCategories([]);
  };

  return (
    <>
      {!isHideLabel && (
        <LabelWrapper>
          {customLabel ??
            t("pages.contractEdit.modals.customSection.selectCategory")}
        </LabelWrapper>
      )}
      <Box>
        <SelectButtonWrapper variant="text" size="small" onClick={selectAll}>
          {t("pages.export.selectAll")}
        </SelectButtonWrapper>
        {" · "}
        <SelectButtonWrapper
          variant="text"
          size="small"
          onClick={resetSelection}
        >
          {t("pages.export.resetSelection")}
        </SelectButtonWrapper>
      </Box>
      <Box>
        <FormControl sx={{ width: "100%", mx: 0, mt: "0.5rem" }}>
          <InputLabel
            id="categorylist-label"
            sx={{ backgroundColor: "white", padding: "0 0.5rem" }}
          >
            {t("pages.export.categories")}
          </InputLabel>
          <Select
            labelId="categorylist-label"
            id="categorylist"
            multiple
            className={fieldClasses.SelectField}
            fullWidth
            value={selectedCategories}
            error={!!error}
            onChange={(event) => {
              handleChange(event.target.value);
            }}
            MenuProps={MenuProps}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => {
                  const category = categoriesById.get(value);
                  if (category) {
                    return (
                      <Chip key={value} color={getColor(category.color)}>
                        {category.name[locale]}
                      </Chip>
                    );
                  } else {
                    return null;
                  }
                })}
              </Box>
            )}
          >
            {categoriesData &&
              categoriesData
                .map((category) => ({
                  name: category.name[locale],
                  category: category,
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(({ name, category }) => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                    disabled={
                      !isEnabledReadAccess && !hasWriteAccess(category.id)
                    }
                  >
                    <Checkbox
                      checked={selectedCategories.indexOf(category.id) > -1}
                      sx={{ minWidth: "42px" }}
                    />
                    <ListItemText
                      primary={name}
                      sx={{ whiteSpace: "break-spaces" }}
                    />
                  </MenuItem>
                ))}
          </Select>
          {error && <FormHelperText error>{t(error)}</FormHelperText>}
        </FormControl>
      </Box>
    </>
  );
};

export default CustomMultipleSelect;
