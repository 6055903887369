import {
  Values,
  Title,
  Wrapper,
  TitleIconWrapper,
  PreviousValue,
  CurrentValue,
  ValueWrapper,
  ValueIconWrapper,
} from "./styles";
import ArrowIcon from "assets/svg/arrow-up.svg?react";
import { ChangeLogDataItemType } from "../../types";

export type EditEntityItemProps = {
  data: ChangeLogDataItemType;
  fieldName?: string;
  oldBg?: string;
  newBg?: string;
  fieldIcon?: JSX.Element;
  variant?: "row";
};

const EditEntityItem = ({
  data,
  fieldName,
  oldBg,
  newBg,
  fieldIcon,
  variant,
}: EditEntityItemProps) => {
  const isCustomField =
    (typeof data.from === "object" && data.from !== null) ||
    (typeof data.to === "object" && data.to !== null);

  const isUpdated = isCustomField ? !!data.from?.value : !!data.from;
  const valueIsRemoved = isCustomField
    ? !!data.from?.value && !data.to?.value
    : !!data.from && !data.to;

  const valueWasNotSet = isCustomField
    ? !data.from?.value && !data.to?.value
    : !data.from && !data.to;

  const getFieldValue = (
    data: ChangeLogDataItemType,
    type: keyof ChangeLogDataItemType
  ) => {
    if (valueWasNotSet) {
      return "-";
    }
    if (isCustomField) {
      return data[type]?.value;
    }
    return data[type] as unknown as string;
  };

  return (
    <Wrapper variant={variant}>
      {fieldName ? (
        <Title>
          <TitleIconWrapper>{fieldIcon}</TitleIconWrapper>
          {fieldName}
        </Title>
      ) : null}

      <Values>
        {isUpdated ? (
          <ValueWrapper>
            <PreviousValue lineThrough={true} oldBg={oldBg}>
              {getFieldValue(data, "from")}
            </PreviousValue>
            {!valueIsRemoved && (
              <ValueIconWrapper>
                <ArrowIcon />
              </ValueIconWrapper>
            )}
            <CurrentValue newBg={newBg}>
              {getFieldValue(data, "to")}
            </CurrentValue>
          </ValueWrapper>
        ) : (
          <CurrentValue newBg={newBg} variant={variant}>
            {getFieldValue(data, "to")}
          </CurrentValue>
        )}
      </Values>
    </Wrapper>
  );
};

export default EditEntityItem;
