import Grid from "@mui/material/Grid";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useLocale } from "hooks/GlobalStateHooks";
import { ContactTypeSectionDTO, ContactDatapointDefinitionDTO } from "openapi";
import { DataPoint } from "components/DatapointsV2/DataPoint";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { ContactDataDTO } from "../../../Contacts/ContactDataDTO";

type ContactSectionProps = {
  section: ContactTypeSectionDTO;
  definitions: ContactDatapointDefinitionDTO[];
  values: ContactDataDTO;
  editable: boolean;
  isCardNested?: boolean;
};

const Section = ({
  editable,
  section,
  definitions,
  values,
  isCardNested,
}: ContactSectionProps) => {
  const { locale } = useLocale();
  const hiddenFields = ["name"];

  return (
    <CardWrapper isCardNested={isCardNested}>
      <CardHeaderWrapper title={section.name[locale]} />
      <Grid container spacing={1.25} sx={{ marginTop: "0.5rem" }}>
        {section.definitions.map((definition) => {
          // Note: We already display some fields in a header section, so here we just hide them.
          const fieldName = definitions.find(
            (d) => d.id === definition.id
          )?.visibleId;
          const isDataPointVisible = hiddenFields.includes(fieldName as string);
          if (isDataPointVisible) return null;

          return (
            <DataPoint
              key={definition.id}
              id={definition.id}
              definitions={definitions}
              contact={values ?? {}}
              editable={editable}
            />
          );
        })}
      </Grid>
    </CardWrapper>
  );
};

export default Section;
