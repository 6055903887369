import Grid from "@mui/material/Grid";
import { TFunction } from "i18next";
import {
  ContractDTOV1,
  ContractFieldDTOV1,
  ListFieldData,
  ListFieldTypeDtoV1,
} from "openapi";
import { useTranslation } from "react-i18next";
import { DataPointProps } from "./types";
import { useLocale } from "hooks/GlobalStateHooks";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { useFormContext } from "react-hook-form";
import { useAnalysis } from "./hooks/useAnalysis";
import { parsePaymentCycle, parsePaymentType } from "@contracthero/common";
import { AnalysisWrapper } from "./components/AnalysisWrapper";
import { DatapointField } from "./components/DatapointField";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";
import { DatapointFieldInputAdornment } from "./components/DatapointFieldInputAdornment";

export const evaluateListItem = (
  t: TFunction<"translation">,
  { definition, values }: Pick<DataPointProps, "definition" | "values">,
  data: ListFieldTypeDtoV1
) => {
  // This is the workaround, when the list was still stored in the field and not in the definition
  data = {
    value: (values[definition.id] as ListFieldTypeDtoV1)?.value,
    items: (definition.data as ListFieldTypeDtoV1)?.items,
  } as ListFieldTypeDtoV1;

  const listData = definition.data as ListFieldData | null;

  if (!data || !data.value || !listData) {
    return "-";
  }

  if (definition.oldStandardField && definition.visibleId) {
    return t(`enums.${definition.visibleId}.` + data.value);
  }
  return listData.items.find((item) => item.id === data.value)?.value ?? "-";
};

const specialParsersByVisibleId = {
  paymentCycle: parsePaymentCycle,
  paymentType: parsePaymentType,
} as const;

export const ListDatapoint = (props: DataPointProps) => {
  const {
    definition,
    values,
    editable,
    showAnalysis,
    contractId,
    teamId,
    fetchData,
  } = props;
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { control, setValue } = useFormContext<ContractDTOV1>();

  const data = values[definition.id] as ListFieldTypeDtoV1;

  const analysis = useAnalysis(definition, data, {
    value:
      specialParsersByVisibleId?.[
        definition.visibleId as keyof typeof specialParsersByVisibleId
      ],
  });

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data,
    definition?.visibleId && definition.visibleId in specialParsersByVisibleId
      ? {
          value: {
            parser:
              specialParsersByVisibleId?.[
                definition.visibleId as keyof typeof specialParsersByVisibleId
              ],
            displayParser: (value) => {
              return t(
                `enums.${definition?.visibleId ?? ""}.${
                  specialParsersByVisibleId?.[
                    definition.visibleId as keyof typeof specialParsersByVisibleId
                  ](value) ?? ""
                }`
              );
            },
          },
        }
      : undefined
  );

  const name = definition.name[locale];
  if (editable) {
    const options = (definition.data as ListFieldTypeDtoV1)?.items?.map(
      (item) => {
        return { key: item.id, value: item.value };
      }
    );
    return (
      <Grid item xs={12} md={6}>
        <AnalysisWrapper
          analysis={showAnalysis ? analysis : undefined}
          fieldKey="value"
          definitionType={ContractFieldDTOV1.type.LIST}
          renderField={(data, selector) => {
            return (
              <FormSelect
                control={control}
                options={options || []}
                name={`fields.${definition.id}.value`}
                translationPrefix={
                  definition.oldStandardField && definition.visibleId
                    ? `enums.${definition.visibleId}`
                    : undefined
                }
                InputProps={{
                  startAdornment: selector ?? (
                    <DatapointFieldInputAdornment
                      definition={definition}
                      fieldKey="value"
                      suggestions={suggestions}
                      setValue={setValue}
                      data={values[definition.id]}
                    />
                  ),
                }}
                inputProps={{
                  suggestion: !!data?.selectedSuggestion.value,
                }}
                label={definition.name[locale]}
              />
            );
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
        name={name}
      >
        {evaluateListItem(t, props, data)}
      </DatapointField>
    </Grid>
  );
};
