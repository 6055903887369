import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImportContractItem from "./components/ImportContractItem";
import { ImportDataDTO } from "openapi";
import { ImportedContractContainer } from "./components/styles";
import { FailedImportIcon, ImportedIcon, ImportStatusHeader } from "./styles";

type Props = {
  importData: ImportDataDTO;
};
const ImportStatusModal = ({ importData }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <ImportStatusHeader>
              <ImportedIcon />
              {`${importData.contracts.length} ${t(
                "pages.import.importModal.successMessage"
              )}`}
            </ImportStatusHeader>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ImportedContractContainer>
            {importData.contracts.map((contract, index) => (
              <ImportContractItem
                key={index}
                contract={contract}
                index={index}
              />
            ))}
          </ImportedContractContainer>
        </AccordionDetails>
      </Accordion>

      {importData.errors.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ImportStatusHeader>
              <FailedImportIcon />
              {`${importData.errors.length} ${t(
                "pages.import.importModal.failedMessage"
              )}`}
            </ImportStatusHeader>
          </AccordionSummary>
        </Accordion>
      )}
    </div>
  );
};

export default ImportStatusModal;
