import { useState } from "react";
import {
  Body,
  Header,
  Logo,
  Status,
  Wrapper,
  Name,
  Description,
  Placeholder,
  ActionButtons,
} from "./styles";
import { AppStatus } from "../../types";
import { CTAButton, NewModal } from "components";
import RequestModalContent from "../RequestModalContent/RequestModalContent";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { OrganizationNewService } from "openapi";
import { useTeam } from "contexts/team/hooks";
import * as Sentry from "@sentry/react";

export type AppCardProps = {
  name: string;
  description: string;
  status: AppStatus;
  logoPath?: string;
};

const AppCard = ({ name, description, status, logoPath }: AppCardProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { organizationId } = useTeam();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    try {
      await OrganizationNewService.requestAccess(name, organizationId);
      enqueueSnackbar(
        t(`pages.settings.organization.apps.messages.success`, { name }),
        {
          variant: "info",
        }
      );
    } catch (error: unknown) {
      enqueueSnackbar(
        t(`pages.settings.organization.apps.messages.error`, { name }),
        {
          variant: "error",
        }
      );
      Sentry.captureException(error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Wrapper onClick={() => setShowModal(true)}>
        <Header>
          {logoPath ? (
            <Logo>
              <img src={logoPath} alt={`${name} logo`} />
            </Logo>
          ) : (
            <Placeholder />
          )}
          <Status status={status}>
            {t(`pages.settings.organization.apps.card.statuses.${status}`)}
          </Status>
        </Header>
        <Body>
          <Name>{name}</Name>
          <Description>{description}</Description>
        </Body>
      </Wrapper>

      {showModal && (
        <NewModal
          open={showModal}
          handleClose={handleClose}
          title={name}
          body={<RequestModalContent name={name} description={description} />}
          footer={
            <ActionButtons>
              <CTAButton
                variant="tertiary"
                name={t("common.buttons.maybeLater")}
                onClick={handleClose}
              />
              <CTAButton
                variant="primary"
                name={t("common.buttons.requestWorkflow")}
                aria-label="request-workflow"
                onClick={handleSubmit}
              />
            </ActionButtons>
          }
        />
      )}
    </>
  );
};

export default AppCard;
