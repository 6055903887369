import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SimpleLink } from "new-components";
import { NewModal, CTAButton, Chip } from "components";
import routePaths from "constants/routePaths";
import { theme } from "theme";
import {
  CategoryDTO,
  ContractFieldDTOV1,
  TaskAutomationDTO,
  TeamMembersDto,
} from "openapi";
import {
  getAssigneesWithoutAccessToCategory,
  getCategoriesWithoutDateField,
  getFieldInfoById,
} from "../utils";
import { getColor } from "components/Chip/helpers";
import { useLocale } from "hooks";
import { ContentRow, Description, Title } from "./styles";
import AssigneesWithoutAcceess from "./AssigneesWithoutAccess";
import { FlexRow } from "../styles";

type Props = {
  open: boolean;
  handleClose: () => void;
  selectedTaskAutomation: TaskAutomationDTO;
  categories?: CategoryDTO[];
  teamMembersData?: TeamMembersDto;
  fields?: ContractFieldDTOV1[];
};

const ActionsModal = ({
  open,
  handleClose,
  selectedTaskAutomation,
  categories,
  teamMembersData,
  fields,
}: Props) => {
  const { t } = useTranslation();
  const { categoryIds, assigneeIds, dueDateConfig } = selectedTaskAutomation;
  const { locale } = useLocale();

  const categoriesWithoutDateField = useMemo(() => {
    return getCategoriesWithoutDateField(
      categories,
      categoryIds,
      dueDateConfig?.relativeFieldId as string
    );
  }, [categories, categoryIds, assigneeIds]);

  const assigneesWithoutAccessToCategory = useMemo(() => {
    return getAssigneesWithoutAccessToCategory(
      categories,
      teamMembersData,
      categoryIds,
      assigneeIds as string[]
    );
  }, [categories, teamMembersData, categoryIds, assigneeIds]);

  const selectedDateField = getFieldInfoById(
    fields,
    dueDateConfig?.relativeFieldId as string
  );

  return (
    <NewModal
      open={open}
      handleClose={handleClose}
      title={t(
        "pages.settings.organization.taskAutomations.actionsModal.title"
      )}
      fullWidth
      maxWidth="sm"
      body={
        <>
          {categoriesWithoutDateField?.length ? (
            <ContentRow>
              <Title>
                {t(
                  "pages.settings.organization.taskAutomations.actionsModal.missingFieldsTitle"
                )}
              </Title>
              <Description>
                <Trans
                  i18nKey={
                    "pages.settings.organization.taskAutomations.notifications.warnings.missingFieldsInfo"
                  }
                  values={{
                    field: selectedDateField?.name[locale],
                  }}
                  components={{
                    strong: <strong />,
                  }}
                />
              </Description>
              <FlexRow>
                {categoriesWithoutDateField?.map((category: CategoryDTO) => (
                  <Chip
                    key={category?.id}
                    blendMode="soft"
                    color={getColor(category?.color || theme.color.blue[600])}
                  >
                    {category?.name[locale]}
                  </Chip>
                ))}
              </FlexRow>
              <p>
                <Trans i18nKey="pages.settings.organization.taskAutomations.actionsModal.missingFieldsAction">
                  <SimpleLink to={routePaths.SETTINGS_CUSTOM_FIELDS} />
                </Trans>
              </p>
            </ContentRow>
          ) : null}

          {assigneesWithoutAccessToCategory?.length ? (
            <ContentRow>
              <Title>
                {t(
                  "pages.settings.organization.taskAutomations.actionsModal.missingAccessTitle"
                )}
              </Title>
              <Description>
                {t(
                  "pages.settings.organization.taskAutomations.actionsModal.missingAccessAction"
                )}
              </Description>
              <FlexRow>
                <AssigneesWithoutAcceess
                  assignees={assigneesWithoutAccessToCategory}
                  nameAsLink
                />
              </FlexRow>
            </ContentRow>
          ) : null}
        </>
      }
      footer={
        <CTAButton
          label="closeBtn"
          type="button"
          variant="primary"
          onClick={handleClose}
          name={t("common.buttons.close")}
        />
      }
    />
  );
};

export default ActionsModal;
