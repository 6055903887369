import { Name } from "./styles";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventIcon from "@mui/icons-material/Event";
import { theme } from "theme";
import { useTranslation } from "react-i18next";
import { getDaysUntilDate, isDatePast } from "utils/helpers";
import { TaskNewDTO } from "openapi";

type Props = {
  value: string;
  data: {
    id: string;
    dueDate?: string;
    reminderAt?: string;
    status?: string;
  };
};

const TitleCellRenderer = ({ value, data }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const isDueDatePast = isDatePast(data?.dueDate);
  const daysUntilDate = getDaysUntilDate(data?.dueDate, 1);
  const isClosed = data?.status === TaskNewDTO.status.CLOSED;
  const { t } = useTranslation();

  const getTooltipTranslation = () => {
    if (isDueDatePast) {
      return t("pages.tasks.table.tooltips.dueDatePast");
    }
    if (daysUntilDate === 0) {
      return t("pages.tasks.table.tooltips.dueToday");
    }
    if (daysUntilDate === 1) {
      return t("pages.tasks.table.tooltips.dueTomorrow");
    }
  };

  if ((isDueDatePast || daysUntilDate === 0 || daysUntilDate) && !isClosed) {
    return (
      <Tooltip
        title={getTooltipTranslation()}
        disableInteractive
        className="editable-cell"
        placement="bottom-start"
      >
        <Name
          onClick={() =>
            setSearchParams({
              task: data.id,
            })
          }
        >
          {isDueDatePast ? (
            <EventBusyIcon
              sx={{
                opacity: 0.5,
                height: theme.font.size.h6,
                marginRight: theme.spacing.sm,
              }}
            />
          ) : (
            <EventIcon
              sx={{
                opacity: 0.5,
                height: theme.font.size.h6,
                marginRight: theme.spacing.sm,
              }}
            />
          )}

          {value}
        </Name>
      </Tooltip>
    );
  }
  return (
    <Name
      onClick={() =>
        setSearchParams({
          task: data.id,
        })
      }
      className="editable-cell"
    >
      {value}
    </Name>
  );
};

export default TitleCellRenderer;
