import styled from "@emotion/styled";
import { Button, Card, Divider as MUIDivider } from "@mui/material";
import MUIAddIcon from "@mui/icons-material/Add";
import { theme } from "theme";

const commonStyles = `
  font-weight: ${theme.font.weight.medium};
  border-radius: ${theme.borderRadius};
  box-shadow: ${theme.shadow.standard};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Header = styled.div`
  margin: ${theme.spacing.lg} 0;
  padding: ${theme.spacing.xxs} ${theme.spacing.lg};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-md);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  margin: ${theme.spacing.xl} ${theme.spacing.xl} 0 ${theme.spacing.xl};
  color: ${theme.color.gray[700]};
`;

export const Title = styled.div`
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.medium};
  word-break: break-word;
  display: flex;
`;

export const Divider = styled(MUIDivider)`
  margin: ${theme.spacing.md} ${theme.spacing.xl};
  color: ${theme.color.red[600]};
`;

export const ContractButton = styled(Button)`
  color: ${theme.color.white};
  background-color: ${theme.color.blue[600]};
  text-transform: none;
  ${commonStyles}

  :hover {
    background-color: ${theme.color.blue[600]};
  }
`;

export const AddIcon = styled(MUIAddIcon)`
  margin-left: ${theme.spacing.md};
`;

export const OptionalText = styled.p`
  margin: ${theme.spacing.md} 0 0;
  font-size: ${theme.font.size.caption};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${theme.spacing.lg};
`;

export const CardWrapper = styled(Card)`
  border-radius: 0.5rem;
  border-color: ${theme.color.gray[200]};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  height: 100%;
`;
