import styled from "@emotion/styled";
import { theme } from "theme";
import Bin from "assets/svg/bin-icon.svg?react";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 0.5rem;
`;

export const HeaderIconStyles = {
  backgroundColor: theme.color.blue[600],
  width: "40px",
  height: "40px",
  borderRadius: theme.borderRadius,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: "0",
  marginRight: theme.spacing.lg,
  svg: {
    fill: "white",
  },
};

export const Message = styled.p`
  margin: ${theme.spacing.xxl} 0 0;
`;

export const SearchBar = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  justify-content: space-between;
  margin: ${theme.spacing.xxl} 0;
  max-width: 40rem;
`;

export const FlexRow = styled.div<{ direction?: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
  flex-direction: ${(props) => props.direction || "row"};
`;

export const CellWithIcon = styled.div`
  display: flex;
  svg {
    color: ${theme.color.orange[600]};
    margin-left: ${theme.spacing.xs};
  }
`;

export const AssigneeNameWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  flex-wrap: wrap;
`;

export const AssigneeName = styled.span`
  position: relative;
  &:not(:last-of-type)::after {
    content: ",";
    position: relative;
  }
} `;

export const BinIcon = styled(Bin)`
  fill: ${theme.color.red[700]};
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
`;

export const TableRow = styled.tr`
  .name {
    color: ${theme.color.gray[700]} !important;
    transition: color 0.25s ease-in-out;
  }

  :hover .name {
    // @todo update with proper color variable one day
    color: #364fa6 !important;
    cursor: pointer;
    text-decoration: underline !important;
  }

  .action-buttons {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  :hover .action-buttons {
    opacity: 1;
  }
`;
