import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DocumentService } from "openapi/services/DocumentService";
import { DocumentUpdateDTO } from "../../openapi";

export const useAddDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      organizationId: string;
      teamId?: string;
      contractId: string;
      file: Blob;
    }) => {
      if (!data.teamId) {
        return;
      }

      try {
        return DocumentService.addDocument(
          data.organizationId,
          data.teamId,
          data.contractId,
          { file: data.file }
        );
      } catch (error) {
        console.error("Unknown Error:", error);
        throw error;
      }
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(["documents"]);
    },
  });
};

/**
 * Fetch documents of a contract using React Query
 */
export const useFetchDocumentsQuery = (
  organizationId: string,
  teamId?: string,
  contractId?: string,
  searchType?: string,
  search?: string
) => {
  return useQuery(
    ["documents", organizationId, teamId, contractId, searchType],
    async () => {
      if (contractId && teamId) {
        return DocumentService.getAllDocuments(
          organizationId,
          teamId,
          contractId,
          searchType === "text" ? search : undefined
        );
      }
    },
    {
      enabled: !!contractId && !!teamId,
    }
  );
};

export const useDownloadDocumentQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.downloadDocument(
          organizationId,
          teamId,
          contractId,
          documentId
        ) as Promise<Blob>;
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};

export const useFetchDocumentQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId, "metadata"],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.getDocument(
          organizationId,
          teamId,
          contractId,
          documentId
        );
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      organizationId?: string;
      teamId?: string;
      contractId?: string;
      documentId?: string;
    }) => {
      if (
        data.organizationId &&
        data.teamId &&
        data.contractId &&
        data.documentId
      ) {
        return DocumentService.deleteDocument(
          data.organizationId,
          data.teamId,
          data.documentId,
          data.contractId
        );
      }
    },

    onSuccess: (_, { organizationId, teamId, contractId }) => {
      void queryClient.invalidateQueries([
        "documents",
        organizationId,
        teamId,
        contractId,
      ]);
    },
  });
};

export const useUpdateDocumentDataMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      organizationId: string;
      teamId?: string;
      contractId: string;
      documentId: string;
      data: DocumentUpdateDTO;
    }) => {
      if (!data.teamId) {
        return;
      }

      return DocumentService.updateDocumentData(
        data.organizationId,
        data.teamId,
        data.documentId,
        data.contractId,
        data.data
      );
    },

    onSuccess: () => {
      void queryClient.invalidateQueries(["documents"]);
    },
  });
};

export const useUpdateDocumentFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      organizationId: string;
      teamId?: string;
      contractId: string;
      documentId: string;
      file: Blob;
    }) => {
      if (!data.teamId) {
        return;
      }
      return DocumentService.updateDocumentFile(
        data.organizationId,
        data.teamId,
        data.documentId,
        data.contractId,
        { file: data.file }
      );
    },

    onSuccess: (_, { organizationId, teamId, contractId, documentId }) => {
      const variables = [organizationId, teamId, contractId, documentId];
      void queryClient.invalidateQueries([
        "document",
        ...variables,
        "metadata",
      ]);

      void queryClient.invalidateQueries(["documents", ...variables]);
    },
  });
};

export const useDocumentOcrResultQuery = (
  organizationId: string,
  teamId: string | undefined,
  contractId: string | undefined,
  documentId: string | undefined
) => {
  return useQuery(
    ["document", organizationId, teamId, contractId, documentId, "ocr"],
    async () => {
      if (organizationId && teamId && contractId && documentId) {
        return DocumentService.getDocumentOcrResult(
          organizationId,
          teamId,
          documentId,
          contractId
        ) as Promise<Record<string, unknown>>;
      }
    },
    {
      enabled: !!documentId && !!teamId && !!contractId && !!documentId,
    }
  );
};
