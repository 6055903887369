import { Title, Wrapper, IconWrapper } from "./styles";
import ContractIcon from "assets/svg/contract-icon.svg?react";
import SignatureIcon from "assets/svg/signature-icon.svg?react";
import TasksIcon from "assets/svg/check-square-icon.svg?react";

export type FieldWithIconEntityItemProps = {
  name?: string;
  variant?: string;
};

const options: Record<string, JSX.Element> = {
  file: <ContractIcon />,
  signature: <SignatureIcon />,
  task: <TasksIcon />,
};

const FieldWithIconEntityItem = ({
  name,
  variant = "file",
}: FieldWithIconEntityItemProps) => {
  return (
    <Wrapper>
      <Title>
        <IconWrapper>{options[variant]}</IconWrapper>
        {name}
        {}
      </Title>
    </Wrapper>
  );
};

export default FieldWithIconEntityItem;
