import { Name } from "./styles";
import { useNavigate } from "react-router-dom";

type Props = {
  value: string;
  data: {
    id: string;
    contractId: string;
  };
};

const ContractCellRenderer = ({ value, data }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <Name onClick={() => navigate(`/contracts/${data.contractId}`)}>
        {value}
      </Name>
    </>
  );
};

export default ContractCellRenderer;
