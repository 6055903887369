import { Role } from "components";
import { Diff, RoleUpdatedDataDTO } from "openapi";
import { Values, Wrapper, ValueIconWrapper } from "./styles";
import ArrowIcon from "assets/svg/arrow-up.svg?react";

export type RoleLabelType = {
  logData: RoleUpdatedDataDTO;
  teamName: string;
};

const RoleLabel = ({ logData, teamName }: RoleLabelType) => {
  const isUpdated = typeof logData.name === "object";

  const getFieldValue = (logData: RoleUpdatedDataDTO, type: keyof Diff) => {
    if (isUpdated) {
      return logData.name[type] as unknown as string;
    }
    return logData.name as unknown as string;
  };
  return (
    <Wrapper>
      {isUpdated ? (
        <Values>
          <Role name={`${getFieldValue(logData, "from")} (${teamName})`} />
          <ValueIconWrapper>
            <ArrowIcon />
          </ValueIconWrapper>
          <Role name={`${getFieldValue(logData, "to")} (${teamName})`} />
        </Values>
      ) : (
        <Role name={`${getFieldValue(logData, "from")} (${teamName})`} />
      )}
    </Wrapper>
  );
};

export default RoleLabel;
