import { forwardRef, SVGProps } from "react";

const CheckDashIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle
          cx="10"
          cy="10"
          r="8.125"
          stroke="#96A3A8"
          strokeWidth="1.25"
          strokeDasharray="2.5 2.5"
        />
      </svg>
    );
  }
);
CheckDashIcon.displayName = "CheckDashIcon";
export default CheckDashIcon;
