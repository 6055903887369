import { useEffect, useMemo, useRef, useState } from "react";
import BaseBodyWrapper from "../../components/BaseBodyWrapper/BaseBodyWrapper";
import AgGridBaseTable from "../../components/AgGridBaseTable/AgGridBaseTable";
import ContractGridHeader from "./ContractGridHeader";
import {
  getColumnDefs,
  getGridOptions,
  getSidebarOptions,
} from "./contractTable.config";
import { useTranslation } from "react-i18next";
import {
  CategoryDTO,
  ContractDTOV1,
  ContractFieldDTOV1,
  DurationFieldTypeDtoV1,
  ListFieldTypeDtoV1,
} from "../../openapi";
import { AlertWrapper } from "components/Alert/Alert";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { useLocation, useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import {
  CellValueChangedEvent,
  ColDef,
  EditableCallback,
  ICellEditorParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import {
  addMonthSuffix,
  ContractDtoWithResolvedParent,
  dateFormats,
  dateFormatter,
  resolveParentsOfContractDto,
} from "constants/utils";
import { CustomEventTypeEnum } from "shared/enums/customevents.enum";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { useTeam } from "contexts/team/hooks";
import { getDateColumnDefinition } from "table/ColumnDefinitions/DateColumnDefinition.config";
import { getActiveViewDeterminerFunction } from "contexts/grid/helpers";
import { Loader } from "components";
import { adjustColumnDefs, AvailableTemplates } from "contexts/grid/default";
import {
  cellValueFormatter,
  countryCellRenderer,
  createUpdateBody,
  defaultEnumFormatter,
  filterComparator,
  getCellClass,
  getCountryNameByKey,
  getFieldValue,
  isContractEditable,
  orderedColumns,
  sortToolPanelColumns,
} from "./helpers";
import { useFieldsQuery } from "shared/api/fields";
import {
  useContractsQuery,
  useUpdateContractMutation,
} from "shared/api/contracts";
import { useContractViewsQuery } from "shared/api/views";
import { useOverviewActions, useOverviewState } from "contexts/grid/hooks";
import { NumberCellEditor } from "./CellEditors/NumberCellEditor";
import { CustomCellEditorType } from "./CellEditors/CustomCellEditor";
import {
  CellEditors,
  CellRenderers,
} from "../../components/Datapoints/DataPoint";
import { DateCellEditor } from "./CellEditors/DateCellEditor";
import { useSnackbar } from "notistack";
import { SelectCellEditor } from "./CellEditors/SelectCellEditor";
import { TitleWrapper, Title, Divider, CardWrapper } from "./styles";
import { useContactsQuery, useContactTypesQuery } from "../../shared/api";
import { getContactOptions } from "../../components/Datapoints/helper";
import type = ContractFieldDTOV1.type;
import { formatContactName } from "../Contact/helpers";
import { getCountriesOptions } from "../../utils/internationalizations";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useOrganizationAllTeamsQuery } from "shared/api/organization";
import VerifyAllBanner from "./components/VerifyAllBanner/VerifyAllBanner";
import { Features } from "constants/features";
import { DefaultCellRenderer } from "./CellRenderers/DefaultCellRenderer";
import { SEARCH_TYPES, useFullTextSearch } from "hooks/useFullTextSearch";

export type ContractsProps = {
  contactId?: string;
  title?: string;
  setHasContracts?: (hasContracts: boolean) => void;
  canCreateContract?: boolean;
  template?: AvailableTemplates;
};

const Contracts = ({
  contactId,
  title,
  setHasContracts,
  canCreateContract,
  template,
}: ContractsProps) => {
  const filterByType: Record<ContractFieldDTOV1.type, string> = {
    TEXTFIELD: "agTextColumnFilter",
    TEXTAREA: "agTextColumnFilter",
    NUMBER: "agNumberColumnFilter",
    LIST: "agSetColumnFilter",
    LINK: "agTextColumnFilter",
    DATE: "agDateColumnFilter",
    AMOUNT: "agNumberColumnFilter",
    COUNTRY: "agSetColumnFilter",
    DURATION: "agSetColumnFilter",
    FORMULA: "agNumberColumnFilter",
    CONTACT: "agSetColumnFilter",
  };

  const { t } = useTranslation();

  const {
    overview: { activeView },
  } = useOverviewState();
  const { overview } = useOverviewActions();
  const { locale } = useLocale();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {
    selectedTeam,
    selectedTeamId,
    isOwner,
    isLimitedUser,
    permissionSet,
    hasWriteAccess,
    parentTeamId,
    organizationId,
    hasFeature,
  } = useTeam();

  const { data: contacts } = useContactsQuery(parentTeamId, selectedTeamId);
  const { data: contactTypes } = useContactTypesQuery(parentTeamId);
  const { data: fields } = useFieldsQuery(organizationId);
  const { data: subTeams } = useOrganizationAllTeamsQuery(organizationId);

  const { data: categories } = useOrganizationCategoriesQuery(organizationId);

  const { data: views } = useContractViewsQuery(selectedTeamId);
  const { teamById, teams, userInfo } = useUserInfo();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>();
  const { enqueueSnackbar } = useSnackbar();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const selectedTeamMetadata = useMemo(
    () => teams?.find((t) => t.id === selectedTeamId),
    [teams, selectedTeamId]
  );

  const hasAiInsights = hasFeature(Features.CONTRACT_ANALYSIS);

  const [isContractLimitReached, setIsContractLimitReached] =
    useState<boolean>(false);
  const [countryItems, setCountries] = useState<
    { key: string; value: string }[]
  >([]);
  const [contractData, setContractData] =
    useState<ContractDtoWithResolvedParent[]>();

  const { searchType, fullTextSearchQuery } = useFullTextSearch();

  const filterByTemplate =
    template ??
    (location.state as { filter?: AvailableTemplates })?.filter ??
    searchParams.get("template");
  const displayNames = new Intl.DisplayNames(locale, { type: "region" });
  const gridRef = useRef<AgGridReact<ContractDtoWithResolvedParent>>();

  const { data: contractsList, isLoading: isContractsLoading } =
    useContractsQuery(
      selectedTeamId,
      contactId,
      organizationId,
      searchType === SEARCH_TYPES.TEXT ? fullTextSearchQuery : "",
      searchType
    );

  const getCountries = () => {
    setCountries(getCountriesOptions(locale));
  };

  const { mutateAsync } = useUpdateContractMutation();
  const updateContractCell = async ({
    newValue,
    oldValue,
    colDef,
    data,
  }: CellValueChangedEvent<ContractDtoWithResolvedParent, unknown>) => {
    const field = colDef.field;
    if (!field || !fields || oldValue === newValue) {
      return;
    }

    const requestBody = createUpdateBody(
      fields,
      field,
      newValue as string | number | CategoryDTO,
      data
    );

    try {
      if (requestBody) {
        await mutateAsync({
          teamId: data.teamId,
          contractId: data.id,
          requestBody,
        });
      }
    } catch (e) {
      enqueueSnackbar(t("pages.contracts.messages.failedToUpdateError"), {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (contractsList) {
      setContractData(resolveParentsOfContractDto(contractsList));
    } else {
      setContractData([]);
    }
  }, [contractsList, contactId]);

  useEffect(() => {
    if (categories && fields && contractData && contacts) {
      const columnDefs = [
        ...getColumnDefs(
          t,
          locale,
          categories,
          teamById,
          selectedTeamMetadata?.children
            ? selectedTeamMetadata.children.length > 0
            : false,
          hasAiInsights,
          isLimitedUser(),
          permissionSet,
          hasWriteAccess,
          userDateFormat
        ),
        ...getCustomColumnDef(),
      ];

      setColumnDefs(adjustColumnDefs(columnDefs, orderedColumns));
    }
    setHasContracts && setHasContracts(!!contractData?.length);
  }, [contractData, fields, categories, contacts]);

  useEffect(() => {
    if (filterByTemplate && views) {
      overview.dispatch({
        type: "setActiveView",
        value: {
          id: filterByTemplate,
          determineActiveView: getActiveViewDeterminerFunction(views),
        },
      });
    }
  }, [filterByTemplate, views]);

  useEffect(() => {
    return () => {
      overview.dispatch({
        type: "notifyUnmount",
      });
    };
  }, []);

  useEffect(() => {
    getCountries();
    const listener = () => {
      setIsContractLimitReached(true);
    };
    document.addEventListener(CustomEventTypeEnum.IMPORTLIMIT, listener);
    return () => {
      document.removeEventListener(CustomEventTypeEnum.IMPORTLIMIT, listener);
    };
  }, []);

  const getDurationColumnDefinitions = (
    durationField: ContractFieldDTOV1
  ): ColDef[] => {
    const dateColumnDefinition = getDateColumnDefinition(
      locale,
      userDateFormat
    );

    const isDefaultDurationDatapoint = durationField.oldStandardField;
    const prefixColId = (colId: string) =>
      isDefaultDurationDatapoint ? colId : `${durationField.id}.${colId}`;

    // When a contract doesn't have a Duration field set, the newValue will always be undefined.
    // This is because the value is fetched using the field key of ColDef for each cell.
    // To update the contract in such cases, we need to manually trigger the update
    // by sending the new value from the CellEditors and simulating the event.

    const setDurationCell = (params: ValueSetterParams, key: string) => {
      if (!fields) {
        return false;
      }
      if (!durationField) {
        return true;
      }
      const newValue = params.newValue as Date | string | number;
      const durationFieldValue = (params.data as ContractDTOV1).fields[
        durationField.id
      ] as DurationFieldTypeDtoV1 | undefined;
      if (durationFieldValue) {
        (params.data as ContractDTOV1).fields[durationField.id] = {
          ...durationFieldValue,
          [key]: newValue,
        } as DurationFieldTypeDtoV1;
        return true;
      }
      (params.data as ContractDTOV1).fields[durationField.id] = {
        automaticRenewal: null,
        endDate: null,
        interval: null,
        noticePeriod: null,
        terminationDate: null,
        type: null,
        startDate: null,
        [key]: newValue,
      };
      return true;
    };

    return [
      {
        colId: prefixColId("durationType"),
        field: `fields.${durationField.id}.type`,
        headerName: t("pages.contracts.tableColumns.durationType"),
        hide: true,
        editable: isContractEditable(hasWriteAccess),
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          values: [...Object.keys(DurationFieldTypeDtoV1.type)],
          translationKey: "duration",
        },

        valueSetter: (params) => setDurationCell(params, "type"),
        cellClass: getCellClass(durationField, fields),
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.type;
          }
          return defaultEnumFormatter(params, `enums.duration`, t);
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) =>
            defaultEnumFormatter(params, `enums.duration`, t),
        },
      },
      {
        colId: prefixColId("durationRenewal"),
        field: `fields.${durationField.id}.automaticRenewal`,
        editable: isContractEditable(hasWriteAccess),
        cellEditor: NumberCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.automaticRenewal,
          };
        },
        valueSetter: (params) => setDurationCell(params, "automaticRenewal"),
        headerName: t("pages.contracts.tableColumns.automaticRenewal"),
        hide: true,
        cellClass: getCellClass(durationField, fields),
        valueFormatter: (
          params: ValueFormatterParams<ContractDTOV1, number>
        ) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.automaticRenewal as number;
          }
          return addMonthSuffix(t, params.value);
        },
      },
      {
        colId: prefixColId("durationStartAt"),
        field: `fields.${durationField.id}.startDate`,
        editable: isContractEditable(hasWriteAccess),
        cellRenderer: DefaultCellRenderer,
        cellRendererParams: { contacts: contacts, definition: durationField },
        cellEditor: DateCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.startDate,
          };
        },
        valueSetter: (params) => setDurationCell(params, "startDate"),
        cellClass: getCellClass(durationField, fields),
        headerName: t("pages.contracts.tableColumns.startDate"),
        ...dateColumnDefinition,
        valueFormatter: (params) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.startDate;
          }
          return dateFormatter(locale, params.value as string, userDateFormat);
        },
      },
      {
        colId: prefixColId("durationEndAt"),
        field: `fields.${durationField.id}.endDate`,
        editable: isContractEditable(hasWriteAccess),
        cellRenderer: DefaultCellRenderer,
        cellRendererParams: { contacts: contacts, definition: durationField },
        cellEditor: DateCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.endDate,
          };
        },
        valueSetter: (params) => setDurationCell(params, "endDate"),
        cellClass: getCellClass(durationField, fields),
        headerName: t("pages.contracts.tableColumns.endDate"),
        ...dateColumnDefinition,
        valueFormatter: (params) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.endDate;
          }
          return dateFormatter(locale, params.value as string, userDateFormat);
        },
      },
      {
        colId: prefixColId("durationCancellationAt"),
        field: `fields.${durationField.id}.terminationDate`,
        editable: isContractEditable(hasWriteAccess),
        cellRenderer: DefaultCellRenderer,
        cellRendererParams: { contacts: contacts, definition: durationField },
        cellEditor: DateCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.terminationDate,
          };
        },
        valueSetter: (params) => setDurationCell(params, "terminationDate"),
        cellClass: getCellClass(durationField, fields),
        headerName: t("pages.contracts.tableColumns.terminationDate"),
        hide: true,
        ...dateColumnDefinition,
        valueFormatter: (params) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.terminationDate;
          }
          return dateFormatter(locale, params.value as string, userDateFormat);
        },
      },
      {
        colId: prefixColId("durationInterval"),
        field: `fields.${durationField.id}.interval`,
        cellRenderer: DefaultCellRenderer,
        cellRendererParams: { contacts: contacts, definition: durationField },
        editable: isContractEditable(hasWriteAccess),
        cellEditor: NumberCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.interval,
          };
        },
        valueSetter: (params) => setDurationCell(params, "interval"),
        cellClass: getCellClass(durationField, fields),
        headerName: t("pages.contracts.tableColumns.durationInterval"),
        hide: true,
        valueFormatter: (params) => {
          if (!params.value) {
            params.value = (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.interval;
          }
          return (params.value as string) ?? "-";
        },
      },
      {
        colId: prefixColId("durationCancellation"),
        field: `fields.${durationField.id}.noticePeriod`,
        editable: isContractEditable(hasWriteAccess),
        cellRenderer: DefaultCellRenderer,
        cellRendererParams: { contacts: contacts, definition: durationField },
        cellEditor: NumberCellEditor,
        cellEditorParams: (params: ICellEditorParams) => {
          return {
            matchedFieldValue: (
              getFieldValue(
                durationField.id,
                params.data as ContractDTOV1
              ) as unknown as DurationFieldTypeDtoV1
            )?.noticePeriod,
          };
        },
        valueSetter: (params) => setDurationCell(params, "noticePeriod"),
        cellClass: getCellClass(durationField, fields),
        headerName: t("pages.contracts.tableColumns.noticePeriodInMonths"),
        hide: true,
        valueFormatter: (params) => {
          params.value = (
            getFieldValue(
              durationField.id,
              params.data as ContractDTOV1
            ) as unknown as DurationFieldTypeDtoV1
          )?.noticePeriod;
          return (params.value as string) ?? "-";
        },
      },
    ];
  };

  const getValuesForParams = (customField: ContractFieldDTOV1) => {
    switch (customField.type) {
      case ContractFieldDTOV1.type.LIST:
        return (
          (customField?.data as ListFieldTypeDtoV1)?.items.map(
            (item) => item.value
          ) ?? []
        );
      case ContractFieldDTOV1.type.COUNTRY:
        return countryItems;
      case ContractFieldDTOV1.type.CONTACT:
        if (!contacts) {
          return [];
        }
        return getContactOptions(contacts);
      default:
        return [];
    }
  };

  const getCustomColumnDef = (): ColDef[] => {
    // TODO: change this as soon as visible id arrive and we can
    // delete default fields / they have their own real uuid
    const defaultViewFields = new Set([
      "partnerCompany",
      "paymentCycle",
      "paymentPriceNet",
    ]);

    if (!fields) {
      return [];
    }

    return fields.reduce<ColDef<ContractDTOV1>[]>((colDef, customField) => {
      if (customField.type === ContractFieldDTOV1.type.DURATION) {
        colDef.push(...getDurationColumnDefinitions(customField));
        return colDef;
      }

      const cellEditorPopup =
        customField.type === ContractFieldDTOV1.type.TEXTAREA;

      const getCellEditorParams = (params: ICellEditorParams) => {
        const matchedFieldValue = getFieldValue(
          customField.id,
          params.data as ContractDTOV1
        )?.value;
        const values = getValuesForParams(customField);
        const type = CustomCellEditorType[customField.type];
        const configuration = customField.configuration;
        const translationKey =
          customField.oldStandardField && customField.visibleId;
        const isCustomField = true;

        const cellRenderer =
          customField.type === ContractFieldDTOV1.type.COUNTRY
            ? countryCellRenderer(locale)
            : undefined;

        return {
          matchedFieldValue,
          values,
          type,
          configuration,
          translationKey,
          isCustomField,
          cellRenderer,
        };
      };

      const getCellValue = (
        customField: ContractFieldDTOV1,
        params: ValueGetterParams
      ) => {
        return getFieldValue(customField.id, params.data as ContractDTOV1)
          ?.value;
      };

      const setCellValue = (
        customField: ContractFieldDTOV1,
        params: ValueSetterParams<ContractDTOV1>
      ) => {
        if (!customField) {
          return true;
        }

        params.data.fields[customField.id] = {
          ...params.data.fields[customField.id],
          ...(params.newValue as ContractDTOV1["fields"][string]),
        };

        return true;
      };

      const getFieldFilterFormattedValue = (
        customField: ContractFieldDTOV1,
        params: ValueFormatterParams
      ): string | undefined => {
        if (!params.value) {
          return "-";
        }
        if (customField.type === type.LIST) {
          const value = params.value as string;
          return customField.oldStandardField
            ? t(`enums.${customField.visibleId as string}.${value}`)
            : value;
        }
        if (customField.type === type.CONTACT) {
          const contact = contacts?.find((item) => item.id === params.value);
          if (!contact) {
            return "-";
          }
          return formatContactName(contact);
        }
        if (customField.type === type.COUNTRY) {
          return (
            getCountryNameByKey(locale, params.value as string) || undefined
          );
        }
      };

      const getFieldFormattedValue = (
        customField: ContractFieldDTOV1,
        params: ValueFormatterParams
      ) => {
        if (!customField) {
          return "-";
        }
        return cellValueFormatter(
          params,
          customField,
          displayNames,
          fields,
          locale,
          t,
          contacts ?? [],
          userDateFormat
        );
      };

      const isEditable: EditableCallback<ContractDTOV1> = (params) => {
        if (!params.data) {
          return false;
        }

        if (!isContractEditable(hasWriteAccess)(params)) {
          return false;
        }

        return customField.type !== ContractFieldDTOV1.type.FORMULA;
      };

      if (customField.type === ContractFieldDTOV1.type.CONTACT) {
        colDef.push({
          colId:
            customField.oldStandardField && customField.visibleId
              ? `${customField.visibleId}-name`
              : `fields.${customField.id}-name`,
          field: `fields.${customField.id}`,
          headerName: t("common.headers.counterParty.id"),
          suppressColumnsToolPanel: true,
          hide: true,
          valueGetter: (params) => getCellValue(customField, params),
        });
      }

      colDef.push({
        // do not change the colId, to just `customField.id`. With the undefined, the colId falls back to the `field` property.
        // Because custom views depend on it, we should not change it, as long as we haven't migrated all views in the backend, removing the 'fields.' prefix .
        colId:
          customField.oldStandardField && customField.visibleId
            ? customField.visibleId
            : undefined,
        field: `fields.${customField.id}`,
        headerName: customField.name[locale],
        hide: !defaultViewFields.has(customField.visibleId ?? customField.id),
        editable: isEditable,
        cellEditor: CellEditors[customField.type],
        cellEditorPopup: cellEditorPopup,
        valueGetter: (params) => getCellValue(customField, params),
        valueSetter: (params) => setCellValue(customField, params),
        getQuickFilterText: (params) =>
          getFieldFilterFormattedValue(customField, params) ??
          (params.value as object).toString(),
        cellEditorParams: getCellEditorParams,
        filter: filterByType[customField.type],
        cellClass: getCellClass(customField, fields),
        cellRenderer: CellRenderers[customField.type] ?? DefaultCellRenderer,
        cellRendererParams: {
          definition: customField,
        },
        valueFormatter: (params: ValueFormatterParams) =>
          getFieldFormattedValue(customField, params),
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) =>
            getFieldFilterFormattedValue(customField, params),
          comparator: (fieldA: string, fieldB: string) =>
            filterComparator(fieldA, fieldB, contacts || [], customField.type),
        },
        comparator: (fieldA: string, fieldB: string) =>
          filterComparator(fieldA, fieldB, contacts || [], customField.type),
        ...(customField.type === ContractFieldDTOV1.type.DATE && {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          filterParams: getDateColumnDefinition(locale, userDateFormat)
            .filterParams,
        }),
      });
      return colDef;
    }, []);
  };

  const gridOptions = useMemo(
    () => (columnDefs ? getGridOptions(columnDefs) : null),
    [columnDefs]
  );

  const sidebarOptions = useMemo(() => getSidebarOptions(t), [t]);

  if (!views) {
    return null;
  }

  const handleOnGridReady = () => {
    overview.dispatch({
      type: "initialize",
      value: {
        gridRef,
        determineActiveView: getActiveViewDeterminerFunction(views),
      },
    });
  };

  const url = new URL(window.location.href);
  const isDraft = url.pathname.includes("draft");
  const contractsCount = isDraft ? 0 : contractData?.length ?? 0;

  const movingContractsAllowed = subTeams && subTeams.length > 1 && isOwner();

  return (
    <>
      {isContractLimitReached && (
        <AlertWrapper
          type={AlertTypeEnum.warning}
          role="alert"
          isLeftBorder={false}
        >
          {t("pages.import.validation.currentContractLimit", {
            contractLimit: selectedTeam?.maxContracts,
          })}
          {isOwner() ? (
            <span>{t("pages.import.validation.upgradePlan")}</span>
          ) : (
            <>{t("pages.import.validation.checkPlan")}</>
          )}
        </AlertWrapper>
      )}
      <BaseBodyWrapper>
        <CardWrapper
          className="flex-column"
          sx={{
            flex: 1,
            borderTopRightRadius: contactId ? "0 !important" : "inherit",
            borderBottomRightRadius: contactId ? "0 !important" : "inherit",
            boxShadow: contactId ? "none" : "inherit",
          }}
        >
          {title && (
            <>
              <TitleWrapper>
                <FolderCopyIcon fontSize="small" />
                <Title>
                  {title} ({contractsCount})
                </Title>
              </TitleWrapper>
              <Divider />
            </>
          )}
          {gridOptions ? (
            <>
              <ContractGridHeader canCreateContract={canCreateContract} />
              {activeView?.id === "contract_analysis_view" &&
                hasFeature(Features.CONTRACT_ANALYSIS) && <VerifyAllBanner />}
              <AgGridBaseTable
                isLoading={isContractsLoading}
                gridName="contract"
                rowData={isDraft ? [] : contractData}
                gridOptions={gridOptions}
                sidebarOptions={sidebarOptions}
                onGridReady={handleOnGridReady}
                shouldFilterOnClient={searchType === SEARCH_TYPES.LIST}
                onCellValueChanged={async (event) =>
                  await updateContractCell(event)
                }
                onToolPanelVisibleChanged={(event) =>
                  sortToolPanelColumns(event, gridRef)
                }
                gridRef={gridRef}
                allowSelection={movingContractsAllowed}
                context={{
                  contacts: contacts ?? [],
                  contactTypes: contactTypes ?? [],
                }}
                searchType={searchType}
              />
            </>
          ) : (
            <Loader />
          )}
        </CardWrapper>
      </BaseBodyWrapper>
    </>
  );
};

export default Contracts;
