import { useState } from "react";
import LinkButton from "../LinkButton";
import { ArrowIcon, SubMenu } from "./styles";
import { Button, Icon, Label } from "../../styles";
import { LayoutItemProps } from "../../links";
import { Tooltip } from "@mui/material";
import ChevronIcon from "assets/svg/chevron-icon.svg?react";

type Props = {
  link: LayoutItemProps;
  expanded: boolean;
};

const Dropdown = ({ link, expanded }: Props) => {
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <Tooltip title={!expanded ? link.label : ""} placement="right" arrow>
        <Button onClick={toggleOpen}>
          <Icon>{link.icon}</Icon>
          <Label visible={expanded}>{link.label}</Label>
          {expanded && (
            <ArrowIcon open={open}>
              <ChevronIcon />
            </ArrowIcon>
          )}
        </Button>
      </Tooltip>
      <SubMenu expanded={expanded}>
        {open &&
          link?.children?.map((child: LayoutItemProps) => {
            if (!child.to || !child.enabled) return null;
            return (
              <LinkButton
                key={child.id}
                id={child.id}
                icon={child.icon}
                to={child.to}
                label={child.label}
                showLabel={expanded}
                showTooltip={!expanded}
              />
            );
          })}
      </SubMenu>
    </>
  );
};

export default Dropdown;
