import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Warning } from "@mui/icons-material";
import { NewModal, CTAButton } from "components";
import { OrganizationService } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { routePaths } from "../../../../constants";

type Props = {
  open: boolean;
  onClose: () => void;
  contactId: string;
};

const DeleteModal = ({ open, onClose, contactId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentTeamId: organizationId } = useTeam();

  const onDelete = async () => {
    if (!contactId) return;

    try {
      await OrganizationService.deleteContact(contactId, organizationId);
      navigate(routePaths.CONTACTS);
      enqueueSnackbar(t("pages.contacts.modal.delete.successMessage"), {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("pages.contacts.modal.delete.errorMessage"), {
        variant: "error",
      });
    }
  };

  return (
    <NewModal
      open={open}
      handleClose={onClose}
      title={t("pages.contacts.modal.delete.title")}
      icon={<Warning fontSize="small" sx={{ color: theme.color.red[600] }} />}
      body={t("pages.contacts.modal.delete.description")}
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            onClick={onClose}
            variant="secondary"
          />
          <CTAButton
            name={t("common.buttons.delete")}
            onClick={onDelete}
            color="danger"
          />
        </>
      }
      fullWidth
    />
  );
};

export default DeleteModal;
