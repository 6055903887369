import { TaskNewDTO } from "openapi";
import { routePaths } from "../../../constants";
import { OverflowTip } from "components";
import {
  ContractName,
  TaskContainer,
  TaskIcon,
  TaskListItem,
  TaskMetadataContainer,
  TaskName,
} from "./styles";
import { TaskItemDate } from "./TaskItemDate";

export type ExtendedTaskDTO = TaskNewDTO & {
  contractName: string;
  dueDate: string;
};

type Props = {
  data: ExtendedTaskDTO;
};

const TaskItem = ({ data }: Props) => {
  const contractTasksLink = `${routePaths.CONTRACTS}/${data.contractId}/tasks/?task=${data.id}`;

  return (
    <TaskListItem key={data.id}>
      <TaskIcon />
      <TaskContainer>
        <OverflowTip title={data.title}>
          <TaskName to={contractTasksLink} className="hover-underline">
            {data.title}
          </TaskName>
        </OverflowTip>
        <TaskMetadataContainer>
          <OverflowTip title={data.contractName}>
            <ContractName>{data.contractName}</ContractName>
          </OverflowTip>
          {data.dueDate && <TaskItemDate dueDate={data.dueDate} />}
        </TaskMetadataContainer>
      </TaskContainer>
    </TaskListItem>
  );
};

export default TaskItem;
