import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextField } from "components";

const TitleComponent = ({
  control,
  disabled,
}: {
  control: unknown;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <FormTextField
      control={control as Control}
      name="title"
      label={t("pages.tasks.modal.fields.title")}
      required
      disabled={disabled}
    />
  );
};

export default TitleComponent;
