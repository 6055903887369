import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useLocale, useUserInfo } from "hooks";
import { TaskDateComponent, TaskDateFlagIcon, TaskDateSpan } from "./styles";
import { getTimeAndColor } from "./utils";

dayjs.extend(localizedFormat);

type TaskDateProps = {
  dueDate: string;
};

export const TaskItemDate = ({ dueDate }: TaskDateProps) => {
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const timeAndColor = getTimeAndColor(locale, dayjs(dueDate), userDateFormat);

  return (
    <TaskDateComponent>
      <TaskDateFlagIcon iconColor={timeAndColor.color} />
      <TaskDateSpan color={timeAndColor.color}>
        {timeAndColor.time}
      </TaskDateSpan>
    </TaskDateComponent>
  );
};
