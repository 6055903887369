import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  ApiError,
  PartiallyTeamInfoUpdateDto,
  S3BackupConfig,
  SFTPBackupConfig,
  SFTPBackupConfigUpdate,
  FTPSBackupConfig,
  FTPSBackupConfigUpdate,
  TeamService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { CodeSnippet, CTAButton, SectionHeader } from "components";
import { CodeTitle, Form, FormContainer, FormTitle, Grid } from "./styles";
import { PasswordField } from "new-components";
import { getDirtyValues } from "../../../../utils/forms";
import CHRadioGroup from "components/FormItems/RadioGroup/RadioGroup";
import CardWrapper from "components/CardWrapper/CardWrapper";
import BackupIcon from "assets/svg/backup-icon.svg?react";
import BackupFormSFTP from "./BackupFormSFTP";
import BackupFormS3 from "./BackupFormS3";
import BackupType = S3BackupConfig.type;
import BackupFormFTPS from "./BackupFormFTPS";

const s3ValidationSchema = Yup.object({
  type: Yup.string().required(),
  accessKey: Yup.string().required(),
  secretKey: Yup.string().required(),
  bucket: Yup.string().required(),
  region: Yup.string().required(),
  storageClass: Yup.string().required(),
  password: Yup.string(),
});

const sftpValidationSchema = Yup.object({
  type: Yup.string().required(),
  hostname: Yup.string().required(),
  port: Yup.number().required(),
  username: Yup.string().required(),
  sftpPassword: Yup.string().required(),
  password: Yup.string(),
  path: Yup.string().required(),
});

const ftpsValidationSchema = Yup.object({
  type: Yup.string().required(),
  hostname: Yup.string().required(),
  port: Yup.number().required(),
  username: Yup.string().required(),
  ftpsPassword: Yup.string().required(),
  password: Yup.string(),
  path: Yup.string().required(),
});

const s3InitialValues: S3BackupConfig = {
  type: BackupType.AWS_S3,
  accessKey: "",
  secretKey: "",
  bucket: "",
  region: "eu-central-1",
  storageClass: "GLACIER",
};

const sftpInitialValues: SFTPBackupConfig = {
  type: BackupType.SFTP,
  hostname: "",
  port: 22,
  username: "",
  password: "",
  sftpPassword: "",
  path: "",
};

const ftpsInitialValues: FTPSBackupConfig = {
  type: BackupType.FTPS,
  hostname: "",
  port: 21,
  username: "",
  password: "",
  ftpsPassword: "",
  path: "",
};

const getValidationSchema = (type: BackupType) => {
  switch (type) {
    case BackupType.AWS_S3:
      return s3ValidationSchema;
    case BackupType.SFTP:
      return sftpValidationSchema;
    case BackupType.FTPS:
      return ftpsValidationSchema;
    default:
      return Yup.object();
  }
};

const getInitialValues = (type: BackupType) => {
  switch (type) {
    case BackupType.AWS_S3:
      return s3InitialValues;
    case BackupType.SFTP:
      return sftpInitialValues;
    case BackupType.FTPS:
      return ftpsInitialValues;
    default:
      return s3InitialValues;
  }
};

const BackupForm = () => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [backupType, setBackupType] = useState(BackupType.AWS_S3);
  const [data, setData] = useState<
    S3BackupConfig | SFTPBackupConfigUpdate | FTPSBackupConfigUpdate
  >();
  const methods = useForm<SFTPBackupConfig | S3BackupConfig | FTPSBackupConfig>(
    {
      defaultValues: { ...getInitialValues(backupType) },
      resolver: yupResolver(getValidationSchema(backupType)),
    }
  );

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    watch,
    reset,
  } = methods;

  const fetchData = async () => {
    const teamInfo = await TeamService.getTeamInfo(selectedTeamId);
    if (teamInfo.backupConfig) {
      setData({
        ...getInitialValues(teamInfo.backupConfig.type),
        ...teamInfo.backupConfig,
      });
    }
  };

  const type = useWatch({
    name: "type",
    control: control,
  });

  useEffect(() => {
    if (type === data?.type) {
      reset(data);
    } else {
      reset(getInitialValues(type));
    }
    setBackupType(type);
  }, [type]);

  useEffect(() => {
    void fetchData();
  }, []);

  useEffect(() => {
    if (data) reset(data);
  }, [data]);

  const onSubmit = async (
    values: S3BackupConfig | SFTPBackupConfig | FTPSBackupConfig
  ) => {
    try {
      const dirtyValues = getDirtyValues(
        dirtyFields,
        values as Record<string, unknown>
      );

      const backupConfig = dirtyValues as
        | S3BackupConfig
        | SFTPBackupConfig
        | FTPSBackupConfig;
      await TeamService.partiallyUpdateTeamInfo(selectedTeamId, {
        backupConfig: { ...backupConfig, type: values.type },
      } as PartiallyTeamInfoUpdateDto);
      await fetchData();
      enqueueSnackbar(t("pages.settings.tabs.backup.information.updated"), {
        variant: "success",
      });
    } catch (e) {
      const error = e as ApiError;
      type BackupError = {
        message: string;
        name: string;
      };
      const body = error.body as BackupError;
      const errorMessage = !body.message
        ? t(`pages.settings.tabs.backup.errors.${body.name}`)
        : body.message;
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const bucketConfig = `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": "s3:PutObject",
            "Resource": "arn:aws:s3:::${watch("bucket") || "ContractHero"}/*"
        }
    ]
  }`;

  return (
    <Grid>
      <CardWrapper>
        <SectionHeader
          title={t("pages.settings.tabs.backup.title")}
          subTitle={t("pages.settings.tabs.backup.subtitle")}
          icon={<BackupIcon />}
          noPadding
          hideButton
        />
        <FormProvider {...methods}>
          <Form name="backupForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormTitle>{t("pages.settings.tabs.backup.form.title")}</FormTitle>
            <CHRadioGroup
              control={control}
              name="type"
              options={[
                {
                  value: BackupType.AWS_S3,
                  label: "AWS S3",
                },
                { value: BackupType.SFTP, label: "SFTP" },
                { value: BackupType.FTPS, label: "FTPS" },
              ]}
              row={true}
            ></CHRadioGroup>
            <FormContainer>
              {type === BackupType.SFTP ? (
                <BackupFormSFTP />
              ) : type === BackupType.FTPS ? (
                <BackupFormFTPS />
              ) : (
                <BackupFormS3 />
              )}
              <PasswordField
                control={control}
                name="password"
                label={t("pages.settings.tabs.backup.form.password")}
                showVisibilityIcon={true}
              />
            </FormContainer>
            <CTAButton
              label="updateBtn"
              variant="primary"
              type="submit"
              size="large"
            >
              {t("pages.settings.tabs.backup.form.submit")}
            </CTAButton>
          </Form>
        </FormProvider>
      </CardWrapper>
      {type === BackupType.AWS_S3 && (
        <div>
          <CodeTitle>
            <p>{t("pages.settings.tabs.backup.information.explanation")}</p>
          </CodeTitle>

          <CodeSnippet
            title=""
            content={bucketConfig}
            copyCaption={{
              before: t(
                "pages.settings.tabs.backup.information.copyToClipboard"
              ),
              after: t(
                "pages.settings.tabs.backup.information.copiedToClipboard"
              ),
            }}
          />
        </div>
      )}
    </Grid>
  );
};

export default BackupForm;
