import { useTranslation } from "react-i18next";
import IntegrationsIcon from "assets/svg/dataflow-03.svg?react";
import { TeamPermissionInfoDto } from "openapi";
import routePaths from "constants/routePaths";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import { useTeamMembersQuery } from "shared/api";
import TaskAutomationIcon from "assets/svg/task-automation-icon.svg?react";
import PermissionLogIcon from "assets/svg/lock-log-icon.svg?react";
import ClusterIcon from "assets/svg/cluster-icon.svg?react";
import DocumentListIcon from "assets/svg/document-list-icon.svg?react";
import SecurityIcon from "assets/svg/security-icon.svg?react";
import ContractTemplateIcon from "assets/svg/contract-template-icon.svg?react";
import CloudUploadIcon from "assets/svg/cloud-upload-icon.svg?react";
import TeamIcon from "assets/svg/team-icon.svg?react";
import TreeIcon from "assets/svg/tree-icon.svg?react";
import DashboardIcon from "assets/svg/dashboard-icon.svg?react";
import ContracttIcon from "assets/svg/contract-icon.svg?react";
import PartnertIcon from "assets/svg/partner-icon.svg?react";
import TasksIcon from "assets/svg/check-square-icon.svg?react";
import ExportIcon from "assets/svg/export-icon.svg?react";
import SettingsIcon from "assets/svg/settings-icon.svg?react";
import { useUserInfo } from "hooks";

export type LayoutItemProps = {
  id: string;
  to?: string;
  icon: JSX.Element;
  label: string;
  enabled?: boolean;
  children?: LayoutItemProps[];
};

export const useOrganizationMainNavLinks = (
  features?: string[],
  isOrganization?: boolean
): LayoutItemProps[] => {
  const { t } = useTranslation();
  const { selectedTeamId, selectedTeam, parentTeamId } = useTeam();
  const { userInfo } = useUserInfo();
  const { data: memberData } = useTeamMembersQuery(selectedTeamId);
  const member = memberData?.members.find(
    (member) => member.id === memberData.memberId
  );

  let settingsLink: string = routePaths.SETTINGS_ORGANIZATION;
  let settingsEnabled = false;
  if (member?.owner) {
    if (isOrganization) {
      settingsEnabled = true;
    }
  } else if (
    selectedTeam?.id === parentTeamId ||
    !userInfo?.teams.find((team) => team.id === parentTeamId)
  ) {
    if (
      features?.includes(Features.CONTRACT_TEMPLATES) &&
      member?.contractTemplateAdmin
    ) {
      settingsEnabled = true;
      settingsLink = routePaths.SETTINGS_CONTRACT_TEMPLATES;
    } else if (
      features?.includes(Features.TASK_AUTOMATION) &&
      member?.taskAutomationAdmin
    ) {
      settingsEnabled = true;
      settingsLink = routePaths.SETTINGS_TASK_AUTOMATION;
    } else if (
      features?.includes(Features.CONTRACT_CUSTOMIZATION) &&
      member?.categoryOwner
    ) {
      settingsEnabled = true;
      settingsLink = routePaths.SETTINGS_CUSTOM_FIELDS;
    }
  }

  return [
    {
      id: "overview",
      icon: <DashboardIcon />,
      to: routePaths.HOME,
      label: t("navbar.overview"),
      enabled: true,
    },
    {
      id: "allContracts",
      icon: <ContracttIcon />,
      to: routePaths.CONTRACTS,
      label: t("navbar.allContracts"),
      enabled: true,
    },
    {
      id: "allContacts",
      icon: <PartnertIcon />,
      to: routePaths.CONTACTS,
      label: t("navbar.allContacts"),
      enabled: true,
    },
    {
      id: "tasks",
      icon: <TasksIcon />,
      to: routePaths.TASKS,
      label: t("navbar.tasks"),
      enabled: true,
    },
    {
      id: "export",
      icon: <ExportIcon />,
      to: routePaths.EXPORT,
      label: t("navbar.export"),
      enabled: features?.includes(Features.EXCEL_EXPORTS),
    },
    {
      id: "settings",
      icon: <SettingsIcon />,
      to: settingsLink,
      label: t("navbar.settings"),
      enabled: settingsEnabled,
    },
  ];
};

export const useOrganizationSettingsNavLinks = (
  role?: TeamPermissionInfoDto.role,
  features?: string[],
  isSubTeam?: boolean
) => {
  const { t } = useTranslation();
  const { selectedTeamId, selectedTeam, parentTeamId } = useTeam();
  const { userInfo } = useUserInfo();
  const { data: memberData } = useTeamMembersQuery(selectedTeamId);
  const member = memberData?.members.find(
    (member) => member.id === memberData.memberId
  );

  return [
    {
      id: "overview",
      icon: <DashboardIcon />,
      to: routePaths.HOME,
      label: t("navbar.overview"),
      enabled: true,
    },
    {
      id: "organization",
      icon: <ClusterIcon />,
      label: t("navbar.team"),
      enabled: role === TeamPermissionInfoDto.role.OWNER,
      children: [
        {
          id: "organization/details",
          icon: <DocumentListIcon />,
          to: routePaths.SETTINGS_ORGANIZATION,
          label: t("navbar.organizationDetails"),
          enabled: role === TeamPermissionInfoDto.role.OWNER,
        },
        {
          id: "organization/persmission-change-log",
          icon: <PermissionLogIcon />,
          to: routePaths.SETTINGS_ORGANIZATION_PERMISSION_CHANGE_LOG,
          label: t("navbar.permissionChangeLog"),
          enabled: role === TeamPermissionInfoDto.role.OWNER,
        },
      ],
    },
    {
      id: "teams",
      to: routePaths.SETTINGS_TEAMS,
      icon: <TeamIcon />,
      label: t("navbar.subTeams"),
      enabled:
        role === TeamPermissionInfoDto.role.OWNER &&
        !isSubTeam &&
        !!features &&
        features.includes(Features.SUB_TEAMS),
    },
    {
      id: "custom-fields",
      to: "/settings/custom-fields",
      icon: <TreeIcon />,
      label: t("navbar.customFields"),
      enabled:
        (member?.owner && selectedTeam?.id === parentTeamId) || // owner only on organization
        (member?.categoryOwner &&
          (selectedTeam?.id === parentTeamId ||
            !userInfo?.teams.find((team) => team.id === parentTeamId))), // other on organization or if no access on organization on subteam aswell
    },
    {
      id: "task-automations",
      to: "/settings/task-automations",
      icon: <TaskAutomationIcon />,
      label: t("navbar.taskAutomations"),
      enabled:
        !!features &&
        features.includes(Features.TASK_AUTOMATION) &&
        (member?.taskAutomationAdmin || member?.owner),
    },
    {
      id: "contract-templates",
      to: routePaths.SETTINGS_CONTRACT_TEMPLATES,
      icon: <ContractTemplateIcon />,
      label: t("navbar.contractTemplates"),
      enabled:
        !!features &&
        features.includes(Features.CONTRACT_TEMPLATES) &&
        (member?.contractTemplateAdmin || member?.owner),
    },
    {
      id: "security",
      to: "/settings/security",
      icon: <SecurityIcon />,
      label: t("navbar.security"),
      enabled:
        !isSubTeam &&
        !!features &&
        features.includes(Features.SSO) &&
        role === TeamPermissionInfoDto.role.OWNER,
    },
    {
      id: "backup",
      to: "/settings/backup",
      icon: <CloudUploadIcon />,
      label: t("navbar.backup"),
      enabled:
        role === TeamPermissionInfoDto.role.OWNER &&
        !isSubTeam &&
        !!features &&
        features.includes(Features.BACKUP),
    },
    {
      id: "integrations",
      to: `${routePaths.SETTINGS_INTEGRATIONS}/apps`,
      icon: <IntegrationsIcon />,
      label: t("navbar.integrations"),
      enabled: role === TeamPermissionInfoDto.role.OWNER && !isSubTeam,
    },
  ];
};
