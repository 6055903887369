import { BasicTable, Loader } from "components";
import { getAuthorName, getDateTitle, getTableHeaders } from "./helpers";
import { useTranslation } from "react-i18next";
import {
  DateWrapper,
  DateTitle,
  Date,
  Title,
  TableCellAlignTop,
} from "./styles";
import { useUserInfo } from "hooks";
import dayjs from "dayjs";
import { useTeam } from "contexts/team/hooks";
import {
  useOrganizationCategoriesQuery,
  useRolesQuery,
  useTeamMembersQuery,
} from "shared/api";
import { Row } from "components/BasicTable/types";
import InvitationEdit from "./components/InvitationEdit/InvitationEdit";
import { useOrganizationTeamsQuery } from "shared/api";
import { useOrganizationPermissionChangeLog } from "shared/api/organization";
import { useOrganizationQuery } from "shared/api/organization/organization";
import {
  InvitationLogDTO,
  MemberDeletedDTO,
  MemberPermissionChangeDTO,
  PermissionChangeLogDTO,
  PermissionLogInitialLogDTO,
  RoleUpdatedDataDTO,
  TeamUpdateLogDTO,
} from "openapi";
import RoleEdit from "./components/RoleEdit/RoleEdit";
import MemberEdit from "./components/MemberEdit/MemberEdit";
import MemberDelete from "./components/MemberDelete/MemberDelete";
import TeamEdit from "./components/TeamEdit/TeamEdit";
import InitialEntry from "./components/InitialEntry/InitialEntry";
import SupportUserInvited from "./components/SupportUserInvited/SupportUserInvited";
import { DefaultTableCellProps } from "components/BasicTable/styles";

const PermissionChangeLog = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfo();
  const headers = getTableHeaders(t);
  const { selectedTeamId, organizationId } = useTeam();

  const { data: teamMemberData, isLoading: teamMemberDataLoading } =
    useTeamMembersQuery(selectedTeamId);

  const { data: organizationRoles, isLoading: organizationRolesLoading } =
    useRolesQuery(selectedTeamId);

  const { data: changeLog, isLoading: changeLogIsloading } =
    useOrganizationPermissionChangeLog(organizationId);

  const { data: organization, isLoading: orgaizationIsLoading } =
    useOrganizationQuery(organizationId);

  const { data: categoriesData, isLoading: categoriesDataLoading } =
    useOrganizationCategoriesQuery(organizationId);

  const {
    data: organizationTeamsData,
    isLoading: isLoadingorganizationTeamsData,
  } = useOrganizationTeamsQuery(selectedTeamId);

  const isLoading =
    !teamMemberData ||
    !organizationRoles ||
    !changeLog ||
    !organization ||
    !organizationTeamsData ||
    !categoriesData ||
    teamMemberDataLoading ||
    organizationRolesLoading ||
    changeLogIsloading ||
    isLoadingorganizationTeamsData ||
    categoriesDataLoading ||
    orgaizationIsLoading;

  if (isLoading) return <Loader />;

  const renderInvitation = (item: PermissionChangeLogDTO) => {
    return (
      <InvitationEdit
        roles={organizationRoles}
        data={item.data as InvitationLogDTO}
        event={item.event}
        organizationName={organization.name}
        teamsData={organizationTeamsData.teams}
      />
    );
  };

  const renderSupportUserInvited = (item: PermissionChangeLogDTO) => {
    return (
      <SupportUserInvited
        event={item.event}
        organizationName={organization.name}
      />
    );
  };

  const renderRole = (item: PermissionChangeLogDTO) => {
    return (
      <RoleEdit
        roles={organizationRoles}
        categories={categoriesData}
        data={item.data as RoleUpdatedDataDTO}
        event={item.event}
        teamData={organizationTeamsData.teams}
      />
    );
  };

  const renderMemberUpdate = (item: PermissionChangeLogDTO) => {
    return (
      <MemberEdit
        roles={organizationRoles}
        members={teamMemberData.members}
        data={item.data as MemberPermissionChangeDTO}
        event={item.event}
        teams={organizationTeamsData.teams}
      />
    );
  };

  const renderTeamUpdate = (item: PermissionChangeLogDTO) => {
    return (
      <TeamEdit
        data={item.data as TeamUpdateLogDTO}
        event={item.event}
        categories={categoriesData}
        organization={organizationTeamsData}
      />
    );
  };

  const renderMemberDelete = (item: PermissionChangeLogDTO) => {
    return (
      <MemberDelete data={item.data as MemberDeletedDTO} event={item.event} />
    );
  };

  const renderInitial = (item: PermissionChangeLogDTO) => {
    return (
      <InitialEntry
        data={item.data as PermissionLogInitialLogDTO}
        event={item.event}
        organization={organizationTeamsData}
        roles={organizationRoles}
        categories={categoriesData}
        members={teamMemberData.members}
      />
    );
  };

  const getActionByType = (item: PermissionChangeLogDTO) => {
    switch (item.event) {
      case PermissionChangeLogDTO.event.INVITATION_SENT:
      case PermissionChangeLogDTO.event.INVITATION_DELETED:
      case PermissionChangeLogDTO.event.INVITATION_ACCEPTED:
        return renderInvitation(item);

      case PermissionChangeLogDTO.event.SUPPORT_USER_INVITED:
        return renderSupportUserInvited(item);

      case PermissionChangeLogDTO.event.ROLE_CREATED:
      case PermissionChangeLogDTO.event.ROLE_UPDATED:
      case PermissionChangeLogDTO.event.ROLE_DELETED:
        return renderRole(item);

      case PermissionChangeLogDTO.event.MEMBER_UPDATED:
        return renderMemberUpdate(item);

      case PermissionChangeLogDTO.event.MEMBER_DELETED:
        return renderMemberDelete(item);

      case PermissionChangeLogDTO.event.TEAM_CREATED:
      case PermissionChangeLogDTO.event.TEAM_UPDATED:
      case PermissionChangeLogDTO.event.TEAM_DELETED:
        return renderTeamUpdate(item);

      case PermissionChangeLogDTO.event.INITIAL_STATE:
        return renderInitial(item);

      default:
        return null;
    }
  };

  const getTableRows = (rawData: PermissionChangeLogDTO[]) =>
    rawData?.map((item) => {
      const date = userInfo?.dateFormat
        ? dayjs(item.createdAt).format(`${userInfo.dateFormat} HH:mm`)
        : "-";

      const authorName = getAuthorName(teamMemberData, item, t);
      const actionByType = getActionByType(item);
      const dateTitle = getDateTitle(item, t);

      return {
        when: (
          <DateWrapper data-order-key={date}>
            {dateTitle && <DateTitle>{dateTitle}</DateTitle>}
            <Date>{date}</Date>
          </DateWrapper>
        ),
        who: authorName,
        action: actionByType,
      };
    });

  const rows = getTableRows(changeLog);

  const TableCellContentAlignTop = (props: DefaultTableCellProps) => (
    <TableCellAlignTop {...props} />
  );

  return (
    <>
      <Title>
        {t("pages.settings.organization.permissionChangeLog.title")}
      </Title>
      <BasicTable
        headers={headers}
        data={rows as Row[]}
        components={{ TableCell: TableCellContentAlignTop }}
      />
    </>
  );
};

export default PermissionChangeLog;
