import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { NewModal } from "components";
import { SingleFileUpload } from "components/ContractUpload/SingleFileUpload";
import { useTeam } from "contexts/team/hooks";
import TemplateCollection from "pages/Settings/OrganizationSettings/ContractTemplates/TemplateCollection/TemplateCollection";
import { ContractTemplateMode } from "pages/Settings/OrganizationSettings/ContractTemplates/types";
import { useTranslation } from "react-i18next";
import { MotionWrapper } from "./components/MotionWrapper";
import { Container } from "./styles";
import { useAddDocumentMutation, useContractQuery } from "shared/api";
import { useParams } from "react-router";

export const SelectFileOrTemplate = () => {
  const { t } = useTranslation();
  const { id: contractId } = useParams();
  const { selectedTeamId } = useTeam();
  const [showTemplateCollection, setShowTemplateCollection] = useState(false);
  const { data: contract } = useContractQuery(selectedTeamId, contractId);
  const { isLoading } = useAddDocumentMutation();

  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;

  return (
    <Container>
      <MotionWrapper padding="var(--spacing-xl)">
        <SingleFileUpload
          showImportFromTemplateModal={() => setShowTemplateCollection(true)}
        />

        <NewModal
          fullWidth
          maxWidth="xl"
          open={showTemplateCollection}
          handleClose={() => setShowTemplateCollection(false)}
          title={t("pages.settings.tabs.contractTemplates.title")}
          body={
            <TemplateCollection
              mode={ContractTemplateMode.SELECT}
              contract={contract}
              handleClose={() => {
                setShowTemplateCollection(false);
              }}
            />
          }
        />
      </MotionWrapper>
    </Container>
  );
};
