import { useTranslation } from "react-i18next";
import { Wrapper, Title } from "./styles";

import {
  CategoryDTO,
  RoleUpdatedDataDTO,
  PermissionChangeLogDTO,
  RoleDTO,
  TeamDTOV1,
} from "openapi";
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import { useLocale } from "hooks";
import StatusLabel from "../StatusLabel/StatusLabel";
import TypeLabel from "../TypeLabel/TypeLabel";
import RoleLabel from "../RoleLabel/RoleLabel";
import { DELETED_CATEGORY_COLOR } from "../../helpers";
import Collapsible from "../Collapsible/Collapsible";

export type RoleEditType = {
  roles: RoleDTO[];
  data: RoleUpdatedDataDTO;
  event: PermissionChangeLogDTO.event;
  categories: CategoryDTO[];
  teamData: TeamDTOV1[];
};

const RoleEdit = ({ data, event, categories, teamData }: RoleEditType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const teamName =
    teamData.find((item) => item.id === (data.teamId as unknown as string))
      ?.name || t("common.labels.empty");
  const showCategories = event !== PermissionChangeLogDTO.event.ROLE_DELETED;

  return (
    <Wrapper>
      <Title>
        <StatusLabel
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
          variant={event}
        />
        <TypeLabel variant="userRole" />
      </Title>

      <RoleLabel teamName={teamName} logData={data} />
      {showCategories && (
        <Collapsible>
          {data.permissions.map((item) => {
            const category = categories.find(
              (category) => category.id === item.categoryId
            );
            const categoryName =
              category?.name[locale] || t("common.labels.deletedCategory");
            const categoryColor = category?.color || DELETED_CATEGORY_COLOR;
            return (
              <CategoryLabel
                key={item.categoryId}
                permissions={item}
                color={categoryColor}
                name={categoryName}
                event={event}
              />
            );
          })}
        </Collapsible>
      )}
    </Wrapper>
  );
};

export default RoleEdit;
