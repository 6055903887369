import { SectionHeader } from "../index";
import { HeaderIconStyles } from "../../pages/Settings/OrganizationSettings/Teams/styles";
import CardWrapper from "../CardWrapper/CardWrapper";

interface Props {
  title: string;
  subtitle?: string;
  icon: JSX.Element;
  children: JSX.Element | JSX.Element[];
}
export const SettingContainer = ({
  title,
  subtitle,
  icon,
  children,
}: Props) => {
  return (
    <CardWrapper>
      <SectionHeader
        title={title}
        subTitle={subtitle}
        icon={icon}
        iconStyle={HeaderIconStyles}
        noPadding
        hideButton
      />
      {children as JSX.Element}
    </CardWrapper>
  );
};
