import { Chip } from "components";
import { getColor } from "components/Chip/helpers";

type GripdChipColumnProps = {
  color?: string;
  value?: string;
  blendMode: "soft" | "hard";
};

export const ChipCellRenderer = ({
  color = "#fff",
  value,
  blendMode = "soft",
}: GripdChipColumnProps) => {
  const freshColor = getColor(color);
  if (!value) return "-";
  return (
    <Chip blendMode={blendMode} color={freshColor}>
      {value}
    </Chip>
  );
};
