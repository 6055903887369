import { Chip } from "components";
import { useTranslation } from "react-i18next";
import { CategoryDTO, ContractDTOV1 } from "openapi";
import theme from "theme/theme";
import { useLocale } from "hooks";
import Tag from "new-components/Tag/Tag";
import { Features } from "constants/";
import { useTeam } from "contexts/team/hooks";
import { countVerificationStatus } from "./helpers";
import { Wrapper } from "./styles";

type Props = {
  contract: ContractDTOV1;
  category: CategoryDTO;
  refetchData?: () => Promise<void>;
};

const Header = ({ contract, category }: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { hasFeature } = useTeam();

  const status =
    contract?.status && t(`enums.status.${contract?.status ?? ""}`);

  const count = contract?.fields && countVerificationStatus(contract);
  const verified = count?.verified || 0;
  const total = verified + (count?.unverified ?? 0);
  const showVerifiedStatus = total > 0;

  return (
    <Wrapper>
      <Chip label={t("pages.contracts.header.category")} color={category.color}>
        {category.name[locale]}
      </Chip>
      {status && (
        <Chip
          label={t("common.status")}
          color={
            contract.status === ContractDTOV1.status.PROCESSING
              ? theme.color.orange[400]
              : undefined
          }
        >
          {status}
        </Chip>
      )}

      {showVerifiedStatus && hasFeature(Features.CONTRACT_ANALYSIS) && (
        <Tag variant="ai" className="tag-container">
          {`${verified} / ${total} ${t("common.labels.verified")}`}
        </Tag>
      )}
    </Wrapper>
  );
};

export default Header;
