import { useTranslation } from "react-i18next";
import TemplateIcon from "assets/svg/contract-template-icon.svg?react";
import TreeIcon from "assets/svg/tree-icon.svg?react";
import UserIcon from "assets/svg/user-icon.svg?react";
import TeamIcon from "assets/svg/team-icon.svg?react";
import TaskAutomationIcon from "assets/svg/task-automation-icon.svg?react";
import LockIcon from "assets/svg/lock-icon.svg?react";
import Envelope from "assets/svg/envelope-icon.svg?react";
import { Wrapper } from "./styles";

export type TypeLabelType = {
  variant:
    | "categoryAdmin"
    | "contractTemplateAdmin"
    | "userRole"
    | "taskAutomation"
    | "team"
    | "categoryAccess"
    | "teamMember"
    | "pendingInvitations"
    | "teamName";
  amount?: string | number;
  title?: string;
};

const TypeLabel = ({ variant, amount, title }: TypeLabelType) => {
  const { t } = useTranslation();

  const getData = () => {
    switch (variant) {
      case "categoryAdmin": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <TreeIcon />,
        };
      }
      case "contractTemplateAdmin": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <TemplateIcon />,
        };
      }
      case "userRole": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <UserIcon />,
        };
      }
      case "taskAutomation": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <TaskAutomationIcon />,
        };
      }
      case "team": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <TeamIcon />,
        };
      }
      case "teamName": {
        return {
          title,
          icon: <TeamIcon />,
        };
      }
      case "categoryAccess": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <LockIcon />,
        };
      }
      case "teamMember": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <UserIcon />,
        };
      }
      case "pendingInvitations": {
        return {
          title: t(
            `pages.settings.organization.permissionChangeLog.labels.${variant}`
          ),
          icon: <Envelope />,
        };
      }
      default: {
        return null;
      }
    }
  };
  const item = getData();

  return (
    <Wrapper>
      {variant && item?.icon}
      {item?.title} {amount ? `(${amount})` : ""}
    </Wrapper>
  );
};

export default TypeLabel;
