import { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { Tooltip, TooltipProps } from "@mui/material";
import { OverflowTip } from "components";
import { CollectionFieldData } from "./types";
import { DraggableFieldTypes } from "./DraggableField";
import Tag, { TagProps } from "new-components/Tag/Tag";
import { DurationFieldDetails, DurationFieldToggleButton } from "./Duration";
import {
  DraggableFieldContainer,
  Container,
  Title,
  TagIndicators,
  DraggableArea,
} from "./styles";

export const TooltipWrapper = ({
  children,
  title,
  placement,
}: TooltipProps) => (
  <Tooltip title={title} placement={placement}>
    {children}
  </Tooltip>
);

const NonDraggableFieldComponent = ({
  id,
  name,
  handleOnClick,
  popoverComponent,
  settings,
  isUsed: isFieldUsed,
  isFieldAiInsight,
  field,
}: DraggableFieldTypes) => {
  const [showDurationFields, setShowDurationFields] = useState(true);
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `non-draggable-field-${id}`,
    data: {
      type: "field",
      id: id,
      set: "collection",
    } satisfies CollectionFieldData,
    disabled: true,
  });

  const showIndicators = isFieldUsed || isFieldAiInsight;
  const isDuration = field.type === "DURATION";

  let variant: TagProps["variant"] = "ai";

  if (
    ["paymentPriceGross", "paymentPriceMonthly"].includes(field.visibleId || "")
  ) {
    variant = "ai-formula";
  }

  const nonDraggableField = (
    <Container id={id} ref={setNodeRef} onClick={handleOnClick}>
      <DraggableFieldContainer disabled>
        <DraggableArea>
          <Title {...attributes} {...listeners}>
            <OverflowTip title={name}>
              <span>{name}</span>
            </OverflowTip>
          </Title>

          {isDuration && (
            <DurationFieldToggleButton
              open={showDurationFields}
              onClick={() => setShowDurationFields(!showDurationFields)}
            />
          )}
          {showIndicators && (
            <TagIndicators>
              {isFieldAiInsight && <Tag variant={variant} />}
              {isFieldUsed && <Tag variant="active" />}
            </TagIndicators>
          )}
          {settings}
        </DraggableArea>

        {isDuration && <DurationFieldDetails visible={showDurationFields} />}
        {popoverComponent}
      </DraggableFieldContainer>
    </Container>
  );

  return nonDraggableField;
};

export default NonDraggableFieldComponent;
