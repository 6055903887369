import React from "react";
import { TabsContainer, TabsWrapper, TabItem, Label } from "./styles";
import Webhooks from "pages/Settings/OrganizationSettings/Webhooks/Webhooks";
import Apps from "../Apps/Apps";
import { Trans, useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { TAB_KEYS } from "../../types";

export type TabItemType = {
  key: string;
  name: string | JSX.Element;
  component: JSX.Element;
  enabled: boolean;
};

export type TabsProps = {
  onTabChange: (e: React.SyntheticEvent, value: string) => void;
  selectedTabKey: string;
};

const Tabs = ({ selectedTabKey, onTabChange }: TabsProps) => {
  const { t } = useTranslation();
  const { features } = useTeam();

  const tabs: TabItemType[] = [
    {
      key: TAB_KEYS.APPS,
      name: (
        <Trans i18nKey={"pages.settings.organization.integrations.tabs.apps"}>
          <span />
          <Label />
        </Trans>
      ),
      component: <Apps />,
      enabled: true,
    },
    {
      key: TAB_KEYS.WEBHOOKS,
      name: t("pages.settings.organization.integrations.tabs.webhooks"),
      component: <Webhooks />,
      enabled: !!features && features.includes(Features.WEBHOOK),
    },
  ];

  return (
    <>
      <TabsWrapper aria-label="integration-tabs">
        <TabsContainer
          value={selectedTabKey}
          onChange={onTabChange}
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs.map(
            (tab) =>
              tab.enabled && (
                <TabItem label={tab.name} key={tab.key} value={tab.key} />
              )
          )}
        </TabsContainer>
      </TabsWrapper>
      {tabs.map((tab) => {
        return (
          selectedTabKey === tab.key && <div key={tab.key}>{tab.component}</div>
        );
      })}
    </>
  );
};

export default Tabs;
