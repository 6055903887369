import { GridApi } from "ag-grid-community";
import { availableTemplates } from "contexts/grid/default";
import { TFunction } from "i18next";
import { ViewItemDTO } from "openapi";

export const getFilterModelAsJson = (gridApi: GridApi): string | null => {
  const filterModel = gridApi.getFilterModel();
  if (!filterModel) {
    return null;
  }

  if (Object.keys(filterModel).length === 0) {
    return null;
  }

  return JSON.stringify(filterModel);
};

export const getViewName = (t: TFunction, activeView?: ViewItemDTO | null) => {
  if (activeView && activeView.name === "DEFAULT_VIEW") {
    return t("pages.contracts.header.defaultView");
  }

  if (activeView) {
    return activeView.id in availableTemplates
      ? t(`pages.contracts.header.${activeView.name}`)
      : activeView.name;
  }
  return t("pages.contracts.header.defaultView");
};
