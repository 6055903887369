import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { TextDatapointDTO } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { DatapointContent } from "pages/Contract/styles";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { FormTextField } from "components";
import { DataPointProps } from "./types";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";

export const SingleLineTextDatapoint = ({
  definition,
  contact,
  editable,
}: DataPointProps) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContactDataDTO>();
  const name = definition.name[locale];

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormTextField
          control={control}
          name={`${definition.visibleId}.value.value`}
          label={definition.name[locale]}
        />
      </Grid>
    );
  }
  const data = contact[definition.visibleId] as TextDatapointDTO;
  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{name}</DetailNameWrapper>
      <DatapointContent>
        {data ? data?.value?.value?.trim() : "-"}
      </DatapointContent>
    </Grid>
  );
};
