import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import routePaths from "constants/routePaths";
import logo from "./logo.svg";
import { Image } from "./styles";

const Logo = () => (
  <Link component={RouterLink} to={routePaths.HOME}>
    <Image src={logo} alt="Contract hero logo" width={28} height={35} />
  </Link>
);

export default Logo;
