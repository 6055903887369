import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ISpace, initializeFlatfile } from "@flatfile/react";
import { Property } from "@flatfile/api/api/resources";
import { IField } from "@flatfile/adapter/build/main/interfaces";
import { ImportDataDTO } from "openapi";
import globalConfig from "shared/config/global.config";
import { useLocale, useUserInfo } from "hooks";
import { theme } from "theme";
import { listener } from "./listener";
import { Wrapper } from "./styles";

type RenderProps = {
  onClick: () => void | Promise<void>;
};

type Props = {
  handleClose?: () => void;
  id: string;
  fields?: IField[];
  config: unknown;
  onResult: (status: ImportDataDTO) => void;
  renderComponent: (props: RenderProps) => JSX.Element;
};

const Import = ({
  id,
  fields,
  config,
  onResult,
  renderComponent,
  handleClose,
}: Props) => {
  const [importerResults, setImporterResults] = useState<ImportDataDTO>();
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const spaceConfig: ISpace = {
    name: globalConfig.REACT_APP_FLATFILE_NAME as string,
    publishableKey: globalConfig.REACT_APP_FLATFILE_PUBLIC_KEY as string,
    environmentId: globalConfig.REACT_APP_FLATFILE_ENV as string,
    listener: listener(setImporterResults, t, locale, userDateFormat),
    spaceBody: {
      metadata: {
        config,
        t,
      },
    },
    workbook: {
      name: t("pages.import.modals.title"),
      sheets: [
        {
          name: "contracts",
          slug: "contracts-slug",
          fields: fields as unknown as Property[],
          allowAdditionalFields: true,
        },
      ],
      actions: [
        {
          operation: "submitActionFg",
          mode: "foreground",
          label: t("common.buttons.submit"),
          primary: true,
          constraints: [{ type: "hasAllValid" }],
        },
      ],
    },
    sidebarConfig: {
      showDataChecklist: false,
      showSidebar: false,
    },
    themeConfig: {
      root: {
        primaryColor: theme.color.blue[600],
      },
    },
    closeSpace: {
      operation: "submitActionFg",
      onClose: () => {
        setShowSpace(false);
        if (importerResults) {
          onResult(importerResults);
        }
        handleClose?.();
      },
    },
    exitTitle: t("pages.import.modals.exit.heading"),
    exitText: t("pages.import.modals.exit.message"),
    exitPrimaryButtonText: t("pages.import.modals.exit.confirm"),
    exitSecondaryButtonText: t("pages.import.modals.exit.cancel"),
  };

  const [showSpace, setShowSpace] = useState(false);
  const { Space, OpenEmbed } = initializeFlatfile(spaceConfig);

  const onOpenSpace = async () => {
    setShowSpace(!showSpace);
    await OpenEmbed();
  };
  return (
    <Wrapper id={id}>
      {renderComponent({ onClick: () => onOpenSpace() })}
      {showSpace && <Space />}
    </Wrapper>
  );
};

export default Import;
