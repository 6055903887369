import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ContractFieldDTOV1 } from "openapi";
import theme from "theme/theme";
import SectionField from "./Base/SectionField";
import { GroupFieldData } from "./types";

type SortableItemProps = {
  id: string;
  field: ContractFieldDTOV1;
  sectionId: string;
  onRemoveField: (field: ContractFieldDTOV1, sectionId: string) => void;
};

export const toSortableFieldId = (id: string) => {
  return `sortable-field-${id}`;
};

const SortableField = ({
  id,
  field,
  sectionId,
  onRemoveField,
}: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: toSortableFieldId(id),
    data: {
      type: "field",
      set: "group",
      id: id,
      parentSectionId: sectionId,
    } satisfies GroupFieldData,
  });

  // @TODO: Update in next steps and use new version of data points
  const isDuration = field?.type === "DURATION";

  const initialStyles: React.CSSProperties = {
    padding: 0,
    minHeight: "90px",
    overflow: "hidden",
    gridColumn: isDuration ? "1 / -1" : "auto",
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    borderRadius: theme.borderRadius,
    border: "2px dashed transparent",
    transition: transition,
  };

  if (!field) {
    return null;
  }

  return (
    <SectionField
      ref={setNodeRef}
      style={initialStyles}
      id={field.id}
      field={field}
      sectionId={sectionId}
      onRemoveField={onRemoveField}
      dragging={isDragging}
      handleProps={{
        ref: setActivatorNodeRef,
        ...attributes,
        ...listeners,
      }}
    />
  );
};

export default SortableField;
