import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import {
  TaskAutomationCreateDTO,
  OrganizationNewService,
  TaskAutomationUpdateDTO,
  CategoryDTO,
  TaskAutomationDTO,
  TaskAutomationDueDateConfigDTO,
} from "openapi";
import {
  ButtonWithArrow,
  CTAButton,
  Chip,
  SectionHeader,
  UnsavedChangesModal,
} from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { FormTextField } from "components";
import { useLocale } from "hooks";
import {
  Form,
  BackButton,
  FormButtons,
  FieldDescription,
  Row,
  AccordionTitle,
  FormMessage,
  AccordionWrapper,
  FormOffsetFieldWrapper,
  VisibilityWrapper,
  DueDateAndReminderWrapper,
  TooltipRow,
} from "./styles";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { FormNumericField, FormSelect } from "components/FormItems";
import {
  useCreateTaskAutomationMutation,
  useFieldsQuery,
  useOrganizationCategoriesQuery,
  useTeamMembersQuery,
  useUpdateTaskAutomationMutation,
} from "shared/api";
import { theme } from "theme";
import { useNavigate, useParams, unstable_useBlocker } from "react-router";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteSection from "../TaskAutomations/components/DeleteTaskAutomation";
import FormMultiSelect from "components/FormItems/FormMultiSelect/FormMultiSelect";
import {
  getAssigneesWithoutAccessToCategory,
  getAvailableDateFieldsBySelectedCategories,
  getCategoriesInfoByIds,
  getCategoriesWithoutDateField,
  getDateFieldOptions,
  getFieldInfoById,
} from "../TaskAutomations/utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Accordion } from "new-components";
import AssigneesWithoutAcceess from "../TaskAutomations/components/AssigneesWithoutAccess";
import { getColor } from "components/Chip/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  defaultFormValues,
  defaultDateValues,
  validationSchema,
} from "./utils";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";

const TaskAutomation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { organizationId, selectedTeamId } = useTeam();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [reminderIsEnabled, setReminderIsEnabled] = useState<boolean>(false);
  const [dueDateIsEnabled, setDueDateIsEnabled] = useState<boolean>(false);
  const [repeatIsEnabled, setRepeatIsEnabled] = useState<boolean>(false);
  const { id: taskAutomationId } = useParams();

  const createTaskAutomationMutation = useCreateTaskAutomationMutation();
  const updateTaskAutomationMutation = useUpdateTaskAutomationMutation();

  const { data: teamMembersData } = useTeamMembersQuery(selectedTeamId);
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { isDirty },
  } = useForm<TaskAutomationDTO>({
    defaultValues: { ...defaultFormValues, ...defaultDateValues },
    resolver: yupResolver(
      validationSchema(dueDateIsEnabled, repeatIsEnabled, reminderIsEnabled)
    ),
  });
  const blocker = unstable_useBlocker(isDirty);

  const repeatConfig = watch("dueDateConfig.repeatConfig.enabled");
  const selectedCategoryIds = watch("categoryIds");
  const selectedDateFieldId = watch("dueDateConfig.relativeFieldId");
  const selectedAssigneeIds = watch("assigneeIds");

  const isEditMode = taskAutomationId !== "new";
  const dateFiledsEnums = TaskAutomationDueDateConfigDTO.unit;

  useEffect(() => {
    if (isEditMode) {
      void getCurrentTaskAutomationDetails();
    }
  }, [isEditMode]);

  useEffect(() => {
    setRepeatIsEnabled(repeatConfig);
  }, [repeatConfig]);

  const categoryOptions = useMemo(() => {
    if (!categories) return [];
    return categories?.map((item) => {
      return {
        key: item.id,
        value: item.name[locale],
        color: item.color,
      };
    });
  }, [categories]);

  const dateFieldsOptions = useMemo(() => {
    if (!categories || !fields || !selectedCategoryIds?.length) return [];
    const selectedCategories = getCategoriesInfoByIds(
      categories,
      selectedCategoryIds
    );
    const dateFields =
      getAvailableDateFieldsBySelectedCategories(selectedCategories, fields) ||
      [];

    const fieldOptions = getDateFieldOptions(dateFields, t, locale);
    return fieldOptions;
  }, [fields, selectedCategoryIds, categories]);

  const teamMembersOptions = useMemo(() => {
    if (!teamMembersData?.members) return [];
    return teamMembersData.members.map((item) => {
      const fullName = `${item.firstname || ""} ${item.lastname || ""}`;
      return {
        key: item.id,
        value: fullName,
      };
    });
  }, [teamMembersData]);

  const categoriesWithoutDateField = useMemo(() => {
    return getCategoriesWithoutDateField(
      categories,
      selectedCategoryIds,
      selectedDateFieldId
    );
  }, [categories, selectedCategoryIds, selectedDateFieldId]);

  const assigneesWithoutAccessToCategory = useMemo(() => {
    return getAssigneesWithoutAccessToCategory(
      categories,
      teamMembersData,
      selectedCategoryIds,
      selectedAssigneeIds
    );
  }, [categories, teamMembersData, selectedCategoryIds, selectedAssigneeIds]);

  const dateUnits = Object.keys(dateFiledsEnums).map((item) => ({
    key: item,
    value: t(
      `common.intevalEnums.${
        dateFiledsEnums[item as keyof typeof dateFiledsEnums] as string
      }`
    ),
  }));

  const getCurrentTaskAutomationDetails = async () => {
    try {
      if (!taskAutomationId) return;
      const response = await OrganizationNewService.getTaskAutomationById(
        organizationId,
        taskAutomationId
      );
      const { dueDateConfig } = response;

      // Check if field is of Duration type (relativeFieldId !== value) and assign relativeFieldId respectively
      const relativeFieldId =
        dueDateConfig?.relativeFieldKey !== "value" ||
        !dueDateConfig?.relativeFieldKey
          ? `${dueDateConfig?.relativeFieldId ?? ""}__${
              dueDateConfig?.relativeFieldKey ?? ""
            }`
          : dueDateConfig?.relativeFieldId ?? "";

      const responseData = {
        ...response,
        dueDateConfig: {
          ...response.dueDateConfig,
          relativeFieldId: relativeFieldId,
        },
      };
      if (responseData?.dueDateConfig?.enabled) setDueDateIsEnabled(true);
      if (responseData?.reminderConfig?.enabled) setReminderIsEnabled(true);
      if (responseData?.dueDateConfig?.repeatConfig?.enabled)
        setRepeatIsEnabled(true);
      // @Initialize text fields
      reset(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  const renderMissingDateFieldMessage = () => {
    const field = getFieldInfoById(fields, selectedDateFieldId);
    return (
      <FormMessage>
        <p>
          <Trans
            i18nKey={
              "pages.settings.organization.taskAutomations.notifications.warnings.missingFieldsInfo"
            }
            values={{
              field: field?.name[locale],
            }}
            components={{
              strong: <strong />,
            }}
          />
        </p>
        <Row justifyItems="flex-start" wrap="wrap">
          {categoriesWithoutDateField?.map((category: CategoryDTO) => (
            <Chip
              key={category?.id}
              blendMode="soft"
              color={getColor(category?.color || theme.color.blue[600])}
            >
              {category?.name[locale]}
            </Chip>
          ))}
        </Row>
        <p>
          {t(
            "pages.settings.organization.taskAutomations.notifications.warnings.categoryMissingField.description"
          )}
        </p>
      </FormMessage>
    );
  };

  const onBack = () => {
    navigate(routePaths.SETTINGS_TASK_AUTOMATION);
  };

  const onSubmit = async (
    data: TaskAutomationCreateDTO | TaskAutomationUpdateDTO
  ) => {
    // getting relativeFieldId and relativeFieldKey from Duration fields
    const [relativeFieldId = "", relativeFieldKey = ""] =
      data.dueDateConfig?.relativeFieldId?.split("__") ?? [];

    const requestBody: TaskAutomationCreateDTO | TaskAutomationUpdateDTO = {
      ...data,
      dueDateConfig: {
        ...data.dueDateConfig,
        relativeFieldId: relativeFieldId,
        relativeFieldKey: relativeFieldKey || "value",
        enabled: dueDateIsEnabled,
        offset: Number(data?.dueDateConfig?.offset),
        repeatConfig: {
          ...data?.dueDateConfig?.repeatConfig,
          enabled: repeatIsEnabled,
        },
      },
      reminderConfig: {
        ...data.reminderConfig,
        enabled: reminderIsEnabled,
        offset: Number(data?.reminderConfig?.offset),
      },
    };

    if (isEditMode && taskAutomationId) {
      await updateTaskAutomationMutation.mutateAsync({
        organizationId,
        taskAutomationId,
        requestBody: requestBody as unknown as TaskAutomationUpdateDTO,
      });
    } else {
      await createTaskAutomationMutation.mutateAsync({
        organizationId,
        requestBody: requestBody as unknown as TaskAutomationCreateDTO,
      });
    }
    reset();
    await getCurrentTaskAutomationDetails();
    blocker.proceed?.();
    navigate(routePaths.SETTINGS_TASK_AUTOMATION);
  };

  // Unify with TaskDetails.tsx (add check for dueDateConfig.dueDate field)
  const resetDueDateFields = () => {
    setValue("dueDateConfig.enabled", defaultDateValues.dueDateConfig.enabled);
    setValue("dueDateConfig.offset", defaultDateValues.dueDateConfig.offset);
    setValue("dueDateConfig.unit", defaultDateValues.dueDateConfig.unit);
    setValue(
      "dueDateConfig.relativeFieldId",
      defaultDateValues.dueDateConfig.relativeFieldId
    );
  };

  // Unify with TaskDetails.tsx
  const resetRepeatFields = () => {
    setValue(
      "dueDateConfig.repeatConfig.enabled",
      defaultDateValues.dueDateConfig.repeatConfig.enabled,
      { shouldDirty: true }
    );
    setValue(
      "dueDateConfig.repeatConfig.offset",
      defaultDateValues.dueDateConfig.repeatConfig.offset,
      { shouldDirty: true }
    );
    setValue(
      "dueDateConfig.repeatConfig.unit",
      defaultDateValues.dueDateConfig.repeatConfig.unit,
      { shouldDirty: true }
    );
  };

  // Unify with TaskDetails.tsx
  const resetReminderFields = () => {
    setValue(
      "reminderConfig.enabled",
      defaultDateValues.reminderConfig.enabled,
      { shouldDirty: true }
    );
    setValue("reminderConfig.offset", defaultDateValues.reminderConfig.offset, {
      shouldDirty: true,
    });
    setValue("reminderConfig.unit", defaultDateValues.reminderConfig.unit, {
      shouldDirty: true,
    });
  };

  const clearReminder = () => {
    resetReminderFields();
    setReminderIsEnabled(false);
  };

  const clearRepeat = () => {
    resetRepeatFields();
    setRepeatIsEnabled(false);
  };

  const clearDueDate = () => {
    resetRepeatFields();
    resetDueDateFields();
    resetReminderFields();
    setDueDateIsEnabled(false);
    setReminderIsEnabled(false);
    setRepeatIsEnabled(false);
  };

  const handleDiscardChanges = () => {
    setShowModal(false);
    blocker.proceed?.();
  };

  const handleCloseModal = (shouldResetBlocker = true) => {
    setShowModal(false);
    if (shouldResetBlocker) {
      blocker.reset?.();
    }
  };

  return (
    <>
      <BackButton>
        <ButtonWithArrow
          onClick={onBack}
          label={t("pages.settings.tabs.subTeams.section.showSubTeams.goBack")}
        />
      </BackButton>
      <CardWrapper>
        <SectionHeader
          title={t(
            "pages.settings.organization.taskAutomations.addAutomation.title"
          )}
          noPadding
          hideButton={true}
        />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldDescription>
            {t(
              "pages.settings.organization.taskAutomations.addAutomation.form.titles.name"
            )}
          </FieldDescription>
          <FormTextField
            control={control}
            name="title"
            label={t(
              "pages.settings.organization.taskAutomations.addAutomation.form.labels.name"
            )}
            style={{ width: "600px" }}
          />
          <FieldDescription>
            {t(
              "pages.settings.organization.taskAutomations.addAutomation.form.titles.category"
            )}
          </FieldDescription>
          <FormMultiSelect
            style={{ marginTop: theme.spacing.lg, width: "600px" }}
            name="categoryIds"
            control={control}
            options={categoryOptions}
            disableCloseOnSelect
            label={t(
              "pages.settings.organization.taskAutomations.addAutomation.form.labels.categories"
            )}
            size="small"
          />

          <FieldDescription>
            <span>
              {t(
                "pages.settings.organization.taskAutomations.addAutomation.form.titles.dueDateAndReminder"
              )}
            </span>
            <InfoTooltip>
              <>
                <TooltipRow>
                  <AccessTimeIcon />
                  <>
                    {t(
                      "pages.settings.organization.taskAutomations.tooltips.dueDate"
                    )}
                  </>
                </TooltipRow>
                <TooltipRow>
                  <NotificationsNoneIcon />
                  <>
                    {t(
                      "pages.settings.organization.taskAutomations.tooltips.reminder"
                    )}
                  </>
                </TooltipRow>
              </>
            </InfoTooltip>
          </FieldDescription>
          <DueDateAndReminderWrapper disabled={!selectedCategoryIds.length}>
            <Row justifyItems="flex-start" gap={theme.spacing.lg} wrap="wrap">
              <AccessTimeIcon />
              {dueDateIsEnabled ? (
                <>
                  <FormOffsetFieldWrapper>
                    <FormNumericField
                      label=""
                      name="dueDateConfig.offset"
                      decimalScale={0}
                      control={control}
                      showButtons
                    />
                  </FormOffsetFieldWrapper>
                  <FormSelect
                    control={control}
                    name="dueDateConfig.unit"
                    options={dateUnits}
                    style={{ maxWidth: "120px" }}
                    margin="none"
                  />
                  <span>{t("common.timePrepositions.before")}</span>
                  <FormSelect
                    control={control}
                    name="dueDateConfig.relativeFieldId"
                    options={dateFieldsOptions}
                    margin="none"
                    style={{ maxWidth: "300px" }}
                  />
                  <IconButton className="delete" onClick={clearDueDate}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <CTAButton
                  variant="tertiary"
                  name={t("common.buttons.addDueDate")}
                  onClick={() => setDueDateIsEnabled(true)}
                />
              )}
            </Row>

            {categoriesWithoutDateField?.length && dueDateIsEnabled ? (
              <Accordion
                variant="warning"
                title={
                  <AccordionTitle>
                    <InfoOutlinedIcon
                      style={{ color: theme.color.orange[600] }}
                    />
                    <span>
                      {t(
                        "pages.settings.organization.taskAutomations.notifications.warnings.categoryMissingField.title",
                        { count: categoriesWithoutDateField?.length }
                      )}
                    </span>
                  </AccordionTitle>
                }
              >
                {renderMissingDateFieldMessage()}
              </Accordion>
            ) : null}

            {repeatIsEnabled ? (
              <Row justifyItems="flex-start" gap={theme.spacing.lg}>
                <RepeatOutlinedIcon />
                <span>{t("common.repeatEvery")}</span>
                <VisibilityWrapper disabled={!repeatIsEnabled}>
                  <FormOffsetFieldWrapper>
                    <FormNumericField
                      label=""
                      name="dueDateConfig.repeatConfig.offset"
                      decimalScale={0}
                      control={control}
                      showButtons
                    />
                  </FormOffsetFieldWrapper>
                  <FormSelect
                    control={control}
                    name="dueDateConfig.repeatConfig.unit"
                    options={dateUnits}
                    style={{ width: "120px" }}
                    margin="none"
                  />
                </VisibilityWrapper>
                <IconButton className="delete" onClick={clearRepeat}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Row>
            ) : (
              <VisibilityWrapper disabled={!dueDateIsEnabled}>
                <Row justifyItems="flex-start" gap={theme.spacing.lg}>
                  <RepeatOutlinedIcon />
                  <CTAButton
                    variant="tertiary"
                    name={t("common.buttons.addRepeat")}
                    onClick={() => setRepeatIsEnabled(true)}
                  />
                </Row>
              </VisibilityWrapper>
            )}

            {reminderIsEnabled ? (
              <Row justifyItems="flex-start" gap={theme.spacing.lg}>
                <NotificationsNoneIcon />
                <FormOffsetFieldWrapper>
                  <FormNumericField
                    label=""
                    name="reminderConfig.offset"
                    decimalScale={0}
                    control={control}
                    showButtons
                  />
                </FormOffsetFieldWrapper>
                <FormSelect
                  control={control}
                  name="reminderConfig.unit"
                  options={dateUnits}
                  style={{ width: "120px" }}
                  margin="none"
                />
                <span>
                  {t(
                    "pages.settings.organization.taskAutomations.addAutomation.form.labels.beforeDueDate"
                  )}
                </span>
                <IconButton className="delete" onClick={clearReminder}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Row>
            ) : (
              <VisibilityWrapper disabled={!dueDateIsEnabled}>
                <Row justifyItems="flex-start" gap={theme.spacing.lg}>
                  <NotificationsNoneIcon />
                  <CTAButton
                    variant="tertiary"
                    name={t("common.buttons.addReminder")}
                    onClick={() => setReminderIsEnabled(true)}
                  />
                </Row>
              </VisibilityWrapper>
            )}
          </DueDateAndReminderWrapper>

          <FieldDescription>
            {t(
              "pages.settings.organization.taskAutomations.addAutomation.form.titles.assignee"
            )}
          </FieldDescription>
          <>
            <FormMultiSelect
              style={{ marginTop: theme.spacing.lg, width: "600px" }}
              name="assigneeIds"
              control={control}
              options={teamMembersOptions}
              disableCloseOnSelect
              label={t(
                "pages.settings.organization.taskAutomations.addAutomation.form.labels.assignee"
              )}
              size="small"
            />

            {assigneesWithoutAccessToCategory?.length > 0 ? (
              <AccordionWrapper>
                <Accordion
                  variant="warning"
                  title={
                    <AccordionTitle>
                      <InfoOutlinedIcon
                        style={{ color: theme.color.orange[600] }}
                      />
                      <span>
                        {t(
                          "pages.settings.organization.taskAutomations.notifications.warnings.assigneeMissingAccess.title",
                          { count: assigneesWithoutAccessToCategory?.length }
                        )}
                      </span>
                    </AccordionTitle>
                  }
                >
                  <FormMessage>
                    <p>
                      {t(
                        "pages.settings.organization.taskAutomations.notifications.warnings.assigneeMissingAccess.description"
                      )}
                    </p>
                    <AssigneesWithoutAcceess
                      assignees={assigneesWithoutAccessToCategory}
                    />
                  </FormMessage>
                </Accordion>
              </AccordionWrapper>
            ) : null}
          </>

          <FieldDescription>
            {t(
              "pages.settings.organization.taskAutomations.addAutomation.form.titles.description"
            )}
          </FieldDescription>
          <FormTextField
            control={control}
            name="description"
            label={t(
              "pages.settings.organization.taskAutomations.addAutomation.form.labels.description"
            )}
            multiline
            rows={4}
            style={{ width: "600px" }}
          />

          <FormButtons>
            <CTAButton
              type="button"
              name={t("common.buttons.cancel")}
              label={t("common.buttons.cancel")}
              variant="secondary"
              onClick={onBack}
            />

            <CTAButton
              type="submit"
              variant="primary"
              label={
                isEditMode
                  ? t("common.buttons.update")
                  : t("common.buttons.saveChanges")
              }
              name={
                isEditMode
                  ? t("common.buttons.update")
                  : t("common.buttons.saveChanges")
              }
              disabled={!isDirty}
            />
          </FormButtons>
        </Form>
      </CardWrapper>
      {isEditMode ? (
        <DeleteSection selectedTaskAutomation={getValues()} />
      ) : null}

      <UnsavedChangesModal
        title={t("common.modals.unsavedChanges.title")}
        description={t("common.modals.unsavedChanges.subtitle")}
        showModal={showModal || (!!blocker && blocker.state === "blocked")}
        handleDiscardChanges={handleDiscardChanges}
        handleSaveChanges={handleSubmit(onSubmit)}
        handleCloseModal={handleCloseModal}
        saveBtnId={"saveTaskAutomationChanges"}
        discardBtnId={"discardTaskAutomationChanges"}
      />
    </>
  );
};

export default TaskAutomation;
