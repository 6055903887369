import Grid from "@mui/material/Grid";
import { FormAmountField } from "components/FormItems/FormAmountField/FormAmountField";
import { useLocale } from "hooks/GlobalStateHooks";
import { AmountFieldTypeDtoV1 } from "openapi";
import { DatapointField } from "./components/DatapointField";
import { useAnalysis } from "./hooks/useAnalysis";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";
import { DataPointProps } from "./types";

export const AmountDatapoint = ({
  definition,
  values,
  editable,
  showAnalysis,
  contractId,
  teamId,
  fetchData,
}: DataPointProps) => {
  const { locale } = useLocale();

  const data = values[definition.id] as AmountFieldTypeDtoV1;
  const analysis = useAnalysis(definition, data);
  const name = definition.name[locale];

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormAmountField
          label={name}
          field={definition}
          suggestions={suggestions}
          analysis={showAnalysis ? analysis : undefined}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        name={name}
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value", "currency"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
      >
        {data && data.value
          ? new Intl.NumberFormat(locale, {
              style: "currency",
              currency: data.currency || "EUR",
            }).format(data.value)
          : "-"}
      </DatapointField>
    </Grid>
  );
};
