import { useTranslation } from "react-i18next";
import { Controller, Control, useForm } from "react-hook-form";
import { Radio, FormControlLabel } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { ContactDataDTO } from "../../../Contacts/ContactDataDTO";
import { theme } from "theme";
import {
  Column,
  Container,
  Info,
  InfoMessage,
  RadioGroup,
  FooterWrapper,
  Footer,
} from "./styles";
import { isEmpty } from "lodash";

type FooterProps = {
  selectedContactId: string;
  loading: boolean;
  showPreview: boolean;
  control: Control;
  onClose: () => void;
  handleConflictResolver: (value: React.ChangeEvent<HTMLInputElement>) => void;
  setShowPreview: (value: boolean) => void;
  selectedContactData: ContactDataDTO;
  defaultConflictResolverSide: string;
};

const FooterComponent = (props: FooterProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control } = useForm();

  return (
    <FooterWrapper>
      {props.selectedContactId && !props.showPreview && (
        <Info>
          <Controller
            control={control}
            name="conflictResolverSelector"
            render={({ field }) => (
              <RadioGroup
                {...field}
                defaultValue={props.defaultConflictResolverSide}
              >
                <InfoMessage style={{ minWidth: "220px" }}>
                  <WarningIcon />
                  {t("pages.contacts.modal.merge.infoBanner")}
                </InfoMessage>

                <Container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: theme.spacing.md,
                    }}
                  >
                    <FormControlLabel
                      value="left"
                      control={
                        <Radio onChange={props.handleConflictResolver} />
                      }
                      label={t(
                        "pages.contacts.modal.merge.selectedRationButtonMessage"
                      )}
                    />
                  </div>
                  <Column>
                    <FormControlLabel
                      value="right"
                      control={
                        <Radio onChange={props.handleConflictResolver} />
                      }
                      label={t(
                        "pages.contacts.modal.merge.selectedRationButtonMessage"
                      )}
                    />
                  </Column>
                </Container>
              </RadioGroup>
            )}
          />
        </Info>
      )}

      <Footer hasBackButton={props.showPreview}>
        {props.showPreview && (
          <Button
            className={classes.cancelButton}
            onClick={() => props.setShowPreview(false)}
          >
            <span style={{ display: "flex", gap: theme.spacing.md }}>
              <ArrowBackIcon fontSize="small" />
              {t("common.buttons.back")}
            </span>
          </Button>
        )}

        <div style={{ display: "flex", gap: theme.spacing.sm }}>
          <Button className={classes.cancelButton} onClick={props.onClose}>
            {t("common.buttons.cancel")}
          </Button>
          {props.showPreview ? (
            <Button
              key="merge"
              form="merge-contracts-form"
              type="submit"
              className={
                !isEmpty(props.selectedContactData)
                  ? classes.submitButton
                  : classes.disabledButton
              }
              isLoading={props.loading}
              disabled={isEmpty(props.selectedContactData)}
            >
              {t("common.buttons.merge")}
            </Button>
          ) : (
            <Button
              key="preview"
              className={
                !isEmpty(props.selectedContactData)
                  ? classes.submitButton
                  : classes.disabledButton
              }
              type="button"
              onClick={() => {
                props.setShowPreview(true);
              }}
              disabled={isEmpty(props.selectedContactData)}
            >
              {t("common.buttons.preview")}
            </Button>
          )}
        </div>
      </Footer>
    </FooterWrapper>
  );
};

export default FooterComponent;
