import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Collapse } from "@mui/material";
import { CodeSnippet } from "components";
import { mockData } from "./mockData";
import { EventOptionLabel as SnippetTitle } from "../styles";
import {
  Wrapper,
  SectionTitle,
  ArrowIcon,
  SectionDescription,
  SnippetContainer,
} from "./styles";

type Props = {
  data: {
    title: string;
    response: {
      content: string;
    };
  };
};

const Section = ({ data: { title, response } }: Props) => {
  return (
    <SnippetContainer>
      <SnippetTitle>{title}</SnippetTitle>
      <CodeSnippet title="Request body" content={response.content} />
    </SnippetContainer>
  );
};

const WebhookResponseExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>
        <span>{t("pages.settings.organization.webhooks.responses.title")}</span>

        <IconButton onClick={() => setIsOpen((prev) => !prev)}>
          <ArrowIcon active={isOpen} />
        </IconButton>
      </SectionTitle>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <SectionDescription>
          <span>
            {t("pages.settings.organization.webhooks.responses.paragraph1")}
          </span>
          <span>
            {t("pages.settings.organization.webhooks.responses.paragraph2")}
          </span>
        </SectionDescription>
        <Wrapper>
          {mockData.map((data, index) => (
            <Section key={`snippet-${index}`} data={data} />
          ))}
        </Wrapper>
      </Collapse>
    </>
  );
};

export default WebhookResponseExample;
