import { WidgetProps } from "pages/Dashboard/Dashboard";
import { Card } from "../../components";
import { useStatisticsData } from "./helpers/helpers";
import { Wrapper } from "./styles";

const Statistics = ({ statisticsViewMode }: WidgetProps) => {
  const formattedData = useStatisticsData(statisticsViewMode);

  return (
    <Wrapper>
      {formattedData.map((cardData) => (
        <Card key={`statistic-card-${cardData.id}`} {...cardData} />
      ))}
    </Wrapper>
  );
};

export default Statistics;
