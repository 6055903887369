import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import { CategoryDTO, TeamInvitationRequestDto } from "openapi";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { AlertWrapper } from "components/Alert/Alert";
import { FormType } from "../TeamRoles/types";
import Table from "./Table/Table";
import { Wrapper, Container } from "./styles";

export type PermissionsProps = {
  control: Control<FormType>;
  selectedRole: string;
  isMemberLimitReached: boolean;
  categories: CategoryDTO[];
};

const Permissions = ({
  control,
  selectedRole,
  isMemberLimitReached,
  categories,
}: PermissionsProps) => {
  const { t } = useTranslation();
  const { isOwner, selectedTeam } = useTeam();

  if (!categories?.length) {
    return (
      <Skeleton
        variant="rounded"
        height={256}
        sx={{ marginTop: theme.spacing.md }}
      />
    );
  }

  return (
    <Wrapper data-testid="permissions">
      <Container>
        {selectedRole === TeamInvitationRequestDto.role.LIMITED_USER && (
          <Table control={control} categories={categories} />
        )}

        {isMemberLimitReached && (
          <AlertWrapper
            type={AlertTypeEnum.warning}
            role="alert"
            isLeftBorder={true}
            data-testid="permissions-alert"
          >
            {t(
              "pages.settings.tabs.team.teamInvitationForm.validation.currentMemberLimit",
              {
                memberLimit: selectedTeam?.maxMembers,
              }
            )}
            {isOwner() ? (
              <div>
                {t(
                  "pages.settings.tabs.team.teamInvitationForm.validation.upgradePlan"
                )}
              </div>
            ) : (
              <div>
                {t(
                  "pages.settings.tabs.team.teamInvitationForm.validation.checkPlan"
                )}
              </div>
            )}
          </AlertWrapper>
        )}
      </Container>
    </Wrapper>
  );
};

export default Permissions;
