/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ContractAttachmentService,
  ContractDTOV1,
  ContractInputDTOV1,
  ContractService,
  OrganizationNewService,
  OrganizationService,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SearchType } from "hooks/useFullTextSearch";
import * as Sentry from "@sentry/react";

export const useContractsQuery = (
  teamId: string,
  contactId?: string,
  organizationId?: string,
  search?: string,
  searchType?: SearchType
) => {
  const queryKey = ["contracts", teamId, contactId, search, searchType];

  let queryFn: () => Promise<ContractDTOV1[]>;
  let enabled: boolean;

  if (contactId) {
    queryFn = () =>
      OrganizationService.getContractsByContact(
        contactId,
        organizationId!,
        teamId
      );
    enabled = !!teamId && !!contactId && !!organizationId;
  } else {
    queryFn = () => OrganizationService.getContractsOfTeam(teamId, search);
    enabled = !!teamId;
  }

  return useQuery({ queryKey, queryFn, enabled });
};

export const useContractQuery = (teamId: string, contractId?: string) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["contracts", teamId, contractId],
    queryFn: () =>
      OrganizationService.getContractOfTeamById(teamId, contractId!),
    enabled: !!teamId && !!contractId,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contract-change-log"] });
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });
};

export const useContractUploadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      teamId: string;
      file: {
        file: Blob;
        categoryId?: string;
        parentId?: string;
      };
    }) => {
      return ContractAttachmentService.createContract(
        false,
        data.teamId,
        data.file
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["contracts"] });
      void queryClient.invalidateQueries({ queryKey: ["overview"] });
      void queryClient.invalidateQueries({ queryKey: ["contract-change-log"] });
    },
  });
};

export const useUpdateContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      teamId: string;
      contractId: string;
      requestBody: ContractInputDTOV1;
    }) => {
      return OrganizationService.updateContract(
        data.teamId,
        data.contractId,
        data.requestBody
      );
    },
    onSuccess: (_, _data) => {
      void queryClient.invalidateQueries({
        queryKey: ["contracts"],
      });
      void queryClient.invalidateQueries({ queryKey: ["contract-change-log"] });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useContractChangeLogQuery = (
  contractId: string,
  organizationId: string
) => {
  return useQuery({
    queryKey: ["contract-change-log", organizationId, contractId],
    queryFn: async () => {
      return await OrganizationNewService.getContractHistory(
        contractId,
        organizationId
      );
    },
    enabled: !!contractId && !!organizationId,
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useDeleteContractDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { teamId: string; contractId: string }) => {
      return ContractService.deleteContract(data.contractId);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contracts", variables.teamId],
      });
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });
};

export const useOCRedContractsQuery = (teamId: string) => {
  return useQuery({
    queryKey: ["contracts", teamId],
    queryFn: () => OrganizationService.getOcRedContractsOfTeam(teamId),
    enabled: !!teamId,
  });
};

export const useGetContractsOfGroup = (contractId: string | undefined) => {
  return useQuery({
    queryKey: ["contracts"],
    queryFn: async () => {
      if (!contractId) {
        return [];
      }
      return ContractAttachmentService.getContractsOfGroup(contractId);
    },
    enabled: Boolean(contractId),
    onError: (error) => {
      Sentry.captureException(error);
    },
  });
};
