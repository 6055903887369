import Button from "./Button/Button";
import ArrowIcon from "./ArrowIcon/ArrowIcon";
import Label from "./Label/Label";

type Props = {
  id?: string;
  onClick?: () => void;
  label: string;
  orientation?: "right" | "left";
  style?: React.CSSProperties;
  ariaLabel?: string;
};

const ButtonWithArrow = ({
  id,
  onClick,
  label,
  orientation,
  style,
  ariaLabel,
}: Props) => (
  <Button id={id} onClick={onClick} style={style}>
    {orientation === "right" ? (
      <>
        <Label label={label} ariaLabel={ariaLabel} />
        <ArrowIcon orientation="right" />
      </>
    ) : (
      <>
        <ArrowIcon />
        <Label label={label} ariaLabel={ariaLabel} />
      </>
    )}
  </Button>
);

export default ButtonWithArrow;
