import styled from "@emotion/styled";
import { theme } from "theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .MuiFormControl-root {
    margin: 0;
  }
`;

export const Row = styled.div<{
  alignItems?: string;
  justifyItems?: string;
  maxWidth?: string;
  gap?: string;
  verticalMargin?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyItems || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  max-width: ${(props) => props.maxWidth || "100%"};
  gap: ${(props) => props.gap || theme.spacing.md};
  margin-top: ${(props) => props.verticalMargin || theme.spacing.md};
  margin-bottom: ${(props) => props.verticalMargin || theme.spacing.md};
  min-height: 40px;
`;

export const FormItemLabel = styled.div`
  flex: 0 0 auto;
`;

export const FormItem = styled.div`
  position: relative;
  display: flex;
  gap: ${theme.spacing.lg};
  align-items: center;
  width: 100%;

  .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    font-size: calc(${theme.font.size.h6} / 2);
    line-height: 1;
    padding: 0;
    margin: var(--spacing-xs) 0;
  }
`;

export const FormItemHint = styled.div`
  position: absolute;
  font-size: ${theme.font.size.caption};
  bottom: -1.25rem;
  left: ${theme.spacing.md};
  color: ${theme.color.blue[600]};
`;

export const FormMessage = styled.span`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
`;

export const FormOffsetFieldWrapper = styled.div`
  max-width: 80px;
`;
