import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { OrganizationService } from "openapi";
import { ButtonWithArrow, CTAButton } from "components";
import { routePaths } from "../../../../constants";
import { DeleteModal } from "..";
import { Wrapper, ActionButtons } from "./styles";
import { Tooltip } from "@mui/material";
import { useTeam } from "../../../../contexts/team/hooks";
import { useContactQuery, useContactTypesQuery } from "shared/api/contacts";
import DeleteIcon from "assets/svg/trash-icon.svg?react";

export type ToolbarProps = {
  contactId: string;
  showDeleteModal: boolean;
  setShowDeleteModal: (value: boolean) => void;
  isEditable: boolean;
  setIsEditable: (value: boolean) => void;
  handleOnGoBackClick: () => void;
  setShowMergeModal: (value: boolean) => void;
};

const Toolbar = ({
  setShowMergeModal,
  showDeleteModal,
  setShowDeleteModal,
  setIsEditable,
  isEditable,
  contactId,
  handleOnGoBackClick,
}: ToolbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    selectedTeamId,
    parentTeamId: organizationId,
    isOwner,
    selectedTeam,
  } = useTeam();
  const [isDeleteAvailable, setIsDeleteAvailable] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const showMergeButton = isOwner() && !selectedTeam?.isSubTeam;

  const { data: contactTypes } = useContactTypesQuery(organizationId);
  const { data: contactData } = useContactQuery(
    contactId,
    organizationId,
    selectedTeamId,
    contactTypes
  );

  const fetchLinkedContacts = async () => {
    try {
      const linkedContacts = await OrganizationService.getContractsByContact(
        contactId,
        organizationId,
        selectedTeamId
      );
      const hasLinkedContracts = linkedContacts?.length > 0;
      setIsDeleteAvailable(!hasLinkedContracts);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (contactId !== "draft") void fetchLinkedContacts();
  }, [contactId]);

  const handleMouseEnter = () => {
    if (!isDeleteAvailable) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  return (
    <>
      <Wrapper aria-label="toolbar">
        {isEditable && contactId !== "draft" ? (
          <ButtonWithArrow
            onClick={handleOnGoBackClick}
            label={t("pages.contact.toolbar.buttons.backToContact")}
            orientation="left"
          />
        ) : (
          <ButtonWithArrow
            onClick={() => navigate(routePaths.CONTACTS)}
            label={t("navbar.allContacts")}
            orientation="left"
          />
        )}

        {!!contactData?.editable && !isEditable && (
          <ActionButtons aria-label="toolbar-action-buttons">
            {showMergeButton && (
              <CTAButton
                variant="secondary"
                label="openMergeModalButton"
                onClick={() => setShowMergeModal(true)}
                name={t("pages.contact.toolbar.buttons.merge")}
                data-testid="openMergeModalButton"
              />
            )}

            <CTAButton
              variant="secondary"
              label="editContactBtn"
              onClick={() => setIsEditable(true)}
              name={t("pages.contact.toolbar.buttons.edit")}
            />
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Tooltip
                title={t("pages.contact.toolbar.buttons.deleteToolTipMessage")}
                open={isTooltipOpen}
                placement="top"
                arrow
              >
                <CTAButton
                  variant="secondary"
                  label="deleteContactBtn"
                  onClick={() => setShowDeleteModal(true)}
                  icon={<DeleteIcon />}
                  disabled={!isDeleteAvailable}
                  size="square"
                  iconSize="small"
                  color="danger"
                />
              </Tooltip>
            </div>
          </ActionButtons>
        )}
      </Wrapper>

      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        contactId={contactId}
      />
    </>
  );
};

export default Toolbar;
