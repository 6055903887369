import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";

import { CategoryDTO, RoleDTO, TeamDTOV1 } from "openapi";
import { Value } from "../InitialEntry/styles";
import CategoryBadge from "../CategoryBadge/CategoryBadge";
import { useLocale } from "hooks";
import { Role } from "components";
import { getNameWithSuffix } from "pages/Settings/OrganizationSettings/Organization/helper";
import { DELETED_CATEGORY_COLOR } from "../../helpers";

export type RoleInitialType = {
  data: RoleDTO;
  categories: CategoryDTO[];
  teams: TeamDTOV1[];
};

const RoleInitial = ({ data, categories, teams }: RoleInitialType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const roleName = getNameWithSuffix(data.name, data.teamId, teams, t);

  const isLimitedUser = !!data.permissions.length;

  return (
    <Wrapper>
      <Role name={roleName} />
      {isLimitedUser ? (
        data.permissions.map((permissionItem) => {
          const category = categories.find(
            (item) => item.id === permissionItem.categoryId
          );
          const categoryName =
            category?.name[locale] || t("common.labels.deletedCategory");
          const categoryColor = category?.color || DELETED_CATEGORY_COLOR;
          return (
            <Value key={permissionItem.id}>
              <CategoryBadge color={categoryColor} name={categoryName} />
              {t(`common.accessOptions.${permissionItem.level}`)}
            </Value>
          );
        })
      ) : (
        <span>
          {t(
            "pages.settings.organization.permissionChangeLog.messages.fullAccessToAllCategories"
          )}
        </span>
      )}
    </Wrapper>
  );
};

export default RoleInitial;
