import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Alert } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import WarningIcon from "@mui/icons-material/Warning";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs, { ManipulateType } from "dayjs";
import {
  TaskNewDTO,
  TeamMemberBasicInfoDTO,
  DurationFieldTypeDtoV1,
  OffsetConfig,
  DateFieldTypeDtoV1,
  TaskNewCreateDTO,
  TaskNewUpdateDTO,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { CTAButton } from "components";
import { Drawer } from "new-components";
import {
  useTaskQuery,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useContractQuery,
  useContractsQuery,
  useFieldsQuery,
  useOrganizationCategoriesQuery,
  useTeamMembersQuery,
} from "shared/api";
import {
  StatusComponent,
  TitleComponent,
  DescriptionComponent,
  ContractLinkComponent,
} from "../components";
import DeleteTaskModal from "./DeleteTaskModal";
import { useLocale, useUserInfo } from "hooks";
import {
  getAvailableDateFieldsBySelectedCategories,
  getDateFieldOptions,
} from "pages/Settings/OrganizationSettings/TaskAutomations/utils";
import { userHasAccessToCategory, validationSchema } from "./utils";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import FormMultiSelect from "components/FormItems/FormMultiSelect/FormMultiSelect";
import { defaultDateValues } from "pages/Settings/OrganizationSettings/TaskAutomation/utils";
import DueDate from "./components/DueDate/DueDate";
import {
  ActionButtonContainer,
  FieldDescription,
  FieldTitle,
  TooltipRow,
} from "./styles";
import Hint from "../components/AssigneeSection/components/Hint";
import { useLocation, useParams } from "react-router";

export const NO_DUE_DATE = "NO_DUE_DATE";
const TASK_FORM_ID = "task-form";

export type CalculatedRelativeDatesType = {
  baseDate: string | null;
  beforeBaseDate: string | null;
  reminderDate: string | null;
  repeatDate: string | null;
};

export type CalculatedFixedDatesType = {
  reminderDate: string | null;
  repeatDate: string | null;
};

export type Task = {
  id?: string;
  title: string;
  description: string;
  status: string;
  assignees: Array<TeamMemberBasicInfoDTO>;
  dueDate?: string | null;
  reminderType?: string;
  reminderAt?: string | null;
  reminderInterval?: number | null;
  reminderUnit?: string | null;
  createdAt?: string;
  updatedAt?: string;
  contractId: string;
  isMigrated: boolean;
  reminderConfig?: OffsetConfig | null;
  dueDateConfig?: Record<string, unknown> | object | undefined;
  dueDateType?: TaskNewDTO.dueDateType | null;
  assigneeIds?: string[];
};

type Props = {
  drawerOpen: boolean;
  taskId?: string;
  handleOnModalClose: () => void;
  handleOnDeleteTask?: () => void;
  contactId?: string | undefined;
};

const defaultValues: Task = {
  title: "",
  description: "",
  status: TaskNewDTO.status.OPEN,
  assignees: [],
  reminderAt: null,
  reminderInterval: null,
  reminderUnit: null,
  contractId: "",
  dueDate: null,
  isMigrated: false,
  assigneeIds: [],
  ...defaultDateValues,
};

const TaskDetails = ({
  handleOnModalClose,
  taskId,
  contactId,
  drawerOpen,
}: Props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { id } = useParams();
  const { selectedTeamId, hasWriteAccess, parentTeamId, organizationId } =
    useTeam();
  const [selectedContract, setSelectedContract] = useState<{
    id: string;
    name: string;
    categoryId?: string;
  } | null>(null);
  const { data: fields } = useFieldsQuery(organizationId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isBaseDate, setIsBaseDate] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("");
  const [reminderIsEnabled, setReminderIsEnabled] = useState<boolean>(false);
  const [repeatIsEnabled, setRepeatIsEnabled] = useState<boolean>(false);
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const isContractsPath = location.pathname.includes("contracts");
  const contractId = isContractsPath ? id : undefined;

  const resetDueDateFields = () => {
    setValue("dueDateConfig.enabled", defaultDateValues.dueDateConfig.enabled);
    setValue("dueDateConfig.offset", defaultDateValues.dueDateConfig.offset);
    setValue("dueDateConfig.unit", defaultDateValues.dueDateConfig.unit);
    setValue("dueDateConfig.dueDate", defaultDateValues.dueDateConfig.dueDate);
    setValue(
      "dueDateConfig.relativeFieldId",
      defaultDateValues.dueDateConfig.relativeFieldId
    );
  };

  const resetRepeatFields = () => {
    setValue(
      "dueDateConfig.repeatConfig.enabled",
      defaultDateValues.dueDateConfig.repeatConfig.enabled as never,
      { shouldDirty: true }
    );
    setValue(
      "dueDateConfig.repeatConfig.offset",
      defaultDateValues.dueDateConfig.repeatConfig.offset as never,
      { shouldDirty: true }
    );
    setValue(
      "dueDateConfig.repeatConfig.unit",
      defaultDateValues.dueDateConfig.repeatConfig.unit as never,
      { shouldDirty: true }
    );
  };

  const resetReminderFields = () => {
    setValue(
      "reminderConfig.enabled",
      defaultDateValues.reminderConfig.enabled,
      { shouldDirty: true }
    );
    setValue("reminderConfig.offset", defaultDateValues.reminderConfig.offset, {
      shouldDirty: true,
    });
    setValue("reminderConfig.unit", defaultDateValues.reminderConfig.unit, {
      shouldDirty: true,
    });
  };

  // Reset date fields on switching between tabs on task create
  useEffect(() => {
    if (taskId) return;
    setRepeatIsEnabled(false);
    setReminderIsEnabled(false);
    resetReminderFields();
    resetRepeatFields();
    resetDueDateFields();
  }, [activeTab]);

  //Reset reminder fields on toggling its visibility
  useEffect(() => {
    if (reminderIsEnabled) return;
    resetReminderFields();
  }, [reminderIsEnabled]);

  //Reset repeat fields on toggling its visibility
  useEffect(() => {
    if (repeatIsEnabled) return;
    resetRepeatFields();
  }, [repeatIsEnabled]);

  const checkAccess = () => {
    if (!contract) {
      return true;
    }
    return isMemberAssignee() || hasWriteAccess(contract.categoryId);
  };

  const { data: task } = useTaskQuery(organizationId, selectedTeamId, taskId);
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);

  const { data: contract } = useContractQuery(
    selectedTeamId,
    selectedContract?.id || contractId || task?.contractId || ""
  );

  const {
    data: contracts = [],
    isLoading: areContractsLoading,
    isError: haveContractsError,
  } = useContractsQuery(selectedTeamId, contactId, parentTeamId);
  const { data: teamMembersData } = useTeamMembersQuery(selectedTeamId);
  const { mutateAsync: createTask, isLoading: isCreateLoading } =
    useCreateTaskMutation();
  const { mutateAsync: updateTask, isLoading: isUpdateLoading } =
    useUpdateTaskMutation();
  const { mutateAsync: deleteTask, isLoading: isDeleteLoading } =
    useDeleteTaskMutation();
  const methods = useForm<Task>({
    defaultValues: {
      ...defaultValues,
      contractId: contractId,
    },
    resolver: yupResolver(
      validationSchema(activeTab, reminderIsEnabled, repeatIsEnabled)
    ),
  });
  const isDefaultDueDateType = useMemo(() => {
    return task?.dueDateType
      ? task?.dueDateType === activeTab
      : activeTab === NO_DUE_DATE;
  }, [task, activeTab]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { isDirty },
  } = methods;

  const isMemberAssignee = () => {
    if (!task || !teamMembersData) {
      return false;
    }
    return task.assigneeIds?.includes(teamMembersData.memberId);
  };

  const drawerTitle = useMemo(() => {
    const title = taskId
      ? t("pages.tasks.modal.title.edit")
      : t("pages.tasks.modal.title.add");
    return title;
  }, [taskId]);

  const handleDrawerClose = () => {
    if (contractId) {
      const data = { ...defaultValues, contractId };
      reset(data);
    } else {
      reset(defaultValues);
    }

    handleOnModalClose();
  };

  useEffect(() => {
    const relevantTaskData = task;
    // Check if field is of Duration type (relativeFieldId !== value) and assign relativeFieldId respectively
    const relativeFieldId =
      (relevantTaskData?.dueDateConfig?.relativeFieldKey as string) !==
        "value" || !relevantTaskData?.dueDateConfig?.relativeFieldKey
        ? `${
            (relevantTaskData?.dueDateConfig?.relativeFieldId as string) ?? ""
          }${
            relevantTaskData?.dueDateConfig?.relativeFieldKey
              ? `__${
                  relevantTaskData?.dueDateConfig?.relativeFieldKey as string
                }`
              : ""
          }`
        : (relevantTaskData?.dueDateConfig?.relativeFieldId as string) ?? "";

    if (relevantTaskData?.contractId) {
      const matchingContract = contracts.find(
        (contract) => contract.id === relevantTaskData.contractId
      );
      reset(relevantTaskData as unknown as Task);
      setValue("contractId", relevantTaskData.contractId);
      setValue("dueDateConfig.relativeFieldId", relativeFieldId);
      if (matchingContract) {
        setSelectedContract({
          id: relevantTaskData.contractId,
          name: matchingContract?.name,
        });
      }
    }
    setReminderIsEnabled(task?.reminderConfig?.enabled ?? false);
    setRepeatIsEnabled(
      (task?.dueDateConfig?.repeatConfig as OffsetConfig)?.enabled ?? false
    );

    if (!taskId) {
      setActiveTab(NO_DUE_DATE);
    } else if (task?.dueDateType === TaskNewCreateDTO.dueDateType.FIXED) {
      setActiveTab(TaskNewCreateDTO.dueDateType.FIXED);
    } else if (task?.dueDateType === TaskNewCreateDTO.dueDateType.RELATIVE) {
      setActiveTab(TaskNewCreateDTO.dueDateType.RELATIVE);
    } else {
      setActiveTab(NO_DUE_DATE);
    }
  }, [task]);

  useEffect(() => {
    if (!teamMembersData?.members?.length || !task) return;
    const membersWithAccess = getMembersWithAccess();
    const memberIdsWithAccess = membersWithAccess.map((member) => member.id);
    const relevantTaskAssigneeIds = task?.assigneeIds?.filter((id) =>
      memberIdsWithAccess.includes(id)
    );

    setValue("assigneeIds", relevantTaskAssigneeIds);
  }, [teamMembersData, task, contract]);

  const handleOnLinkContractChange = (e: { key: string; value: string }) => {
    const contractId = e.key;
    const matchingContract = contracts.find(
      (contract) => contract.id === contractId
    );
    const contractName = matchingContract?.name ?? "";
    const contractCategoryId = matchingContract?.categoryId ?? "";

    setValue("contractId", contractId);
    setSelectedContract({
      id: contractId,
      name: contractName,
      categoryId: contractCategoryId,
    });
  };
  const isContractSelected = watch("contractId");
  const selectedDueDateField = watch("dueDateConfig.relativeFieldId") as string;
  const selectedDueDateOffset = watch("dueDateConfig.offset") as string;
  const selectedDueDateUnit = watch("dueDateConfig.unit") as ManipulateType;
  const selectedRepeatOffset = watch(
    "dueDateConfig.repeatConfig.offset"
  ) as string;
  const selectedRepeatUnit = watch(
    "dueDateConfig.repeatConfig.unit"
  ) as ManipulateType;
  const selectedReminderOffset = watch("reminderConfig.offset") as number;
  const selectedReminderUnit: unknown = watch("reminderConfig.unit");
  const taskStatus = watch("status");
  const selectedFixedDueDate = watch("dueDateConfig.dueDate");

  const getMembersWithAccess = () => {
    if (!teamMembersData?.members || !contract) return [];
    const membersWithAccess = teamMembersData.members.filter((member) => {
      const hasAccessToCategory = userHasAccessToCategory(member, contract);
      return hasAccessToCategory;
    });

    return membersWithAccess;
  };

  const getMembersWithOutAccess = () => {
    if (!teamMembersData?.members || !contract) return [];
    const membersWithAccess = teamMembersData.members.filter((member) => {
      const hasAccessToCategory = userHasAccessToCategory(member, contract);
      return !hasAccessToCategory;
    });

    return membersWithAccess;
  };
  const teamMembersOptions = useMemo(() => {
    const membersWithAccess = getMembersWithAccess();
    return membersWithAccess?.map((item) => {
      const fullName = `${item.firstname || ""} ${item.lastname || ""}`;
      return {
        key: item.id,
        value: fullName,
      };
    });
  }, [teamMembersData, contract]);

  const teamMembersWithoutAccess = useMemo(() => {
    if (!teamMembersData?.members || !contract || !task?.assigneeIds) return [];
    const membersWithOutAccess = getMembersWithOutAccess();
    const taskAssigness = task?.assigneeIds;

    if (!membersWithOutAccess) return [];

    const assigneeWithoutAccess = membersWithOutAccess.filter((member) =>
      taskAssigness.includes(member.id)
    );

    return assigneeWithoutAccess.map((item) => {
      const fullName = `${item.firstname || ""} ${item.lastname || ""}`;
      return {
        key: item.id,
        value: fullName,
      };
    });
  }, [teamMembersOptions, teamMembersData, contract, task]);

  const selectedCategory = useMemo(() => {
    if (!contract || !categories?.length) return [];
    return categories.filter((category) => category.id === contract.categoryId);
  }, [contract, categories]);

  const dateFieldOptions = useMemo(() => {
    if (!contract || !fields?.length || !categories?.length) return [];

    const dateFields =
      getAvailableDateFieldsBySelectedCategories(selectedCategory, fields) ||
      [];

    const fieldOptions = getDateFieldOptions(dateFields, t, locale);
    return fieldOptions;
  }, [fields, contract]);

  const dateFromSelect = useMemo(() => {
    if (!contract || !selectedDueDateField) return null;

    const [id, subField] = selectedDueDateField.split("__");

    const contractField = contract.fields?.[id];
    const fieldData = fields?.find((item) => id === item.id);
    const fieldType = fieldData?.type;

    if (fieldType === "DATE") {
      return (contractField as DateFieldTypeDtoV1)?.value;
    }
    if (fieldType === "DURATION") {
      const relativeFieldKey: string =
        subField ?? task?.dueDateConfig?.relativeFieldKey ?? "";

      if (!contractField || !relativeFieldKey) return;

      return (contractField as DurationFieldTypeDtoV1)[
        relativeFieldKey as keyof DurationFieldTypeDtoV1
      ];
    }
  }, [contract, selectedDueDateField]);

  const getDynamicFieldValue = useMemo(() => {
    if (!selectedDueDateField || !contract?.fields) return;

    const baseDate = dateFromSelect as string;
    setIsBaseDate(!!baseDate);

    if (!baseDate) return;

    const dueDate =
      selectedDueDateOffset && selectedDueDateUnit
        ? dayjs(baseDate).subtract(
            Number(selectedDueDateOffset),
            selectedDueDateUnit
          )
        : "";

    const calculatedDates: CalculatedRelativeDatesType = {
      baseDate: baseDate ? dayjs(baseDate).format(userDateFormat) : "",
      beforeBaseDate: dueDate ? dueDate.format(userDateFormat) : "",
      repeatDate:
        selectedRepeatOffset && selectedRepeatUnit
          ? dayjs(dueDate)
              .add(Number(selectedRepeatOffset), selectedRepeatUnit)
              .format(userDateFormat)
          : "",
      reminderDate:
        selectedReminderOffset && selectedReminderUnit
          ? dayjs(dueDate)
              .subtract(
                Number(selectedReminderOffset),
                selectedReminderUnit as ManipulateType
              )
              .format(userDateFormat)
          : "",
    };
    return calculatedDates;
  }, [
    selectedDueDateField,
    contract,
    selectedDueDateOffset,
    selectedDueDateUnit,
    selectedRepeatOffset,
    selectedRepeatUnit,
    selectedReminderOffset,
    selectedReminderUnit,
    dateFromSelect,
  ]);

  const getFixedFieldValue = useMemo(() => {
    if (!selectedFixedDueDate) return;

    const dueDate = selectedFixedDueDate as string;

    const calculatedDates: CalculatedFixedDatesType = {
      repeatDate:
        selectedRepeatOffset && selectedRepeatUnit
          ? dayjs(dueDate)
              .add(Number(selectedRepeatOffset), selectedRepeatUnit)
              .format(userDateFormat)
          : "",
      reminderDate:
        selectedReminderOffset && selectedReminderUnit
          ? dayjs(dueDate)
              .subtract(
                Number(selectedReminderOffset),
                selectedReminderUnit as ManipulateType
              )
              .format(userDateFormat)
          : "",
    };
    return calculatedDates;
  }, [
    selectedFixedDueDate,
    contract,
    selectedRepeatOffset,
    selectedRepeatUnit,
    selectedReminderOffset,
    selectedReminderUnit,
  ]);

  const handleOnCreate = async (data: TaskNewCreateDTO) => {
    try {
      const dueDateType = activeTab === "NO_DUE_DATE" ? null : activeTab;
      const requestBody = {
        ...data,
        dueDateType,
      };

      const dataTyped = data;
      const { dueDateConfig } = dataTyped;
      const [relativeFieldId = "", relativeFieldKey = ""] =
        (dueDateConfig?.relativeFieldId as string | undefined)?.split("__") ??
        [];

      switch (activeTab) {
        case TaskNewCreateDTO.dueDateType.FIXED:
          requestBody.dueDateConfig = {
            ...data.dueDateConfig,
            enabled: !!data?.dueDateConfig?.dueDate,
          };
          if (reminderIsEnabled) {
            requestBody.reminderConfig = {
              ...data.reminderConfig,
              enabled: true,
            };
          }
          if (repeatIsEnabled) {
            requestBody.dueDateConfig.repeatConfig = {
              ...(data?.dueDateConfig?.repeatConfig as OffsetConfig),
              enabled: true,
            };
          }
          break;
        case TaskNewCreateDTO.dueDateType.RELATIVE:
          if (!data?.dueDateConfig?.relativeFieldId) return;

          requestBody.dueDateConfig = {
            ...data.dueDateConfig,
            enabled: true,
            relativeFieldId: relativeFieldId,
            relativeFieldKey: relativeFieldKey,
          };
          if (reminderIsEnabled) {
            requestBody.reminderConfig = {
              ...data.reminderConfig,
              enabled: true,
            };
          }
          if (repeatIsEnabled) {
            requestBody.dueDateConfig.repeatConfig = {
              ...(data?.dueDateConfig?.repeatConfig as OffsetConfig),
              enabled: true,
            };
          }
          break;
        default:
          delete requestBody.dueDateConfig;
          delete requestBody.reminderConfig;
          break;
      }
      await createTask({
        organizationId,
        teamId: selectedTeamId,
        requestBody: requestBody as TaskNewCreateDTO,
      });

      handleDrawerClose();
      enqueueSnackbar(t("pages.tasks.notifications.create.success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("pages.tasks.notifications.create.error"), {
        variant: "error",
      });
    }
  };

  const handleOnUpdate = async (data: TaskNewUpdateDTO & { id: string }) => {
    if (!data.id) {
      return;
    }

    try {
      const dueDateType = activeTab === "NO_DUE_DATE" ? null : activeTab;
      const requestBody = {
        ...data,
        dueDateType,
      };

      const dataTyped = data;
      const { dueDateConfig } = dataTyped;
      const [relativeFieldId = "", relativeFieldKey = ""] =
        (dueDateConfig?.relativeFieldId as string | undefined)?.split("__") ??
        [];

      switch (activeTab) {
        case TaskNewUpdateDTO.dueDateType.FIXED:
          requestBody.dueDateConfig = {
            ...data.dueDateConfig,
            enabled: !!data?.dueDateConfig?.dueDate,
          };
          if (reminderIsEnabled) {
            requestBody.reminderConfig = {
              ...data.reminderConfig,
              enabled: true,
            };
          }
          if (repeatIsEnabled) {
            requestBody.dueDateConfig.repeatConfig = {
              ...(data?.dueDateConfig?.repeatConfig as OffsetConfig),
              enabled: true,
            };
          }
          break;
        case TaskNewUpdateDTO.dueDateType.RELATIVE:
          if (!data?.dueDateConfig?.relativeFieldId) return;

          requestBody.dueDateConfig = {
            ...data.dueDateConfig,
            enabled: true,
            relativeFieldId,
            relativeFieldKey,
          };
          if (reminderIsEnabled) {
            requestBody.reminderConfig = {
              ...data.reminderConfig,
              enabled: true,
            };
          }
          if (repeatIsEnabled) {
            requestBody.dueDateConfig.repeatConfig = {
              ...(data?.dueDateConfig?.repeatConfig as OffsetConfig),
              enabled: true,
            };
          }
          break;
        default:
          delete requestBody.dueDateConfig;
          delete requestBody.reminderConfig;
          break;
      }

      await updateTask({
        taskId: data.id,
        organizationId,
        teamId: selectedTeamId,
        requestBody: requestBody as TaskNewUpdateDTO,
      });

      handleDrawerClose();
      enqueueSnackbar(t("pages.tasks.notifications.update.success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("pages.tasks.notifications.update.error"), {
        variant: "error",
      });
    }
  };

  const handleOnDeleteTask = async () => {
    if (!task) {
      return;
    }
    try {
      await deleteTask({
        taskId: task.id,
        organizationId,
        teamId: selectedTeamId,
      });

      setShowDeleteModal(false);
      handleDrawerClose();
      enqueueSnackbar(t("pages.tasks.notifications.delete.success"), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("pages.tasks.notifications.delete.error"), {
        variant: "error",
      });
    }
  };

  const onSubmit = (data: unknown) => {
    if (!taskId) {
      void handleOnCreate(data as TaskNewCreateDTO);
    } else {
      void handleOnUpdate(data as TaskNewUpdateDTO[] & { id: string });
    }
  };

  const onMarkAsClosed = () => {
    setValue("status", TaskNewDTO.status.CLOSED);
    void handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (!taskId) {
      setValue("status", TaskNewDTO.status.OPEN);
    }
  }, []);

  if (areContractsLoading || (taskId && !task) || !categories) return <></>;

  if (haveContractsError) {
    handleDrawerClose();
    enqueueSnackbar(t("pages.tasks.notifications.retrieve.error"), {
      variant: "error",
    });
  }

  return (
    <>
      <Drawer
        title={drawerTitle}
        open={drawerOpen}
        handleClose={handleDrawerClose}
        footer={
          <>
            <ActionButtonContainer isTaskCreate={!taskId}>
              {!!taskId && checkAccess() ? (
                <CTAButton
                  variant="secondary"
                  name={t("common.buttons.delete")}
                  type="button"
                  onClick={() => setShowDeleteModal(true)}
                  color="danger"
                />
              ) : (
                <div />
              )}

              <div style={{ display: "flex", gap: theme.spacing.lg }}>
                <CTAButton
                  name={t("common.buttons.cancel")}
                  type="button"
                  variant="secondary"
                  onClick={handleDrawerClose}
                />
                {checkAccess() && (
                  <CTAButton
                    type="submit"
                    name={
                      !taskId
                        ? t("common.buttons.create")
                        : t("common.buttons.update")
                    }
                    form={TASK_FORM_ID}
                    disabled={
                      isCreateLoading ||
                      isUpdateLoading ||
                      (!isDirty && isDefaultDueDateType)
                    }
                  />
                )}
              </div>
            </ActionButtonContainer>
          </>
        }
        body={
          drawerOpen ? (
            <>
              {!checkAccess() && (
                <Alert
                  icon={<WarningIcon fontSize="small" />}
                  color="warning"
                  sx={{ marginBottom: theme.spacing.md }}
                >
                  {t("common.permissions.limitedAccess")}
                </Alert>
              )}
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  id={TASK_FORM_ID}
                >
                  <FieldTitle>
                    {t("pages.tasks.modal.formTitles.name")}
                  </FieldTitle>
                  <TitleComponent control={control} disabled={!checkAccess()} />

                  <FieldTitle>{t("common.status")}</FieldTitle>

                  <StatusComponent
                    control={control}
                    disabled={!checkAccess()}
                    onButtonClick={onMarkAsClosed}
                    taskClosed={taskStatus === TaskNewDTO.status.CLOSED}
                    buttonHidden={!taskId}
                  />

                  <FieldTitle>
                    {t("pages.tasks.modal.formTitles.contract")}
                  </FieldTitle>

                  <ContractLinkComponent
                    control={control}
                    contracts={contracts}
                    handleOnModalClose={handleOnModalClose}
                    onChange={handleOnLinkContractChange}
                    disabled={!!taskId && !checkAccess()}
                  />

                  <FieldTitle>
                    <span>
                      {t("pages.tasks.modal.formTitles.dueDateAndReminder")}
                    </span>
                    <InfoTooltip>
                      <>
                        <TooltipRow>
                          <AccessTimeIcon />
                          <>{t("pages.tasks.modal.tooltips.dueDate")}</>
                        </TooltipRow>
                        <TooltipRow>
                          <NotificationsNoneIcon />
                          <>{t("pages.tasks.modal.tooltips.reminder")}</>
                        </TooltipRow>
                      </>
                    </InfoTooltip>
                  </FieldTitle>

                  <FieldDescription>
                    {t(
                      "pages.tasks.modal.formTitles.dueDateAndReminderDescription"
                    )}
                  </FieldDescription>

                  <DueDate
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    control={control}
                    isDisabled={!isContractSelected}
                    checkAccess={checkAccess}
                    reminderIsEnabled={reminderIsEnabled}
                    setReminderIsEnabled={setReminderIsEnabled}
                    repeatIsEnabled={repeatIsEnabled}
                    setRepeatIsEnabled={setRepeatIsEnabled}
                    getFixedFieldValue={getFixedFieldValue}
                    dateFieldOptions={dateFieldOptions}
                    getDynamicFieldValue={getDynamicFieldValue}
                    task={task}
                    isBaseDate={isBaseDate}
                    taskId={taskId}
                    fields={fields}
                  />

                  <FieldTitle>
                    {t("pages.tasks.modal.formTitles.assignee")}
                  </FieldTitle>

                  <FormMultiSelect
                    style={{ marginTop: theme.spacing.lg }}
                    name="assigneeIds"
                    control={control}
                    options={teamMembersOptions}
                    disableCloseOnSelect
                    label={t(
                      "pages.settings.organization.taskAutomations.addAutomation.form.labels.assignee"
                    )}
                    size="small"
                  />
                  {teamMembersWithoutAccess.length > 0 ? (
                    <Hint
                      teamMembersWithoutAccess={teamMembersWithoutAccess}
                      selectedCategory={selectedCategory}
                    />
                  ) : null}
                  <FieldTitle>
                    {t("pages.tasks.modal.formTitles.description")}
                  </FieldTitle>

                  <DescriptionComponent
                    control={control}
                    disabled={!checkAccess()}
                  />
                </form>
              </FormProvider>
            </>
          ) : (
            <></>
          )
        }
      />
      <DeleteTaskModal
        open={showDeleteModal}
        handleOnClose={() => setShowDeleteModal(false)}
        handleOnDelete={handleOnDeleteTask}
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default TaskDetails;
