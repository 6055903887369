import { SxProps, Tooltip, IconButtonProps } from "@mui/material";
import { ReactElement, FC } from "react";
import { ToolbarButtonContainer } from "../styles";
import CTAButton from "components/CTAButton/CTAButton";

type ToolbarButtonProps = {
  highlighted?: boolean;
  tooltip: string;
  children: ReactElement;
  sx?: SxProps;
  collapses?: boolean | "collapse" | "negative-collapse" | undefined;
  onClick?: IconButtonProps["onClick"];
  iconSize?: "tiny" | "small" | "medium" | "large" | undefined;
};

export const ToolbarButton: FC<ToolbarButtonProps> = ({
  tooltip,
  children,
  sx,
  onClick,
  collapses,
  highlighted = false,
  iconSize = "small",
}) => {
  let className = "";
  if (collapses) {
    className =
      collapses === "negative-collapse"
        ? "toolbar-negative-collapsing-button"
        : "toolbar-collapsing-button";
  }

  return (
    <ToolbarButtonContainer className={className} sx={sx}>
      <Tooltip
        PopperProps={{
          container: document.fullscreenElement ?? document.body,
        }}
        title={tooltip}
      >
        <CTAButton
          onClick={onClick}
          icon={children}
          variant="tertiary"
          color={highlighted ? "blue" : "gray"}
          iconSize={iconSize}
        />
      </Tooltip>
    </ToolbarButtonContainer>
  );
};
