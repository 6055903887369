import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddIconWrapper } from "./styles";

type Props = {
  status: string;
  onClick: () => void;
};

const AddTagIcon = ({ status, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <AddIconWrapper status={status} onClick={onClick} aria-label="add-tags">
      <Tooltip
        title={t("pages.contractDetails.tags.addTagsHint")}
        placement="top"
        arrow
      >
        <AddCircleOutlineIcon />
      </Tooltip>
    </AddIconWrapper>
  );
};

export default AddTagIcon;
