import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import ContactsIcon from "@mui/icons-material/Contacts";
import { merge } from "lodash";
import { Radio, RadioGroup } from "@mui/material";
import { OrganizationService, TextDatapointDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import * as api from "shared/api";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { ContactDataDTO } from "pages/Contacts/ContactDataDTO";
import { Button } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import ContactDetails from "./ContactDetails";
import { Container, Form, Main, Footer, NoDuplicateMessage } from "./styles";
import { MergeDuplicatesButton as NonDuplicateButton } from "../Header/styles";
import { enqueueSnackbar } from "notistack";
import { findDuplicates } from "./helpers";
import { removeEmptyValues } from "pages/Contact/helpers";

export type Duplicate = {
  first: string;
  second: string;
  distance: number;
};

const DuplicateEntry = ({
  duplicate,
  contacts,
  nonDuplicateCallback,
}: {
  duplicate: Duplicate;
  contacts: ContactDataDTO[];
  nonDuplicateCallback: () => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [previewContact, setPreviewContact] = useState<ContactDataDTO>();
  const [selected, setSelected] = useState("primary");
  const [primaryContactId, setPrimaryContactId] = useState(duplicate.first);
  const {
    parentTeamId: organizationId,
    selectedTeamId,
    organizationId: migratedOrganizationId,
  } = useTeam();
  const { data: fields } = api.useFieldsQuery(migratedOrganizationId);
  const { mutateAsync: updateContact } = api.useUpdateContactMutation();
  const deleteContactMutation = api.useDeleteContactMutation();

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [markedAsNonDuplicate, setMarkedAsNonDuplicate] = useState(false);
  const { control, handleSubmit } = useForm();

  const getContact = (id: string) =>
    contacts.find((contact) => contact.id === id);

  const flatten = (contact: ContactDataDTO) => {
    if (!contact) return {};

    const result: Record<string, string | JSX.Element> = {};

    for (const key of Object.keys(contact)) {
      const item = contact[key];
      if (!item) {
        continue;
      }
      if (typeof item === "boolean") {
        // `editable`
        continue;
      }
      if (typeof item === "number") {
        // `#contracts`
        continue;
      }
      if (typeof item === "string") {
        result[key] = contact[key] as string;
      } else {
        const typedItem = item as TextDatapointDTO;
        try {
          result[key] = typedItem.value.value as string;
        } catch (e) {
          console.log(e);
          console.log(typeof typedItem);
          console.log(key);
        }
      }
    }

    return result;
  };

  const executeMerge = async () => {
    if (!previewContact) return;

    let requestBody = {
      ...previewContact,
    } as Partial<ContactDataDTO>;

    // Note: Removed as the props are not part of the request body
    delete requestBody.createdAt;
    delete requestBody.updatedAt;
    delete requestBody.contractsNumber;
    delete requestBody.editable;

    requestBody = removeEmptyValues(requestBody);

    try {
      setLoading(true);
      await updateContact({
        organizationId,
        contactId: previewContact.id,
        requestBody: requestBody,
      });

      const toDeleteContactId =
        duplicate.first === previewContact.id
          ? duplicate.second
          : duplicate.first;

      // @Note: Before cleaning up the merged contact,
      // we need to update the contracts that use this contact.
      const contracts = await OrganizationService.getContractsByContact(
        toDeleteContactId,
        organizationId,
        selectedTeamId
      );

      for (const contract of contracts) {
        const partnerField = fields?.find(
          (field) => field.visibleId === "partnerCompany"
        );

        if (!partnerField) return;

        await OrganizationService.updateContract(contract.teamId, contract.id, {
          fields: {
            [partnerField?.id]: { value: previewContact.id },
          },
        });
      }

      await deleteContactMutation.mutateAsync({
        organizationId,
        contactId: toDeleteContactId,
      });

      setDone(true);
      enqueueSnackbar(t("pages.contacts.modal.duplicates.successMessage"), {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("pages.contacts.modal.duplicates.errorMessage"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const markAsNonDuplicate = () => {
    OrganizationService.setContactAsNonDuplicateOf(
      duplicate.first,
      duplicate.second,
      organizationId
    )
      .then(() => {
        setMarkedAsNonDuplicate(true);
        enqueueSnackbar(t("pages.contacts.modal.nonDuplicate.successMessage"), {
          variant: "success",
        });
        void nonDuplicateCallback();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(t("pages.contacts.modal.nonDuplicate.errorMessage"), {
          variant: "error",
        });
      });
  };

  const getEntry = (duplicate: Duplicate) => {
    const first = getContact(duplicate.first);
    const second = getContact(duplicate.second);

    if (!first || !second) return null;

    const handlePreviewClick = () => {
      if (!selected) return;

      let merged: ContactDataDTO;

      if (duplicate.first === primaryContactId) {
        merged = merge(
          {},
          getContact(duplicate.second),
          getContact(duplicate.first)
        );
      } else {
        merged = merge(
          {},
          getContact(duplicate.first),
          getContact(duplicate.second)
        );
      }
      setPreviewContact(merged);
    };

    if (markedAsNonDuplicate || done) return null;

    if (previewContact) {
      return (
        <Container>
          <Main>
            <ContactDetails
              data={flatten(previewContact) as ContactDataDTO}
              isPreview
              component={null}
            />
          </Main>
          <Footer>
            <Button
              onClick={() => setPreviewContact(undefined)}
              data-cy="backButton"
              className={classes.cancelButton}
            >
              {t("common.buttons.back")}
            </Button>
            <Button
              onClick={handleSubmit(executeMerge)}
              data-cy="previewButton"
              className={classes.successButton}
              isLoading={loading}
            >
              {t("common.buttons.merge")}
            </Button>
          </Footer>
        </Container>
      );
    }

    return (
      <Form>
        <Main>
          <Controller
            name="primary"
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup {...field} defaultValue={selected}>
                  <ContactDetails
                    isSelected={selected === "primary"}
                    data={flatten(first) as ContactDataDTO}
                    component={
                      <Radio
                        value="primary"
                        onChange={(e) => {
                          setSelected(e.target.value);
                          setPrimaryContactId(first.id);
                        }}
                      />
                    }
                  />
                  <ContactDetails
                    isSelected={selected === "secondary"}
                    data={flatten(second) as ContactDataDTO}
                    component={
                      <Radio
                        value="secondary"
                        onChange={(e) => {
                          setSelected(e.target.value);
                          setPrimaryContactId(second.id);
                        }}
                      />
                    }
                  />
                </RadioGroup>
              );
            }}
          />
        </Main>
        <Footer>
          <NonDuplicateButton
            data-cy="nonDuplicateButton"
            onClick={() => markAsNonDuplicate()}
            className={classes.submitButton}
          >
            {t("common.buttons.nonDuplicate")}
          </NonDuplicateButton>
          <Button
            type="submit"
            data-cy="previewButton"
            className={classes.submitButton}
            onClick={() => handlePreviewClick()}
          >
            {t("common.buttons.preview")}
          </Button>
        </Footer>
      </Form>
    );
  };

  return getEntry(duplicate);
};

export const DuplicatesModal = () => {
  const { t } = useTranslation();
  const { parentTeamId: organizationId, selectedTeamId } = useTeam();
  const { data: contacts } = api.useContactsQuery(
    organizationId,
    selectedTeamId
  );
  const [duplicates, setDuplicates] = useState<Duplicate[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNonDuplicates = async () => {
    if (duplicates === null) {
      setIsLoading(true);
    }

    if (!organizationId) return;

    try {
      const nonDuplicates = await OrganizationService.getNonDuplicates(
        organizationId
      );
      setIsLoading(false);
      return nonDuplicates;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return { data: {} };
    }
  };

  const handleDuplicates = async () => {
    const nonDuplicates = await fetchNonDuplicates();

    if (!nonDuplicates || !nonDuplicates?.data) return;
    if (!contacts) return;

    const foundDuplicates = findDuplicates(contacts, nonDuplicates) ?? [];
    setDuplicates(foundDuplicates);
  };

  useEffect(() => void handleDuplicates(), [contacts]);

  if (isLoading) return <CircularLoading isLoading />;

  const getLastNonDuplicates = () => {
    if (!duplicates || duplicates.length > 1) return;
    void handleDuplicates();
  };

  if (duplicates?.length === 0) {
    return (
      <NoDuplicateMessage>
        <ContactsIcon />
        <span>{t("pages.contacts.modal.duplicates.emptyMessage")}</span>
      </NoDuplicateMessage>
    );
  }

  return (
    <>
      {duplicates?.map((duplicate) => (
        <DuplicateEntry
          key={`${duplicate.first}${duplicate.second}`}
          duplicate={duplicate}
          contacts={contacts ?? []}
          nonDuplicateCallback={getLastNonDuplicates}
        />
      ))}
    </>
  );
};
