import { useTranslation } from "react-i18next";
import { ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import {
  GoogleImage,
  MicrosoftImage,
  OpenIDImage,
} from "pages/LoginPage/components/style";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { BaseIdentityProps } from "./types";
import { useUserInfo } from "hooks/GlobalStateHooks";

export const BaseIdentity = ({
  provider,
  id,
  name,
  onClick,
}: BaseIdentityProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userInfo } = useUserInfo();

  const canClick = userInfo && (!id || userInfo.providerType !== provider);

  return (
    <ListItem sx={{ padding: ".5rem 0" }}>
      <ListItemIcon>
        {(provider === "google" && <GoogleImage />) ||
          (provider === "microsoft" && <MicrosoftImage />) ||
          (provider === "oidc" && <OpenIDImage />)}
      </ListItemIcon>
      <ListItemText>
        {t(`pages.settings.tabs.security.identities.providers.${provider}`, {
          name,
        })}
      </ListItemText>

      {canClick && (
        <Button className={classes.baseButton} onClick={onClick}>
          {id != null
            ? t("pages.settings.tabs.security.identities.disconnect")
            : t("pages.settings.tabs.security.identities.connect")}
        </Button>
      )}
    </ListItem>
  );
};
