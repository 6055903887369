import { useEffect, useMemo, useState } from "react";
import { ContractDTOV1, ContractAttachmentListItemDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { Signature, ContractViewSwitcher, Dropdown } from "./components";
import { Wrapper } from "./styles";
import { CTAButton } from "components";
import { AcceptedFileType } from "shared/enums/document.enum";
import { useContractDetails } from "pages/Contract/context";
import { getTemplateObj } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useFetchDocumentsQuery } from "shared/api";

type Props = {
  contractFile?: Blob | undefined | null;
  //TODO: to be updated from the route documentId
  firstDocumentId?: string;
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  contract: ContractDTOV1;
  editable: boolean;
  contractGroup: ContractAttachmentListItemDTO[];
};

const Toolbar = ({
  contractFile,
  fetchData,
  contract,
  editable,
  contractGroup,
  firstDocumentId,
}: Props) => {
  const { t } = useTranslation();

  const { hasWriteAccess, hasFeature, organizationId, selectedTeamId } =
    useTeam();
  const { createPDFDocument, isLoadingCreatePDFDocument, updateDocument } =
    useContractDetails();

  const [templateId, setTemplateId] = useState("");

  const { data: documents } = useFetchDocumentsQuery(
    organizationId,
    contract?.teamId || selectedTeamId,
    contract?.id
  );
  const hasPdfDocument = useMemo(
    () =>
      documents?.some(
        (document) => document?.mimetype === AcceptedFileType.PDF
      ),
    [documents]
  );

  const isESignatureActive = hasFeature(Features.ESIGNATURE);
  const isRequestSignatureDisabled =
    (!hasPdfDocument && isESignatureActive) ||
    contract?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === "DECLINED" ||
    contract.signature?.status === "SIGNED";
  const showRequestSignature =
    isESignatureActive && contract.signature?.status !== "completed";

  const getTemplateId = async () => {
    if (!contractFile) return;

    const templateData = await getTemplateObj(contractFile);

    if (!templateData) return;

    setTemplateId(templateData.templateId);
  };

  useEffect(() => {
    void getTemplateId();
  }, [contractFile]);

  if (!editable) {
    return (
      <Wrapper>
        {showRequestSignature && hasPdfDocument && (
          <Signature
            hasPDF={hasPdfDocument}
            disabled={isRequestSignatureDisabled}
            fetchData={fetchData}
          />
        )}

        {hasWriteAccess(contract.categoryId, contract.teamId) && (
          <>
            <ContractViewSwitcher />
            <Dropdown contractGroup={contractGroup} />
          </>
        )}
      </Wrapper>
    );
  }

  const refetch = () => {
    void fetchData();
  };
  const isLexicalDocument = contractFile?.type === AcceptedFileType.LEXICAL;

  return (
    <>
      <Wrapper>
        {isLexicalDocument && (
          <>
            <CTAButton
              data-testid="saveDocumentBtn"
              name={t("pages.contractDetails.buttons.saveDraft")}
              onClick={() =>
                void updateDocument({
                  contractId: contract?.id,
                  templateId,
                  organizationId,
                  contractTeamId: contract.teamId,
                  documentId: firstDocumentId,
                })
              }
              variant="secondary"
              size="square"
              iconSize="small"
            />
            <CTAButton
              data-testid="createDocumentBtn"
              name={t("pages.contractDetails.buttons.createPDF")}
              onClick={() =>
                void createPDFDocument({
                  contractId: contract?.id,
                  refetch: refetch,
                  organizationId,
                  contractTeamId: contract.teamId,
                  documentId: firstDocumentId,
                })
              }
              variant="secondary"
              size="square"
              iconSize="small"
              disabled={isLoadingCreatePDFDocument}
            />
          </>
        )}
        <ContractViewSwitcher />
      </Wrapper>
    </>
  );
};

export default Toolbar;
