import { Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import Linkify from "linkify-react";
import { ContractDTOV1, ContractFieldDTOV1, LinkFieldTypeDtoV1 } from "openapi";
import { DataPointProps } from "./types";
import { FormTextField } from "components";
import { useLocale } from "hooks/GlobalStateHooks";
import { useFormContext } from "react-hook-form";
import { useAnalysis } from "./hooks/useAnalysis";
import { AnalysisWrapper } from "./components/AnalysisWrapper";
import { DatapointField } from "./components/DatapointField";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";

export const evaluateLink = (data: LinkFieldTypeDtoV1) => {
  return (
    <Linkify options={{ tagName: Link, target: "_blank" }}>
      {data ? data.value : "-"}
    </Linkify>
  );
};

export const LinkDatapoint = ({
  definition,
  values,
  editable,
  showAnalysis,
  contractId,
  teamId,
  fetchData,
}: DataPointProps) => {
  const { locale } = useLocale();
  const { control } = useFormContext<ContractDTOV1>();

  const name = definition.name[locale];

  const data = values[definition.id] as LinkFieldTypeDtoV1;
  const analysis = useAnalysis(definition, data);

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <AnalysisWrapper
          analysis={showAnalysis ? analysis : undefined}
          fieldKey="value"
          definitionType={ContractFieldDTOV1.type.LINK}
          renderField={(data, selector) => {
            return (
              <FormTextField
                control={control}
                name={`fields.${definition.id}.value`}
                label={definition.name[locale]}
                InputProps={{
                  startAdornment: selector,
                }}
                inputProps={{
                  suggestion: !!data?.selectedSuggestion.value,
                }}
              />
            );
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
        name={name}
      >
        {evaluateLink(data)}
      </DatapointField>
    </Grid>
  );
};
