import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormTextField } from "components";
import { AdminService, TeamListItemDto } from "openapi";

type Props = {
  setData: (data: TeamListItemDto[]) => void;
};

const SearchOrganization = ({ setData }: Props) => {
  const { t } = useTranslation();
  const { control, getValues } = useForm();

  const handleKeyboardDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const search = getValues().search as string;
      const response = await AdminService.findTeams(search);
      setData(response);
    }

    return null;
  };

  return (
    <form
      name="searchOrganizationForm"
      noValidate
      style={{
        paddingTop: "0px",
        paddingBottom: "0px",
        margin: "1rem 0",
        width: "100%",
      }}
    >
      <FormTextField
        control={control}
        margin="none"
        name="search"
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
          handleKeyboardDown(e)
        }
        label={t("pages.admin.tabs.organizations.searchOrganization")}
      />
    </form>
  );
};

export default SearchOrganization;
