import { useState, createElement } from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { ContractDTOV1 } from "openapi";
import { useContractVerificationMutation } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, NewModal } from "components";
import { Info } from "new-components";
import { Wrapper, AiIcon } from "./styles";

type Props = {
  contract?: ContractDTOV1;
  refetch: () => Promise<void>;
};

const VerifyAllBanner = ({ contract, refetch }: Props) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const [isOpen, setIsOpen] = useState(false);
  const contractVerificationMutation = useContractVerificationMutation();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleVerifyAllSubmit = async () => {
    try {
      if (!contract) return;
      if (!contract.unapprovedAnalysisValues) return;

      await contractVerificationMutation.mutateAsync({
        organizationId: organizationId,
        teamId: contract.teamId,
        contractId: contract.id,
      });

      void refetch();

      enqueueSnackbar(
        t("pages.contracts.messages.successfullyVerifiedAllContracts"),
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(t("pages.contracts.messages.failedVerifyAllContracts"), {
        variant: "error",
      });
    }
    setIsOpen(false);
  };

  const text = t("pages.contracts.verifyBanner.text");
  const icon = <AiIcon className="cta" />;
  const verifyAllButton = () => (
    <CTAButton
      label="Verify all"
      name={t("common.buttons.verifyAll")}
      variant="secondary"
      onClick={handleOpen}
    />
  );

  if (!contract?.unapprovedAnalysisValues) return null;

  return (
    <>
      <Wrapper>
        <Info
          className="verify-all-banner"
          text={text}
          element={createElement(verifyAllButton)}
        />
      </Wrapper>

      <NewModal
        open={isOpen}
        handleClose={handleClose}
        fullWidth
        icon={icon}
        title={t("pages.contracts.verifyBanner.modal.title")}
        body={t("pages.contracts.verifyBanner.text")}
        footer={
          <>
            <CTAButton
              label="cancel"
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={handleClose}
            />
            <CTAButton
              label="verifyAll"
              name={t("common.buttons.verifyAll")}
              variant="primary"
              onClick={handleVerifyAllSubmit}
            />
          </>
        }
      />
    </>
  );
};

export default VerifyAllBanner;
