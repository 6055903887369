import { useTranslation } from "react-i18next";
import { CreateTeam } from "containers";
import { NewModal } from "components";

const CreateTeamModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <NewModal
      open={open}
      handleClose={onClose}
      title={t("pages.settings.tabs.subTeams.section.createTeam.title")}
      body={
        <CreateTeam
          variant="sub-team"
          isNewDesign
          onCancel={onClose}
          header={
            <span>
              {t("pages.settings.tabs.subTeams.section.createTeam.subtitle")}
            </span>
          }
        />
      }
      maxWidth="sm"
    />
  );
};

export default CreateTeamModal;
