import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { UserService } from "openapi";
import { setInvalidTokenError } from "shared/service/errorResponseService";
import { Loader } from "components";
import { routePaths } from "../../constants";
import { useUserInfo } from "hooks/GlobalStateHooks";

const InvitationVerification = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { refreshUserInfo } = useUserInfo();
  const { enqueueSnackbar } = useSnackbar();
  const invitationToken = new URLSearchParams(search).get("invitationToken");

  function acceptInvitation(invitationToken: string) {
    UserService.acceptInvitation(invitationToken)
      .then(({ teamName }) => {
        enqueueSnackbar(t("acceptInvitation.successMessage", { teamName }), {
          variant: "success",
        });
      })
      .catch((error) => {
        setInvalidTokenError(error, "acceptInvitation", enqueueSnackbar, t);
      })
      .finally(() => {
        void refreshUserInfo().then(() => {
          navigate(routePaths.HOME);
        });
      });
  }

  useEffect(() => {
    if (!invitationToken) {
      void refreshUserInfo();
      navigate(routePaths.HOME);
      return;
    }

    acceptInvitation(invitationToken);
  }, []);

  return <Loader />;
};

export default InvitationVerification;
