import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IconButton, InputAdornment, Select } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { SearchControl, PaperStyles } from "components/EntityGrid/styles";
import { SEARCH_TYPES, useFullTextSearch } from "hooks/useFullTextSearch";
import ListIcon from "assets/svg/list-icon.svg?react";
import OCRIcon from "assets/svg/scan-text.svg?react";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import IconWrapper from "new-components/IconWrapper/IconWrapper";
import { MenuItem, StyledFormTextField } from "./styles";
import { retrieveTemporarySearch } from "contexts/grid/storage";
import { ViewItemDTO } from "openapi";
import { useLocation } from "react-router";

export type SearchBarOnChangeValue =
  ChangeEvent<HTMLInputElement>["target"]["value"];

export type SearchBarProps = {
  onChange: (value: SearchBarOnChangeValue) => void;
};

const pathMapping = {
  contracts: ViewItemDTO.type.CONTRACT,
  tasks: ViewItemDTO.type.TASK,
  contacts: ViewItemDTO.type.CONTACT,
};

const SearchBar = ({ onChange }: SearchBarProps) => {
  const { control } = useForm();
  const { t } = useTranslation();
  const {
    handleFullTextSearchQueryChange,
    handleSearchTypeChange,
    searchType,
  } = useFullTextSearch();
  const { hasFeature } = useTeam();
  const showSearchSwitcher = hasFeature(Features.FULL_TEXT_SEARCH);

  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const [search, setSearch] = useState(
    retrieveTemporarySearch(pathMapping[path as keyof typeof pathMapping])
  );

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchType === SEARCH_TYPES.TEXT) {
        handleFullTextSearchQueryChange(search);
      }
    }, 350);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  const clearSearch = () => {
    setSearch("");
    onChange("");
  };

  return (
    <StyledFormTextField
      $showSearchSwitcher={showSearchSwitcher}
      name="search"
      control={control}
      label={t("common.components.search.placeholder")}
      margin="none"
      InputProps={{
        value: search,
        onInput: (e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
          setSearch(e.target.value);
        },
        startAdornment: (
          <>
            <InputAdornment position="start">
              <SearchOutlinedIcon fontSize="large" />
            </InputAdornment>
          </>
        ),
        endAdornment: (
          <>
            {search !== "" && (
              <IconButton onClick={clearSearch} aria-label="clear">
                <ClearIcon />
              </IconButton>
            )}
            {showSearchSwitcher && (
              <SearchControl id="searchTypeSwitch">
                <Select
                  aria-label="search-type-select"
                  value={searchType}
                  onChange={handleSearchTypeChange}
                  size="small"
                  MenuProps={{
                    PaperProps: {
                      sx: PaperStyles,
                    },
                  }}
                >
                  <MenuItem value={SEARCH_TYPES.LIST}>
                    <IconWrapper size={16}>
                      <ListIcon />
                    </IconWrapper>
                    <span>{t("pages.contracts.SEARCH_TYPES.LIST")}</span>
                  </MenuItem>
                  <MenuItem value={SEARCH_TYPES.TEXT}>
                    <IconWrapper size={20}>
                      <OCRIcon />
                    </IconWrapper>
                    <span>{t("pages.contracts.SEARCH_TYPES.TEXT")}</span>
                  </MenuItem>
                </Select>
              </SearchControl>
            )}
          </>
        ),
      }}
    />
  );
};

export default SearchBar;
