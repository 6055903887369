import { ICellRendererParams } from "ag-grid-community";
import { ContractDTOV1, ContractFieldDTOV1 } from "../../../openapi";
import { AnalysisCellRendererWrapper } from "./AnalysisCellRendererWrapper";
import useDataPointAnalysis from "components/Datapoints/hooks/useDatapointAnalysis";
import { isUUID } from "pages/Contract/helpers";
import { parsePaymentCycle, parsePaymentType } from "@contracthero/common";
import { useTranslation } from "react-i18next";

const specialParsersByVisibleId = {
  paymentCycle: parsePaymentCycle,
  paymentType: parsePaymentType,
} as const;

export const ListCellRenderer = (
  props: ICellRendererParams<ContractDTOV1, unknown> & {
    definition: ContractFieldDTOV1;
  }
) => {
  const { t } = useTranslation();
  const fieldData = props.data?.fields[props.definition.id];
  const { suggestions, selectInsight } = useDataPointAnalysis(
    props.definition,
    props.data as ContractDTOV1,
    fieldData,
    props.definition?.visibleId &&
      props.definition.visibleId in specialParsersByVisibleId
      ? {
          value: {
            parser:
              specialParsersByVisibleId?.[
                props.definition
                  .visibleId as keyof typeof specialParsersByVisibleId
              ],
            displayParser: (value: string) => {
              return t(
                `enums.${props.definition?.visibleId ?? ""}.${
                  specialParsersByVisibleId?.[
                    props.definition
                      .visibleId as keyof typeof specialParsersByVisibleId
                  ](value) ?? ""
                }`
              );
            },
          },
        }
      : undefined
  );

  const path = props.colDef?.field?.split(".") ?? [];
  const fieldKey = isUUID(path.at(-1) ?? "") ? "value" : path.at(-1) ?? "";

  return (
    <AnalysisCellRendererWrapper
      {...props}
      field={fieldKey}
      suggestions={suggestions}
      onSelectInsight={(value) => {
        void selectInsight(fieldKey as never, value);
      }}
    >
      <span>{props.valueFormatted}</span>
    </AnalysisCellRendererWrapper>
  );
};
