import { NavLink as Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Divider from "./Divider";
import { Button, Icon, Label } from "../styles";

export type Props = {
  id: string;
  to: string;
  icon: JSX.Element;
  label: string;
  replace?: boolean;
  divider?: string;
  showLabel?: boolean;
  showTooltip?: boolean;
  onClick?: () => void;
};

const LinkButton = ({
  id,
  showLabel,
  icon,
  onClick,
  to,
  replace,
  label,
  divider,
  showTooltip,
  ...props
}: Props) => {
  return (
    <Link to={to} replace={replace} {...props} data-testid="link-button">
      {(linkProps) => {
        const active = linkProps.isActive.toString();
        return (
          <Tooltip title={showTooltip ? label : ""} placement="right" arrow>
            <div>
              {divider === "top" && <Divider />}
              <Button active={active} onClick={onClick}>
                <Icon active={active}>{icon}</Icon>
                <Label
                  active={active}
                  visible={showLabel}
                  data-testid="link-button-label"
                >
                  {label}
                </Label>
              </Button>
              {divider === "bottom" && <Divider />}
            </div>
          </Tooltip>
        );
      }}
    </Link>
  );
};

export default LinkButton;
