import { useTranslation } from "react-i18next";
import { CTAButton, NewModal } from "components";
import { ContractSectionDTOV1 } from "openapi";
import { Box, DialogActions } from "@mui/material";
import { useLocale } from "hooks/GlobalStateHooks";
import theme from "theme/theme";
import {
  AlertIconWrapper,
  AlertText,
  AlertWrapper,
} from "pages/Settings/OrganizationSettings/Team/styles";
import AlarmIcon from "assets/svg/alarm-icon.svg?react";

type Props = {
  open: boolean;
  handleOnClose: () => void;
  onRemoveSection: (id: string) => Promise<void>;
  section: ContractSectionDTOV1;
  sections: ContractSectionDTOV1[];
  setSections: (value: ContractSectionDTOV1[]) => void;
};

const DeleteSection = ({
  open,
  handleOnClose,
  onRemoveSection,
  section,
  sections,
  setSections,
}: Props) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  const handleOnClick = async () => {
    const updatedSections = sections.filter(
      (item: { id: string }) => item.id !== section.id
    );
    setSections(updatedSections);
    handleOnClose();
    await onRemoveSection(section.id);
  };

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t(
        "pages.settings.organization.administrator.sections.modal.delete.title",
        { sectionName: section.name[locale] }
      )}
      fullWidth
      body={
        <div>
          {/* @Todo make new component (used in delete modals: Team, Category) */}
          <Box
            className="flex-align-center"
            sx={{ marginBottom: theme.spacing.lg }}
          >
            <Box>
              <AlertWrapper>
                <AlertIconWrapper>
                  <AlarmIcon />
                </AlertIconWrapper>
                <AlertText>
                  <h3>
                    <span>
                      {t(
                        "pages.settings.organization.administrator.sections.modal.delete.description",
                        { sectionName: section.name[locale] }
                      )}
                    </span>
                  </h3>
                </AlertText>
              </AlertWrapper>
            </Box>
          </Box>
          <DialogActions sx={{ marginTop: theme.spacing.xxl }}>
            <CTAButton
              type="reset"
              variant="secondary"
              label="cancelRemoveCategory"
              onClick={handleOnClose}
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              variant="primary"
              type="submit"
              label="deleteSectionButton"
              name={t("common.buttons.delete")}
              onClick={() => handleOnClick()}
              color="danger"
            />
          </DialogActions>
        </div>
      }
    />
  );
};

export default DeleteSection;
