import styled from "@emotion/styled";
import {
  Button as MUIButton,
  Select as MUISelect,
  MenuItem as MUIMenuItem,
} from "@mui/material";
import { theme } from "theme";

export const TitleWrapper = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Select = styled(MUISelect)`
  color: ${theme.color.gray[700]};
  height: fit-content;
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.medium};
  text-align: center;
  text-transform: capitalize;
  background-color: ${theme.color.gray[100]};

  ${({ status }: MenuItemProps) =>
    status === "open" &&
    `
    color: ${theme.color.gray[700]};
    background-color: ${theme.color.gray[400]};
  `}

  ${({ status }: MenuItemProps) =>
    status === "in progress" &&
    `
    color: ${theme.color.gray[700]};
    background-color: ${theme.color.orange[400]};
  `}

  ${({ status }: MenuItemProps) =>
    status === "closed" &&
    `
    color: ${theme.color.gray[700]};
    background-color: ${theme.color.green[400]};
  `}
`;

type MenuItemProps = {
  status: string;
};

export const MenuItem = styled(MUIMenuItem)<MenuItemProps>`
  font-size: ${theme.font.size.body};
  padding-left: ${theme.spacing.xxl};

  :before {
    content: "";
    position: absolute;
    left: 12px;
    width: 12px;
    height: 12px;
    border-radius: 4px;

    ${({ status }) =>
      status === "open" &&
      `
      background-color: ${theme.color.gray[400]};
    `}

    ${({ status }) =>
      status === "in progress" &&
      `
      background-color: ${theme.color.orange[400]};
    `}

    ${({ status }) =>
      status === "closed" &&
      `
      background-color: ${theme.color.green[400]};
    `}
  }
`;

export const MenuButton = styled(MUIButton)`
  display: flex;
  color: ${theme.color.gray[700]};
  gap: ${theme.spacing.md};
  font-size: ${theme.font.size.caption};
  height: fit-content;
  border: 1px solid ${theme.color.gray[500]};
  background-color: ${theme.color.gray[100]};

  :focus,
  :hover {
    border: 1px solid ${theme.color.gray[900]};
  }

  svg {
    font-size: ${theme.font.size.h6};
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
  width: 100%;
  justify-content: ${({ isTaskCreate }: { isTaskCreate?: boolean }) =>
    !isTaskCreate ? `space-between;` : `flex-end;`};
`;

export const FieldTitle = styled.h4`
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
  margin-bottom: 0;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const FieldDescription = styled.div`
  margin-top: ${theme.spacing.sm};
`;

export const TooltipRow = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  svg {
    width: 14px;
    height: 14px;
  }
`;
