import React from "react";
import Tabs from "./components/Tabs/Tabs";
import { SectionHeader } from "components";
import IntegrationsIcon from "assets/svg/dataflow-03.svg?react";
import { Card, HeaderIconStyles, Wrapper } from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { routePaths } from "constants/";
import { TAB_KEYS } from "./types";

const Integrations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab = TAB_KEYS.APPS } = useParams();

  const onTabChange = (event: React.SyntheticEvent, key: string) => {
    if (key !== tab) {
      navigate(`${routePaths.SETTINGS_INTEGRATIONS}/${key}`);
    }
  };

  const handleOnWebhookCreate = () =>
    navigate(`${routePaths.SETTINGS_WEBHOOK}/new`);

  const getHeaderButtonData = () => {
    if (!tab) return null;

    switch (tab) {
      case TAB_KEYS.WEBHOOKS:
        return {
          baseButtonText: t(
            "pages.settings.organization.webhooks.header.main.addButton"
          ),
          buttonId: "webhook-add-button",
          onButtonClick: handleOnWebhookCreate,
        };
      default:
        return null;
    }
  };

  const buttonData = getHeaderButtonData();

  return (
    <Card>
      <Wrapper>
        <SectionHeader
          title={t("pages.settings.organization.integrations.title")}
          icon={<IntegrationsIcon />}
          iconStyle={HeaderIconStyles}
          noPadding
          hideButton={!buttonData}
          buttonVariant="primary"
          baseButtonText={buttonData?.baseButtonText}
          buttonId={buttonData?.buttonId}
          onButtonClick={buttonData?.onButtonClick}
        />
        <div>{t("pages.settings.organization.integrations.description")}</div>
        <Tabs
          onTabChange={(e: React.SyntheticEvent, key: string) =>
            onTabChange(e, key)
          }
          selectedTabKey={tab}
        />
      </Wrapper>
    </Card>
  );
};

export default Integrations;
