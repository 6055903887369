import styled from "@emotion/styled";
import { theme } from "theme";
import Bin from "assets/svg/bin-icon.svg?react";

export const LinkAddress = styled.span`
  color: ${theme.color.gray[600]};
`;

export const Empty = styled.div`
  color: ${theme.color.gray[600]};
`;

export const TableRow = styled.tr`
  .name {
    color: ${theme.color.gray[700]};
    transition: color 0.25s ease-in-out;
  }

  :hover .name {
    color: ${theme.color.indigo[600]};
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  :hover svg {
    opacity: 1;
  }
`;

export const BinIcon = styled(Bin)`
  fill: ${theme.color.red[700]};
`;
