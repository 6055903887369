import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { BasicTableProps, Header, OrderType, Row } from "./types";
import {
  OrderIndicator,
  DefaultTable,
  DefaultTableCell,
  DefaultTableHead,
  DefaultTableHeadCell,
  DefaultTableRow,
  TableHeadCellRow,
  TableHeadCellText,
} from "./styles";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { dateFormats } from "constants/utils";

type Children = {
  children: React.ReactNode;
};

const renderDate = (
  value: string,
  type: string,
  locale: string,
  userDateFormat: string
) => {
  if (!dayjs(value).isValid()) return "-";
  if (type === "date") return dayjs(value).format(userDateFormat);
  if (type === "datetime")
    return dayjs(value).locale(locale).format(`${userDateFormat} HH:mm:ss`);
};

const StandardTable = (props: Children) => <DefaultTable {...props} />;
const StandardTableHead = (props: Children) => <DefaultTableHead {...props} />;
const StandardTableHeadCell = (props: Children) => (
  <DefaultTableHeadCell {...props} />
);
const StandardTableRow = (props: Children) => <DefaultTableRow {...props} />;
const StandardTableCell = (props: Children) => <DefaultTableCell {...props} />;

const BasicTable = ({
  id,
  headers,
  data,
  isLastCellStyled,
  defaultSort,
  disableDefaultStyles,
  components = {},
  sortable = true,
}: BasicTableProps) => {
  const { locale } = useLocale();
  const [order, setOrder] = useState<OrderType>(defaultSort);
  const [sortedData, setSortedData] = useState<Row[]>(data);
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  useEffect(() => {
    sortByOrder(order);
  }, [data]);

  const sortByOrder = (order: OrderType) => {
    if (!order) {
      setSortedData(data);
      return;
    }
    data.sort((a: Row, b: Row) => {
      let aValue = a[order.column] || 0;
      if (typeof aValue === "object" && aValue.props) {
        const props = aValue.props as { "data-order-key": string };
        if (props["data-order-key"]) {
          aValue = props["data-order-key"];
        }
      }
      let bValue = b[order.column] || 0;
      if (typeof bValue === "object" && bValue.props) {
        const props = bValue.props as { "data-order-key": string };
        if (props["data-order-key"]) {
          bValue = props["data-order-key"];
        }
      }
      if (aValue < bValue) {
        return -1 * order.direction;
      }
      if (aValue > bValue) {
        return 1 * order.direction;
      }
      return 0;
    });
    setSortedData(data);
  };

  const sort = (key: string) => {
    if (headers.find((header) => header.key === key)?.notSortable) {
      return;
    }
    let direction = 1;
    if (order && order.column === key) {
      direction = order.direction *= -1;
    }
    const orderNew = { column: key, direction: direction };
    setOrder(orderNew);
    sortByOrder(orderNew);
  };

  const formatValue = (
    header: Header,
    value: string | number | boolean | JSX.Element | null | undefined
  ) => {
    if (value === undefined) {
      return "";
    }
    if (!header.type) {
      return value;
    }
    if (header.type === "date") {
      return renderDate(value as string, "date", locale, userDateFormat);
    }
    if (header.type === "datetime") {
      return renderDate(value as string, "datetime", locale, userDateFormat);
    }
    if (header.type === "price") {
      // The price is stored in cents
      const quotient = (value as number) / 100;
      const result = quotient.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return `${result}  €`;
    }
  };

  const {
    Table = StandardTable,
    TableHead = StandardTableHead,
    TableRow = StandardTableRow,
    TableHeadCell = StandardTableHeadCell,
    TableCell = StandardTableCell,
  } = components;

  return (
    <OverlayScrollbarsComponent
      defer
      style={{
        overflowX: "auto",
        padding: "0 1px", // please do not remove, needed for horizontal borders
      }}
    >
      <Table id={id} data-cy={id} disableDefaultStyles={disableDefaultStyles}>
        <TableHead disableDefaultStyles={disableDefaultStyles}>
          {headers.map((header: Header, index: number) => {
            return (
              <TableHeadCell
                disableDefaultStyles={disableDefaultStyles}
                key={index}
                onClick={() => {
                  if (!sortable) return;
                  sort(header.key);
                }}
              >
                <TableHeadCellRow>
                  <TableHeadCellText>{header.name}</TableHeadCellText>
                  {header.tooltipText ? (
                    <InfoTooltip>{header.tooltipText}</InfoTooltip>
                  ) : null}

                  {sortable && (
                    <OrderIndicator>
                      {order?.direction && order?.column === header.key && (
                        <span>{order?.direction > 0 ? "▲" : "▼"}</span>
                      )}
                    </OrderIndicator>
                  )}
                </TableHeadCellRow>
              </TableHeadCell>
            );
          })}
        </TableHead>
        <tbody>
          {sortedData.map((row: Row, rowIndex: number) => {
            return (
              <TableRow
                key={rowIndex}
                disableDefaultStyles={disableDefaultStyles}
              >
                {headers.map((header: Header, colIndex: number) => {
                  const isLastCell = colIndex === headers.length - 1;
                  return (
                    <TableCell
                      key={`${rowIndex}${colIndex}`}
                      isLastCellStyled={isLastCell && isLastCellStyled}
                      disableDefaultStyles={disableDefaultStyles}
                    >
                      {formatValue(header, row[header.key])}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </OverlayScrollbarsComponent>
  );
};

export default BasicTable;
