import { useRef, useEffect } from "react";

export function usePreviousState(value: number) {
  const ref = useRef<number>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
