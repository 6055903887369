import { useState } from "react";
import { useTranslation } from "react-i18next";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import { useTeam } from "contexts/team/hooks";
import { TeamPermissionInfoDto } from "openapi";
import { useOrganizationTeamsQuery } from "shared/api";
import { CTAButton, NewModal } from "components";
import { EntityGridViewSelector } from "components/EntityGrid/EntityGridViewSelector";
import { EntityGridFilterResetButton } from "components/EntityGrid/EntityGridFilterResetButton";
import { EntityGridSearchInput } from "components/EntityGrid/EntityGridSearchInput";
import { DuplicatesModal } from "../DuplicatesModal/DuplicatesModal";
import { Wrapper, ActionButtons, AddIcon } from "./styles";

type Props = {
  onCreateNewContact: () => void;
};

const Header = ({ onCreateNewContact }: Props) => {
  const { t } = useTranslation();
  const { parentTeamId: organizationId, isOwner, selectedTeam } = useTeam();

  const { data: organization } = useOrganizationTeamsQuery(organizationId);

  const organizationTeam = organization?.teams.find(
    (t) => t.id === organization.id
  );

  const organizationRole = organizationTeam?.permissions?.role;
  const [showDuplicatesModal, setShowDuplicatesModal] = useState(false);

  const hasMergePermissions = isOwner() && !selectedTeam?.isSubTeam;

  return (
    <Wrapper>
      <EntityGridSearchInput
        data-cy="searchContacts"
        label={t("pages.contacts.header.searchContacts")}
      />

      <ActionButtons>
        <EntityGridFilterResetButton />
        <EntityGridViewSelector />

        {hasMergePermissions && (
          <CTAButton
            data-cy="findDuplicatesButton"
            onClick={() => setShowDuplicatesModal(true)}
            name={t("pages.contacts.header.mergeDuplicates")}
            iconSize="medium"
            variant="secondary"
          />
        )}

        {organizationRole === TeamPermissionInfoDto.role.OWNER ||
        organizationRole === TeamPermissionInfoDto.role.USER ? (
          <CTAButton
            data-cy="createNewContactButton"
            onClick={onCreateNewContact}
            name={t("pages.contacts.header.newContact")}
            icon={<AddIcon fontSize="small" />}
            iconSize="medium"
          />
        ) : null}
      </ActionButtons>

      {hasMergePermissions && (
        <NewModal
          open={showDuplicatesModal}
          handleClose={() => setShowDuplicatesModal(false)}
          icon={<CallMergeIcon />}
          title={t("pages.contacts.modal.duplicates.title")}
          body={<DuplicatesModal />}
          fullWidth
          maxWidth="lg"
        />
      )}
    </Wrapper>
  );
};

export default Header;
