import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import { getTrialDaysLeft } from "./helpers";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { MinimalAlert } from "./styles";
import { Tooltip } from "@mui/material";

const TrialAlert = ({ minimal }: { minimal?: boolean }) => {
  const { t } = useTranslation();
  const { selectedTeam } = useTeam();
  const trialDays = getTrialDaysLeft(selectedTeam?.planTrialEndDate);
  const isVisible =
    trialDays !== undefined &&
    selectedTeam?.planStatus === "in_trial" &&
    !selectedTeam.isSubTeam;

  if (!isVisible) return null;

  return (
    <div style={{ marginBottom: "1rem" }}>
      {minimal ? (
        <Tooltip
          title={t("pages.settings.tabs.payment.trialPeriod", {
            number: trialDays,
          })}
          placement="right"
          arrow
        >
          <MinimalAlert>
            <InfoIcon fontSize="small" sx={{ color: theme.color.blue[600] }} />
          </MinimalAlert>
        </Tooltip>
      ) : (
        <Alert severity="info">
          {t("pages.settings.tabs.payment.trialPeriod", { number: trialDays })}
        </Alert>
      )}
    </div>
  );
};

export default TrialAlert;
