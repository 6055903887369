import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import {
  useAuthentication,
  useLocale,
  useUserInfo,
} from "hooks/GlobalStateHooks";
import { AdminService, UserDetailsDto } from "openapi";
import BasicTable from "components/BasicTable/BasicTable";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { dateFormatter } from "constants/utils";
import { Header, Row } from "components/BasicTable/types";

const UserDataTypeTitle = styled.span`
  font-weight: bolder;
  color: #1a202c;
  font-size: 0.92rem;
`;

const WarningMessageTitle = styled(Box)`
  color: red;
  font-size: 0.8rem;
`;

const UserImpersonate = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { id } = useParams();
  const { impersonate } = useAuthentication();
  const classes = useStyles();
  const [userData, setUserData] = useState<UserDetailsDto>();
  const [data, setData] = useState<Row[]>([]);
  const [isUserUnverified, setIsUserUnverified] = useState<boolean>(false);
  const [isUserTeamless, setIsUserTeamless] = useState<boolean>(false);
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const fetchData = async () => {
    if (id) {
      setUserData(await AdminService.getUserDetails(id));
    }
  };

  useEffect(() => void fetchData(), []);

  useEffect(() => {
    if (userData) {
      const translationPrefix = "pages.settings.tabs.team.roleOptions.";
      const data = userData.teams.map((team) => {
        return {
          teamName: team.teamName,
          role: t(`${translationPrefix}${team.role}`),
        };
      });
      setData(data);
      setIsUserUnverified(!userData.emailVerified);
      if (userData.teams.length < 0) setIsUserTeamless(true);
    }
  }, [userData]);

  const headers: Header[] = [
    {
      key: "teamName",
      name: t(
        "pages.admin.tabs.users.impersonatePage.tableColumns.name"
      ).toString(),
    },
    {
      key: "role",
      name: t(
        "pages.admin.tabs.users.impersonatePage.tableColumns.role"
      ).toString(),
    },
  ];

  const onSubmit = async () => {
    if (id) return await impersonate(id);
  };

  return (
    <>
      <CardWrapper>
        <>
          <CardHeaderWrapper title={userData?.name ?? ""} />
          <Box>
            <UserDataTypeTitle>
              {`${t("pages.admin.tabs.users.impersonatePage.email")}: `}
            </UserDataTypeTitle>
            {userData?.email}
          </Box>
          <Box>
            <UserDataTypeTitle>
              {`${t("pages.admin.tabs.users.impersonatePage.joined")}: `}
            </UserDataTypeTitle>
            {dateFormatter(locale, userData?.createdAt, userDateFormat)}
          </Box>
          <Button
            size="large"
            className={classes.baseButton}
            disabled={isUserUnverified || isUserTeamless}
            onClick={onSubmit}
          >
            {t("pages.admin.tabs.users.impersonatePage.impersonateButton")}
          </Button>
          {isUserUnverified && (
            <WarningMessageTitle>
              {t(
                "pages.admin.tabs.users.impersonatePage.unverifiedUserWarningMessage"
              )}
            </WarningMessageTitle>
          )}
          {!isUserUnverified && isUserTeamless && (
            <WarningMessageTitle>
              {t(
                "pages.admin.tabs.users.impersonatePage.userWithoutTeamWarningMessage"
              )}
            </WarningMessageTitle>
          )}
          <BasicTable headers={headers} data={data} />
        </>
      </CardWrapper>
    </>
  );
};

export default UserImpersonate;
