import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CategoryDTO, ContractTemplateV1DTO } from "openapi";
import { useLocale } from "hooks";
import { getContrastColor } from "utils/color";
import { enqueueSnackbar } from "notistack";
import { useContractTemplateDeleteMutationV1 } from "shared/api/contract-templates";
import { useTeam } from "contexts/team/hooks";
import { CTAButton, NewModal } from "components";
import { Tag } from "new-components";
import { WarningIcon } from "components/Icons";
import routePaths from "constants/routePaths";
import { BodyWrapper, TemplateIcon, TemplateName, Title } from "./styles";

type DeleteTemplateProps = {
  open: boolean;
  onClose: () => void;
  currentCategory?: CategoryDTO;
  data?: ContractTemplateV1DTO;
};

const DeleteTemplate = ({
  open,
  onClose,
  currentCategory,
  data,
}: DeleteTemplateProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { organizationId } = useTeam();
  const deleteTemplate = useContractTemplateDeleteMutationV1();
  const navigate = useNavigate();

  const onDelete = async () => {
    if (!data) return;

    try {
      await deleteTemplate.mutateAsync({
        organizationId: organizationId,
        templateId: data?.id,
      });

      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.snackbars.deleteSuccess"),
        {
          variant: "success",
        }
      );

      onClose();
      navigate(routePaths.SETTINGS_CONTRACT_TEMPLATES);
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.snackbars.deleteFailure"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <NewModal
      open={open}
      handleClose={onClose}
      title={t(
        "pages.settings.organization.contractTemplates.modals.delete.title"
      )}
      fullWidth
      icon={<WarningIcon />}
      body={
        <>
          <BodyWrapper>
            <Title>
              <TemplateIcon />
              <TemplateName>{data?.name}</TemplateName>
            </Title>

            {currentCategory && (
              <Tag
                variant="custom"
                backgroundColor={currentCategory.color}
                color={getContrastColor(currentCategory.color)}
              >
                {currentCategory.name[locale]}
              </Tag>
            )}
          </BodyWrapper>
          <p>
            {t(
              "pages.settings.organization.contractTemplates.modals.delete.description"
            )}
          </p>
        </>
      }
      footer={
        <>
          <CTAButton
            variant="secondary"
            onClick={onClose}
            name={t("common.buttons.cancel")}
          />
          <CTAButton
            type="submit"
            variant="primary"
            color="danger"
            onClick={onDelete}
            name={t("common.buttons.delete")}
          />
        </>
      }
    />
  );
};

export default DeleteTemplate;
