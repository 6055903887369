import { useTranslation } from "react-i18next";
import { ListWrapper, Wrapper } from "./styles";

import { InvitationLogDTO, RoleDTO, TeamDTOV1 } from "openapi";
import { Role } from "components";
import { getNameWithSuffix } from "pages/Settings/OrganizationSettings/Organization/helper";

export type InvitationInititalType = {
  data: InvitationLogDTO;
  roles: RoleDTO[];
  teams: TeamDTOV1[];
};

const InvitationInitital = ({ data, roles, teams }: InvitationInititalType) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {data.email}

      {!!data.roles.length && (
        <ListWrapper>
          {data.roles.map((roleItem) => {
            const role = roles.find((item) => item.id === roleItem.id);

            const roleName = role
              ? getNameWithSuffix(role.name, role.teamId, teams, t)
              : t("common.labels.deletedRole");

            return <Role key={roleItem.id} name={roleName} />;
          })}
        </ListWrapper>
      )}
    </Wrapper>
  );
};

export default InvitationInitital;
