import { FormTextField } from "components";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { FTPSBackupConfig } from "openapi";
import { PasswordField } from "new-components";

const BackupFormFTPS = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<FTPSBackupConfig>();

  return (
    <>
      <FormTextField
        control={control}
        name="hostname"
        label={t("pages.settings.tabs.backup.ftps.form.hostname")}
        required
      />
      <FormTextField
        control={control}
        name="port"
        defaultValue={21}
        label={t("pages.settings.tabs.backup.ftps.form.port")}
        required
      />
      <FormTextField
        control={control}
        name="username"
        label={t("pages.settings.tabs.backup.ftps.form.username")}
        required
      />
      <PasswordField
        control={control}
        name="ftpsPassword"
        label={t("pages.settings.tabs.backup.ftps.form.password")}
        required
      />
      <FormTextField
        control={control}
        name="path"
        label={t("pages.settings.tabs.backup.ftps.form.path")}
        required
      />
    </>
  );
};

export default BackupFormFTPS;
