import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ContractFieldDTOV1,
  ContractFieldPromptTestDTO,
  I18nDto,
  OrganizationNewService,
} from "openapi";
import Tabs from "./components/Tabs/Tabs";
import ContractSelector from "../ContractSelector/ContractSelector";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import {
  Heading,
  HeadingIcon,
  HeadingText,
  TextArea,
  Wrapper,
  Button,
  LoaderContainer,
  Overlay,
  GradientTextAnimated,
  Results,
} from "./styles";
import ContractIcon from "assets/svg/contract-icon.svg?react";
// import StopIcon from "assets/svg/stop-circle.svg?react";
// import Suggestions from "./components/Suggestions/Suggestions";
import Rating from "./components/Rating/Rating";
import Result from "./components/Result/Result";
import { CircularProgress } from "@mui/material";
import GradientHeading from "./components/GradientHeading/GradientHeading";
import { useOCRedContractsQuery } from "shared/api/contracts";

type Props = {
  fieldType: string;
  editMode?: boolean;
  fieldName: I18nDto;
  promptTestIsLoading?: boolean;
  setPromptTestIsLoading: (promptTestIsLoading: boolean) => void;
};

type promptTestResult = {
  value?: string | null;
  snippet?: string | null;
  clarity?: number;
};

const Prompt = ({
  fieldType,
  editMode,
  fieldName,
  promptTestIsLoading,
  setPromptTestIsLoading,
}: Props) => {
  const { t } = useTranslation();
  const amountTabs = [
    {
      name: t("customFields.typeOptions.AMOUNT"),
      key: "prompt.value",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.currency"),
      key: "prompt.currency",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
  ];

  const durationTabs = [
    {
      name: t("pages.contracts.tableColumns.startDate"),
      key: "prompt.startDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.endDate"),
      key: "prompt.endDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.durationInMonth"),
      key: "prompt.interval",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.noticePeriodInMonths"),
      key: "prompt.noticePeriod",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.automaticRenewal"),
      key: "prompt.automaticRenewal",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.terminationDate"),
      key: "prompt.terminationDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
      ),
    },
  ];

  const getTabNameByIndex = (tabIndex: number) => {
    if (
      fieldType !== ContractFieldDTOV1.type.AMOUNT &&
      fieldType !== ContractFieldDTOV1.type.DURATION
    )
      return "";

    const tabList =
      fieldType === ContractFieldDTOV1.type.AMOUNT ? amountTabs : durationTabs;
    return tabList[tabIndex].key.split(`prompt.`)[1];
  };

  const { control, setValue, watch, getValues } = useFormContext();
  const [testInContract, setTestInContract] = useState(false);
  const [selectedTab, setSelectedTab] = useState(getTabNameByIndex(0));
  const { selectedTeamId, hasFeature, organizationId } = useTeam();
  const [aiResult, setAiResult] = useState<
    Record<string, promptTestResult | promptTestResult[]>
  >({});
  const [noAiResultFound, setNoAiResultFound] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState<string>("");
  const { data: contracts } = useOCRedContractsQuery(selectedTeamId);

  const textAreaName = "prompt.value";
  const promptValue = watch(textAreaName) as string;

  const resetResult = () => {
    setAiResult({});
    setNoAiResultFound(false);
  };

  useEffect(() => {
    if (!editMode) {
      setValue(textAreaName, "");
    }
  }, [fieldType]);

  const onContractChange = (e: { key: string; value: string }) => {
    const contractId = e.key;
    setValue("contractId", contractId);
    setSelectedContractId(contractId);
  };

  const getPromptData = () => {
    switch (fieldType) {
      case ContractFieldDTOV1.type.AMOUNT:
      case ContractFieldDTOV1.type.DURATION:
        return getValues("prompt") as Record<string, string>;
      default:
        return { value: promptValue };
    }
  };

  const onPromptSubmit = async () => {
    if (!selectedContractId || !fieldType) return;
    resetResult();

    const promptData = getPromptData();

    const requestBody = {
      type: fieldType as ContractFieldDTOV1.type,
      name: { ...fieldName },
      prompt: promptData,
      contractId: selectedContractId,
    } satisfies ContractFieldPromptTestDTO;

    try {
      setPromptTestIsLoading(true);
      const response =
        (await OrganizationNewService.testPromptMessageForContract(
          organizationId,
          requestBody
        )) as Record<string, promptTestResult>[];

      const result:
        | Record<string, promptTestResult>
        | Record<string, promptTestResult[]> = response[0];

      //@Todo Temporary handling both result types object and array of objects. Only one will remain late - array. tech-debt
      const hasResult = Object.values(result).some((item) => {
        return Array.isArray(item)
          ? (item as promptTestResult[])[0]?.value ||
              (item as promptTestResult[])[0]?.snippet
          : item?.value || item?.snippet;
      });

      if (hasResult) {
        //@Todo Temporory solution - as above. Should be replaces by array only. tech-debt
        const sanitisedResult = Object.entries(result).reduce(
          (acc, [key, value]) => {
            if (!value) return { ...acc, [key]: null };

            return {
              ...acc,
              [key]: Array.isArray(value) ? value : [value],
            };
          },
          {}
        );
        setAiResult(sanitisedResult);
      } else {
        setNoAiResultFound(true);
      }
    } catch (error: unknown) {
      // Replace with Sentry before deploy
      console.log(error);
    } finally {
      setPromptTestIsLoading(false);
    }
  };

  const handleTabChange = (tabIndex: number) => {
    const tab = getTabNameByIndex(tabIndex);
    setSelectedTab(tab);
  };

  const renderContentByFieldType = () => {
    switch (fieldType) {
      case ContractFieldDTOV1.type.AMOUNT:
        return <Tabs setSelectedTab={handleTabChange} tabs={amountTabs} />;

      case ContractFieldDTOV1.type.DURATION:
        return (
          <Tabs
            setSelectedTab={handleTabChange}
            tabs={durationTabs}
            scrollable
          />
        );

      default:
        return (
          <>
            <TextArea
              control={control}
              name={textAreaName}
              multiline
              rows={4}
              placeholder={t(
                "pages.contractEdit.modals.customField.prompt.placeholders.promptField"
              )}
            />
          </>
        );
    }
  };

  const contentFieldType = renderContentByFieldType();

  const promptData = getPromptData();
  const hasFilledFields = Object.values(promptData).some(
    (value) => value === "0" || Boolean(value)
  );

  const showTestPrompt = hasFeature(Features.CONTRACT_ANALYSIS);
  const testButtonDisabled = !hasFilledFields || !fieldType;

  const field = fieldName.en.toLowerCase().replaceAll(" ", "_");

  const aiResultByTab =
    aiResult?.[`${field}.${selectedTab}`] ||
    aiResult?.[`${field}.value`] ||
    aiResult?.value;

  return (
    <Wrapper>
      <Heading>
        <HeadingIcon />
        <HeadingText>
          {t("pages.contractEdit.modals.customField.prompt.heading")}
        </HeadingText>
      </Heading>
      {contentFieldType}
      {showTestPrompt && (
        <>
          {!testInContract && (
            <Button
              variant="tertiary"
              name={t("pages.contractEdit.modals.customField.prompt.test")}
              label="test-toggle-button"
              icon={<ContractIcon />}
              iconSize="small"
              size="tiny"
              disabled={testButtonDisabled}
              onClick={() => setTestInContract(true)}
            />
          )}
          {testInContract && (
            <>
              <strong>
                {t("pages.contractEdit.modals.customField.prompt.test")}
              </strong>
              {contracts && (
                <ContractSelector
                  control={control}
                  contracts={contracts}
                  onChange={onContractChange}
                  onPromptSubmit={onPromptSubmit}
                  fieldType={fieldType}
                />
              )}
            </>
          )}
          {promptTestIsLoading && (
            <>
              <LoaderContainer>
                <CircularProgress size="1em" />
                <GradientTextAnimated>
                  {t("pages.contractEdit.modals.customField.prompt.loaderText")}
                </GradientTextAnimated>
              </LoaderContainer>
              {/* Nice to have, prepared for later */}
              {/* <Button
                variant="tertiary"
                name="Stop researching"
                icon={<StopIcon />}
                iconSize="small"
                size="tiny"
                onClick={onCancel}
              /> */}
            </>
          )}
          {noAiResultFound && (
            <GradientHeading
              text={t(
                "pages.contractEdit.modals.customField.prompt.messages.resultNotFound"
              )}
            />
          )}
          {Boolean((aiResultByTab as promptTestResult[])?.length) &&
            (aiResultByTab as promptTestResult[]).map((item, index) => {
              return (
                <Results key={index}>
                  <Result value={item?.value} snippet={item?.snippet} />
                  <Rating snippet={item?.snippet} fieldName={fieldName} />
                </Results>
              );
            })}
        </>
      )}
      {/* Hidden for now */}
      {/* <Suggestions /> */}
      {promptTestIsLoading && <Overlay />}
    </Wrapper>
  );
};

export default Prompt;
