import { LoadError } from "@react-pdf-viewer/core";
import { ErrorWrapper } from "./styles";
import AlertV2 from "components/AlertV2/AlertV2";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as Sentry from "@sentry/react";
// This is used instead of useTranslation hook, because the latest leads to an error (conditinal rendering)
import { t } from "i18next";

export type ErrorMessageType = Record<string, string>;

const RenderError = (error: LoadError) => {
  const errorMessages: ErrorMessageType = {
    AbortException: t("common.errors.pdfLoad.abort"),
    FormatError: t("common.errors.pdfLoad.format"),
    InvalidPDFException: t("common.errors.pdfLoad.invalid"),
    MissingPDFException: t("common.errors.pdfLoad.missing"),
    PasswordException: t("common.errors.pdfLoad.password"),
    UnexpectedResponseException: t("common.errors.pdfLoad.unexpected"),
    UnknownErrorException: t("common.errors.pdfLoad.unknown"),
  };

  if (!errorMessages[error.name as keyof ErrorMessageType]) {
    Sentry.captureMessage(`PDFViewer translation: ${JSON.stringify(error)}`);
  }

  const errorMessage =
    errorMessages[error.name as keyof ErrorMessageType] ||
    t("pdfViewer.errors.pdfLoad.unknown");

  return (
    <ErrorWrapper>
      <AlertV2 type={AlertTypeEnum.error} icon={<ErrorOutlineIcon />}>
        <span>{errorMessage}</span>
      </AlertV2>
    </ErrorWrapper>
  );
};

export default RenderError;
