import { useEffect, useState } from "react";
import BasicTable from "components/BasicTable/BasicTable";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import CardWrapper from "components/CardWrapper/CardWrapper";
import routePaths from "constants/routePaths";
import { UserListDto, UserListItemDto } from "openapi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { TFunction } from "i18next";
import { theme } from "theme";
import { Header, Row } from "components/BasicTable/types";

type Props = {
  userData?: UserListDto;
};

const UserResultTable = ({ userData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<Row[]>([]);

  const [selectedIdToImpersonate, setSelectedIndexToImpersonate] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (userData) {
      setData(convertData(userData.users, t));
    }
  }, [userData]);

  const headers: Header[] = [
    {
      key: "action",
      name: "",
      notSortable: true,
    },
    {
      key: "userFullName",
      name: t("pages.admin.tabs.users.resultTable.tableHeader.name").toString(),
    },
    {
      key: "email",
      name: t(
        "pages.admin.tabs.users.resultTable.tableHeader.email"
      ).toString(),
    },
  ];

  const convertData = (
    users: UserListItemDto[],
    t: TFunction<"translation">
  ) => {
    return users.map((user) => {
      return {
        id: user.id,
        userFullName: user.name,
        email: user.email,
        action: (
          <Box
            sx={{ width: "100%", justifyContent: "center" }}
            className="flex"
          >
            <PersonIcon
              fontSize="small"
              sx={{
                color: "black",
                ":hover": { cursor: "pointer" },
                margin: theme.spacing.sm,
              }}
              onClick={() => handleActionClick(user.id)}
            />
          </Box>
        ),
      };
    });
  };

  const handleActionClick = (id: string) => {
    setSelectedIndexToImpersonate(id);
  };

  useEffect(() => {
    if (selectedIdToImpersonate) {
      navigate(
        routePaths.ADMIN_USERS_IMPERSONATE.replace(
          ":id",
          selectedIdToImpersonate
        )
      );
    }
  }, [selectedIdToImpersonate]);

  return (
    <CardWrapper>
      <>
        <CardHeaderWrapper
          title={t("pages.admin.tabs.users.resultTable.title")}
        />
        {data.length > 0 ? (
          <BasicTable headers={headers} data={data} />
        ) : (
          <Box>{t("pages.admin.tabs.users.resultTable.noResult")}</Box>
        )}
      </>
    </CardWrapper>
  );
};

export default UserResultTable;
