import { TagDto } from "openapi";
import { TagBox, CloseIcon } from "./styles";

type Props = {
  tag: TagDto;
  onDelete: () => void;
  hasWriteAccess?: boolean;
};

const Tag = ({ tag, onDelete, hasWriteAccess = true }: Props) => (
  <TagBox>
    {tag.name}
    {hasWriteAccess && <CloseIcon onClick={onDelete} />}
  </TagBox>
);

export default Tag;
