import { useMemo, useState } from "react";
import { CTAButton, NewModal } from "components";
import {
  BackgroundBox,
  ContractsList,
  SubTitle,
  TeamIconFilled,
  TeamLine,
  TeamsInfo,
} from "./styles";
import { FormSelect } from "components/FormItems";
import { useTeam } from "contexts/team/hooks";
import { theme } from "theme";
import { CategoryDTO, ContractTransferResultDTO } from "openapi";
import { useForm, useWatch } from "react-hook-form";
import ContractInfo from "../components/ContractInfo/ContractInfo";
import AccessSwitcher from "../components/AccessSwitcher/AccessSwitcher";
import { ContractDTOV1 } from "openapi";
import {
  useOrganizationCategoriesQuery,
  useRemoveTeamFromCategoryMutation,
} from "shared/api/organization/categories";
import {
  useOrganizationTeamsQuery,
  useTransferContractsMutation,
} from "shared/api/organization/teams";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export type Props = {
  handleClose: () => void;
  contracts: ContractDTOV1[];
};

type MoveContractsContentForm = {
  destinationTeamId: string | null;
};

type CategoryWithContracts = CategoryDTO & {
  contracts: ContractDTOV1[];
};

export type ContractsGroupedByCategory = Record<string, ContractDTOV1[]>;

export type CategoriesGroupedByAccess = {
  availableCategories: CategoryWithContracts[];
  unavailableCategories: CategoryWithContracts[];
  categoriesWithRecentlyGrantedAccess: CategoryWithContracts[];
};

const MoveContractsModal = ({ handleClose, contracts }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedTeam, organizationId } = useTeam();

  const [categoriesWithGrantedAccess, setCategoriesWithGrantedAccess] =
    useState<Pick<CategoryDTO, "id" | "name">[]>([]);

  const { control } = useForm<MoveContractsContentForm>();
  const destinationTeamId = useWatch({
    control,
    name: "destinationTeamId",
    defaultValue: null,
  });

  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: teams } = useOrganizationTeamsQuery(organizationId);
  const transferContracts = useTransferContractsMutation(organizationId);

  const destinationTeamName = destinationTeamId
    ? teams?.find((team) => team.id === destinationTeamId)?.name ?? ""
    : "";

  const {
    availableCategories,
    unavailableCategories,
    categoriesWithRecentlyGrantedAccess,
  } = useMemo<CategoriesGroupedByAccess>(() => {
    // Group contracts by category ID to updated categories with contracts later
    const contractsGroupedByCategory: ContractsGroupedByCategory =
      contracts.reduce((acc: ContractsGroupedByCategory, contract) => {
        const categoryId = contract.categoryId;
        return {
          ...acc,
          [categoryId]: acc[categoryId]
            ? [...acc[categoryId], contract]
            : [contract],
        };
      }, {});

    // Filter out categories from selected contracts
    const filteredCategories = categories?.filter(
      (category) => !!contractsGroupedByCategory[category.id]
    );

    // Update required categories with list of contracts
    const categoriesWithContractData = filteredCategories?.map((category) => ({
      ...category,
      contracts:
        contractsGroupedByCategory[category.id as keyof typeof categories],
    }));

    // Split categories in 3 groups for rendering:
    // availableCategories, unavailableCategories, categoriesWithRecentlyGrantedAccess
    return categoriesWithContractData?.reduce(
      (acc: CategoriesGroupedByAccess, category) => {
        // Check if category is categoriesWithRecentlyGrantedAccess
        if (
          categoriesWithGrantedAccess.length &&
          categoriesWithGrantedAccess.some((item) => item.id === category.id)
        ) {
          return {
            ...acc,
            categoriesWithRecentlyGrantedAccess: [
              ...acc.categoriesWithRecentlyGrantedAccess,
              category,
            ],
          };
        }
        // Check if selected team has access to category
        if (category.teams.includes(destinationTeamId as string)) {
          return {
            ...acc,
            availableCategories: [...acc.availableCategories, category],
          };
        }
        // Otherwise add category to unavailableCategories
        return {
          ...acc,
          unavailableCategories: [...acc.unavailableCategories, category],
        };
      },
      {
        availableCategories: [],
        unavailableCategories: [],
        categoriesWithRecentlyGrantedAccess: [],
      }
    ) as CategoriesGroupedByAccess;
  }, [categories, contracts, destinationTeamId, categoriesWithGrantedAccess]);

  const countOfContractsAvailable = useMemo(() => {
    const availableContractsCount = availableCategories?.reduce(
      (sum, category) => sum + category.contracts?.length,
      0
    );
    const contractsWithResentlyGrantedAccessCount =
      categoriesWithRecentlyGrantedAccess?.reduce(
        (sum, category) => sum + category.contracts?.length,
        0
      );

    return availableContractsCount + contractsWithResentlyGrantedAccessCount;
  }, [availableCategories, categoriesWithRecentlyGrantedAccess]);

  const removeTeamFromCategory =
    useRemoveTeamFromCategoryMutation(organizationId);

  const updateAccessPermissions = async (category: CategoryDTO) => {
    await removeTeamFromCategory.mutateAsync({
      teamId: destinationTeamId as string,
      categoryId: category.id,
    });

    setCategoriesWithGrantedAccess(
      categoriesWithGrantedAccess.filter((item) => item.id !== category.id)
    );
  };

  if (!teams) {
    return null;
  }

  const moveContracts = async () => {
    if (!destinationTeamId) {
      return;
    }

    const contractsToMove: ContractDTOV1[] = [
      ...availableCategories,
      ...categoriesWithRecentlyGrantedAccess,
    ].reduce((acc: ContractDTOV1[], category: CategoryWithContracts) => {
      return [...acc, ...category.contracts];
    }, []);

    const contractIdsByTeam = new Map<string, string[]>();
    for (const contract of contractsToMove) {
      if (
        contract.parentId ||
        contract.type === ContractDTOV1.type.ATTACHMENT
      ) {
        continue;
      }
      if (!contractIdsByTeam.has(contract.teamId)) {
        contractIdsByTeam.set(contract.teamId, []);
      }

      contractIdsByTeam.get(contract.teamId)?.push(contract.id);
    }

    const results: ContractTransferResultDTO[] = [];
    for (const [teamId, contractIds] of contractIdsByTeam) {
      results.push(
        ...(await transferContracts.mutateAsync({
          sourceTeamId: teamId,
          targetTeamId: destinationTeamId,
          contracts: {
            contractIds: contractIds,
          },
        }))
      );
    }

    const successes: ContractTransferResultDTO[] = [];
    const failures: ContractTransferResultDTO[] = [];
    for (const result of results) {
      if (result.status === ContractTransferResultDTO.status.SUCCESS) {
        successes.push(result);
      } else {
        failures.push(result);
      }
    }

    if (failures.length > 0 && successes.length > 0) {
      enqueueSnackbar(
        t("pages.contracts.modals.moveContracts.mixedSnackbar", {
          selectedTeam: destinationTeamName,
          successCount: successes.length,
          failureCount: failures.length,
        }),
        {
          variant: "error",
        }
      );
    } else if (failures.length === 0) {
      enqueueSnackbar(
        t("pages.contracts.modals.moveContracts.successSnackbar", {
          selectedTeam: destinationTeamName,
          count: successes.length,
        }),
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(
        t("pages.contracts.modals.moveContracts.failureSnackbar", {
          selectedTeam: destinationTeamName,
          count: failures.length,
        }),
        {
          variant: "error",
        }
      );
    }
  };

  const renderContractsGruopedByCategory = (
    category: CategoryWithContracts,
    categoryAccessible?: boolean
  ) => (
    <>
      {category.contracts.map((contract: ContractDTOV1) => (
        <ContractInfo
          key={contract.id}
          contract={contract}
          categoryAccessible={categoryAccessible}
          category={category}
        />
      ))}
    </>
  );

  const modalContent = (
    <>
      <span>{t("pages.contracts.modals.moveContracts.selectNewTeam")}</span>
      <FormSelect
        control={control}
        name="destinationTeamId"
        options={teams.map(({ id, name }) => ({
          key: id,
          value: name,
        }))}
        label="Teams"
      />

      <>
        <>
          <TeamsInfo>
            <h2>{t("pages.contracts.modals.moveContracts.summary")}</h2>
            <TeamLine>
              <span>{t("pages.contracts.modals.moveContracts.from")}</span>
              <TeamIconFilled /> <strong>{selectedTeam?.name}</strong>
            </TeamLine>
            <TeamLine>
              <span>{t("pages.contracts.modals.moveContracts.to")}</span>
              <TeamIconFilled />
              <strong>
                {destinationTeamId
                  ? destinationTeamName
                  : t("pages.contracts.modals.moveContracts.selectTeam")}
              </strong>
            </TeamLine>
          </TeamsInfo>

          {destinationTeamId ? (
            <>
              <BackgroundBox>
                <SubTitle>
                  {t("pages.contracts.modals.moveContracts.contractsMoved", {
                    countOfContractsAvailable,
                  })}
                </SubTitle>
                {!!availableCategories.length && (
                  <ContractsList>
                    {availableCategories.map((category) =>
                      renderContractsGruopedByCategory(category, true)
                    )}
                  </ContractsList>
                )}

                {!!categoriesWithRecentlyGrantedAccess.length && (
                  <>
                    <SubTitle
                      style={{
                        color: theme.color.green[600],
                        marginTop: theme.spacing.lg,
                        marginBottom: theme.spacing.lg,
                      }}
                    >
                      {t(
                        "pages.contracts.modals.moveContracts.headings.accessToCategoryGranted"
                      )}
                    </SubTitle>
                    <ContractsList>
                      {categoriesWithRecentlyGrantedAccess.map((category) => (
                        <>
                          {renderContractsGruopedByCategory(category, true)}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <CTAButton
                              name={t("common.buttons.undo")}
                              label="undo-category-access-grant"
                              variant="tertiary"
                              onClick={() => updateAccessPermissions(category)}
                            />
                          </div>
                        </>
                      ))}
                    </ContractsList>
                  </>
                )}
              </BackgroundBox>

              {!!unavailableCategories.length && (
                <BackgroundBox>
                  <SubTitle>
                    {t(
                      "pages.contracts.modals.moveContracts.headings.categoriesWihtoutAccess"
                    )}
                  </SubTitle>
                  <ContractsList>
                    {unavailableCategories.map((category) => (
                      <>
                        {renderContractsGruopedByCategory(category, false)}
                        <AccessSwitcher
                          key={category.id}
                          organizationId={organizationId}
                          team={{
                            id: destinationTeamId,
                            name: destinationTeamName,
                          }}
                          category={category}
                          onAccessGranted={() => {
                            setCategoriesWithGrantedAccess([
                              ...categoriesWithGrantedAccess,
                              category,
                            ]);
                          }}
                          onAccessRevoked={() => {
                            setCategoriesWithGrantedAccess(
                              categoriesWithGrantedAccess.filter(
                                (item) => item.id !== category.id
                              )
                            );
                          }}
                        />
                      </>
                    ))}
                  </ContractsList>
                </BackgroundBox>
              )}
            </>
          ) : (
            <BackgroundBox>
              <SubTitle>
                {t(
                  "pages.contracts.modals.moveContracts.headings.pendingContracts",
                  { contractsCount: contracts.length }
                )}
              </SubTitle>
              <ContractsList>
                {unavailableCategories.map((category) =>
                  renderContractsGruopedByCategory(category)
                )}
              </ContractsList>
            </BackgroundBox>
          )}
        </>
      </>
    </>
  );

  return (
    <NewModal
      open={true}
      handleClose={handleClose}
      title={t("pages.contracts.modals.moveContracts.title")}
      body={modalContent}
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            variant="secondary"
            onClick={() => {
              handleClose();
            }}
          />
          <CTAButton
            name={t("common.buttons.confirm")}
            disabled={!destinationTeamId || !countOfContractsAvailable}
            onClick={() => {
              void moveContracts();
              handleClose();
            }}
          />
        </>
      }
      fullWidth
      maxWidth="sm"
    />
  );
};

export default MoveContractsModal;
