import { useTranslation } from "react-i18next";
import AuthCardWrapper from "../../components/AuthCardWrapper/AuthCardWrapper";
import { AuthCardContainer } from "components/AuthCardWrapper/AuthCardWrapper.styles";
import { Container } from "./styles";
import { UserService } from "openapi";
import { useNavigate, useParams } from "react-router-dom";
import routePaths from "constants/routePaths";
import { Button } from "@mui/material";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";

const Link = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();

  const onLink = () => {
    if (!id) {
      return;
    }
    void UserService.approveFederatedIdentity(id).then((approvalData) => {
      let url: URL = new URL(window.location.href);
      if (url.searchParams.has("redirect")) {
        window.location.replace(url.searchParams.get("redirect") as string);
      } else {
        url = new URL(window.location.href);
        if (approvalData.hostname) {
          url.hostname = `${approvalData.hostname}.` + url.hostname;
        }
        url.pathname = routePaths.LOGIN;
        window.location.replace(url);
      }
    });
  };

  const onCancel = () => {
    navigate(routePaths.HOME);
  };

  return (
    <Container component="main">
      <AuthCardWrapper
        title={t("pages.link.title")}
        subTitle={t("pages.link.prompt")}
      >
        <AuthCardContainer>
          <Button
            id="linkBtn"
            type="submit"
            size="large"
            fullWidth
            onClick={onLink}
            className={classes.baseButton}
            style={{ margin: 0 }}
          >
            {t("pages.link.form.submit")}
          </Button>
          <Button
            id="linkBtn"
            type="submit"
            size="large"
            fullWidth
            onClick={onCancel}
            className={classes.baseButton}
            style={{ margin: 0 }}
          >
            {t("common.buttons.cancel")}
          </Button>
        </AuthCardContainer>
      </AuthCardWrapper>
    </Container>
  );
};

export default Link;
