import {
  AlertWrapper,
  IconWrapper,
  ButtonWrapper,
  ContentWrapper,
} from "./styles";
import { AlertTypeEnum } from "shared/enums/alert.enum";

export type AlertV2Props = {
  message?: string;
  icon?: JSX.Element;
  type?: AlertTypeEnum;
  buttonText?: string;
  buttonOnClickCallback?: () => void;
  children?: JSX.Element;
};

const AlertV2 = ({
  message,
  icon,
  type,
  buttonText,
  buttonOnClickCallback,
  children,
}: AlertV2Props) => {
  return (
    <AlertWrapper aria-label="alert" alertType={type}>
      {icon ? <IconWrapper alertType={type}>{icon}</IconWrapper> : null}
      {/* @Todo using children instead of just message provides more flexibility, update usage of component with children and remove message prop */}
      {children ? <ContentWrapper>{children}</ContentWrapper> : null}
      {message && !children ? <ContentWrapper>{message}</ContentWrapper> : null}
      {buttonText && buttonOnClickCallback ? (
        <ButtonWrapper onClick={buttonOnClickCallback}>
          {buttonText}
        </ButtonWrapper>
      ) : null}
    </AlertWrapper>
  );
};

export default AlertV2;
