import Grid from "@mui/material/Grid";
import { AmountDatapointDTO } from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { DatapointContent } from "pages/Contract/styles";
import DetailNameWrapper from "components/DetailNameWrapper/DetailNameWrapper";
import { FormAmountField } from "components/FormItems/FormAmountField/FormAmountField";
import { DataPointProps } from "./types";

export const AmountDatapoint = ({
  definition,
  contact,
  editable,
}: DataPointProps) => {
  const { locale } = useLocale();

  const name = definition.name[locale];
  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <FormAmountField label={name} field={definition} />
      </Grid>
    );
  }

  const data = contact[definition.visibleId] as AmountDatapointDTO;
  return (
    <Grid item xs={12} md={6}>
      <DetailNameWrapper>{name}</DetailNameWrapper>
      <DatapointContent>
        {data && data.value && data.value.value
          ? new Intl.NumberFormat(locale, {
              style: "currency",
              currency: data.currency.value || "EUR",
            }).format(data.value.value)
          : "-"}
      </DatapointContent>
    </Grid>
  );
};
