import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { CategoryDTO, OrganizationNewService } from "openapi";
import { useSnackbar } from "notistack";
import { AlertIconWrapper, AlertText, AlertWrapper } from "../styles";
import AlarmIcon from "assets/svg/alarm-icon.svg?react";
import { theme } from "theme";
import { useTeam } from "contexts/team/hooks";
import { CTAButton } from "components";
import { ButtonsWrapper } from "./styles";
import { useNavigate } from "react-router";
import { useLocale } from "hooks";
import routePaths from "constants/routePaths";

type Props = {
  category: CategoryDTO;
  handleClose: () => void;
};

const DeleteCategoryContent = ({ category, handleClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId } = useTeam();
  const navigate = useNavigate();
  const { locale } = useLocale();

  const onDelete = async () => {
    try {
      await OrganizationNewService.deleteCategory(organizationId, category.id);
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.delete.success"
        ),
        {
          variant: "success",
        }
      );
      handleClose();
      navigate(routePaths.SETTINGS_CUSTOM_FIELDS);
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.organization.administrator.categories.modal.delete.failure"
        ),
        {
          variant: "error",
        }
      );
      handleClose();
    }
  };

  return (
    <div>
      <Box
        className="flex-align-center"
        sx={{ marginBottom: theme.spacing.lg }}
      >
        <Box>
          <AlertWrapper>
            <AlertIconWrapper>
              <AlarmIcon />
            </AlertIconWrapper>
            <AlertText>
              <h3>
                <span>
                  {t("pages.categoryDetails.modals.delete.messageTitle", {
                    categoryName: category.name[locale],
                  })}
                </span>
              </h3>
              <span id="deleteTeamDescription">
                {t("pages.categoryDetails.modals.delete.messageContent", {
                  categoryName: category.name[locale],
                })}
              </span>
            </AlertText>
          </AlertWrapper>
        </Box>
      </Box>

      <ButtonsWrapper>
        <CTAButton
          label="cancelBtn"
          type="reset"
          onClick={handleClose}
          name={t("common.buttons.cancel")}
          variant="secondary"
        />
        <CTAButton
          label="deleteBtn"
          type="submit"
          variant="primary"
          color="danger"
          onClick={() => onDelete()}
          name={t("pages.categoryDetails.buttons.deletePermanently")}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default DeleteCategoryContent;
