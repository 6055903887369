import { useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTeam } from "contexts/team/hooks";
import LoadingComponent from "./Loading";
import { createSampleData } from "utils/sample-data";
import { FooterComponent } from "./GuideModal";
import {
  ModalContent,
  List,
  ListItem,
  FileIcon,
  ImportIcon,
  ItemButton,
  Header,
} from "./styles";
import { Tooltip } from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
  setSteps: (val: string[]) => void;
  steps: string[];
  fetchData: () => Promise<void>;
};

const GuideModal = ({ handleClose, steps, setSteps, fetchData }: Props) => {
  const { t } = useTranslation();
  const { selectedTeamId, parentTeamId, organizationId } = useTeam();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateSampleData = async () => {
    setIsLoading(true);

    await createSampleData(selectedTeamId, true, parentTeamId, organizationId)
      .then(() => {
        void fetchData();
        setIsLoading(false);
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <ModalContent>
        <Header>
          <Typography
            id="modal-title"
            variant="h5"
            component="h1"
            sx={{ marginBottom: "1rem" }}
          >
            {t("guide.steps.first.title")}
          </Typography>
          <Typography
            id="modal-subtitle1"
            variant="subtitle1"
            component="div"
            sx={{ marginBottom: "1rem" }}
          >
            {t("guide.steps.first.subtitle1")}
          </Typography>
          <Typography
            id="modal-subtitle2"
            variant="subtitle1"
            component="div"
            sx={{ marginBottom: "1rem" }}
          >
            {t("guide.steps.first.subtitle2")}
          </Typography>
        </Header>
        <List>
          <ListItem>
            <Tooltip
              title={t("guide.steps.first.options.addContractsDescription")}
            >
              <ItemButton
                onClick={() => setSteps([...steps, "import-contracts"])}
              >
                <FileIcon sx={{ marginRight: ".5rem" }} />
                {t("guide.steps.first.options.addContracts")}
              </ItemButton>
            </Tooltip>
          </ListItem>

          <ListItem>
            <Tooltip
              title={t("guide.steps.first.options.loadSamplesDescription")}
            >
              <ItemButton onClick={() => handleCreateSampleData()}>
                <ImportIcon sx={{ marginRight: ".5rem" }} />
                {t("guide.steps.first.options.loadSamples")}
              </ItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </ModalContent>
      <FooterComponent handleClose={handleClose} />
    </>
  );
};

export default GuideModal;
