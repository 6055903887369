import { NotificationItemDto } from "openapi/models/NotificationItemDto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import GroupsIcon from "@mui/icons-material/Groups";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CloudUpload from "@mui/icons-material/CloudUpload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { TaskAlt as TaskIcon } from "@mui/icons-material";
import WebhookIcon from "assets/svg/webhook-icon.svg?react";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import { TFunction } from "i18next";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Trans } from "react-i18next";
import { theme } from "theme";
import { SignatureIcon } from "components/Icons";

dayjs.extend(duration);

const NotificationNavigationButton = styled(Button)`
  text-transform: none;
  font-size: 0.8rem;
  border: 1px solid ${theme.color.gray[300]};
  border-radius: 0.25rem;
  padding: ${theme.spacing.sm};
  ${theme.spacing.md};
  color: ${theme.color.blue[500]};
  background-color: transparent;
  &:hover {
    text-decoration: underline;
    background-color: transparent;
    color: ${theme.color.blue[600]};
  }
`;

export const getIcon = (type: NotificationItemDto.notificationType) => {
  switch (type) {
    case NotificationItemDto.notificationType.CONTRACT_DELETED:
      return <DeleteForeverIcon sx={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.JOINED_TEAM:
      return <GroupsIcon sx={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.CONTRACT_REMINDER:
      return <WatchLaterIcon sx={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.TASK_ASSIGNED:
    case NotificationItemDto.notificationType.TASK_CLOSED:
    case NotificationItemDto.notificationType.TASK_DUE_DATE_CHANGED:
    case NotificationItemDto.notificationType.TASK_MEMBER_ASSIGNED:
    case NotificationItemDto.notificationType.TASK_DUE_DATE_REACHED:
      return <TaskIcon sx={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.RETRYING_WEBHOOK_FAILED:
      return <WebhookIcon style={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.BACKUP_FAILED:
      return <CloudUpload sx={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.CONTRACT_SIGNED:
    case NotificationItemDto.notificationType.CONTRACT_SIGNING_FAILED:
    case NotificationItemDto.notificationType.CONTRACT_SIGNING_DECLINED:
      return <SignatureIcon style={{ fill: theme.color.blue[300] }} />;
    case NotificationItemDto.notificationType.MONTHLY_SUMMARY:
      return <CalendarMonthIcon sx={{ fill: theme.color.blue[300] }} />;
    default:
      return <InsertDriveFileIcon sx={{ fill: theme.color.blue[300] }} />;
  }
};

// Deprecated
export const getNavigationButton = (
  t: TFunction<"translation">,
  type: NotificationItemDto.notificationType,
  closeModalAndNavigateToContractsOverview: () => void,
  closeModalAndNavigateToContractPreview: () => void,
  closeModalAndNavigateToTeams: () => void,
  closeModalAndNavigateToTask: () => void,
  hasTaskId: boolean
) => {
  switch (type) {
    case NotificationItemDto.notificationType.CONTRACT_DELETED:
      return (
        <NotificationNavigationButton
          onClick={closeModalAndNavigateToContractsOverview}
        >
          {t(
            "notificationsModal.notificationItem.buttons.navigateToContractsOverview"
          )}
        </NotificationNavigationButton>
      );
    case NotificationItemDto.notificationType.CONTRACTS_IMPORTED:
      return (
        <NotificationNavigationButton
          onClick={closeModalAndNavigateToContractsOverview}
        >
          {t(
            "notificationsModal.notificationItem.buttons.navigateToContractsOverview"
          )}
        </NotificationNavigationButton>
      );
    case NotificationItemDto.notificationType.JOINED_TEAM:
      return (
        <NotificationNavigationButton onClick={closeModalAndNavigateToTeams}>
          {t("notificationsModal.notificationItem.buttons.navigateToTeams")}
        </NotificationNavigationButton>
      );
    case NotificationItemDto.notificationType.CONTRACT_REMINDER:
    case NotificationItemDto.notificationType.TASK_ASSIGNED:
    case NotificationItemDto.notificationType.TASK_CLOSED:
    case NotificationItemDto.notificationType.TASK_DUE_DATE_CHANGED:
      if (hasTaskId) {
        return (
          <NotificationNavigationButton onClick={closeModalAndNavigateToTask}>
            {t("notificationsModal.notificationItem.buttons.navigateToTask")}
          </NotificationNavigationButton>
        );
      }
      return (
        <NotificationNavigationButton
          onClick={closeModalAndNavigateToContractPreview}
        >
          {t(
            "notificationsModal.notificationItem.buttons.navigateToContractPreview"
          )}
        </NotificationNavigationButton>
      );
    case NotificationItemDto.notificationType.MONTHLY_SUMMARY:
      return undefined;
    default:
      return (
        <NotificationNavigationButton
          onClick={closeModalAndNavigateToContractPreview}
        >
          {t(
            "notificationsModal.notificationItem.buttons.navigateToContractPreview"
          )}
        </NotificationNavigationButton>
      );
  }
};

export const getTranslation = (
  t: TFunction<"translation">,
  data: NotificationItemDto
) => {
  return (
    <Trans
      i18nKey={`notificationsModal.notificationItem.notificationTypeTitle.${data.notificationType}`}
      values={{
        userName: data.userName,
        contractName: data.contractName,
        count: data.numberOfContracts,
        taskName: data.taskName,
      }}
      components={{
        strong: (
          <Box component="span" sx={{ fontWeight: theme.font.weight.medium }} />
        ),
      }}
    />
  );
};

export const getTime = (t: TFunction<"translation">, createdAt: string) => {
  const translationPrefix = "notificationsModal.notificationItem.timeAgo";

  const now = dayjs();
  const difference = dayjs.duration(now.diff(dayjs(createdAt)));

  const monthsDiff = Math.round(difference.as("months"));
  const daysDiff = Math.round(difference.as("days"));
  const hoursDiff = Math.round(difference.as("hours"));
  const minutesDiff = Math.round(difference.as("minutes"));

  if (monthsDiff > 1) {
    return t(`${translationPrefix}.monthsAgo`, {
      timeAgo: monthsDiff,
    });
  } else if (monthsDiff === 1) {
    return t(`${translationPrefix}.aMonthAgo`);
  } else {
    if (daysDiff > 1) {
      return t(`${translationPrefix}.daysAgo`, {
        timeAgo: daysDiff,
      });
    } else if (daysDiff === 1) {
      return t(`${translationPrefix}.aDayAgo`);
    } else {
      if (hoursDiff > 1) {
        return t(`${translationPrefix}.hoursAgo`, {
          timeAgo: hoursDiff,
        });
      } else if (hoursDiff === 1) {
        return t(`${translationPrefix}.anHourAgo`);
      } else {
        if (minutesDiff > 1) {
          return t(`${translationPrefix}.minutesAgo`, {
            timeAgo: minutesDiff,
          });
        } else if (minutesDiff === 1) {
          return t(`${translationPrefix}.aMinuteAgo`);
        } else {
          return t(`${translationPrefix}.fewSecondsAgo`);
        }
      }
    }
  }
};
