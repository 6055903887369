import {
  SubTitleWrapper,
  TitleWrapper,
} from "../../../../components/CardHeaderWrapper/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useStyles } from "../../../../components/StyledComponents/StyledBaseButtons";
import { UserService } from "../../../../openapi";
import { useSnackbar } from "notistack";
import { ReviewIFrame, ReviewModal } from "./styles";

export const ReviewSection = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [url, setURL] = useState("");
  const { t } = useTranslation();

  const requestReview = async () => {
    try {
      const reviewFormAccess = await UserService.generateReviewFormUrl();
      setURL(reviewFormAccess?.url);
      setShowReviewForm(true);
    } catch (e) {
      enqueueSnackbar(t(`pages.settings.tabs.review.error`), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <TitleWrapper>{t("pages.settings.tabs.review.title")}</TitleWrapper>
      <SubTitleWrapper>
        {t("pages.settings.tabs.review.subTitle")}
      </SubTitleWrapper>

      <Button className={classes.baseButton} onClick={requestReview}>
        {t("pages.settings.tabs.review.button")}
      </Button>
      <ReviewModal
        open={showReviewForm}
        onClose={() => setShowReviewForm(false)}
      >
        <ReviewIFrame src={url} frameBorder={0} />
      </ReviewModal>
    </>
  );
};
