import { useTranslation } from "react-i18next";
import TemplateCollection from "pages/Settings/OrganizationSettings/ContractTemplates/TemplateCollection/TemplateCollection";
import { ContractTemplateMode } from "pages/Settings/OrganizationSettings/ContractTemplates/types";
import { NewModal } from "components";

const ContractTemplatesModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <NewModal
      fullWidth
      maxWidth="xl"
      open={open}
      handleClose={handleClose}
      title={t("pages.settings.tabs.contractTemplates.title")}
      body={
        <TemplateCollection
          mode={ContractTemplateMode.PRESELECT}
          handleClose={handleClose}
        />
      }
    />
  );
};

export default ContractTemplatesModal;
