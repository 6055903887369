import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routePaths from "constants/routePaths";
import { NotificationItemDto } from "openapi";
import { getNavigationToContractViewById } from "constants/utils";
import { Chip } from "components";
import { theme } from "theme";
import {
  getIcon,
  getTranslation,
  getTime,
} from "./helper/notificationItem.helper";
import {
  Wrapper,
  IconContainer,
  Description,
  TimeDetails,
  MainFooter,
} from "./styles";

type Props = {
  value: NotificationItemDto;
  handleClose?: () => void;
  isEven: boolean;
};

const NotificationItem = ({ value, handleClose, isEven }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const teamId = value.teamId as string;
  const taskId = value.taskId as string;

  const links = {
    teams: `/organizations/${teamId}${routePaths.SETTINGS_TEAMS}`,
    contract: `/organizations/${teamId}${getNavigationToContractViewById(
      value.contractId
    )}`,
    contracts: `/organizations/${teamId}${routePaths.CONTRACTS}`,
    task: `/organizations/${teamId}/tasks?task=${taskId}`,
  };

  const getLink = (type: NotificationItemDto.notificationType) => {
    switch (type) {
      case NotificationItemDto.notificationType.CONTRACT_DELETED:
      case NotificationItemDto.notificationType.CONTRACTS_IMPORTED:
        return links.contracts;
      case NotificationItemDto.notificationType.JOINED_TEAM:
        return links.teams;
      case NotificationItemDto.notificationType.MONTHLY_SUMMARY:
        return undefined;
      case NotificationItemDto.notificationType.CONTRACT_REMINDER:
      case NotificationItemDto.notificationType.TASK_ASSIGNED:
      case NotificationItemDto.notificationType.TASK_CLOSED:
      case NotificationItemDto.notificationType.TASK_DUE_DATE_CHANGED:
        if (value.taskId) return links.task;
        return links.contract;
      case NotificationItemDto.notificationType.TASK_DUE_DATE_REACHED:
        if (value.taskId) return links.task;
        return links.contract;
      default:
        return links.contract;
    }
  };

  const handleOnClick = () => {
    if (!teamId) {
      handleClose && handleClose();
      return;
    }

    handleClose && handleClose();
    const linkTo = getLink(value.notificationType) as string;
    navigate(linkTo);
  };

  return (
    <Wrapper onClick={handleOnClick} even={isEven}>
      <IconContainer>{getIcon(value.notificationType)}</IconContainer>

      <div>
        <Description>{getTranslation(t, value)}</Description>

        <MainFooter>
          <TimeDetails>{getTime(t, value.createdAt)}</TimeDetails>

          <Chip color={theme.color.blue[500]}>{value.teamName}</Chip>
        </MainFooter>
      </div>
    </Wrapper>
  );
};

export default NotificationItem;
