import styled from "@emotion/styled";
import { theme } from "theme";

export const AssigneesWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
`;

export const AssigneeName = styled.div`
  margin-top: ${theme.spacing.xxs};
  flex: 0 0 auto;
  width: 120px;
`;

export const ContentRow = styled.div`
  margin-bottom: ${theme.spacing.xl};
  &:not(:first-of-type) {
    padding-top: ${theme.spacing.xl};
    border-top: 1px solid ${theme.color.gray[500]};
  }
`;

export const Title = styled.div`
  font-weight: ${theme.font.weight.semiBold};
  margin-bottom: ${theme.spacing.xl} 0;
`;

export const Description = styled.div`
  margin: ${theme.spacing.xl} 0;
`;

export const SearchBarWrapper = styled.div`
  margin: ${theme.spacing.sm} 0;
  max-width: 286px;
  width: 100%;
`;
