import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid, Button, InputAdornment, IconButton } from "@mui/material";
import {
  ContactFieldTypeDTOV1,
  ContractFieldDTOV1,
  TextDatapointDTO,
} from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { DataPointProps } from "./types";
import { getContactFieldValue, getContactOptions } from "./helper";
import AutoCompleteSelect, {
  Option,
} from "components/FormItems/AutoCompleteSelect/AutoCompleteSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextField, NewModal } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { Add } from "@mui/icons-material";
import ContactsIcon from "@mui/icons-material/Contacts";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "theme";
import { useAnalysis } from "./hooks/useAnalysis";
import { AnalysisWrapper } from "./components/AnalysisWrapper";

interface ContactFormType {
  fields: Record<string, ContactFieldTypeDTOV1>;
}

export const AdminContactDatapoint = ({
  definition,
  values,
  isLoading,
  contactTypes,
  contacts,
}: DataPointProps) => {
  const { locale } = useLocale();
  const methods = useFormContext<ContactFormType>();
  const { t } = useTranslation();
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const data = values[definition.id] as ContactFieldTypeDTOV1;
  const analysis = useAnalysis(definition, data, undefined, {
    value: (value) => {
      const existingContact = contacts.find(
        (c) => (c.name as TextDatapointDTO).value.value === value
      );

      if (existingContact) {
        return existingContact.id;
      }

      methods.setValue(
        `fields.${definition.id}.$create.name.value.value`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value,
        {
          shouldDirty: true,
        }
      );
      return null;
    },
  });

  const createName = useWatch({
    control: methods.control,
    name: `fields.${definition.id}.$create.name.value.value`,
  }) as string | undefined;

  useEffect(() => {
    if (!createName || contactTypes.length < 1) {
      methods.setValue(`fields.${definition.id}.$create`, undefined);
      methods.setValue(
        `fields.${definition.id}`,
        values[definition.id] as ContactFieldTypeDTOV1
      );
      return;
    }

    methods.setValue(
      `fields.${definition.id}.$create.typeId`,
      contactTypes[0].id,
      {
        shouldDirty: true,
      }
    );

    methods.setValue(`fields.${definition.id}.value`, null);
  }, [createName]);

  if (!contacts || !contactTypes) return <></>;
  const options = getContactOptions(contacts).sort((a, b) =>
    a.value.localeCompare(b.value)
  );

  const dataPointValueId = (values[definition.id] as ContactFieldTypeDTOV1)
    ?.value;
  const contactData = contacts.find(
    (contact) => contact.id === dataPointValueId
  );
  const displayValue = contactData ? getContactFieldValue(contactData) : "";

  const defaultValue: Option = {
    key: dataPointValueId ?? "",
    value: displayValue,
  };

  if (isLoading) return null;

  return (
    <Grid item xs={12} md={6}>
      {createName ? (
        <FormTextField
          control={methods.control}
          label={definition.name[locale]}
          name={`fields.${definition.id}.$create.name.value.value`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    methods.setValue(`fields.${definition.id}.$create`, null);
                  }}
                >
                  <ClearIcon sx={{ color: theme.color.red[700] }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled
        />
      ) : (
        <Controller
          name={`fields.${definition.id}.value`}
          control={methods.control}
          render={({ field }) => (
            <AnalysisWrapper
              analysis={analysis}
              fieldKey="value"
              definitionType={ContractFieldDTOV1.type.CONTACT}
              renderField={(_data, selector) => {
                return (
                  <AutoCompleteSelect
                    {...field}
                    defaultValue={defaultValue}
                    dataCy="contactsAutocompleteList"
                    id="contactsAutocompleteList"
                    options={options}
                    onSelect={(data) => field.onChange(data.key)}
                    label={definition.name[locale]}
                    startAdornment={selector}
                    paperComponent={
                      <Button
                        className={styles.navBlueButton}
                        style={{ margin: 0 }}
                        fullWidth
                      >
                        <span>{t("pages.contacts.header.newContact")}</span>
                        <Add fontSize="small" />
                      </Button>
                    }
                    onPapperClick={(_value: string) => {
                      setShowModal(true);
                    }}
                  />
                );
              }}
            />
          )}
        />
      )}

      <NewModal
        fullWidth
        open={showModal}
        handleClose={() => setShowModal(false)}
        icon={<ContactsIcon fontSize="small" />}
        title={t("pages.contacts.header.newContact")}
        body={
          <>
            <FormTextField
              control={methods.control}
              name={`fields.${definition.id}.$create.name.value.value`}
            />
            <Button onClick={() => setShowModal(false)} fullWidth>
              {t("common.buttons.save")}
            </Button>
          </>
        }
      />
    </Grid>
  );
};
