import { Suggestions } from "../hooks/types";
import AiSuggestion from "components/AiSuggestion/AiSuggestion";
import Tag from "new-components/Tag";
import { InputAdornmentWrapper } from "./styles";
import { UseFormSetValue } from "react-hook-form";
import { ContractDTOV1, ContractFieldLimitedDTOV1 } from "openapi";

type DatapointFieldInputAdornmentProps = {
  definition: ContractFieldLimitedDTOV1;
  fieldKey: string;
  suggestions: Record<string, Suggestions> | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
  data: ContractDTOV1["fields"][string] | undefined | null;
};

export const DatapointFieldInputAdornment = ({
  definition,
  fieldKey,
  setValue,
  suggestions,
  data,
}: DatapointFieldInputAdornmentProps) => {
  const fieldSuggestions = suggestions?.[fieldKey];
  const metadata = data?.metadata;
  const fieldMetadata = metadata?.[fieldKey];
  const shouldShowSuggestions =
    fieldSuggestions &&
    fieldSuggestions.length > 0 &&
    !!fieldMetadata?.unverified;

  if (!shouldShowSuggestions) {
    return null;
  }

  return (
    <AiSuggestion
      options={fieldSuggestions}
      handleOptionClick={(option) => {
        setValue(`fields.${definition.id}.${fieldKey}`, option, {
          shouldDirty: true,
        });
      }}
    >
      <InputAdornmentWrapper>
        <Tag variant="ai" />
      </InputAdornmentWrapper>
    </AiSuggestion>
  );
};
