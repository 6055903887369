import { useTranslation } from "react-i18next";
import { Wrapper, Title, AccessWrapper } from "./styles";

import {
  PermissionChangeLogDTO,
  RoleDTO,
  MemberPermissionChangeDTO,
  TeamMemberDto,
  Diff,
  RoleChange,
  TeamDTOV1,
} from "openapi";

import StatusLabel from "../StatusLabel/StatusLabel";
import TypeLabel from "../TypeLabel/TypeLabel";
import FromTo from "../FromTo/FromTo";
import { Role } from "components";
import { getNameWithSuffix } from "pages/Settings/OrganizationSettings/Organization/helper";

export type MemberEditType = {
  roles: RoleDTO[];
  data: MemberPermissionChangeDTO;
  event: PermissionChangeLogDTO.event;
  members: TeamMemberDto[];
  teams: TeamDTOV1[];
};

const MemberEdit = ({ data, event, roles, members, teams }: MemberEditType) => {
  const { t } = useTranslation();

  const GRANTED = t(
    "pages.settings.organization.permissionChangeLog.messages.granted"
  );
  const REVOKED = t(
    "pages.settings.organization.permissionChangeLog.messages.revoked"
  );
  const ACCESS_FOR = t(
    "pages.settings.organization.permissionChangeLog.messages.accessFor"
  );
  const FULL_OWNERSHIP_TO = t(
    "pages.settings.organization.permissionChangeLog.messages.fullOwnership"
  );

  const member = members.find((member) => member.id === data.memberId);

  const memberName = member
    ? `${member?.firstname ?? ""} ${member?.lastname ?? ""}`
    : t("common.labels.deletedUser");

  const categoryAccessUpdated = data.categoryAdmin;
  const taskAutomationAccessUpdated = data?.taskAutomationAdmin;
  const contractTemplateAccessUpdated = data?.contractTemplateAdmin;
  const ownershipUpdated = data?.owner;
  const roleAccessUpdated = data?.roles?.length;

  if (ownershipUpdated) {
    return (
      <Title>
        <StatusLabel
          title={data?.owner?.to ? GRANTED : REVOKED}
          variant={event}
        />
        {FULL_OWNERSHIP_TO} {memberName}
      </Title>
    );
  }

  return (
    <Wrapper>
      <Title>
        <StatusLabel
          title={t(
            `pages.settings.organization.permissionChangeLog.status.${event}`
          )}
          variant={event}
        />
        <>
          {ACCESS_FOR} {memberName}
        </>
      </Title>

      {categoryAccessUpdated && (
        <AccessWrapper>
          <TypeLabel variant="categoryAdmin" />
          <FromTo data={data.categoryAdmin as Diff} />
        </AccessWrapper>
      )}

      {taskAutomationAccessUpdated && (
        <AccessWrapper>
          <TypeLabel variant="taskAutomation" />
          <FromTo data={data.taskAutomationAdmin as Diff} />
        </AccessWrapper>
      )}

      {roleAccessUpdated && (
        <>
          <TypeLabel variant="userRole" />
          {data?.roles?.map((role) => {
            const roleData = roles.find((item) => item.id === role.id);

            const roleName = roleData
              ? getNameWithSuffix(roleData.name, roleData.teamId, teams, t)
              : t("common.labels.deletedRole");

            const fromTo = {
              to: role.action === RoleChange.action.ALLOWED,
              from: role.action !== RoleChange.action.ALLOWED,
            };

            return (
              <AccessWrapper key={role.id}>
                <FromTo data={fromTo} />
                <Role name={roleName} />
              </AccessWrapper>
            );
          })}
        </>
      )}

      {contractTemplateAccessUpdated && (
        <AccessWrapper>
          <TypeLabel variant="contractTemplateAdmin" />
          <FromTo data={data.contractTemplateAdmin as Diff} />
        </AccessWrapper>
      )}
    </Wrapper>
  );
};

export default MemberEdit;
