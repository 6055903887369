import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ModalResultEnum } from "components/Modal/Modal";
import { setValidationErrors } from "shared/service/errorResponseService";
import { CTAButton, FormTextField, NewModal } from "components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ViewInputDTO, ViewItemDTO } from "openapi";
import { AgGridReact } from "ag-grid-react";
import { Switch } from "new-components";
import { DeleteButton, SwitcherWrapper, Form, ModalBody } from "./styles";
import { theme } from "theme";
import { isEqual } from "lodash";
import { initialValues } from "../../EntityGridViewSelector";

type Props = {
  open: boolean;
  selectedView: ViewItemDTO | null;
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  filterModelGetter: () => string | null;
  getFilterModelAsJson: () => string | null;
  createView: (viewData: Omit<ViewInputDTO, "type">) => Promise<void>;
  updateView: (viewData: Omit<ViewItemDTO, "owner">) => Promise<void>;
  setShowSaveOrEditViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteView?: (viewData: ViewItemDTO) => void;
};

const ContractSaveOrEditViewModal = ({
  open,
  selectedView,
  gridRef,
  filterModelGetter,
  getFilterModelAsJson,
  updateView,
  setShowSaveOrEditViewModal,
  createView,
  deleteView,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, getValues, setValue, watch, reset, setError } =
    useFormContext<{
      name: string;
      shared: boolean;
      default: boolean;
    }>();

  const handleSaveOrEditViewModalClose = async (
    action?: ModalResultEnum,
    values?: unknown
  ) => {
    try {
      if (action === ModalResultEnum.SAVE) {
        const columnStateAsJson = JSON.stringify(
          gridRef.current?.api.getColumnState()
        );
        const name = (values as { name: string }).name;
        await createView({
          name: name,
          data: columnStateAsJson,
          filter: filterModelGetter(),
          shared: watch().shared,
          default: watch().default,
        });
      } else if (action === ModalResultEnum.EDIT && selectedView) {
        // @Note: In order to keep the translations consistent
        // we display the default view with consistent translations
        // while still sending the value "DEFAULT_VIEW" to the API until the migration is done
        const getName = (name: string) => {
          const defaultViewEN = name === "Default view";
          const defaultViewDE = name === "Standardansicht";

          if (defaultViewEN || defaultViewDE) return "DEFAULT_VIEW";

          return name;
        };

        const viewData = {
          id: selectedView.id,
          name: getName((values as { name: string }).name),
          data: selectedView.data,
          filter: getFilterModelAsJson(),
          type: selectedView.type,
          shared: watch().shared,
          default: watch().default,
        };
        await updateView(viewData);

        if (watch().default) {
          enqueueSnackbar(
            t("pages.contracts.header.modals.notifications.success", {
              viewName: getName((values as { name: string }).name),
            }),
            { variant: "success" }
          );
        }
      }
      reset(initialValues);
      setShowSaveOrEditViewModal(false);
    } catch (e) {
      if (watch().default) {
        enqueueSnackbar(
          t("pages.contracts.header.modals.notifications.success"),
          { variant: "error" }
        );
      }

      setValidationErrors(
        e,
        setError,
        "pages.contracts.header.modals.saveViewModal",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  const handleKeyboardDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleModalClose = () => {
    void handleSaveOrEditViewModalClose(ModalResultEnum.CANCEL);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!selectedView?.id) return;
    e.preventDefault();
    e.stopPropagation();

    const deleteHandler = deleteView
      ? (type: ModalResultEnum.DELETE, id: ViewItemDTO | string | null) =>
          deleteView(selectedView)
      : () => null;
    deleteHandler && deleteHandler(ModalResultEnum.DELETE, selectedView.id);
  };

  const onSubmit = () => {
    void handleSaveOrEditViewModalClose(
      !selectedView ? ModalResultEnum.SAVE : ModalResultEnum.EDIT,
      getValues()
    );
  };

  const preselected = {
    name: selectedView?.name,
    shared: selectedView?.shared,
    default: selectedView?.default,
  };

  const currentFields = {
    name: watch()?.name,
    shared: watch()?.shared,
    default: watch()?.default,
  };

  const saveButtonDisabled = isEqual(preselected, currentFields);

  return (
    <NewModal
      open={open}
      handleClose={handleModalClose}
      fullWidth
      title={
        !selectedView
          ? t("pages.contracts.header.modals.saveViewModal.title")
          : t("pages.contracts.header.modals.editViewModal.title")
      }
      body={
        <ModalBody>
          <div>
            {!selectedView
              ? t("pages.contracts.header.modals.saveViewModal.subtitle")
              : t("pages.contracts.header.modals.editViewModal.subtitle")}
          </div>
          <Form name="contractSaveOrEditView" noValidate>
            <FormTextField
              control={control}
              name="name"
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleKeyboardDown(e)
              }
              label={
                !selectedView
                  ? t("pages.contracts.header.modals.saveViewModal.name")
                  : t("pages.contracts.header.modals.editViewModal.name")
              }
            />

            <SwitcherWrapper>
              <Tooltip
                title={
                  selectedView?.default
                    ? t(
                        "pages.contracts.header.modals.editViewModal.tooltip.switcher.default.disabled"
                      )
                    : ""
                }
              >
                <Switch
                  id="default"
                  checked={watch().default}
                  onChange={(e) => {
                    setValue("default", e.target.checked);
                    return e.target.checked;
                  }}
                  name="default"
                  label={t(
                    "pages.contracts.header.modals.editViewModal.setAsDefault"
                  )}
                  labelPosition="left"
                  stretch
                  disabled={!!selectedView?.default}
                />
              </Tooltip>
            </SwitcherWrapper>

            <SwitcherWrapper>
              <Switch
                id="shared"
                checked={watch().shared}
                onChange={(e) => {
                  setValue("shared", e.target.checked);
                  return e.target.checked;
                }}
                name="shared"
                label={t("pages.contracts.header.modals.editViewModal.share")}
                labelPosition="left"
                stretch
              />
            </SwitcherWrapper>
          </Form>
        </ModalBody>
      }
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: selectedView ? "space-between" : "flex-end",
            width: "100%",
          }}
        >
          {selectedView && (
            <Tooltip
              title={
                !!selectedView?.default || !!selectedView?.shared
                  ? t(
                      "pages.contracts.header.modals.editViewModal.tooltip.button.delete.message"
                    )
                  : ""
              }
              placement="top"
            >
              <div>
                <DeleteButton
                  label="delete-view"
                  name={t("pages.contracts.header.modals.buttons.deleteView")}
                  variant="secondary"
                  color="danger"
                  onClick={(e) => handleDeleteClick(e)}
                  disabled={!!selectedView?.default || !!selectedView?.shared}
                />
              </div>
            </Tooltip>
          )}
          <div
            style={{
              display: "flex",
              gap: theme.spacing.xl,
              justifySelf: "flex-end",
            }}
          >
            <CTAButton
              label="cancel"
              name={t("pages.contracts.header.modals.buttons.cancel")}
              variant="secondary"
              onClick={() => {
                handleModalClose();
              }}
            />
            <CTAButton
              label="save-view"
              type="submit"
              name={t("pages.contracts.header.modals.buttons.save")}
              variant="primary"
              onClick={onSubmit}
              disabled={saveButtonDisabled}
            />
          </div>
        </div>
      }
    />
  );
};

export default ContractSaveOrEditViewModal;
