import { CTAButton, NewModal } from "components";
import { AccessTokenDTO } from "openapi";
import { useSnackbar } from "notistack";
import { useAccessTokenDeleteMutation } from "shared/api/users";
import { Trans, useTranslation } from "react-i18next";

const TRANSLATION_PREFIX = "pages.settings.tabs.accessToken.deleteModal";

type Props = {
  selectedAccessToken: AccessTokenDTO;
  isOpen: boolean;
  handleOnClose: () => void;
};
const AccessTokenDeleteModal = ({
  selectedAccessToken,
  isOpen,
  handleOnClose,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: deleteAccessToken, isLoading: deleteLoading } =
    useAccessTokenDeleteMutation();

  const handleDeleteAccessToken = async () => {
    try {
      if (selectedAccessToken) {
        await deleteAccessToken({ id: selectedAccessToken.id });
        enqueueSnackbar(t(`${TRANSLATION_PREFIX}.snackbarMessage.success`), {
          variant: "success",
        });
        handleOnClose();
      }
    } catch (e) {
      enqueueSnackbar(t(`${TRANSLATION_PREFIX}.snackbarMessage.error`), {
        variant: "error",
      });
    }
  };

  return (
    <NewModal
      open={isOpen}
      handleClose={handleOnClose}
      title={t(`${TRANSLATION_PREFIX}.title`)}
      body={
        <Trans
          i18nKey={t(`${TRANSLATION_PREFIX}.description`)}
          values={{ accessTokenName: selectedAccessToken?.name }}
        />
      }
      footer={
        <>
          <CTAButton
            onClick={handleOnClose}
            name={t("common.buttons.close")}
            variant="secondary"
          />

          <CTAButton
            onClick={handleDeleteAccessToken}
            name={t("common.buttons.deleteConfirm")}
            color="danger"
            disabled={deleteLoading}
          />
        </>
      }
      fullWidth
    />
  );
};

export default AccessTokenDeleteModal;
