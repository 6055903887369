import { ICellRendererParams } from "ag-grid-community";
import { TagWrapper } from "./styles";
import { Chip } from "components";
import theme from "theme/theme";
import { ContractDTOV1 } from "openapi";

export const TagCellRenderer = (props: ICellRendererParams) => {
  const data = props.data as ContractDTOV1;
  if (!data || !data.tags) return;
  const tags = data.tags.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  if (!tags.length) return "-";

  return (
    <TagWrapper>
      {data.tags.map((tagData, index) => (
        <Chip blendMode="soft" key={index} color={theme.color.blue[600]}>
          {tagData.name}
        </Chip>
      ))}
    </TagWrapper>
  );
};
