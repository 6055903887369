import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { theme } from "theme";
import { Wrapper, Container } from "./styles";

type Props = {
  isOpen?: boolean;
  onClick?: () => void;
};

const ToggleArrowButton = ({ isOpen, onClick }: Props) => (
  <Wrapper>
    {isOpen ? (
      <Container open onClick={onClick}>
        <KeyboardDoubleArrowLeftIcon sx={{ color: theme.color.blue[600] }} />
      </Container>
    ) : (
      <Container onClick={onClick}>
        <KeyboardDoubleArrowRightIcon sx={{ color: theme.color.blue[600] }} />
      </Container>
    )}
  </Wrapper>
);

export default ToggleArrowButton;
