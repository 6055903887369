import { Box } from "@mui/material";
import CTAButton from "components/CTAButton/CTAButton";
import { theme } from "theme";
import { SubTitle, Title, TitleWrapper } from "./styles";

type SectionHeaderProps = {
  title?: string;
  subTitle?: string;
  baseButtonText?: string;
  colorStyle?: string;
  onButtonClick?: () => void;
  icon?: JSX.Element;
  noPadding?: boolean;
  iconStyle?: React.CSSProperties;
  buttonId?: string;
  hideButton?: boolean;
  buttonVariant?: "primary" | "secondary" | "tertiary" | undefined;
  buttonType?: "button" | "reset" | "submit" | undefined;
  buttonIcon?: JSX.Element | undefined | null;
  buttonIconPosition?: "right" | "left" | undefined;
  buttonColor?: keyof typeof theme.color | "danger";
  buttonHoverColor?: keyof typeof theme.color | undefined;
  buttonDisabled?: boolean;
  padding?: string;
  renameFieldActionButtons?: React.ReactNode;
};

const SectionHeader = ({
  title,
  subTitle,
  baseButtonText,
  buttonVariant,
  colorStyle = "none",
  onButtonClick,
  icon,
  padding = "0 0.75rem 1rem 1.25rem",
  noPadding,
  iconStyle,
  buttonIcon,
  buttonId,
  hideButton,
  buttonColor,
  buttonType,
  buttonIconPosition,
  buttonDisabled,
  buttonHoverColor,
  renameFieldActionButtons,
}: SectionHeaderProps) => {
  const boxPadding = noPadding ? { padding: "0" } : { padding: padding };

  return (
    <Box sx={boxPadding}>
      <Box className="flex-align-center">
        {icon && (
          <Box
            sx={{
              marginRight: "0.5rem",
              color: colorStyle,
              ...iconStyle,
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          className="flex-align-center"
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TitleWrapper>
            {title ? <Title colorstyle={colorStyle}>{title}</Title> : null}
            {subTitle ? (
              <SubTitle colorstyle={colorStyle}>{subTitle}</SubTitle>
            ) : null}
          </TitleWrapper>

          {!hideButton ? (
            <CTAButton
              name={baseButtonText}
              type={buttonType}
              variant={buttonVariant}
              icon={buttonIcon}
              iconPosition={buttonIconPosition}
              onClick={onButtonClick}
              label={buttonId}
              color={buttonColor}
              disabled={buttonDisabled}
              hoverColor={buttonHoverColor}
            />
          ) : (
            renameFieldActionButtons
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SectionHeader;
