import { useTranslation } from "react-i18next";
import { CTAButton, NewModal } from "components";
import { WarningIcon } from "components/Icons";

type Props = {
  title: string;
  description: string;
  showModal: boolean;
  handleSaveChanges: () => void;
  handleDiscardChanges: () => void;
  handleCloseModal: (shouldResetBlocker?: boolean) => void;
  saveBtnId?: string;
  discardBtnId?: string;
  loading?: boolean;
};

export const UnsavedChangesModal = ({
  title,
  description,
  loading,
  showModal,
  handleDiscardChanges,
  handleSaveChanges,
  handleCloseModal,
  saveBtnId,
  discardBtnId,
}: Props) => {
  const { t } = useTranslation();

  if (!showModal) return null;

  const onSave = () => {
    handleSaveChanges();
    handleCloseModal(false);
  };

  const onDiscard = () => {
    handleDiscardChanges();
    handleCloseModal(false);
  };

  const body = <span>{description}</span>;

  const footer = (
    <>
      <CTAButton
        variant="secondary"
        label={saveBtnId}
        type="submit"
        onClick={onSave}
        name={t("common.buttons.save")}
        loading={loading}
      />

      <CTAButton
        label={discardBtnId}
        color="danger"
        onClick={onDiscard}
        name={t("common.buttons.discard")}
      />
    </>
  );

  return (
    <NewModal
      aria-label="unsavedChangesModal"
      open={showModal}
      handleClose={handleCloseModal}
      fullWidth
      title={title}
      body={body}
      footer={footer}
      icon={<WarningIcon />}
    />
  );
};
