import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Box, Divider } from "@mui/material";
import { ContractDTOV1 } from "openapi";
import { Chip } from "components";
import theme from "theme/theme";

const MainTitleWrapper = styled.h1`
  margin: 0;
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.semiBold};
  line-height: 2.25rem;
  word-break: break-word;
  display: flex;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
`;

const SubTitleWrapper = styled.p`
  margin-bottom: ${theme.spacing.xl};
  margin-top: 0;
`;

type Prop = {
  titleKey: string;
  mainTitle?: string;
  isMainContract?: boolean;
  mainContractCategoryId: string | undefined;
  titleInformation?: string;
  subTitleKey?: string;
  baseButtonTextKey?: string;
  icon?: JSX.Element;
  contractData?: ContractDTOV1;
  contractId: string | undefined;
  refetchData?: () => Promise<void>;
};

const HeaderWrapper = ({
  mainTitle,
  isMainContract = false,
  subTitleKey,
}: Prop) => {
  const { t } = useTranslation();

  const chipStyles = {
    whiteSpace: "nowrap",
    height: "fit-content",
  } as React.CSSProperties;

  return (
    <>
      {mainTitle && (
        <>
          <Box className="flex" sx={{ justifyContent: "space-between" }}>
            <Header>
              <MainTitleWrapper data-cy="contractTitle">
                <span>{mainTitle}</span>
              </MainTitleWrapper>

              <Chip
                color={
                  isMainContract
                    ? theme.color.blue[600]
                    : theme.color.green[600]
                }
                style={chipStyles}
              >
                {isMainContract
                  ? t("contractType.MAIN_CONTRACT")
                  : t("contractType.ATTACHMENT")}
              </Chip>
            </Header>
          </Box>

          <Divider
            sx={{
              backgroundColor: theme.color.gray[100],
              margin: `${theme.spacing.sm} 0`,
            }}
          />
        </>
      )}
      {subTitleKey && <SubTitleWrapper>{t(subTitleKey)}</SubTitleWrapper>}
    </>
  );
};

export default HeaderWrapper;
