import { Wrapper, Title } from "./styles";

type InfoProps = {
  className?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  text: string;
  element?: React.ReactNode;
};

const Info = ({ className, onClick, icon, text, element }: InfoProps) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Title>
        {icon}
        <div>{text}</div>
      </Title>
      {element}
    </Wrapper>
  );
};

export default Info;
