import { Container } from "../ContractSummary/styles";

const DocumentSummary = () => {
  return (
    <Container
      data-testid="document-summary"
      style={{ margin: "var(--spacing-lg)" }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar,
      eros vitae ultricies congue, ipsum velit semper felis, in dictum ligula
      enim ac velit. Sed vel velit in ipsum gravida gravida. Donec condimentum,
      velit sed pellentesque dignissim, velit urna imperdiet nisi, at commodo
      felis velit ut justo. Aliquam erat volutpat.
    </Container>
  );
};

export default DocumentSummary;
