import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryDTO, ContractSectionDTOV1 } from "openapi";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { ColoredPoint } from "components";
import { AddNewButton } from "../../components";
import {
  Column,
  ColumnTitle,
  ListContainer,
  Background,
} from "../containerStyles";
import { AddNewCategoryModal } from "./Modals";
import { CategoryItem, CategoryLabelBlock } from "./styles";
import { getIdByName } from "../../utils";
import { UnsavedChangesModal } from "components";
import { unstable_useBlocker, useNavigate } from "react-router-dom";
import { routePaths } from "../../../../../../constants";
import { useLocale } from "hooks";

type Props = {
  categories: CategoryDTO[];
  refetchData: () => Promise<void>;
  setSections: (value: ContractSectionDTOV1[]) => void;
  selectedCategory: CategoryDTO | null;
  setSelectedCategory: (value: CategoryDTO) => void;
  isDirty: boolean;
  setIsDirty: (value: boolean) => void;
  handleUpdateCategory: () => Promise<void>;
  sections: ContractSectionDTOV1[] | null;
};

const Categories = ({
  categories: data,
  refetchData,
  setSections,
  selectedCategory,
  setSelectedCategory,
  isDirty,
  setIsDirty,
  handleUpdateCategory,
  sections,
}: Props) => {
  const { t } = useTranslation();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [preSelectedCategory, setPreSelectedCategory] =
    useState(selectedCategory);
  const [preSelectedSections, setPreSelectedSections] = useState(
    selectedCategory?.sections
  );
  const [isCreateModalFollows, setIsCreateModalFollows] = useState(false);
  const { locale } = useLocale();

  const navigate = useNavigate();
  const blocker = unstable_useBlocker(isDirty);

  const handleDiscardChanges = () => {
    if (!selectedCategory) return;
    if (isCreateModalFollows) {
      setOpenCreateModal(true);
      setIsCreateModalFollows(false);
    }

    setSections(selectedCategory?.sections);
    setIsDirty(false);
    handleOnCategoryChange(selectedCategory, selectedCategory?.sections);
    blocker.proceed?.();
  };

  const handleSaveChanges = async () => {
    await handleUpdateCategory();
    if (isCreateModalFollows) {
      setOpenCreateModal(true);
      setIsCreateModalFollows(false);
    }
    setIsDirty(false);
    handleOnCategoryChange(
      preSelectedCategory as CategoryDTO,
      preSelectedSections as ContractSectionDTOV1[]
    );
    blocker.proceed?.();
  };

  const handleOnCategoryChange = (
    category: CategoryDTO,
    sections: ContractSectionDTOV1[]
  ) => {
    setSelectedCategory(category);
    setSections(sections);
  };

  const handleCloseModal = (shouldResetBlocker = true) => {
    setShowModal(false);
    if (shouldResetBlocker) {
      blocker.reset?.();
    }
  };

  return (
    <Column type="secondary">
      <ColumnTitle>
        <span>
          {t("pages.settings.organization.administrator.categories.title")}
        </span>
        <AddNewButton
          id="addNewCategoryButton"
          onClick={() => {
            if (isDirty) {
              setPreSelectedCategory(selectedCategory);
              setPreSelectedSections(sections || selectedCategory?.sections);
              setShowModal(true);
              setIsCreateModalFollows(true);
              return;
            } else {
              setOpenCreateModal(true);
              setIsCreateModalFollows(false);
              return;
            }
          }}
          text={t(
            "pages.settings.organization.administrator.categories.addNew"
          )}
        />
      </ColumnTitle>

      <Background white={true}>
        <ListContainer id="category-list">
          {!isNil(data) &&
            !isEmpty(data) &&
            data.map((item) => {
              return (
                <div key={item.id} id={getIdByName(item.name.en)}>
                  <CategoryItem active={selectedCategory?.id === item.id}>
                    <CategoryLabelBlock
                      onClick={() => {
                        if (selectedCategory?.id === item.id) return;

                        if (isDirty) {
                          setPreSelectedCategory(item);
                          setPreSelectedSections(item.sections);
                          setShowModal(true);
                          return;
                        }

                        handleOnCategoryChange(item, item.sections);
                      }}
                    >
                      <ColoredPoint color={item.color} />
                      <span>{item.name[locale]}</span>
                    </CategoryLabelBlock>

                    <IconButton
                      id={`${getIdByName(
                        item.name.en.toLowerCase()
                      )}-settings-btn`}
                      className="settings"
                      onClick={() =>
                        navigate(`${routePaths.SETTINGS_CATEGORY}/${item.id}`)
                      }
                    >
                      <SettingsIcon fontSize="small" />
                    </IconButton>
                  </CategoryItem>
                </div>
              );
            })}
        </ListContainer>
      </Background>

      <UnsavedChangesModal
        title={t(
          "pages.settings.organization.administrator.categories.modal.unsavedChanges.title"
        )}
        description={t(
          "pages.settings.organization.administrator.categories.modal.unsavedChanges.description"
        )}
        showModal={showModal || (!!blocker && blocker.state === "blocked")}
        handleDiscardChanges={handleDiscardChanges}
        handleSaveChanges={handleSaveChanges}
        handleCloseModal={handleCloseModal}
        saveBtnId={"saveCategoryChanges"}
        discardBtnId={"discardCategoryChanges"}
      />

      <AddNewCategoryModal
        open={openCreateModal}
        categories={data}
        onClose={(category) => {
          void refetchData();
          if (category) {
            handleOnCategoryChange(category, category.sections);
          }
          setOpenCreateModal(false);
        }}
      />
    </Column>
  );
};

export default Categories;
