import { useTranslation } from "react-i18next";
import { useAuthentication } from "hooks/GlobalStateHooks";
import AuthCardWrapper from "components/AuthCardWrapper/AuthCardWrapper";
import { CreateTeam } from "containers";
import { Wrapper } from "./styles";
import {
  SubTitleWrapper,
  TitleWrapper,
} from "components/AuthCardWrapper/AuthCardWrapper.styles";

const OrganizationCreation = () => {
  const { t } = useTranslation();
  const { logout } = useAuthentication();

  return (
    <Wrapper>
      <AuthCardWrapper
        useFooterActionInsteadofLink
        footerActionLabelKey={"navbar.logout"}
        footerActionClick={() => logout()}
      >
        <CreateTeam
          shouldRedirect
          variant="parent"
          header={
            <>
              <TitleWrapper>{t("pages.teamCreation.title")}</TitleWrapper>
              <SubTitleWrapper>
                {t("pages.teamCreation.subtitle")}
              </SubTitleWrapper>
            </>
          }
        />
      </AuthCardWrapper>
    </Wrapper>
  );
};

export default OrganizationCreation;
