import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import AuthCardWrapper from "components/AuthCardWrapper/AuthCardWrapper";
import VerifyEmailForm from "./verifyEmailForm";

const VerifyEmail = () => {
  const { t } = useTranslation();

  return (
    <Container component="main" sx={{ marginTop: "2.5rem" }}>
      <AuthCardWrapper
        title={t("pages.verify.title")}
        subTitle={t("pages.verify.subtitle")}
      >
        <VerifyEmailForm />
      </AuthCardWrapper>
    </Container>
  );
};

export default VerifyEmail;
