import { useTranslation } from "react-i18next";
import { Wrapper, Header, Title, AiIcon, Divider, Container } from "./styles";

const ContractSummary = () => {
  const { t } = useTranslation();
  return (
    <Wrapper data-testid="contract-summary">
      <Header>
        <Title>
          <AiIcon />
          <span>{t("pages.contractDetails.summary.contract.title")}</span>
          <Divider />
        </Title>
      </Header>
      <Container data-testid="summary-text">
        {/* @Todo: Update */}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pulvinar,
        eros vitae ultricies congue, ipsum velit semper felis, in dictum ligula
        enim ac velit. Sed vel velit in ipsum gravida gravida. Donec
        condimentum, velit sed pellentesque dignissim, velit urna imperdiet
        nisi, at commodo felis velit ut justo. Aliquam erat volutpat.
      </Container>
    </Wrapper>
  );
};

export default ContractSummary;
