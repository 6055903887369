import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useStyles as useStyledButtons } from "components/StyledComponents/StyledBaseButtons";
import theme from "theme/theme";
import { Tooltip } from "@mui/material";

type Props = {
  id: string;
  text: string;
  onClick: () => void;
};

const AddNewButton = ({ onClick, text, ...props }: Props) => {
  const buttonClasses = useStyledButtons();
  return (
    <IconButton
      {...props}
      className={buttonClasses.addFieldButton}
      sx={{ width: "fit-content" }}
      onClick={onClick}
    >
      <Tooltip title={text}>
        <AddCircleIcon sx={{ color: theme.color.green[400] }} />
      </Tooltip>
    </IconButton>
  );
};

export default AddNewButton;
