import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormTextField } from "components";

const DescriptionComponent = ({
  control,
  disabled,
}: {
  control: unknown;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <FormTextField
      control={control as Control}
      name="description"
      label={t("pages.tasks.modal.fields.description")}
      multiline
      rows={4}
      disabled={disabled}
    />
  );
};

export default DescriptionComponent;
