import { useEffect, useState } from "react";
import { Header, Title, Wrapper, Top, Bottom, Buttons, Button } from "./styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DocumentListItem from "../../../../new-components/DocumentListItem/DocumentListItem";
import { useTranslation } from "react-i18next";
import { CTAButton } from "components";
import SearchBar, {
  SearchBarOnChangeValue,
} from "new-components/TextEditor/components/SearchBar";
import Plus from "assets/svg/plus.svg?react";
import ImportDocument from "./ImportDocument/ImportDocument";
import { DocumentDTO } from "openapi/models/DocumentDTO";
import {
  useDeleteDocumentMutation,
  useFetchDocumentsQuery,
  useUpdateDocumentDataMutation,
} from "shared/api/documents";
import { useTeam } from "contexts/team/hooks";
import { useNavigate, useParams } from "react-router";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import BinIcon from "assets/svg/trash-icon.svg?react";
import DownloadIcon from "assets/svg/file-download-03.svg?react";
import PreviewIcon from "assets/svg/file-preview.svg?react";
import SearchIcon from "assets/svg/search-lg.svg?react";
import { Tooltip } from "@mui/material";
import DeleteDocumentModal from "pages/Contract/Modals/DeleteDocument";
import { useTeamMembersQuery } from "shared/api/team-members";
import saveAs from "file-saver";
import { DocumentService } from "openapi/services/DocumentService";
import Document from "../Document/Document";
import { useSnackbar } from "notistack";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { AcceptedFileType } from "../../../../shared/enums/document.enum";
import {
  extractFileExtension,
  extractFileName,
  isAcceptedFileType,
} from "constants/utils";
import { getNewOrder } from "./Documents-utils";
import { CardWrapper } from "../Document/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useBulkDownloadDocuments } from "../../../../shared/api/organization";
import { Features } from "../../../../constants";
import routePaths from "constants/routePaths";
import { useSearchParams } from "react-router-dom";
import DocumentIcon from "assets/svg/document.svg?react";
import ImageIcon from "assets/svg/image.svg?react";
import { ContentWrapper } from "../RightSide/styles";
import { CONTENT_HEADER_HEIGHT, CONTENT_HEIGHT } from "../RightSide/helpers";
import { SEARCH_TYPES, useFullTextSearch } from "hooks/useFullTextSearch";
import OCRIcon from "assets/svg/scan-text.svg?react";
import { IconWrapper } from "new-components";
import { SelectFileOrTemplate } from "../Document/SelectFileOrTemplate";
import { useContractQuery } from "shared/api";

const Documents = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { id: contractId, documentId: documentIdParam } = useParams();
  const { organizationId, selectedTeamId, hasFeature } = useTeam();
  const {
    fullTextSearchQuery,
    handleFullTextSearchQueryChange,
    isFullTextSearch,
    searchType,
  } = useFullTextSearch();
  const { data: contract } = useContractQuery(selectedTeamId, contractId);

  const {
    data: documents,
    isLoading,
    refetch,
  } = useFetchDocumentsQuery(
    organizationId,
    contract?.teamId,
    contract?.id,
    searchType,
    isFullTextSearch ? fullTextSearchQuery : undefined
  );

  useEffect(() => {
    if (!(searchType === SEARCH_TYPES.TEXT)) {
      return;
    }

    const timeout = setTimeout(async () => {
      await refetch();
    }, 350);

    return () => clearTimeout(timeout);
  }, [fullTextSearchQuery]);

  const { mutateAsync: updateDocumentData } = useUpdateDocumentDataMutation();
  const { mutateAsync: bulkDownloadDocuments } = useBulkDownloadDocuments();

  const { data: teamMemberData } = useTeamMembersQuery(selectedTeamId);

  const { t } = useTranslation();
  const [showSearch, setShowSearch] = useState(isFullTextSearch);
  const [showAddDocumentModal, setShowAddDocumentModal] =
    useState<boolean>(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDocumentList, setShowDocumentList] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentDTO>();
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentDTO[]>([]);
  const { mutateAsync: deleteDocument } = useDeleteDocumentMutation();

  const handleModal = () => {
    setShowAddDocumentModal((prevProps) => !prevProps);
  };

  const handleSearch = () => {
    setShowSearch((prevProps) => !prevProps);
  };

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (contract && active && over && active.id !== over.id) {
      const oldIndex = filteredDocuments.findIndex(
        (document) => document.id === active.id
      );
      const newIndex = filteredDocuments.findIndex(
        (document) => document.id === over.id
      );

      const reorderedDocument = arrayMove(
        filteredDocuments,
        oldIndex,
        newIndex
      );
      setFilteredDocuments(reorderedDocument);

      const newOrder = getNewOrder(reorderedDocument, newIndex);

      try {
        await updateDocumentData({
          organizationId,
          teamId: contract?.teamId,
          documentId: active.id as string,
          data: { order: newOrder },
          contractId: contract.id,
        });
      } catch (e) {
        enqueueSnackbar({
          message: t("common.errors.unexpected"),
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    setFilteredDocuments(documents || []);
  }, [showSearch]);

  useEffect(() => {
    if (isLoading || !documents) return;
    setFilteredDocuments(documents);

    if (selectedDocument) {
      // if document name is updated, it should get update in selectedDocument
      const selectedDoc = documents.find((doc) => doc.id === documentIdParam);
      if (selectedDoc && selectedDocument.filename !== selectedDoc.filename) {
        setSelectedDocument(selectedDoc);
      }
      return;
    }

    if (!isFullTextSearch && documents.length === 0) {
      setShowDocumentList(false);
      return;
    }

    // preview selected doc if id is in url
    if (documentIdParam) {
      const selectedDoc = documents.find((doc) => doc.id === documentIdParam);
      previewDocument(selectedDoc as DocumentDTO);
      setShowDocumentList(false);
      return;
    }

    if (showDocumentList === true) {
      return;
    }

    if (documents.length === 1 && isPreview(documents[0])) {
      previewDocument(documents[0]);
      setShowDocumentList(false);
      return;
    }

    setShowDocumentList(true);
  }, [isLoading, documents]);

  const onFilterChange = (value: SearchBarOnChangeValue) => {
    if (searchType === SEARCH_TYPES.TEXT) {
      handleFullTextSearchQueryChange(value);
      return;
    }

    const filteredItems = documents?.filter((document) =>
      document?.filename?.toLowerCase().includes(value.toLowerCase())
    );
    if (filteredItems) setFilteredDocuments(filteredItems);
  };

  const getAuthorName = (authorId: string | null) => {
    if (authorId) {
      const author = teamMemberData?.members?.find(
        (member) => member.id === authorId
      );
      const authorName = `${author?.firstname ? author.firstname : ""} ${
        author?.lastname ? author.lastname : ""
      }`;
      return authorName;
    }
    return "";
  };

  const previewDocument = (document: DocumentDTO) => {
    setSelectedDocument(document);
    setShowDocumentList(false);
  };

  const isPreview = (document: DocumentDTO) => {
    return (
      document.mimetype === AcceptedFileType.PDF ||
      document.mimetype === AcceptedFileType.LEXICAL
    );
  };

  const downloadAllDocuments = async () => {
    if (!contract?.id) {
      return;
    }
    await bulkDownloadDocuments({
      organizationId: organizationId,
      requestBody: { contractIds: [contract.id], skipAttachments: true },
    });
  };

  const downloadDocument = async (document: DocumentDTO) => {
    try {
      if (!contract?.id || !contract?.teamId) {
        return;
      }

      const documentFileBlob = (await DocumentService.downloadDocument(
        organizationId,
        contract.teamId,
        contract.id,
        document.id
      )) as Blob;
      if (documentFileBlob) {
        saveAs(documentFileBlob, document.filename);
      }
    } catch (err) {
      enqueueSnackbar(t("common.errors.unexpected"), {
        variant: "error",
      });
    }
  };

  const handleOnDeleteDocument = async () => {
    if (selectedDocument) {
      const documentId = selectedDocument.id;
      await deleteDocument(
        {
          organizationId,
          teamId: contract?.teamId,
          contractId: contract?.id,
          documentId,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(
              t("pages.contractDetails.documents.deleteSuccess"),
              { variant: "success" }
            );
          },
          onError: () => {
            enqueueSnackbar(t("pages.contractDetails.documents.deleteError"), {
              variant: "error",
            });
          },
        }
      );
      setSelectedDocument(undefined);
      setShowDeleteDocumentModal(false);
    }
  };

  const confirmDelete = (document: DocumentDTO) => {
    setSelectedDocument(document);
    setShowDeleteDocumentModal(true);
  };

  const resetStates = () => {
    setShowDeleteDocumentModal(false);
    setShowDocumentList(true);
    setSelectedDocument(undefined);
    navigate(
      `${routePaths.CONTRACT_DOCUMENTS.replace(
        ":id",
        contract?.id ?? ""
      )}?${searchParams.toString()}`
    );
  };

  if (!documents) {
    return (
      <CardWrapper data-testid="document-loader">
        <CircularProgress />
      </CardWrapper>
    );
  }

  if (documents.length === 0) {
    return <SelectFileOrTemplate />;
  }

  // Quick fix for deleting newly uploaded single document. Find a better way
  const getSelectedDocument = () => {
    if (!showDocumentList && documents?.length === 1) {
      return documents[0];
    }
    return selectedDocument;
  };

  const onNextClick = () => {
    if (!selectedDocument) {
      return;
    }
    const currentIndex = documents.indexOf(selectedDocument);

    if (currentIndex !== -1) {
      const nextIndex = currentIndex + 1;
      if (nextIndex < documents.length) {
        setSelectedDocument(documents[nextIndex]);
      }
    }
  };

  const canNavigateNext = () => {
    if (!selectedDocument) {
      return false;
    }

    const currentIndex = documents.indexOf(selectedDocument);
    return currentIndex !== -1 && currentIndex < documents.length - 1;
  };

  const canNavigatePrevious = () => {
    if (!selectedDocument) {
      return false;
    }

    const currentIndex = documents.indexOf(selectedDocument);
    return currentIndex > 0; // Returns true if not the first document
  };

  const onPreviousClick = () => {
    if (!selectedDocument) {
      return;
    }

    const currentIndex = documents.indexOf(selectedDocument);

    if (currentIndex !== -1) {
      const previousIndex =
        (currentIndex - 1 + documents.length) % documents.length;
      setSelectedDocument(documents[previousIndex]);
    }
  };

  const getDocumentFileType = (document: DocumentDTO) => {
    const type = extractFileExtension(document.filename, document.mimetype)
      .toUpperCase()
      .replace(".", "");
    const icon = document.mimetype.startsWith("image/") ? (
      <ImageIcon />
    ) : (
      <DocumentIcon />
    );
    return { icon: icon, text: type };
  };

  const getTooltipTextOnDisable = (document: DocumentDTO) => {
    return t("pages.contractDetails.documents.canNotPreview", {
      fileType: extractFileExtension("", document?.mimetype),
    });
  };

  return (
    <ContentWrapper>
      {showDocumentList && (
        <>
          <Header>
            <Top>
              <Title>
                <span>{t("pages.contractDetails.folders.documents")}</span>
              </Title>
              <Buttons>
                <Tooltip title={t("pages.contractDetails.documents.search")}>
                  <Button
                    data-testid={"documents-search-button"}
                    variant="tertiary"
                    icon={<SearchIcon />}
                    size="tiny"
                    onClick={handleSearch}
                  />
                </Tooltip>
                {hasFeature(Features.BULK_DOWNLOAD) && (
                  <Tooltip
                    title={t("pages.contractDetails.documents.downloadAll")}
                  >
                    <Button
                      data-testid={"documents-download-button"}
                      variant="tertiary"
                      icon={<DownloadIcon />}
                      size="tiny"
                      onClick={downloadAllDocuments}
                    />
                  </Tooltip>
                )}
                <Tooltip
                  title={t("pages.contractDetails.documents.addDocument")}
                >
                  <Button
                    data-testid={"documents-add-button"}
                    variant="tertiary"
                    icon={<Plus />}
                    size="tiny"
                    onClick={handleModal}
                  />
                </Tooltip>
              </Buttons>
            </Top>
            {showSearch && (
              <Bottom>
                <SearchBar onChange={onFilterChange} />
              </Bottom>
            )}
          </Header>
          <OverlayScrollbarsComponent
            defer
            style={{
              overflowX: "auto",
              height: `calc(${CONTENT_HEIGHT} - ${CONTENT_HEADER_HEIGHT})`,
            }}
          >
            <Wrapper>
              <DndContext
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                {filteredDocuments && (
                  <SortableContext
                    items={filteredDocuments}
                    strategy={verticalListSortingStrategy}
                  >
                    {filteredDocuments.map((filteredDocument) => {
                      const shallPreview = isAcceptedFileType(
                        filteredDocument.mimetype
                      );
                      return (
                        <DocumentListItem
                          id={filteredDocument.id}
                          key={filteredDocument.id}
                          listItem={{
                            createdAt: filteredDocument.createdAt,
                            categoryId: "",
                            name: extractFileName(filteredDocument.filename),
                          }}
                          authorName={getAuthorName(filteredDocument.authorId)}
                          iconContent={getDocumentFileType(filteredDocument)}
                          onClick={
                            isPreview(filteredDocument)
                              ? () => previewDocument(filteredDocument)
                              : undefined
                          }
                          buttonPlaceholder={
                            isFullTextSearch && (
                              <IconWrapper color="var(--color-text-primary)">
                                <OCRIcon />
                              </IconWrapper>
                            )
                          }
                          buttons={
                            <>
                              <Tooltip
                                placement="top"
                                title={t("common.buttons.download")}
                              >
                                <CTAButton
                                  variant="tertiary"
                                  icon={<DownloadIcon />}
                                  iconSize="medium"
                                  onClick={() =>
                                    downloadDocument(filteredDocument)
                                  }
                                />
                              </Tooltip>
                              {
                                <Tooltip
                                  placement="top"
                                  title={
                                    shallPreview
                                      ? t("common.buttons.preview")
                                      : getTooltipTextOnDisable(
                                          filteredDocument
                                        )
                                  }
                                >
                                  <span>
                                    <CTAButton
                                      variant="tertiary"
                                      icon={<PreviewIcon />}
                                      iconSize="medium"
                                      disabled={!shallPreview}
                                      onClick={() =>
                                        shallPreview
                                          ? previewDocument(filteredDocument)
                                          : null
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              }
                              <Tooltip
                                placement="top"
                                title={t("common.buttons.delete")}
                              >
                                <CTAButton
                                  variant="tertiary"
                                  icon={<BinIcon />}
                                  iconSize="medium"
                                  onClick={() =>
                                    confirmDelete(filteredDocument)
                                  }
                                />
                              </Tooltip>
                            </>
                          }
                          isDraggable={true}
                        />
                      );
                    })}
                  </SortableContext>
                )}
              </DndContext>
            </Wrapper>
          </OverlayScrollbarsComponent>
        </>
      )}
      {showAddDocumentModal && (
        <ImportDocument
          documents={documents}
          contractTeamId={contract?.teamId}
          showAddDocumentModal={showAddDocumentModal}
          handleClose={() => setShowAddDocumentModal(false)}
        />
      )}
      {showDeleteDocumentModal && (
        <DeleteDocumentModal
          open={showDeleteDocumentModal}
          handleClose={() => setShowDeleteDocumentModal(false)}
          handleOnDeleteDocument={handleOnDeleteDocument}
        />
      )}
      {!showDocumentList && (
        <Document
          selectedDocument={selectedDocument}
          onBackClick={() => resetStates()}
          onDeleteClick={() =>
            confirmDelete(getSelectedDocument() as DocumentDTO)
          }
          onDownloadClick={() =>
            downloadDocument(getSelectedDocument() as DocumentDTO)
          }
          onNextClick={canNavigateNext() ? onNextClick : undefined}
          onPreviousClick={canNavigatePrevious() ? onPreviousClick : undefined}
        />
      )}
    </ContentWrapper>
  );
};

export default Documents;
