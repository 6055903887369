import * as Sentry from "@sentry/react";
import { ICellRendererParams } from "ag-grid-community";
import { ContractFieldDTOV1 } from "openapi/models/ContractFieldDTOV1";
import React from "react";
import { CustomCellEditor } from "../../pages/Contracts/CellEditors/CustomCellEditor";
import { DateCellEditor } from "../../pages/Contracts/CellEditors/DateCellEditor";
import { NumberCellEditor } from "../../pages/Contracts/CellEditors/NumberCellEditor";
import { SelectCellEditor } from "../../pages/Contracts/CellEditors/SelectCellEditor";
import ContactCellRenderer from "../../pages/Contracts/CellRenderers/ContactCellRenderer";
import { CellValue } from "../../pages/Contracts/helpers";
import { AmountDatapoint } from "./AmountDatapoint";
import { ContactDatapoint } from "./ContactDatapoint";
import { CountryDatapoint } from "./CountryDatapoint";
import { DateDatapoint } from "./DateDatapoint";
import { DurationDatapoint } from "./DurationDatapoint";
import { FormulaDatapoint } from "./FormulaDatapoint";
import { evaluateLink, LinkDatapoint } from "./LinkDatapoint";
import { ListDatapoint } from "./ListDatapoint";
import { MultiLineTextDatapoint } from "./MultiLineTextDatapoint";
import { NumberDatapoint } from "./NumberDatapoint";
import { SingleLineTextDatapoint } from "./SingleLineTextDatapoint";
import { DataPointFactoryProps, DataPointProps } from "./types";
import { AdminContactDatapoint } from "./AdminContactDatapoint";
import { ListCellRenderer } from "pages/Contracts/CellRenderers/ListCellRenderer";

export const DataPoint = ({
  id,
  definitions,
  values,
  editable,
  isLoading,
  fetchData,
  contactTypes,
  contactDefinitions,
  contacts,
  refetchContacts,
  analysisSuggestions,
  showAnalysis,
  categoryId,
  teamId,
  contractId,
  admin = false,
}: DataPointFactoryProps) => {
  const definition = definitions.find((definition) => definition.id === id);
  if (!definition) {
    Sentry.captureMessage(`Couldn't find defintion id: ${id}`);
    return null;
  }

  const datapointsByType: Record<
    ContractFieldDTOV1.type,
    React.FC<DataPointProps>
  > = {
    [ContractFieldDTOV1.type.TEXTFIELD]: SingleLineTextDatapoint,
    [ContractFieldDTOV1.type.TEXTAREA]: MultiLineTextDatapoint,
    [ContractFieldDTOV1.type.NUMBER]: NumberDatapoint,
    [ContractFieldDTOV1.type.DATE]: DateDatapoint,
    [ContractFieldDTOV1.type.LIST]: ListDatapoint,
    [ContractFieldDTOV1.type.AMOUNT]: AmountDatapoint,
    [ContractFieldDTOV1.type.LINK]: LinkDatapoint,
    [ContractFieldDTOV1.type.COUNTRY]: CountryDatapoint,
    [ContractFieldDTOV1.type.FORMULA]: FormulaDatapoint,
    [ContractFieldDTOV1.type.DURATION]: DurationDatapoint,
    [ContractFieldDTOV1.type.CONTACT]: admin
      ? AdminContactDatapoint
      : ContactDatapoint,
  };

  const object = datapointsByType[definition.type];
  return React.createElement(object, {
    definition: definition,
    definitions: definitions,
    values: values,
    editable: editable,
    isLoading: isLoading,
    fetchData: fetchData,
    contactTypes: contactTypes,
    contactDefinitions: contactDefinitions,
    contacts: contacts,
    refetchContacts: refetchContacts,
    analysisSuggestions: analysisSuggestions,
    showAnalysis: showAnalysis,
    categoryId: categoryId,
    teamId: teamId,
    contractId: contractId,
  });
};

export const CellEditors: Record<ContractFieldDTOV1.type, unknown> = {
  TEXTFIELD: CustomCellEditor,
  TEXTAREA: CustomCellEditor,
  NUMBER: NumberCellEditor,
  LIST: SelectCellEditor,
  LINK: CustomCellEditor,
  DATE: DateCellEditor,
  AMOUNT: NumberCellEditor,
  COUNTRY: SelectCellEditor,
  DURATION: CustomCellEditor,
  FORMULA: CustomCellEditor,
  CONTACT: SelectCellEditor,
};
export const CellRenderers: Record<string, unknown> = {
  CONTACT: ContactCellRenderer,
  LIST: ListCellRenderer,
  LINK: (params: ICellRendererParams) => {
    const cellValue = params.value as CellValue;
    const value = cellValue?.value ?? cellValue;
    return evaluateLink({
      value: value as string,
    });
  },
};
