import { Control } from "react-hook-form";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { useTranslation } from "react-i18next";
import { MarkAsDoneButton, StatusWrapper } from "./styles";
import { Tooltip } from "@mui/material";
import DoneIcon from "assets/svg/done-icon.svg?react";
import { TaskNewDTO } from "openapi";

type Props = {
  control: unknown;
  onButtonClick: () => void;
  disabled?: boolean;
  taskClosed?: boolean;
  buttonHidden?: boolean;
};

const Status = ({
  control,
  disabled,
  onButtonClick,
  taskClosed,
  buttonHidden,
}: Props) => {
  const { t } = useTranslation();

  return (
    <StatusWrapper>
      <FormSelect
        label={t("common.status")}
        name="status"
        control={control as Control}
        options={Object.keys(TaskNewDTO.status)}
        translationPrefix="pages.tasks.modal.fields.status.options"
        required
        disabled={disabled}
        style={{ maxWidth: "140px" }}
      />
      {!buttonHidden ? (
        <Tooltip title={t("pages.tasks.modal.hints.markAsDone")}>
          <MarkAsDoneButton
            type="button"
            onClick={onButtonClick}
            done={taskClosed}
          >
            <DoneIcon />
          </MarkAsDoneButton>
        </Tooltip>
      ) : null}
    </StatusWrapper>
  );
};

export default Status;
