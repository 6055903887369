import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export type FormCheckboxProps<T extends FieldValues> = Omit<
  CheckboxProps,
  "name"
> & {
  control?: Control<T>;
  name: Path<T>;
  label: React.ReactNode;
  required?: boolean;
  onChangeCallback?: () => void;
};

export function FormCheckbox<T extends FieldValues>({
  name,
  control,
  label,
  required,
  onChangeCallback,
  ...rest
}: FormCheckboxProps<T>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { invalid, error },
      }) => {
        return (
          <FormControl required={required} error={invalid} fullWidth>
            <FormGroup
              row
              sx={{
                alignItems: "center",
              }}
            >
              <FormControlLabel
                label={label || ""}
                control={
                  <Checkbox
                    {...rest}
                    id={name}
                    name={name}
                    color="primary"
                    size="medium"
                    style={{
                      color: invalid ? red[400] : undefined,
                      minWidth: "42px",
                    }}
                    value={value}
                    checked={!!value}
                    onChange={() => {
                      onChange(!value);
                      onChangeCallback && onChangeCallback();
                    }}
                  />
                }
              />
              {error?.message && (
                <FormHelperText error={invalid}>
                  {t(error.message)}
                </FormHelperText>
              )}
            </FormGroup>
          </FormControl>
        );
      }}
    />
  );
}
