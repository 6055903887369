import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { SnackbarKey, useSnackbar } from "notistack";

type Props = {
  snackbarKey: SnackbarKey;
};

export const SnackbarWithCloseButton = ({ snackbarKey }: Props) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Close sx={{ color: "white" }} />
    </IconButton>
  );
};
