import { useTranslation } from "react-i18next";
import { Content, Title, Wrapper } from "./styles";
import GradientHeading from "../GradientHeading/GradientHeading";

export type ResultProps = {
  value?: string | null;
  snippet?: string | null;
};

const Result = ({ value, snippet }: ResultProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <GradientHeading
        text={t(
          "pages.contractEdit.modals.customField.prompt.messages.resultFound"
        )}
      />
      <div>
        {value && <Title>{value}</Title>}
        {snippet && <Content>{snippet}</Content>}
      </div>
    </Wrapper>
  );
};

export default Result;
