import { Wrapper, Arrow } from "./styles";

type ToggleButtonProps = {
  open: boolean;
  onClick: () => void;
};

const ToggleButton = ({ open, onClick }: ToggleButtonProps) => (
  <Wrapper onClick={onClick}>
    <Arrow className="chevronIcon" active={open} />
  </Wrapper>
);

export default ToggleButton;
