import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Grid, Button, IconButton, Tooltip } from "@mui/material";
import {
  ContactCreateDTO,
  ContactDTO,
  ContactFieldTypeDTOV1,
  OrganizationService,
} from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { DataPointProps } from "./types";
import { getContactFieldValue, getContactOptions } from "./helper";
import { routePaths } from "../../constants";
import AutoCompleteSelect, {
  Option,
} from "components/FormItems/AutoCompleteSelect/AutoCompleteSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NewModal } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { Add } from "@mui/icons-material";
import { ContactData } from "pages/Contact/components";
import { useTeam } from "contexts/team/hooks";
import ContactsIcon from "@mui/icons-material/Contacts";
import { ContactDataDTO } from "../../pages/Contacts/ContactDataDTO";
import { EditIcon } from "pages/Settings/OrganizationSettings/Organization/components/TeamRoles/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useUpdateContractMutation } from "shared/api/contracts";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TitleButton,
  ActionButtons,
  ButtonWithIcon,
} from "./styles";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";
import { DatapointField } from "./components/DatapointField";
import { DatapointFieldInputAdornment } from "./components/DatapointFieldInputAdornment";

interface ContactFormType {
  fields: Record<string, ContactDataDTO>;
}

export const ContactDatapoint = ({
  definition,
  values,
  editable,
  isLoading,
  fetchData,
  contactTypes,
  contactDefinitions,
  contacts,
  refetchContacts,
  categoryId,
  contractId,
  teamId,
}: DataPointProps) => {
  const { locale } = useLocale();
  const methods = useFormContext<ContactFormType>();
  const name = definition.name[locale];
  const { t } = useTranslation();
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { selectedTeam } = useTeam();
  const [preselectedName, setPreselectedName] = useState("");
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { mutateAsync: updateContract } = useUpdateContractMutation();

  const { selectedTeamId, hasWriteAccess } = useTeam();

  const data = values[definition.id] as ContactFieldTypeDTOV1 | undefined;

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  const organizationId = (selectedTeam?.parentTeamId ||
    selectedTeam?.id) as string;

  if (!contacts || !contactTypes) return <></>;
  const options = getContactOptions(contacts).sort((a, b) =>
    a.value.localeCompare(b.value)
  );

  const dataPointValueId = data?.value;
  const contactData = contacts.find(
    (contact) => contact.id === dataPointValueId
  );
  const displayValue = contactData ? getContactFieldValue(contactData) : "";
  const contactUrl = `${routePaths.CONTACTS}/${contactData?.id as string}`;

  const defaultValue: Option = {
    key: dataPointValueId ?? "",
    value: displayValue,
  };

  if (isLoading) return null;

  const createContactThroughContract = async (contact: {
    organizationId: string;
    requestBody: ContactCreateDTO;
  }): Promise<ContactDTO> => {
    const contract = await updateContract({
      teamId: teamId,
      contractId: contractId,
      requestBody: {
        fields: {
          [definition.id]: {
            value: null,
            $create: contact.requestBody,
          } as ContactFieldTypeDTOV1,
        },
      },
    });

    await refetchContacts();

    //only fetch for non-editable datapoint, otherwise we override the edit form data
    if (!editable) {
      void fetchData?.();
    }
    return OrganizationService.getContact(
      (contract.fields[definition.id] as ContactFieldTypeDTOV1).value as string,
      organizationId,
      selectedTeam?.id as string
    );
  };

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <Controller
          name={`fields.${definition.id}.value`}
          control={methods.control}
          render={({ field }) => (
            <>
              <AutoCompleteSelect
                {...field}
                defaultValue={defaultValue}
                dataCy="contactsAutocompleteList"
                id="contactsAutocompleteList"
                options={options}
                onSelect={(data) => {
                  data ? field.onChange(data.key) : field.onChange(null);
                }}
                label={definition.name[locale]}
                startAdornment={
                  <DatapointFieldInputAdornment
                    definition={definition}
                    fieldKey="value"
                    suggestions={suggestions}
                    data={values[definition.id]}
                    setValue={async (name, value, opts) => {
                      let contactId = options.find(
                        (option) => option.value === value
                      )?.key;
                      if (!contactId) {
                        const contact = await createContactThroughContract({
                          organizationId: organizationId,
                          requestBody: {
                            typeId: contactTypes[0].id,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            name: {
                              value: {
                                value: value,
                              },
                            },
                          },
                        });
                        contactId = contact.id;
                      }
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      methods.setValue(name, contactId, opts);
                    }}
                  />
                }
                paperComponent={
                  <Button
                    className={styles.navBlueButton}
                    style={{ margin: 0 }}
                    fullWidth
                  >
                    <span>{t("pages.contacts.header.newContact")}</span>
                    <Add fontSize="small" />
                  </Button>
                }
                onPapperClick={(value: string) => {
                  setShowModal(true);
                  setPreselectedName(value);
                }}
                clearable={true}
              />
            </>
          )}
        />

        <NewModal
          fullWidth
          open={showModal}
          handleClose={() => setShowModal(false)}
          icon={<ContactsIcon fontSize="small" />}
          title={t("pages.contacts.header.newContact")}
          body={
            <ContactData
              getCreatedContactId={(contactId) => {
                methods.setValue(`fields.${definition.id}.value`, contactId, {
                  shouldDirty: true,
                });
              }}
              externalCreateContact={createContactThroughContract}
              contactId="draft"
              contactTypes={contactTypes ?? []}
              contactDefinitions={contactDefinitions ?? []}
              isEditable={editable}
              setIsEditable={() => ({})}
              onCreateRedirect={false}
              handleModalClose={() => setShowModal(false)}
              preselectedName={preselectedName}
            />
          }
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={12} md={12}>
      {contactData?.id ? (
        <DatapointField
          definition={definition}
          data={data}
          contacts={contacts}
          field="value"
          suggestions={suggestions}
          onVerify={async () => {
            await verify(["value"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            const contactId = options.find(
              (option) => option.value === value
            )?.key;
            if (contactId) {
              await selectInsight("value", contactId);
            } else {
              await createContactThroughContract({
                organizationId: organizationId,

                requestBody: {
                  typeId: contactTypes[0].id,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name: {
                    value: {
                      value: value,
                    },
                  },
                },
              });
            }
            void fetchData?.();
          }}
          name={name}
        >
          <Accordion
            disableGutters
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary>
              <TitleButton>
                <span>{displayValue}</span>
              </TitleButton>
              <ActionButtons>
                <Tooltip
                  title={
                    expanded
                      ? t(
                          "pages.contractDetails.modals.editContact.tooltips.collapse"
                        )
                      : t(
                          "pages.contractDetails.modals.editContact.tooltips.expand"
                        )
                  }
                  placement="top"
                  arrow
                  disableInteractive
                >
                  <IconButton
                    className="edit arrow"
                    sx={{ transform: `rotate(${expanded ? "180deg" : "0"})` }}
                  >
                    <ExpandCircleDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t(
                    "pages.contractDetails.modals.editContact.tooltips.goto"
                  )}
                  placement="top"
                  arrow
                  disableInteractive
                >
                  <IconButton
                    className="edit"
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(contactUrl);
                    }}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                {hasWriteAccess(categoryId, selectedTeamId) && (
                  <Tooltip
                    title={t(
                      "pages.contractDetails.modals.editContact.tooltips.edit"
                    )}
                    placement="top"
                    arrow
                    disableInteractive
                  >
                    <IconButton
                      className="edit"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowModal(true);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </ActionButtons>
            </AccordionSummary>
            <AccordionDetails>
              <ContactData
                getCreatedContactId={(contactId) => {
                  methods.setValue(`fields.${definition.id}.value`, contactId, {
                    shouldDirty: true,
                  });
                }}
                contactId={contactData?.id}
                contactTypes={contactTypes ?? []}
                contactDefinitions={contactDefinitions ?? []}
                isEditable={false}
                setIsEditable={() => ({})}
                onCreateRedirect={false}
                handleModalClose={() => setShowModal(false)}
                isCardNested
                hideTitle
              />
              <ButtonWithIcon type="button" onClick={() => setExpanded(false)}>
                <ExpandCircleDownIcon
                  fontSize="small"
                  sx={{ transform: "rotate(180deg)" }}
                />
                <span>
                  {t(
                    "pages.contractDetails.modals.editContact.tooltips.collapse"
                  )}
                </span>
              </ButtonWithIcon>
            </AccordionDetails>
          </Accordion>
          <NewModal
            fullWidth
            open={showModal}
            handleClose={() => setShowModal(false)}
            icon={<ContactsIcon fontSize="small" />}
            title={t("pages.contractDetails.modals.editContact.title")}
            body={
              <ContactData
                getCreatedContactId={(contactId) => {
                  methods.setValue(`fields.${definition.id}.value`, contactId, {
                    shouldDirty: true,
                  });
                }}
                contactId={contactData?.id}
                contactTypes={contactTypes ?? []}
                contactDefinitions={contactDefinitions ?? []}
                isEditable={true}
                externalCreateContact={createContactThroughContract}
                setIsEditable={() => ({})}
                onCreateRedirect={false}
                handleModalClose={() => setShowModal(false)}
                onSaveCallback={() => setShowModal(false)}
                displayFormMessage
                displayFormButtons
              />
            }
          />
        </DatapointField>
      ) : (
        <DatapointField
          definition={definition}
          contacts={contacts}
          data={data}
          field="value"
          suggestions={suggestions}
          onVerify={async () => {
            await verify(["value"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            const contactId = options.find(
              (option) => option.value === value
            )?.key;
            if (contactId) {
              await selectInsight("value", contactId);
            } else {
              await createContactThroughContract({
                organizationId: organizationId,
                requestBody: {
                  typeId: contactTypes[0].id,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  name: {
                    value: {
                      value: value,
                    },
                  },
                },
              });
            }

            void fetchData?.();
          }}
          name={name}
        >
          {displayValue || "-"}
        </DatapointField>
      )}
    </Grid>
  );
};
