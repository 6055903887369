import { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ProfileDto } from "openapi";
import { setValidationErrors } from "shared/service/errorResponseService";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTextField } from "components";
import { useStyles } from "components/StyledComponents/StyledBaseButtons";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { Language } from "shared/i18n/i18n";
import { useSnackbar } from "notistack";
import { dateFormats, nonFreeEmailVaidator } from "constants/utils";
import { Tag } from "new-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useProfileQuery,
  useProfileUpdateMutation,
  useVerifyEmailMutation,
} from "shared/api/profile";
import { EmailContainer } from "./styles";
import { useLocale } from "../../../../hooks";

const validationSchema = () =>
  Yup.object({
    email: nonFreeEmailVaidator(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    phone: Yup.string().nullable(),
  });

const initialValues: ProfileDto = {
  email: "",
  firstname: "",
  lastname: "",
  phone: null,
  locale: ProfileDto.locale.DE,
  dateFormat: dateFormats.EU_DOTS,
};

const TRANSLATION_KEY = "pages.settings.tabs.profile";

const ProfileForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { changeLocale } = useLocale();
  const [searchParams] = useSearchParams();
  const emailVerificationToken = new URLSearchParams(searchParams).get(
    "emailVerificationToken"
  );
  const navigate = useNavigate();

  const { data } = useProfileQuery();
  const { mutateAsync: updateProfile } = useProfileUpdateMutation();
  const { mutateAsync: verifyEmail } = useVerifyEmailMutation();

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    void handleVerifyEmail();
  }, []);

  useEffect(() => {
    if (data) {
      const formData = {
        ...data,
        email: data.newEmail || data.email,
      };
      reset(formData);
    }
  }, [data]);

  const handleVerifyEmail = async () => {
    if (!emailVerificationToken) {
      return;
    }
    try {
      await verifyEmail(emailVerificationToken);

      enqueueSnackbar(t(`${TRANSLATION_KEY}.information.emailVerified`), {
        variant: "success",
      });
    } catch (e) {
      const error = e as {
        body: {
          error: string;
        };
      };
      if (error.body.error === "InvalidEmailVerificationTokenException") {
        enqueueSnackbar(
          t(
            `${TRANSLATION_KEY}.information.InvalidEmailVerificationTokenException`
          ),
          { variant: "warning" }
        );
      } else {
        enqueueSnackbar(t(`${TRANSLATION_KEY}.information.unexpectedError`), {
          variant: "error",
        });
      }
    } finally {
      searchParams.delete("emailVerificationToken");
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await updateProfile(values);

      if (data?.email !== values.email && data?.newEmail !== values.email) {
        enqueueSnackbar(
          t(`${TRANSLATION_KEY}.information.emailVerificationEmailSent`),
          { variant: "info" }
        );
      }
      if (data?.locale !== values.locale) {
        await changeLocale(values.locale as unknown as Language);
      }
      enqueueSnackbar(t(`${TRANSLATION_KEY}.information.profileUpdated`), {
        variant: "success",
      });
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        TRANSLATION_KEY,
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  return (
    <>
      <form
        name="profileForm"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{
          margin: "1rem 0",
          maxWidth: "36rem",
        }}
      >
        <EmailContainer isVerificationPending={!!data?.newEmail}>
          <FormTextField
            control={control}
            name="email"
            label={t(`${TRANSLATION_KEY}.form.emailAddress`)}
            type="email"
            required
          />
          {!!data?.newEmail && (
            <Tag variant="pending">
              {t(`${TRANSLATION_KEY}.information.pendingVerification`)}
            </Tag>
          )}
        </EmailContainer>
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="firstname"
              label={t(`${TRANSLATION_KEY}.form.firstName`)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              control={control}
              name="lastname"
              label={t(`${TRANSLATION_KEY}.form.lastName`)}
              required
            />
          </Grid>
        </Grid>
        <Grid>
          <FormTextField
            control={control}
            name="phone"
            label={t(`${TRANSLATION_KEY}.form.phone`)}
            type="tel"
            required
          />
        </Grid>
        <Grid container spacing={1.25}>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="locale"
              options={Object.keys(Language)}
              translationPrefix={"common.languages"}
              label={t(`${TRANSLATION_KEY}.form.language`)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              control={control}
              name="dateFormat"
              options={Object.values(dateFormats)}
              label={t(`${TRANSLATION_KEY}.form.dateFormat`)}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            id="updateBtn"
            type="submit"
            size="large"
            className={classes.baseButton}
            fullWidth
          >
            {t(`${TRANSLATION_KEY}.form.submit`)}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ProfileForm;
