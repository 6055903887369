import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextFieldProps } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { DatePicker } from "components/DatePicker/DatePicker";
import { DatePickerProps } from "@mui/x-date-pickers";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export type FormDatePickerFieldProps<TControl extends FieldValues> = Omit<
  DatePickerProps<Dayjs>,
  "value" | "onChange" | "renderInput"
> & {
  name: Path<TControl>;
  control?: Control<TControl>;
  inputProps?: TextFieldProps & { suggestion?: boolean };
  margin?: "none" | "dense" | "normal";
  disablePast?: boolean;
};

export default function FormDatePickerField<TControl extends FieldValues>({
  name,
  inputProps,
  control,
  margin,
  disablePast,
  ...rest
}: FormDatePickerFieldProps<TControl>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => {
        return (
          <DatePicker
            value={value}
            onDateChanged={(date) => {
              onChange(date);
            }}
            inputProps={{
              ...inputProps,
              helperText: t(error?.message as string),
              error: invalid,
            }}
            datePickerProps={rest}
            disablePast={disablePast}
          />
        );
      }}
    />
  );
}
