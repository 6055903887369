import { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  ContractAttachmentListItemDTO,
  ContractAttachmentService,
  ContractDTOV1,
  ContractNameDto,
  OrganizationService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import VerifyAllBanner from "./components/VerifyAllBanner/VerifyAllBanner";
import { Features } from "constants/";
import { RightSide } from "./components/RightSide/RightSide";
import { PrintProvider } from "contexts/contract/context";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useFieldsQuery } from "shared/api/fields";
import CircularLoading from "components/CircularLoading/CircularLoading";
import ContractData from "./components/ContractData/ContractData";
import { Header, Toolbar } from "./components";
import { ContractGrid, ContractColumn } from "./styles";
import { PDFViewerActionsProvider } from "components/PDFViewer/PDFViewerActionContext";
import { useContractQuery } from "shared/api";
import { ContractDetailsProvider, viewModeType } from "./context";
import { AcceptedFileType } from "shared/enums/document.enum";
import { FormProvider, useForm } from "react-hook-form";
import { Loader } from "components";
import {
  useDownloadDocumentQuery,
  useFetchDocumentQuery,
} from "shared/api/documents";

const Contract = () => {
  const {
    permissionSet,
    setSelectedTeam,
    selectedTeamId,
    organizationId,
    hasFeature,
  } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);
  const { id: contractId, mode: routeParamMode, documentId } = useParams();
  const [searchParams] = useSearchParams();
  const modeAsSearchParam = searchParams.get("mode");
  const mode =
    routeParamMode === viewModeType.EDIT ||
    modeAsSearchParam === viewModeType.EDIT
      ? viewModeType.EDIT
      : viewModeType.VIEW;

  const [contractGroup, setContractGroup] = useState<
    ContractNameDto[] | ContractAttachmentListItemDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: contract, refetch } = useContractQuery(
    selectedTeamId,
    contractId
  );
  const [isContractDataFetching, setIsContractDataFetching] = useState(false);
  const printRef = useRef(null);

  const { data: contractDocument } = useFetchDocumentQuery(
    organizationId,
    contract?.teamId,
    contract?.id,
    documentId
  );
  const { data: contractFileBlob } = useDownloadDocumentQuery(
    organizationId,
    contract?.teamId,
    contract?.id,
    contractDocument?.id
  );

  const editable = mode === "edit";

  const category = useMemo(
    () => categories?.find((category) => category.id === contract?.categoryId),
    [categories, contract]
  );

  const methods = useForm<ContractDTOV1>({
    defaultValues: { ...contract, fields: {} },
  });

  const fetchData = async () => {
    await refetch();
    await fetchContractGroup();
  };

  // TODO: Refactor to use React Query with `onSuccess` for invalidating queries when needed.
  //       - Link each request to a specific query key that can be invalidated.
  //       - Use `invalidateQueries` in the `onSuccess` callback to trigger refetches only when required.
  //       - Avoid passing `fetchData` or `refetch` as props; keep the logic local to this component.
  const fetchContractGroup = async () => {
    if (!contract) {
      return;
    }
    try {
      setIsContractDataFetching(true);
      setIsLoading(false);
      const updatedContract = await OrganizationService.getContractOfTeamById(
        selectedTeamId,
        contract.id
      );
      const contractGroup = await ContractAttachmentService.getContractsOfGroup(
        updatedContract.parentId ?? updatedContract.id
      );
      setContractGroup(contractGroup);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsContractDataFetching(false);
    }
  };

  useEffect(() => {
    void fetchContractGroup();
  }, [contract?.id]);

  useEffect(() => {
    if (contract && !permissionSet?.[contract.teamId]) {
      void setSelectedTeam(contract.teamId);
    }
  }, [contract]);

  if (isLoading) return <Loader />;

  return (
    <ContractDetailsProvider>
      <PrintProvider title={contract?.name}>
        <div>
          <CircularLoading isLoading={isLoading} />
          {contract && category && fields && (
            <>
              <Header editable={editable}>
                <Toolbar
                  contract={contract}
                  fetchData={fetchData}
                  contractGroup={
                    contractGroup as ContractAttachmentListItemDTO[]
                  }
                  contractFile={contractFileBlob}
                  firstDocumentId={contractDocument?.id}
                  editable={editable}
                />
              </Header>
              <PDFViewerActionsProvider>
                <FormProvider {...methods}>
                  <ContractGrid>
                    <ContractColumn>
                      {hasFeature(Features.CONTRACT_ANALYSIS) && (
                        <VerifyAllBanner
                          contract={contract}
                          refetch={fetchData}
                        />
                      )}
                      <OverlayScrollbarsComponent
                        defer
                        style={{ maxHeight: "100%" }}
                      >
                        <ContractData
                          isLoading={isLoading}
                          isCategoryDisabled={
                            contractFileBlob?.type === AcceptedFileType.LEXICAL
                          }
                          contract={contract}
                          isContractDataFetching={isContractDataFetching}
                          category={category}
                          fields={fields}
                          fetchData={fetchData}
                          contractGroup={contractGroup}
                          ref={printRef}
                        />
                      </OverlayScrollbarsComponent>
                    </ContractColumn>
                    <ContractColumn>
                      <RightSide
                        fetchData={fetchData}
                        contract={contract}
                        contractGroup={
                          contractGroup as ContractAttachmentListItemDTO[]
                        }
                      />
                    </ContractColumn>
                  </ContractGrid>
                </FormProvider>
              </PDFViewerActionsProvider>
            </>
          )}
        </div>
      </PrintProvider>
    </ContractDetailsProvider>
  );
};

export default Contract;
