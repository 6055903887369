import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { DialogActions } from "@mui/material";
import {
  CommonService,
  ContractSectionDTOV1,
  I18nDto,
  TranslationDTO,
} from "openapi";
import { useLocale } from "hooks/GlobalStateHooks";
import { CTAButton, NewModal } from "components";
import { FormTextField } from "components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TranslationIcon from "assets/svg/translation-icon.svg?react";
import { ButtonWithIcon } from "pages/Settings/OrganizationSettings/Category/styles";
import { useSnackbar } from "notistack";

type Props = {
  open: boolean;
  onClose: () => void;
  section: ContractSectionDTOV1;
  sections: ContractSectionDTOV1[];
  setSections: (value: ContractSectionDTOV1[]) => void;
};

type EditSectionForm = {
  name: I18nDto;
};

const EditSection = ({
  open,
  onClose,
  section,
  sections,
  setSections,
}: Props) => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isTranslationLoading, setIsTranslationLoading] =
    useState<boolean>(false);
  const sourceLanguage = locale === "en" ? "en" : "de";
  const targetLanguage = locale === "en" ? "de" : "en";

  const validationSchema = () =>
    Yup.object().shape({
      name: Yup.object().shape({
        [sourceLanguage ?? targetLanguage]: Yup.string().required(),
      }),
    });

  const { control, handleSubmit, getValues, setValue, reset } =
    useForm<EditSectionForm>({
      resolver: yupResolver(validationSchema()),
    });

  useEffect(() => {
    reset({
      name: {
        de: section.name.de,
        en: section.name.en,
      },
    });
  }, [section.id]);

  const getTranslation = async () => {
    const data = getValues();

    if (!data.name || !data.name[sourceLanguage]) return;

    if (data.name[sourceLanguage] === "") {
      setValue(`name.${targetLanguage}`, "");
      return;
    }
    const requestBody = {
      text: data.name[locale],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
    };

    try {
      setIsTranslationLoading(true);
      const translatedName = await CommonService.translateText(
        requestBody as TranslationDTO
      );
      setValue(`name.${targetLanguage}`, translatedName.text);
    } catch (error) {
      enqueueSnackbar(t("pages.categoryDetails.messages.translationFailed"), {
        variant: "error",
      });
    } finally {
      setIsTranslationLoading(false);
    }
  };

  const onSubmit = () => {
    const data = getValues() as ContractSectionDTOV1;

    const updatedSection = {
      ...section,
      name: data.name,
    };

    const updatedSections = sections.map((item) => {
      if (item.id === updatedSection.id) {
        return updatedSection;
      }

      return item;
    });

    setSections(updatedSections);

    handleOnClose();
  };

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <NewModal
      open={open}
      handleClose={handleOnClose}
      title={t(
        "pages.settings.organization.administrator.sections.modal.edit.title"
      )}
      fullWidth
      body={
        <form
          id="sectionEditForm"
          name="sectionEditForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormTextField
            control={control}
            name={`name.${sourceLanguage}`}
            label={`${t(
              "pages.settings.tabs.customFields.form.customSectionName"
            )} (${t(`common.languages.${sourceLanguage}`)})`}
          />
          <FormTextField
            control={control}
            name={`name.${targetLanguage}`}
            label={`${t(
              "pages.settings.tabs.customFields.form.customSectionName"
            )} (${t(`common.languages.${targetLanguage}`)})`}
          />
          <ButtonWithIcon
            onClick={getTranslation}
            startIcon={<TranslationIcon />}
            loading={isTranslationLoading}
            loadingPosition="start"
            variant="contained"
          >
            <span>{t("pages.categoryDetails.buttons.translation")}</span>
          </ButtonWithIcon>
          <DialogActions>
            <CTAButton
              type="reset"
              variant="secondary"
              label="cancel"
              onClick={handleOnClose}
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              variant="primary"
              type="submit"
              label="updateSectionButton"
              name={t("common.buttons.update")}
            />
          </DialogActions>
        </form>
      }
    />
  );
};

export default EditSection;
