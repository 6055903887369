import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import CategoryItem from "./CategoryItem";
import { theme } from "theme";
import { Form, FormButtons, Table, Title } from "../styles";
import { t } from "i18next";
import {
  ApiError,
  AssignCategoryToTeamDTO,
  OrganizationNewService,
} from "openapi";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useTeam } from "contexts/team/hooks";
import { CTAButton } from "components";
import {
  useOrganizationCategoriesQuery,
  useOrganizationAllTeamsQuery,
} from "shared/api";
import { getDirtyValues } from "utils/forms";

export type CheckBoxesProps = Record<string, boolean>;

type Props = {
  hideButton?: boolean;
  setParentCheckboxes?: Dispatch<SetStateAction<CheckBoxesProps>>;
};

const PermissionsForm = ({ hideButton, setParentCheckboxes }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
    setError,
    getValues,
    setValue,
    resetField,
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { id: teamId } = useParams();
  const queryClient = useQueryClient();
  const { organizationId } = useTeam();

  const [allChecked, setAllChecked] = useState<boolean>(false);

  const { refetch: refetchTeams } =
    useOrganizationAllTeamsQuery(organizationId);
  const { data: categories, refetch: refetchCategories } =
    useOrganizationCategoriesQuery(organizationId);

  const refetch = () => {
    void refetchTeams();
    void refetchCategories();
  };

  useEffect(() => {
    getIntialState();
  }, [categories]);

  const form = useWatch({
    control: control,
  });

  useEffect(() => {
    setAllChecked(Object.values(getValues()).every((item) => item === true));
    if (setParentCheckboxes) {
      setParentCheckboxes(getValues());
    }
  }, [form]);

  const getIntialState = () => {
    if (!categories) return;
    const mappedCategories = categories?.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.teams.includes(teamId as string),
      };
    }, {});

    reset(mappedCategories);
  };

  const onAllChange = () => {
    const values = getValues();
    for (const key in values) {
      setValue(key, !allChecked, {
        shouldDirty: true,
      });
    }
  };

  const onCancel = () => {
    reset();
  };

  const onSubmit = async () => {
    const dirtyValues = getDirtyValues(dirtyFields, getValues());

    const addPromises: Promise<unknown>[] = [];
    const removePromises: Promise<unknown>[] = [];

    const handleError = (key: string, e: unknown) => {
      if (e instanceof ApiError) {
        setError(key, {
          type: "custom",
          message: `common.exceptions.${(e.body as { error: string })?.error}`,
        });
      }
      throw e;
    };

    for (const [key, value] of Object.entries(dirtyValues)) {
      if (value) {
        addPromises.push(
          assignCategory(key)
            .then(() => {
              resetField(key, {
                defaultValue: true,
              });
            })
            .catch((e) => {
              handleError(key, e);
            })
        );
      } else {
        removePromises.push(
          removeCategory(key)
            .then(() => {
              resetField(key, {
                defaultValue: false,
              });
            })
            .catch((e) => {
              handleError(key, e);
            })
        );
      }
    }

    try {
      await Promise.all(addPromises);
      await Promise.all(removePromises);
      void refetch();

      enqueueSnackbar(
        t("pages.settings.tabs.subTeams.messages.categoryAccessSuccess"),
        {
          variant: "success",
        }
      );

      void queryClient.invalidateQueries({
        queryKey: ["categories", organizationId],
      });
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.subTeams.messages.categoryAccessError"),
        {
          variant: "error",
        }
      );
    }
  };

  const assignCategory = async (id: string) => {
    const requestBody: AssignCategoryToTeamDTO = { categoryId: id };
    return OrganizationNewService.assignTeamToCategory(
      organizationId,
      teamId as string,
      requestBody
    );
  };

  const removeCategory = async (id: string) => {
    return OrganizationNewService.removeTeamFromCategory(
      organizationId,
      teamId as string,
      id
    );
  };

  return (
    <Form
      style={{ marginTop: theme.spacing.xl }}
      onSubmit={handleSubmit(() => onSubmit())}
    >
      <Table>
        <Title>
          {t("pages.settings.tabs.subTeams.section.showSubTeams.categories")}
        </Title>
        <Title>
          <FormControlLabel
            control={
              <Checkbox
                onChange={onAllChange}
                indeterminate={
                  !allChecked &&
                  Object.values(getValues()).some((item) => !!item)
                }
                name="all"
                checked={allChecked}
              />
            }
            label={t(
              "pages.settings.tabs.subTeams.section.showSubTeams.accessAll"
            )}
          />
        </Title>

        {categories?.map((item) => (
          <CategoryItem
            key={item.id}
            name={item.id}
            category={item}
            control={control}
          />
        ))}
      </Table>
      {!hideButton && (
        <FormButtons>
          <CTAButton
            name={t(
              "pages.settings.tabs.team.teamNameForm.common.buttons.cancel"
            )}
            type="reset"
            variant="secondary"
            onClick={onCancel}
            disabled={!isDirty}
          />
          <CTAButton
            name={t(
              "pages.settings.tabs.team.teamNameForm.common.buttons.save"
            )}
            type="submit"
            variant="primary"
            label="category-name-save-button"
            disabled={!isDirty}
            aria-label="update-category-access-button"
          />
        </FormButtons>
      )}
    </Form>
  );
};

export default PermissionsForm;
