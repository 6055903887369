import { Box, Grid, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategorySelector } from "./CategorySelector";
import { CategoryDTO } from "openapi";
import ClosedFolder from "../../assets/svg/folder-closed.svg?react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FilenameLabel, PaddedGrid } from "./styles";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { theme } from "theme";

export enum ContractUploadItemType {
  MAIN_CONTRACT,
  ATTACHMENT,
  SELECTABLE,
}

export type ContractFile = {
  id: string;
  file: File;
  categoryId: string;
  errorMessage?: string;
  isUploaded?: boolean;
};

type ContractUploadProps = {
  type: ContractUploadItemType;
  selected?: boolean;
  disabled?: boolean;
  categories?: CategoryDTO[];
  contractFile: ContractFile;
  onSelect?: (idx: string) => void;
  onChangeCategory?: (id: string, categoryId: string) => void;
  onDelete(id: string): void;
};

export const ContractUploadItem = ({
  type,
  contractFile,
  selected = false,
  disabled = false,
  categories,
  onSelect,
  onDelete,
  onChangeCategory,
}: ContractUploadProps) => {
  const { t } = useTranslation();
  const { id, file, categoryId } = contractFile;

  const getFlexibleAnnotation = () => {
    switch (type) {
      case ContractUploadItemType.MAIN_CONTRACT:
        return t("contractUpload.mainContract");
      case ContractUploadItemType.ATTACHMENT:
        return t("contractUpload.attachmentContract");
      case ContractUploadItemType.SELECTABLE:
        return selected
          ? t("contractUpload.mainContract")
          : t("contractUpload.attachmentContract");
    }
  };

  if (!categories) {
    return <CircularLoading isLoading />;
  }

  return (
    <PaddedGrid container spacing={2} alignItems="center">
      <Grid item xs={2} className="flex-center">
        {type === ContractUploadItemType.MAIN_CONTRACT ||
        (type === ContractUploadItemType.SELECTABLE && selected) ? (
          <ClosedFolder />
        ) : (
          <AttachFileIcon fontSize="large" />
        )}
      </Grid>
      <Grid item xs={onChangeCategory ? 4 : 9}>
        <Box className="flex-column">
          <FilenameLabel>{file.name}</FilenameLabel>
          <Box className="flex-align-center">
            {type === ContractUploadItemType.SELECTABLE && (
              <Radio
                checked={selected}
                onChange={() => onSelect && onSelect(id)}
                value={selected.toString()}
                name={`main-contract-radio-${id}`}
                id={`main-contract-radio-${id}`}
                disabled={disabled}
              />
            )}
            <Box>
              <label htmlFor={`main-contract-radio-${id}`}>
                {getFlexibleAnnotation()}
              </label>
            </Box>
          </Box>
        </Box>
      </Grid>
      {onChangeCategory && (
        <Grid item xs={5}>
          <CategorySelector
            selectedCategoryId={categoryId}
            categories={categories}
            disabled={disabled}
            onSelectCategory={(categoryId) =>
              onChangeCategory && onChangeCategory(id, categoryId)
            }
          />
        </Grid>
      )}
      {!disabled && (
        <Grid item xs={1}>
          <Box className="flex-center">
            <DeleteForeverIcon
              id="deleteButton"
              fontSize="small"
              sx={{
                color: theme.color.red[700],
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => onDelete(id)}
            />
          </Box>
        </Grid>
      )}
    </PaddedGrid>
  );
};
